import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import * as StackTrace from 'stacktrace-js';

@Injectable({
    providedIn: 'root'
})
export class ErrorService {

    getClientMessage(error: Error): string {
        if (!navigator.onLine) {
            error.message = 'No Internet Connection';
        }
        return error.message ? error.message : error.toString();
    }

    getClientStack(error: any): string {
        return 'CLIENT: ' + error.stack;
    }

    getServerMessage(error: HttpErrorResponse): string {
        return(error.message) ? error.message : 'Unknown';
    }

    getServerStack(error: any): string {
        // handle stack trace
        return 'SERVER: ' + (error.stack) ? error.stack : (error.message) ? error.message : 'Unknown';
    }

    getJSStackTrace(error: Error) {
      let stackString: string;
      return StackTrace.fromError(error).then((stackframes: any) => {
        stackString = stackframes
          .splice(0, 20)
          .map((sf: any) => {
            return sf.toString();
          }).join('\n');

          return stackString;
      });
    }
}
