import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [

  { path: 'requests', loadChildren: './pages/requests/requests.module#RequestsPageModule' },
  { path: 'login', loadChildren: './pages/login/login.module#LoginPageModule' },
  { path: 'manage', loadChildren: './pages/manage/manage.module#ManagePageModule' },
  { path: 'operator', loadChildren: './pages/operator/operator.module#OperatorPageModule' },
  { path: 'reports', loadChildren: './pages/reports/reports.module#ReportsPageModule' },
  { path: 'debug', loadChildren: './pages/debug/debug.module#DebugPageModule' },
  { path: '**', redirectTo: 'login' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
