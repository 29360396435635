// declare const Pusher: any;

// const Pusher = require('pusher-js');
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import Pusher from 'pusher-js';

@Injectable({
  providedIn: 'root'
})
export class PusherService {
  private env = environment.ENVIRONMENT;
  public pusher: any = null;
  public waiterChannel: any = null;
  public venueChannel: any = null;
  public pagersChannel: any = null;
  public deviceChannel: any = null;
  public requestsChannel: any = null;
  public systemChannel: any = null;

  constructor() {}

  connectToServer(waiterId: number, venueId: number, deviceId: string) {

    this.pusher = new Pusher(environment.pusher.key, {
      cluster: environment.pusher.cluster,
      forceTLS: true
    });

    this.pusher.log = (message: any) => {
      if (window.console && window.console.log) {
        window.console.log(message);
      }
    };

    this.waiterChannel = this.pusher.subscribe(this.env + '-waiter-' + waiterId);
    this.venueChannel = this.pusher.subscribe(this.env + '-restaurant-' + venueId);
    // NEW
    this.pagersChannel = this.pusher.subscribe(this.env + '-venue-' + venueId + '.pagers');
    this.requestsChannel = this.pusher.subscribe(this.env + '-venue-' + venueId + '.requests');
    this.deviceChannel = this.pusher.subscribe(this.env + '-device-' + deviceId + '.system');
    this.systemChannel = this.pusher.subscribe(this.env + '-venue-' + venueId + '.system');

    console.log(this.pusher);
  }

  logout() {
    if (this.pusher != null) {
      this.pusher.disconnect();
      this.waiterChannel = null;
      this.venueChannel = null;
      this.pagersChannel = null;
      this.deviceChannel = null;
      this.requestsChannel = null;
      this.systemChannel = null;
    }
  }

  bindWaiter(eventName: any, callback: any) {
    this.waiterChannel.bind(eventName, callback);
  }

  unbindWaiter(eventName: any, callback: any) {
    this.waiterChannel.unbind(eventName, callback);
  }

  bindVenue(eventName: any, callback: any) {
    if (this.venueChannel !== null) {
      this.venueChannel.bind(eventName, callback);
    }
  }

  unbindVenue(eventName: any, callback: any) {
    this.venueChannel.unbind(eventName, callback);
  }

  bindPagers(eventName: any, callback: any) {
    if (this.pagersChannel !== null) {
      this.pagersChannel.bind(eventName, callback);
    }
  }

  unbindPagers(eventName: any, callback: any) {
    this.pagersChannel.unbind(eventName, callback);
  }

  bindDevice(eventName: any, callback: any) {
    if (this.deviceChannel !== null) {
      this.deviceChannel.bind(eventName, callback);
    }
  }

  unbindDevice(eventName: any, callback: any) {
    this.deviceChannel.unbind(eventName, callback);
  }

  bindRequests(eventName: any, callback: any) {
    if (this.requestsChannel !== null) {
      this.requestsChannel.bind(eventName, callback);
    }
  }

  unbindRequests(eventName: any, callback: any) {
    this.requestsChannel.unbind(eventName, callback);
  }

  bindSystem(eventName: any, callback: any) {
    if (this.systemChannel !== null) {
      this.systemChannel.bind(eventName, callback);
    }
  }

  unbindSystem(eventName: any, callback: any) {
    this.systemChannel.unbind(eventName, callback);
  }

  isLoaded() {
    return !!this.pusher;
  }
}
