import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AlertController, Platform, MenuController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Insomnia } from '@ionic-native/insomnia/ngx';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { NetworkInterface } from '@ionic-native/network-interface/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { Network } from '@ionic-native/network/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment.prod';
import {
  LocaleService,
  TranslationService
} from '@serviworldwide/core-js/modules/locale';
import {
  AuthService,
  BackgroundModeService,
  DataService,
  DeviceService,
  LocalStorageService,
  PusherService,
  StateService,
  VenueService,
  WaitersService
} from '@services/index';
import { Device } from '@ionic-native/device/ngx';

declare var gtag: any;
declare var window: any;
declare var WifiWizard2: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit {
  public appPages: any;
  public notPluggedAlert: any;
  public installMsg: any;
  public alertShown = false;
  public appVersion = environment.VERSION;
  public countDownTime = 15;
  public burndown: any;
  public viewUpdatesModal: any;
  public venueName: string;
  public waiterName: string;
  public updateUrl: string;
  public hbInterval: any;
  public isOnlineInterval: any;
  public updateAlert: any;
  public today = new Date();
  public v: any;

  constructor(
    private platform: Platform,
    private device: Device,
    private splashScreen: SplashScreen,
    private insomnia: Insomnia,
    private keyboard: Keyboard,
    private networkInterface: NetworkInterface,
    private statusBar: StatusBar,
    private router: Router,
    private alert: AlertController,
    private geolocation: Geolocation,
    private menuCtrl: MenuController,
    private deviceSvc: DeviceService,
    private venueSvc: VenueService,
    private waiterSvc: WaitersService,
    private authSvc: AuthService,
    private dataSvc: DataService,
    private bgModeSvc: BackgroundModeService,
    private pusher: PusherService,
    private iab: InAppBrowser,
    private translationSvc: TranslationService,
    private localeSvc: LocaleService,
    private network: Network,
    private state: StateService,
    private localStorageSvc: LocalStorageService
  ) {
    this.localeSvc.setDefaultLanguage('en');
    this.localeSvc.load();

    // Next 3 lines are hardcoded, but they should be decided by the venue data/country/settings languages
    this.localeSvc.setLocale('US');
    this.localeSvc.setLanguages(['en', 'nl']);
    this.localeSvc.setUserLanguage('en');

    // Split languages from venueSettings
    // const languages = venue.venueSettings.LANGUAGES || '').replace(/\s/g,'').toLowerCase().split('|')
    // this.localeSvc.setLanguages(languages);

    this.hbInterval = false;
    this.isOnlineInterval = false;

    this.appPages = [
      { title: 'Main Page', url: '/requests', icon: 'fa-home' },
      { title: 'Manage', url: '/manage', icon: 'fa-cog' },
      { title: 'Reports', url: '/reports', icon: 'fa-print' },
      { title: 'Restart', url: '/login?restart=true', icon: 'fa-refresh' },
      { title: 'Logout', url: '/login?logout=true', icon: 'fa-sign-out' }
    //  { title: 'Debug', url: '/debug', icon: 'fa-bug' }
    ];
    this.updateUrl = 'https://app.ser.vi';

    this.initializeApp();
  }

  async showUpdateAlert(version: string) {
    this.updateAlert = await this.alert.create({
      // mode: 'ios', // by default you will get the button in center
      header: 'A Required Update is Available!',
      backdropDismiss: false,
      message:
        'A new Staff App version ' +
        version +
        ' is available.  Press OK to be redirected to the Play Store where you can download and install the update.',
      buttons: [
        {
          text: 'OK',
          handler: () => {
            window.open(
              'https://play.google.com/store/apps/details?id=vi.ser&hl=en',
              '_system'
            );
          }
        }
      ]
    });

    await this.updateAlert.present();
  }

  async showOptionalUpdateAlert(version: string) {
    this.updateAlert = await this.alert.create({
      // mode: 'ios', // by default you will get the button in center
      header: 'A Recommended Update is Available!',
      backdropDismiss: false,
      message:
        'A new Staff App version ' +
        version +
        ' is available.  Press OK to be redirected to the Play Store where you can download and install the update.',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            // console.log('Confirm Cancel');
          }
        },
        {
          text: 'OK',
          handler: () => {
            window.open(
              'https://play.google.com/store/apps/details?id=vi.ser&hl=en',
              '_system'
            );
          }
        }
      ]
    });

    await this.updateAlert.present();
  }

  async logoutConfirm(mode) {
    const alert = await this.alert.create({
      header: mode === 'restart' ? 'Restart Confirm!' : 'Logout Confirm!',
      message: 'Do you really want to ' + mode + '?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel'
        },
        {
          text: 'Confirm',
          handler: () => {
            // this.authSvc.logout().subscribe((res: any) => {
            if (mode === 'restart') {
              this.restartApp();
            } else {
              this.authSvc.logout().subscribe(() => {
                this.router.navigateByUrl('login?logout=true');
              });
            }
            // });
          }
        }
      ]
    });

    await alert.present();
  }

  async showInstallMsg() {
    if (this.device.platform === 'Android') {
      if (Number(this.device.version) >= 10) {
        const msg =
          this.device.version === '10'
            ? 'Please visit Settings > Apps & notifications > See All Apps > Servi Staff > Advanced and allow “Display over other apps”'
            : 'Please visit Settings > Apps > Servi Staff and allow “Appear on top”';
        this.installMsg = await this.alert.create({
          header: 'Additional Settings Required!',
          message: msg,
          buttons: ['OK']
        });

        await this.installMsg.present();
      }
    }
  }

  async showNotPluggedAlert() {
    this.notPluggedAlert = await this.alert.create({
      // mode: 'ios', // by default you will get the button in center
      header: 'Device Not Plugged In!',
      message: 'Please connect to a power source.',
      buttons: ['OK']
    });

    await this.notPluggedAlert.present();
  }

  ngOnInit() {
    this.authSvc.initialized.subscribe((response) => {
      if (response.status) {
        console.log('INIT', this.state.venue.restaurantName);

        this.venueName = this.state.venue.restaurantName || 'MENU';
        if (this.venueName.indexOf('|') !== -1) {
          this.venueName = this.translationSvc.translateContent(this.venueName);
          // this.venueName = this.venueName.split('|')[0];
        }
        this.waiterName = this.waiterSvc.waiter.name || 'Unknown';

        if (this.state.venue.type === 'professional') {
          this.appPages = [
            { title: 'Main Page', url: '/operator', icon: 'fa-home' },
            { title: 'Manage', url: '/manage', icon: 'fa-cog' },
            { title: 'Reports', url: '/reports', icon: 'fa-print' },
            {
              title: 'Restart',
              url: '/login?restart=true',
              icon: 'fa-refresh'
            },
            { title: 'Logout', url: '/login?logout=true', icon: 'fa-sign-out' }
          ];
        }

        this.venueSvc.checkForUpdates().subscribe((updateResponse) => {
          if (updateResponse.success) {
            if (updateResponse.result.hasUpdate) {
              if (updateResponse.result.required) {
                this.showUpdateAlert(updateResponse.result.version);
              } else {
                this.showOptionalUpdateAlert(updateResponse.result.version);
              }
            }
          }
          console.log('check-for-updates', updateResponse);
        });
      }
    });
  }

  needsAndroidPadding () {
    return this.venueSvc.useAndroidPadding;
  }

  showUpdates() {
    if (!!window.cordova) {
      const browser = this.iab.create(
        'https://app.ser.vi',
        '_system',
        'location=yes'
      );
      browser.show();
    }
  }

  goToPage(page: string) {
    if (page === '/login?logout=true') {
      this.logoutConfirm('logout');
    } else if (page === '/login?restart=true') {
      this.logoutConfirm('restart');
    } else {
      this.router.navigateByUrl(page);
    }
  }

  switchLanguage(language: string) {
    this.localeSvc.setUserLanguage(language);
  }

  menuClosed() {
    this.stopTimer();
    this.resetTimer();
  }

  menuOpened() {
    this.startTimer();
  }

  startTimer() {
    this.burndown = setInterval(
      () => {
        this.countDownTime--;
        if (this.countDownTime === 0) {
          this.menuCtrl.close();
          this.resetTimer();
          clearInterval(this.burndown);
        }
      },
      1000,
      0
    );
  }

  stopTimer() {
    clearInterval(this.burndown);
  }

  resetTimer() {
    this.countDownTime = 15;
    this.venueSvc.countDownTime = 60;
  }

  restartApp() {
    this.splashScreen.show();
    document.location.href = 'index.html';
  }

  initializeApp() {
    if (this.hbInterval) {
      clearInterval(this.hbInterval);
    }
    if (this.isOnlineInterval) {
      clearInterval(this.isOnlineInterval);
    }
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.statusBar.overlaysWebView(true);
      this.statusBar.backgroundColorByHexString('#000');

      this.splashScreen.hide();

      this.v = this.device.version;

      // disable back button
      this.platform.backButton.subscribeWithPriority(0, () => {
        return;
      });

      // check if this if the first run after install and show settings msg
      const shown = this.localStorageSvc.get('servi-first-install');
      if ((shown === undefined || !shown) && this.device.platform === 'Android') {
        this.showInstallMsg();
        this.localStorageSvc.set('servi-first-install', true);
      }

      if (!!window.cordova) {
        // enable background mode
        this.bgModeSvc.enable();

        //   if (this.deviceSvc.isTablet()) {
        this.insomnia.keepAwake().then(
          () => (this.deviceSvc.deviceData.isKeepAwakeOn = true),
          () => (this.deviceSvc.deviceData.isKeepAwakeOn = false)
        );
        //   }

        // watch network for a disconnection
        const disconnectSubscription = this.network
          .onDisconnect()
          .subscribe(() => {
            console.log('network was disconnected :-(');
            this.deviceSvc.setOfflineStatus(false);
          });

        // watch network for a connection
        const connectSubscription = this.network.onConnect().subscribe(() => {
          console.log('network connected!');
          this.deviceSvc.setOfflineStatus(false);
        });

        this.networkInterface.getWiFiIPAddress().then(
          (address) => (this.deviceSvc.deviceData.wifiIpAddress = address.ip),
          () => (this.deviceSvc.deviceData.wifiIpAddress = 'None Found')
        );

        this.networkInterface.getCarrierIPAddress().then(
          (address) =>
            (this.deviceSvc.deviceData.carrierIpAddress = address.ip),
          () => (this.deviceSvc.deviceData.carrierIpAddress = 'None Found')
        );

        this.keyboard.hideFormAccessoryBar(true);

        window.addEventListener('batterystatus', (info: any) => {
          this.deviceSvc.setBatteryStatus(info);
          if (this.state.venue.plugremind) {
            const status = this.deviceSvc.getBatteryStatus();
            if (status.isPlugged) {
              if (this.notPluggedAlert != null) {
                this.notPluggedAlert.dismiss();
                // this.notPluggedAlert = null;
              }
            } else {
              if (this.notPluggedAlert != null) {
              } else {
                this.showNotPluggedAlert();
              }
            }
          }
        });

        // document.addEventListener('pause', () => {
        //   this.deviceSvc.setOnPause();
        // });

        // document.addEventListener('resume', () => {
        //   this.deviceSvc.setOnResume();
        // });

        window.androidVolume.getNotification((volume: number) => {
          this.deviceSvc.deviceData.volume = volume;
        }, false);

        WifiWizard2.getConnectedSSID().then((ssid: string) => {
          this.deviceSvc.deviceData.ssid = ssid;
        });
      }

      // Google Analytics
      const script = document.createElement('script');
      script.async = true;
      script.src =
        'https://www.googletagmanager.com/gtag/js?id=' +
        environment.UA_TRACKING_ID;
      document.head.appendChild(script);

      const navEndEvent$ = this.router.events.pipe(
        filter((e) => e instanceof NavigationEnd)
      );
      navEndEvent$.subscribe((e: NavigationEnd) => {
        gtag('config', environment.UA_TRACKING_ID, {
          page_path: e.urlAfterRedirects
        });
      });

      setTimeout(() => {
        let thisDate: any;
        if (!this.hbInterval) {
          this.runIntervalProcesses();
          this.hbInterval = setInterval(() => {
            if (!this.alertShown && !this.deviceSvc.deviceData.offline) {
              this.runIntervalProcesses();
            }
          }, 120000);
        }

        if (!this.isOnlineInterval) {
          // console.log('ONLINE-CHK-INTERVAL-START', this.venueSvc.getOfflineCheck());
          this.isOnlineInterval = setInterval(() => {
            this.dataSvc.isOnline().subscribe((response: any) => {
              // console.log('isOnline: ', JSON.stringify(response));
            });
          }, 30000); // check offline status every 20 secs
        } else if (!this.venueSvc.getOfflineCheck()) {
          // console.log('ONLINE-CHK-INTERVAL-CLEAR', this.venueSvc.getOfflineCheck());
          clearInterval(this.isOnlineInterval);
        }
      }, 10000);
    });
  }

  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (resp) => {
          resolve({ long: resp.coords.longitude, lat: resp.coords.latitude });
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  setLocation(): void {
    if (navigator.geolocation) {
      this.getPosition().then((pos) => {
        // console.log(`Positon: ${pos.long} ${pos.lat}`);
        this.deviceSvc.setPosition({
          latitude: pos.lat,
          longitude: pos.long
        });
      });
    }
  }

  runIntervalProcesses() {
    this.setLocation();
    this.deviceSvc.heartbeat();
    // check for pusher channel and reconnect if necessary
    if (!this.pusher.isLoaded()) {
      this.pusher.connectToServer(
        this.waiterSvc.waiter.id,
        this.state.venue.id,
        this.deviceSvc.getDeviceId()
      );
    }
    // check for QP printer keep alive
    if (this.state.venue.qpkeepalive) {
      console.log(this.state.venue.qpkeepalive);
      this.deviceSvc.pingPrinter();
    }
  }
}
