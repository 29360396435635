import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { MainHeaderComponent } from './partials/main-header/main-header.component';
import { TruncateModule } from '@yellowspot/ng-truncate';
import { OfflineMessageComponent } from './partials/offline-message/offline-message.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MainFooterComponent } from './partials/main-footer/main-footer.component';
import { ViewOrderComponent } from './shared/view-order/view-order.component';
import { ReprintStationsModalComponent } from './shared/reprint-stations-modal/reprint-stations-modal.component';
import { PasswordModalComponent } from './shared/password-modal/password-modal.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    TruncateModule,
    MatProgressBarModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule
  ],
  declarations: [
    MainHeaderComponent,
    MainFooterComponent,
    OfflineMessageComponent,
    ViewOrderComponent,
    ReprintStationsModalComponent,
    PasswordModalComponent
  ],
  exports: [
    CommonModule,
    FormsModule,
    IonicModule,
    MatProgressBarModule,
    MainHeaderComponent,
    MainFooterComponent,
    OfflineMessageComponent,
    ViewOrderComponent,
    ReprintStationsModalComponent,
    PasswordModalComponent,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule
  ]
})
export class SharedModule {}
