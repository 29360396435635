import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { WaitersService } from './waiters.service';
import { map } from 'rxjs/operators';
import { LocalStorageService } from './local-storage.service';

@Injectable({
    providedIn: 'root'
})
export class SessionService {

    public period = (60 * 1000);
    public timer: any;
    public storage = window.localStorage;

    constructor(
        private dataSvc: DataService,
        private storageSvc: LocalStorageService,
        private waitersSvc: WaitersService) { }

    saveReloginData(seriesIdentifier, reloginToken, venueCode, username) {
        // console.log('saving relogin data');
        // console.log(seriesIdentifier);
        // console.log(reloginToken);
        // console.log(venueCode, username);

        try {
            this.storage.setItem('seriesIdentifier', seriesIdentifier);
            this.storage.setItem('reloginToken', reloginToken);
        } catch (e) { }
        if (venueCode !== '' && username !== '') {
            try {
                this.storage.setItem('reloginVenueCode', venueCode);
                this.storage.setItem('reloginVenueUser', username);
            } catch (e) { }
        }
    }

    getSavedCredentials() {
        const seriesIdentifier = this.storage.getItem('seriesIdentifier');
        const reloginToken = this.storage.getItem('reloginToken');

        // console.log('getSavedCredentials');
        // console.log(seriesIdentifier);
        // console.log(reloginToken);

        if (seriesIdentifier != null && reloginToken != null) {
            return {
                seriesIdentifier: seriesIdentifier,
                reloginToken: reloginToken
            };
        }
        return null;
    }

    clearCredentials() {
        this.storage.setItem('seriesIdentifier', null);
        this.storage.setItem('reloginToken', null);
    }

    goOnline() {
        // console.log('GO-ONLINE');
        if (this.timer != null) {
            clearInterval(this.timer);
        }

        return this.dataSvc.GoOnline({ waiterId: this.waitersSvc.waiter.id }).pipe(
            map(
                (response: any) => {

                    this.timer = setInterval(() => {
                        this.goOnline();
                    }, this.period);

                    return response;
                },
                (error: any) => {
                    return error;
                }
            )
        );
    }

    goOffline() {
        // console.log('GO-OFFLINE');
        if (this.timer != null) {
            clearInterval(this.timer);
            return this.dataSvc.GoOffline({ waiterId: this.waitersSvc.waiter.id }).pipe(
                map(
                    (response: any) => {
                        return response;
                    },
                    (error: any) => {
                        return error;
                    }
                )
            );
        }
    }
}
