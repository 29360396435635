import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError, retryWhen, take, delay } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { LocalStorageService } from './local-storage.service';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private _env = environment.ENVIRONMENT;
  private _envFile = environment.ENV_FILE;
  private _url = environment.API_URL;
  private _alturl = environment.ALT_API_URL;

  private LOGIN_PARAMS: any;
  private headerOptions = {};

  constructor(private _http: HttpClient, private _store: LocalStorageService, private _route: ActivatedRoute) {
    let key = this._store.get('X-SID-KEY');
    if (key === null) {
      key = this._guid();
      this._store.set('X-SID-KEY', key);
    }

    console.log('ENVIRON: ' + this._env);
    console.log('ENV_FILE: ' + this._envFile);
    console.log('API URL: ' + this._url);
    console.log('X-SID-KEY: ' + key);

    this.headerOptions = {
      'Content-Type': 'application/json',
      'X-API-KEY': '8e0c71c4eae846848cabe48c7f14ba22',
      'X-SID-KEY': key,
    };
  }

  GetLatestVersion(): Observable<any> {
    return this._http
      .get(this._url + 'staff/version', { headers: this.headerOptions })
      .pipe(
        map(this.handleSuccess),
        catchError(this.handleError)
      );
  }

  isOnline(): Observable<any> {
    return this._http
      .get('https://assets.ser.vi/_assets/pixel_trans.gif', { headers: {'Content-Type': 'application/octet-stream'}, responseType: 'blob'})
      .pipe(
        map((res) => { return { result: 'success'} }),
        retryWhen((errors) => errors.pipe(delay(1000), take(3))),
        catchError(this.handleError)
      );
  }

  // heartbeat(data: any): Observable<any> {
  //   return this._http
  //     .post<any>(this._alturl + '?action=heartbeat', data, { headers: this.headerOptions })
  //     .pipe(
  //       map(this.handleSuccess),
  //       catchError(this.handleError)
  //     );
  // }

  heartbeat(data: any): Observable<any> {
    return this._http
      .post<any>(this._url + 'waiter/heartbeat/ping', data, { headers: this.headerOptions })
      .pipe(
        map(this.handleSuccess),
        catchError(this.handleError)
      );
  }

  /* Hours */
  GetVenueSchedule(data: any): Observable<any> {
    return this._http
      .post<any>(this._url + 'waiter/getStoreSchedule', data, { headers: this.headerOptions })
      .pipe(
        map(this.handleSuccess),
        retryWhen((errors) => errors.pipe(delay(1000), take(3))),
        catchError(this.handleError)
      );
  }

  GetMainVenueHours(data: any): Observable<any> {
    return this._http
      .post<any>(this._url + 'waiter/getMainVenueHours', data, { headers: this.headerOptions })
      .pipe(
        map(this.handleSuccess),
        retryWhen((errors) => errors.pipe(delay(1000), take(3))),
        catchError(this.handleError)
      );
  }

  BroadcastMainVenueHours(data: any): Observable<any> {
    return this._http
      .post<any>(this._url + 'staff/broadcast/hours', data, { headers: this.headerOptions })
      .pipe(
        map(this.handleSuccess),
        retryWhen((errors) => errors.pipe(delay(1000), take(3))),
        catchError(this.handleError)
      );
  }

  /* Notification Service */
  SetStationPrintedStatus(data: any): Observable<any> {
    return this._http
      .post<any>(this._url + 'setStationPrintedStatus', data, { headers: this.headerOptions })
      .pipe(
        map(this.handleSuccess),
        retryWhen((errors) => errors.pipe(delay(1000), take(3))),
        catchError(this.handleError)
      );
  }

  SetPrintedStatus(data: any): Observable<any> {
    return this._http
      .post<any>(this._url + 'setPrintedStatus', data, { headers: this.headerOptions })
      .pipe(
        map(this.handleSuccess),
        retryWhen((errors) => errors.pipe(delay(1000), take(3))),
        catchError(this.handleError)
      );
  }

  UpdateOrderNotification(data: any): Observable<any> {
    return this._http
      .post<any>(this._url + 'staff/notification/update', data, { headers: this.headerOptions })
      .pipe(
        map(this.handleSuccess),
        retryWhen((errors) => errors.pipe(delay(1000), take(3))),
        catchError(this.handleError)
      );
  }

  AcknowledgeNotification(data: any): Observable<any> {
    return this._http
      .post<any>(this._url + 'waiter/call/receive', data, { headers: this.headerOptions })
      .pipe(
        map(this.handleSuccess),
        retryWhen((errors) => errors.pipe(delay(1000), take(3))),
        catchError(this.handleError)
      );
  }

  // SaveNotificationsToServer(data: any): Observable<any> {
  //   return this._http
  //     .post<any>(this._url + 'saveNotificationsToServer', data, { headers: this.headerOptions })
  //     .pipe(
  //       map(this.handleSuccess),
  //       retryWhen((errors) => errors.pipe(delay(1000), take(3))),
  //       catchError(this.handleError)
  //     );
  // }

  /* Messaging Service */
  CallConfirm(data: any): Observable<any> {
    return this._http
      .post<any>(this._url + 'waiter/call/confirm', data, { headers: this.headerOptions })
      .pipe(
        map(this.handleSuccess),
        retryWhen((errors) => errors.pipe(delay(1000), take(3))),
        catchError(this.handleError)
      );
  }

  CallAcknowledge(data: any): Observable<any> {
    return this._http
      .post<any>(this._url + 'waiter/call/receive', data, { headers: this.headerOptions })
      .pipe(
        map(this.handleSuccess),
        retryWhen((errors) => errors.pipe(delay(1000), take(3))),
        catchError(this.handleError)
      );
  }

  CallReject(data: any): Observable<any> {
    return this._http
      .post<any>(this._url + 'staff/order/reject', data, { headers: this.headerOptions })
      .pipe(
        map(this.handleSuccess),
        retryWhen((errors) => errors.pipe(delay(1000), take(3))),
        catchError(this.handleError)
      );
  }

  MarkPaid(data: any): Observable<any> {
    return this._http
      .post<any>(this._url + 'staff/order/paid', data, { headers: this.headerOptions })
      .pipe(
        map(this.handleSuccess),
        retryWhen((errors) => errors.pipe(delay(1000), take(3))),
        catchError(this.handleError)
      );
  }

  RevertPaid(data: any): Observable<any> {
    return this._http
      .post<any>(this._url + 'staff/order/unpay', data, { headers: this.headerOptions })
      .pipe(
        map(this.handleSuccess),
        retryWhen((errors) => errors.pipe(delay(1000), take(3))),
        catchError(this.handleError)
      );
  }

  CompOrder(data: any): Observable<any> {
    return this._http
      .post<any>(this._url + 'staff/order/comp', data, { headers: this.headerOptions })
      .pipe(
        map(this.handleSuccess),
        retryWhen((errors) => errors.pipe(delay(1000), take(3))),
        catchError(this.handleError)
      );
  }

  RefundOrder(data: any): Observable<any> {
    return this._http
      .post<any>(this._url + 'staff/order/refund', data, { headers: this.headerOptions })
      .pipe(
        map(this.handleSuccess),
        retryWhen((errors) => errors.pipe(delay(1000), take(3))),
        catchError(this.handleError)
      );
  }

  ValidateManagerPassword(data: any): Observable<any> {
    return this._http
      .post<any>(this._url + 'staff/validate/manager', data, { headers: this.headerOptions })
      .pipe(
        map(this.handleSuccess),
        retryWhen((errors) => errors.pipe(delay(1000), take(3))),
        catchError(this.handleError)
      );
  }

  /* Auth Service */
  ReLogin(path: string, data: any): Observable<any> {
    return this._http
      .post<any>(this._url + path, data, { headers: this.headerOptions })
      .pipe(
        map(this.handleSuccess),
        retryWhen((errors) => errors.pipe(delay(1000), take(3))),
        catchError(this.handleError)
      );
  }

  Login(data: any): Observable<any> {
    return this._http
      .post<any>(this._url + 'waiter/login', data, { headers: this.headerOptions })
      .pipe(
        map(this.handleSuccess),
        retryWhen((errors) => errors.pipe(delay(1000), take(3))),
        catchError(this.handleError)
      );
  }

  Logout(data: any): Observable<any> {
    return this._http
      .post<any>(this._url + 'waiter/logout', data, { headers: this.headerOptions })
      .pipe(
        map(this.handleSuccess),
        retryWhen((errors) => errors.pipe(delay(1000), take(3))),
        catchError(this.handleError)
      );
  }

  /* Session Service */
  GoOnline(data: any): Observable<any> {
    return this._http
      .post<any>(this._url + 'waiter/iAmOnline', data, { headers: this.headerOptions })
      .pipe(
        map(this.handleSuccess),
        retryWhen((errors) => errors.pipe(delay(1000), take(3))),
        catchError(this.handleError)
      );
  }

  GoOffline(data: any): Observable<any> {
    return this._http
      .post<any>(this._url + 'waiter/iAmOffline', data, { headers: this.headerOptions })
      .pipe(
        map(this.handleSuccess),
        retryWhen((errors) => errors.pipe(delay(1000), take(3))),
        catchError(this.handleError)
      );
  }

  /* Product Specials Service */
  GetSpecials(data: any): Observable<any> {
    return this._http
      .post<any>(this._url + 'staff/refreshSpecials', data, { headers: this.headerOptions })
      .pipe(
        map(this.handleSuccess),
        retryWhen((errors) => errors.pipe(delay(1000), take(3))),
        catchError(this.handleError)
      );
  }

  UpdateSpecial(data: any): Observable<any> {
    return this._http
      .post<any>(this._url + 'staff/updateSpecial', data, { headers: this.headerOptions })
      .pipe(
        map(this.handleSuccess),
        retryWhen((errors) => errors.pipe(delay(1000), take(3))),
        catchError(this.handleError)
      );
  }

  SetSpecialStatus(data: any): Observable<any> {
    return this._http
      .post<any>(this._url + 'staff/setSpecialStatus', data, { headers: this.headerOptions })
      .pipe(
        map(this.handleSuccess),
        retryWhen((errors) => errors.pipe(delay(1000), take(3))),
        catchError(this.handleError)
      );
  }

  SetProductMenuStatus(data: any): Observable<any> {
    return this._http
      .post<any>(this._url + 'staff/setProductMenuStatus', data, { headers: this.headerOptions })
      .pipe(
        map(this.handleSuccess),
       // retryWhen((errors) => errors.pipe(delay(1000), take(3))),
        catchError(this.handleError)
      );
  }

  GetAllMenus(data: any): Observable<any> {
    return this._http
      .post<any>(this._url + 'staff/getAllMenus', data, { headers: this.headerOptions })
      .pipe(
        map(this.handleSuccess),
       // retryWhen((errors) => errors.pipe(delay(1000), take(3))),
        catchError(this.handleError)
      );
  }

  /* Seats Service */
  LoadOneSeatInformation(data: any): Observable<any> {
    return this._http
      .post<any>(this._url + 'waiter/getTableInfo', data, { headers: this.headerOptions })
      .pipe(
        map(this.handleSuccess),
        retryWhen((errors) => errors.pipe(delay(1000), take(3))),
        catchError(this.handleError)
      );
  }

  ReleaseActiveSeat(data: any): Observable<any> {
    return this._http
      .post<any>(this._url + 'waiter/releaseTable', data, { headers: this.headerOptions })
      .pipe(
        map(this.handleSuccess),
        retryWhen((errors) => errors.pipe(delay(1000), take(3))),
        catchError(this.handleError)
      );
  }

  AddSeatToActive(data: any): Observable<any> {
    return this._http
      .post<any>(this._url + 'waiter/takeTable', data, { headers: this.headerOptions })
      .pipe(
        map(this.handleSuccess),
        retryWhen((errors) => errors.pipe(delay(1000), take(3))),
        catchError(this.handleError)
      );
  }

  LoadSeats(data: any): Observable<any> {
    return this._http
      .post<any>(this._url + 'waiter/listTables', data, { headers: this.headerOptions })
      .pipe(
        map(this.handleSuccess),
      //  retryWhen((errors) => errors.pipe(delay(1000), take(3))),
        catchError(this.handleError)
      );
  }

  GetSeats(data: any): Observable<any> {
    return this._http
      .post<any>(this._url + 'waiter/getSeats', data, { headers: this.headerOptions })
      .pipe(
        map(this.handleSuccess),
      //  retryWhen((errors) => errors.pipe(delay(1000), take(3))),
        catchError(this.handleError)
      );
  }

  ProcessSeat(data: any): Observable<any> {
    return this._http
      .post<any>(this._url + 'waiter/processTable', data, { headers: this.headerOptions })
      .pipe(
        map(this.handleSuccess),
     //   retryWhen((errors) => errors.pipe(delay(1000), take(3))),
        catchError(this.handleError)
      );
  }

  /* Waiters Service */
  LoadWaiters(data: any): Observable<any> {
    return this._http
      .post<any>(this._url + 'waiter/listWaiters', data, { headers: this.headerOptions })
      .pipe(
        map(this.handleSuccess),
        retryWhen((errors) => errors.pipe(delay(1000), take(3))),
        catchError(this.handleError)
      );
  }

  /* Zones Service */
  AddZoneToActive(data: any): Observable<any> {
    return this._http
      .post<any>(this._url + 'waiter/takeZone', data, { headers: this.headerOptions })
      .pipe(
        map(this.handleSuccess),
        retryWhen((errors) => errors.pipe(delay(1000), take(3))),
        catchError(this.handleError)
      );
  }

  ReleaseActiveZone(data: any): Observable<any> {
    return this._http
      .post<any>(this._url + 'waiter/releaseZone', data, { headers: this.headerOptions })
      .pipe(
        map(this.handleSuccess),
        retryWhen((errors) => errors.pipe(delay(1000), take(3))),
        catchError(this.handleError)
      );
  }

  LoadOneZoneInformation(data: any): Observable<any> {
    return this._http
      .post<any>(this._url + 'waiter/getZoneInfo', data, { headers: this.headerOptions })
      .pipe(
        map(this.handleSuccess),
        retryWhen((errors) => errors.pipe(delay(1000), take(3))),
        catchError(this.handleError)
      );
  }

  ListZones(data: any): Observable<any> {
    return this._http
      .post<any>(this._url + 'waiter/listZones', data, { headers: this.headerOptions })
      .pipe(
        map(this.handleSuccess),
        retryWhen((errors) => errors.pipe(delay(1000), take(3))),
        catchError(this.handleError)
      );
  }

  /* Venue Service */
  GetAllStatusByWaiter(data: any): Observable<any> {
    return this._http
      .post<any>(this._url + 'staff/seats/getAllStatusByWaiter', data, { headers: this.headerOptions })
      .pipe(
        map(this.handleSuccess),
     //   debounceTime(1000),
     //   distinctUntilChanged(),
     //   retryWhen((errors) => errors.pipe(delay(1000), take(3))),
        catchError(this.handleError)
      );
  }

  GetSeatStatus(data: any): Observable<any> {
    return this._http
      .post<any>(this._url + 'staff/seats/getStatus', data, { headers: this.headerOptions })
      .pipe(
        map(this.handleSuccess),
     //   debounceTime(1000),
     //   distinctUntilChanged(),
        //  retryWhen(errors => errors.pipe(delay(1000), take(3))),
        catchError(this.handleError)
      );
  }

  UpdateSeatStatus(data: any): Observable<any> {
    return this._http
      .post<any>(this._url + 'staff/seats/updateStatus', data, { headers: this.headerOptions })
      .pipe(
        map(this.handleSuccess),
        // retryWhen(errors => errors.pipe(delay(1000), take(3))),
        catchError(this.handleError)
      );
  }

  ResendMissedMessages(data: any): Observable<any> {
    return this._http
      .post<any>(this._url + 'staff/messages/resend', data, { headers: this.headerOptions })
      .pipe(
        map(this.handleSuccess),
        retryWhen((errors) => errors.pipe(delay(1000), take(3))),
        catchError(this.handleError)
      );
  }

  UpdatePrinters(data: any): Observable<any> {
    return this._http
      .post<any>(this._url + 'waiter/settings/updatePrinters', data, { headers: this.headerOptions })
      .pipe(
        map(this.handleSuccess),
        retryWhen((errors) => errors.pipe(delay(1000), take(3))),
        catchError(this.handleError)
      );
  }

  SetOrderDelivered(data: any): Observable<any> {
    return this._http
      .post<any>(this._url + 'waiter/order/setDelivered', data, { headers: this.headerOptions })
      .pipe(
        map(this.handleSuccess),
        retryWhen((errors) => errors.pipe(delay(1000), take(3))),
        catchError(this.handleError)
      );
  }

  GetVenueInfo(data: any): Observable<any> {
    return this._http
      .post<any>(this._url + 'getRestaurantById', data, { headers: this.headerOptions })
      .pipe(
        map(this.handleSuccess),
        retryWhen((errors) => errors.pipe(delay(1000), take(3))),
        catchError(this.handleError)
      );
  }

  RestoreCallHistoryByDate(data: any): Observable<any> {
    return this._http
      .post<any>(this._url + 'staff/restoreCallHistoryByDate', data, { headers: this.headerOptions })
      .pipe(
        map(this.handleSuccess),
        retryWhen((errors) => errors.pipe(delay(1000), take(3))),
        catchError(this.handleError)
      );
  }

  // RestoreNotificationHistory(data: any): Observable<any> {
  //   return this._http
  //     .post<any>(this._url + 'getSavedNotifications', data, { headers: this.headerOptions })
  //     .pipe(
  //       map(this.handleSuccess),
  //       retryWhen((errors) => errors.pipe(delay(1000), take(3))),
  //       catchError(this.handleError)
  //     );
  // }

  GetSalesSummaryReport(data: any): Observable<any> {
    return this._http
      .post<any>(this._url + 'getSalesSummaryReport', data, { headers: this.headerOptions })
      .pipe(
        map(this.handleSuccess),
        retryWhen((errors) => errors.pipe(delay(1000), take(3))),
        catchError(this.handleError)
      );
  }

  GetShiftReport(data: any): Observable<any> {
    return this._http
      .post<any>(this._url + 'staff/reports/shift', data, { headers: this.headerOptions })
      .pipe(
        map(this.handleSuccess),
        retryWhen((errors) => errors.pipe(delay(1000), take(3))),
        catchError(this.handleError)
      );
  }

  SetOpenToday(data: any): Observable<any> {
    return this._http
      .post<any>(this._url + 'waiter/setOpenToday', data, { headers: this.headerOptions })
      .pipe(
        map(this.handleSuccess),
        retryWhen((errors) => errors.pipe(delay(1000), take(3))),
        catchError(this.handleError)
      );
  }

  SetZoneOpenToday(data: any): Observable<any> {
    return this._http
      .post<any>(this._url + 'waiter/setZoneOpenToday', data, { headers: this.headerOptions })
      .pipe(
        map(this.handleSuccess),
        retryWhen((errors) => errors.pipe(delay(1000), take(3))),
        catchError(this.handleError)
      );
  }

  GetReverseGeo(data: any): Observable<any> {
    return this._http
      .post<any>(this._url + 'waiter/getReverseGeo', data, { headers: this.headerOptions })
      .pipe(
        map(this.handleSuccess),
        retryWhen((errors) => errors.pipe(delay(1000), take(3))),
        catchError(this.handleError)
      );
  }

  UpdateVenueGeoLocation(data: any): Observable<any> {
    return this._http
      .post<any>(this._url + 'waiter/updateVenueGeoLocation', data, {
        headers: this.headerOptions,
      })
      .pipe(
        map(this.handleSuccess),
        retryWhen((errors) => errors.pipe(delay(1000), take(3))),
        catchError(this.handleError)
      );
  }

  RestockProducts(data: any): Observable<any> {
    return this._http
      .post<any>(this._url + 'waiter/restockAllProducts', data, { headers: this.headerOptions })
      .pipe(
        map(this.handleSuccess),
        retryWhen((errors) => errors.pipe(delay(1000), take(3))),
        catchError(this.handleError)
      );
  }

  GetProducts(data: any): Observable<any> {
    return this._http
      .post<any>(this._url + 'waiter/getProducts', data, { headers: this.headerOptions })
      .pipe(
        map(this.handleSuccess),
        retryWhen((errors) => errors.pipe(delay(1000), take(3))),
        catchError(this.handleError)
      );
  }

  GetOrderNotification(data: any): Observable<any> {
    return this._http
      .post<any>(this._url + 'staff/getOrderNotification', data, { headers: this.headerOptions })
      .pipe(
        map(this.handleSuccess),
        retryWhen((errors) => errors.pipe(delay(1000), take(3))),
        catchError(this.handleError)
      );
  }

  /* Pickup Queue */
  GetPickupQueue(data: any): Observable<any> {
    return this._http
      .post<any>(this._url + 'staff/getPickupQueue', data, { headers: this.headerOptions })
      .pipe(
        map(this.handleSuccess),
        retryWhen((errors) => errors.pipe(delay(1000), take(3))),
        catchError(this.handleError)
      );
  }

  SetOrderReady(data: any): Observable<any> {
    return this._http
      .post<any>(this._url + 'staff/setOrderReady', data, { headers: this.headerOptions })
      .pipe(
        map(this.handleSuccess),
        retryWhen((errors) => errors.pipe(delay(1000), take(3))),
        catchError(this.handleError)
      );
  }

  UndoOrderReady(data: any): Observable<any> {
    return this._http
      .post<any>(this._url + 'staff/undoOrderReady', data, { headers: this.headerOptions })
      .pipe(
        map(this.handleSuccess),
        retryWhen((errors) => errors.pipe(delay(1000), take(3))),
        catchError(this.handleError)
      );
  }

  SetOrderPickedUp(data: any): Observable<any> {
    return this._http
      .post<any>(this._url + 'staff/setOrderPickedUp', data, { headers: this.headerOptions })
      .pipe(
        map(this.handleSuccess),
        retryWhen((errors) => errors.pipe(delay(1000), take(3))),
        catchError(this.handleError)
      );
  }

  SetOrderDismissed(data: any): Observable<any> {
    return this._http
      .post<any>(this._url + 'staff/setOrderDismissed', data, { headers: this.headerOptions })
      .pipe(
        map(this.handleSuccess),
        retryWhen((errors) => errors.pipe(delay(1000), take(3))),
        catchError(this.handleError)
      );
  }

  SetPaidStatus(data: any): Observable<any> {
    return this._http
      .post<any>(this._url + 'staff/setPaidStatus', data, { headers: this.headerOptions })
      .pipe(
        map(this.handleSuccess),
        retryWhen((errors) => errors.pipe(delay(1000), take(3))),
        catchError(this.handleError)
      );
  }

  /* order history */
  GetOrderHistory(data: any): Observable<any> {
    return this._http
      .get<any>(this._url + 'staff/orders', { params: data, headers: this.headerOptions })
      .pipe(
        map(this.handleSuccess),
        retryWhen((errors) => errors.pipe(delay(1000), take(3))),
        catchError(this.handleError)
      );
  }

  UpdateOrderTicketCount(data: any): Observable<any> {
    return this._http
      .post<any>(this._url + 'staff/updateTicketCount', data, { headers: this.headerOptions })
      .pipe(
        map(this.handleSuccess),
     //   retryWhen((errors) => errors.pipe(delay(1000), take(3))),
        catchError(this.handleError)
      );
  }

  /* Reports Service */
  GetTicketCount(data: any): Observable<any> {
    return this._http
      .post<any>(this._url + 'waiter/getTicketCount', data, { headers: this.headerOptions })
      .pipe(
        map(this.handleSuccess),
        retryWhen((errors) => errors.pipe(delay(1000), take(3))),
        catchError(this.handleError)
      );
  }

  LogError(data: any): Observable<any> {
    return this._http
      .post<any>(this._url + 'waiter/system/error', data, { headers: this.headerOptions })
      .pipe(map(this.handleSuccess), catchError(this.handleError));
  }

  /* private methods */
  handleSuccess(response: any) {
    if (response.rpcStatus === 1) {
      const result = response.data;
      return { success: true, result: result };
    } else {
      return { success: false, result: response.msg };
    }
  }

  handleError(error: any) {
    return throwError(error.msg || 'Unknown Server Error');
    // return function () {
    //   return { success: false, message: error };
    // };
  }

  private _guid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return s4() + s4() + s4() + s4() + s4() + s4() + s4() + s4();
  }
}
