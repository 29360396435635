import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { VenueService } from './venue.service';
import { SeatsService } from './seats.service';
import { Notification } from '../models/notification';
import { WaitersService } from './waiters.service';
import { ProductsService } from './products.service';
import { DataService } from './data.service';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment.prod';
import { DatePipe } from '@angular/common';
import { ZonesService } from './zones.service';
import { ProductSpecialsService } from './product-specials.service';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { NativeAudio } from '@ionic-native/native-audio/ngx';
import { LocalStorageService } from './local-storage.service';
import { StateService } from '@services/state.service';
import { Vibration } from '@ionic-native/vibration/ngx';

declare var window: any;

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  public notification = {} as Notification;
  public expTimer: any;
  private ENVIRONMENT = environment.ENVIRONMENT;
  public soundLoop: any;
  public zoneOpen: boolean;

  constructor(
    public snackBar: MatSnackBar,
    private router: Router,
    private datePipe: DatePipe,
    private zone: NgZone,
    private dataSvc: DataService,
    private waiterSvc: WaitersService,
    private seatsSvc: SeatsService,
    private productsSvc: ProductsService,
    private specialsSvc: ProductSpecialsService,
   // private messageSvc: MessagingService,
    private zonesSvc: ZonesService,
    private venueSvc: VenueService,
    private storeSvc: LocalStorageService,
    private splashScreen: SplashScreen,
    private nativeAudio: NativeAudio,
    private state: StateService,
    private vibration: Vibration
  ) {
    this.notification.history = [];
    this.notification.pagersHistory = [];
    this.notification.activeNotifications = {};
    this.notification.startNotificationId = 0;
    this.notification.changeListeners = [];
    this.zoneOpen = true;
  }

  // new notification received
  onGuestNotification(data: any) {
    // if no guid, no call
    if (!data.guid) {
      return;
    }
    // ignore service request if disabled
    if (this.state.venue.disablerequests && data.type === 'call.call') {
      return;
    }

    this.zoneOpen = this.zonesSvc.zoneIsOpenBySeatId(data.tableId);

    const notificationId = Number(Math.random() * 1000000000).toFixed(0);
    this.notification.id = notificationId;
    this.notification.guid = data.guid;
    this.notification.previousGuid = data.previousGuid;
    this.notification.svcDeviceId = data.svcDeviceId;
    this.notification.ticketCount = data.ticketCount;
    this.notification.data = data.data || {};
    this.notification.table = data.table;
    this.notification.tableId = data.tableId;
    this.notification.tableName = data.tableName;
    this.notification.seatToken = data.seatToken;
    this.notification.callType = data.type;
    this.notification.callGroup = data.group;
    this.notification.venueType = this.state.venue.type;
    this.notification.shortAddress = data.shortAddress;
    this.notification.longAddress = data.longAddress;
    this.notification.time = '0s';
    this.notification.received = (data.sendDate) ? data.sendDate : new Date();

    // this.removeNotification(notificationId);
    this.notification.activeNotifications[notificationId] = this.notification;

    switch (this.notification.callType) {

      case 'staff_msg':
        this.acknowledgeNotification(this.notification);
        if (
          this.notification.callGroup !== 'seats' &&
          this.notification.callGroup !== 'specials' &&
          this.notification.callGroup !== 'historyReset' &&
          this.notification.callGroup !== 'delivered' &&
          this.notification.callGroup !== 'hours' && // recently added to stop auto hours notice on request tab; not needed since open/close icon in the header
          this.notification.callGroup !== 'hoursReset' &&
          this.notification.callGroup !== 'zoneHours' &&
          this.notification.callGroup !== 'stock'
        ) {
          this.startExpirationTimer(this.notification);
          this.setupLocalNotification(this.notification);
          this.confirmNotification(notificationId);
        }

        if (this.notification.callGroup === 'zoneHours') {
          setTimeout(() => {
            this.zonesSvc.init().subscribe((res: any) => {
              console.log('ZONES-INIT', res);
            });
          }, 500);
        }

        if (this.notification.callGroup === 'specials') {
          this.specialsSvc
            .getSpecials({ id: this.state.venue.id })
            .subscribe((res: any) => {});
        }

        if (
          this.notification.callGroup === 'hours' ||
          this.notification.callGroup === 'hoursReset'
        ) {

          if (this.notification.callGroup === 'hoursReset') {
            this.splashScreen.show();
            // clear message history for next day
            this.storeSvc.remove('servi-staff-messages');
            // restart the app
            document.location.href = 'index.html';
          } else {
            setTimeout(() => {
              this.venueSvc.updateMainVenueHours().subscribe();
            }, 500);
          }
        }
        break;

      case 'call.call': // request
      case 'customer_call': // order
      default:
        this.startExpirationTimer(this.notification);
        this.setupLocalNotification(this.notification);
        this.acknowledgeNotification(this.notification);
        this.seatsSvc.attachNotification(this.notification);

        // setTimeout(() => {
        //   if (this.notification.venueType === 'professional') {
        //     this.router.navigateByUrl('/operator');
        //   } else {
        //     this.router.navigateByUrl('/requests');
        //   }
        // }, 500);
        break;
    }
  }

  setupLocalNotification(notification: any) {
    const notificationActions = [
      {
        identifier: 'confirm',
        title: 'confirm',
        icon: 'res://icon/confirm-icon',
        activationMode: 'background',
        destructive: false,
        authenticationRequired: false
      }
    ];
    let openToday = true;
    let hrsOvr = 'off';
    let auto = false;
    let kitchenOpenNext = null;
    let autoText = null;
    let openText = null;
    let subText = null;
    let caption = null;
    let title = null;
    let seatToken = null;
    let msgText = null;
    let orderType = 'dinein';
    let paid = false;

    switch (notification.callType) {
      case 'staff_msg':
        if (notification.callGroup === 'refund') {
          const symbol =
            this.state.venue.currency.symbol.text || '$';
          const thisSeatToken = notification.data.seatToken || '';
          const prefix = thisSeatToken === 'login_room' ? 'Room' : 'Table';
          const seatName =
            notification.data.seatNumber
              ? prefix + ' ' + notification.data.seatNumber
              : '';
          const orderId =
            notification.data.orderId != null ? notification.data.orderId : '';
          const total =
            notification.data.orderTotal != null
              ? notification.data.orderTotal
              : '';
          const orderTotal = Number(total).toFixed(2);
          const today = new Date();
          const dateStr = this.datePipe.transform(today, 'EEE MMM d, h:mm a');
          const timeStr = this.datePipe.transform(today, 'h:mm a');
          title = 'Payment Canceled';
          msgText =
            '<b>' +
            symbol +
            orderTotal +
            '</b> from <b>' +
            seatName +
            '</b> was <span class="text-danger">canceled</span><br />' +
            dateStr +
            ' (<b>#' +
            orderId +
            '</b>)';
          subText = 'It may take 24 hours for the refund to appear.';
        } else if (notification.callGroup === 'hours') {
          const today = new Date();
          const timeStr = this.datePipe.transform(today, 'h:mm a');
          openToday =
            notification.data.mainHours.isOpenNow != null
              ? notification.data.mainHours.isOpenNow
              : false;
          hrsOvr =
            notification.data.hrsOvr != null ? notification.data.hrsOvr : 'off';
          auto =
            notification.data.auto != null ? notification.data.auto : false;
          kitchenOpenNext =
            notification.data.mainHours.openAt != null
              ? notification.data.mainHours.openAt
              : null;
          autoText = auto ? ' Auto ' : ' Manually ';
          openText = openToday ? 'Open' : 'Closed';
          title = 'Ordering ' + openText + ' ' + timeStr;
          msgText = !openToday
            ? 'Ordering ' + autoText + ' Closed'
            : 'Ordering ' + autoText + ' Open';
          if (kitchenOpenNext != null) {
            subText = !openToday
              ? 'Kitchen will auto open at ' + kitchenOpenNext
              : null;
          } else {
            subText = !openToday
              ? 'Kitchen will auto open next business day'
              : null;
          }
        }
        break;

      case 'customer_call':
      default:
        if (notification.venueType === 'professional') {
          caption = !!notification.data.items
            ? ' - ' + notification.data.items[0].customCaption
            : null;
        } else {
          if (notification.callType === 'order') {

            paid = (!!notification.data.order) ? notification.data.order.paid : false;

            caption = 'Order';
            seatToken =
              notification.data.order.table.tableToken !== undefined
                ? notification.data.order.table.tableToken
                : '';

            if (!!notification.data.order) {
              if (
                !!notification.data.order.extraInfo &&
                notification.data.order.extraInfo.address != null
              ) {
                const parts = notification.data.order.extraInfo.address.split(
                  ', '
                );
                notification.data.order.extraInfo.addrLine1 = !!parts[0]
                  ? parts[0]
                  : null;
                notification.data.order.extraInfo.addrLine2 =
                  !!parts[0] && !!parts[1] ? parts[1] + ', ' + parts[2] : null;
                notification.data.order.extraInfo.addrLine3 =
                  notification.data.order.extraInfo.phone != null
                    ? notification.data.order.extraInfo.phone.replace(/\D/g, '')
                    : null;
              }
            }
          } else {
            seatToken =
              this.notification.seatToken !== undefined
                ? this.notification.seatToken
                : '';
            caption =
              !!notification.data.items &&
              notification.data.items[0].customCaption.length > 0
                ? notification.data.items[0].customCaption
                : 'Server Requested';
          }
        }
        if (notification.callType === 'call.call') {
          title =
            notification.tableName !== undefined
              ? notification.tableName
              : 'Unknown';
        } else {
          if (
            !!notification.table &&
            notification.table.selectedChildSeatName !== undefined
          ) {
            title = notification.table.selectedChildSeatName;
            orderType = 'delivery';
            title = 'Delivery';
          }

          if (seatToken.indexOf('takeout') !== -1) {
            orderType = 'takeout';
            title = 'TakeOut';
          }

          if (seatToken.indexOf('delivery') !== -1) {
            orderType = 'delivery';
            title = 'Delivery';
          }
        }
    }

    const localNotification = {
      id: notification.id,
      guid: notification.guid,
      previousGuid: notification.previousGuid,
      svcDeviceId: notification.svcDeviceId,
      tableId: notification.tableId,
      type: notification.callType,
      venueType: notification.venueType,
      extraInfo: notification.extraInfo,
      shortAddress: notification.shortAddress,
      longAddress: notification.longAddress,
      category:
        notification.callType === 'staff_msg'
          ? 'VENUE_NOTIFICATION'
          : 'WAITER_NOTIFICATION',
      actions: notificationActions,
      autoCancel: true,
      seatToken: seatToken,
      date: null,
      title: title,
      orderType: orderType,
      caption: caption,
      openToday: openToday,
      hrsOvr: hrsOvr,
      subText: subText,
      text: notification.callType === 'staff_msg' ? msgText : 'Customer Request',
      sound: 'sounds/empty.wav', // mutes the beep from $cordovaLocalNotification.schedule()
      printed: false,
      printedreq: false,
      products: [],
      order: [],
      paid: paid,
      visitId: notification.visitId,
      serviceMode: notification.serviceMode
    };

    //  console.log('LOCAL-NOTIFICATION', localNotification);

    // Ding-dong
    this.waiterSvc.waiter.lastCall = localNotification;
    this.waiterSvc.waiter.lastCallType = notification.callType;
    if (
      notification.callType !== 'staff_msg' &&
      notification.venueType !== 'professional' &&
      this.zoneOpen
    ) {
      if (this.state.venue.print || this.state.venue.printstation) {
        if (this.state.venue.print) {
          if (this.waiterSvc.waiter.lastCallType !== 'order') {
            if (this.state.venue.autoprintreq) {
              localNotification.printedreq = true;
              this.venueSvc.setPrintedReq(false);
            } else {
              this.setSoundAlarm();
            }
          } else {
            if (this.state.venue.autoprint) {
              localNotification.printed = true;
              this.venueSvc.setPrinted(false);
            } else {
              if (!this.state.venue.printstation) {
                this.setSoundAlarm();
              }
            }
          }
          if (this.state.venue.printstation) {
            this.venueSvc.setStationPrinted(false);
          }
        }
      } else {
        this.setSoundAlarm();
      }
    }

    if (notification.venueType === 'professional') {
      //  navigator.notification.beep();
    }

    if (notification.callType === 'call.call') {
      this.vibration.vibrate([2000,1000,2000,1000,2000]);
    }

    switch (notification.callType) {
      case 'call.call':
        localNotification.date = notification.received;
        break;

      case 'order':
        if (
          !notification.data ||
          !notification.data.products ||
          !notification.data.products.length
        ) {
          // TODO: log the issue
          return;
        }
        localNotification.products = notification.data.products;
        localNotification.order = notification.data.order;
        localNotification.date = notification.data.order.order_date;
        //  localNotification.orderType = 'dinein';
        if (seatToken === 'login_room') {
          localNotification.orderType = 'delivery';
        } else if (seatToken === 'login_takeout') {
          localNotification.orderType = 'takeout';
        }

        let text = '';
        localNotification.products.forEach((notificationProduct) => {
          if (notificationProduct.product_id === 0) {
            return true;
          }
          const storedProduct = this.productsSvc.getById(
            notificationProduct.product_id
          );
          notificationProduct.name =
            storedProduct != null ? storedProduct.name : 'Unknown';
          if (notificationProduct.variant_name) {
            notificationProduct.name +=
              ' - ' + notificationProduct.variant_name;
          }
          text +=
            notificationProduct.name +
            ' x ' +
            (notificationProduct.qty || 1) +
            ', ';
        });

        localNotification.text = localNotification.text.slice(0, -2);

        // if redirect/bank payments, update order notificaiton in order history
        if (notification.data.order.method.type === 'redirect') {
          this.updateOrderNotification(localNotification).subscribe((result: any) => {
            console.log('UPDATE-RESULT', result);
          }, (error: any) => {});
        }
        break;
    }

    this.notification.history.push(
      JSON.parse(JSON.stringify(localNotification))
    );

    // if (!!window.cordova) {
    //     $cordovaLocalNotification.schedule(localNotification);
    // }
  }

  // remove notification without sending confirmation to the client
  removeNotification(notificationId) {
    if (!this.notification.activeNotifications[notificationId]) {
      this.stopSoundAlarm();
      return;
    }

    const notification = this.notification.activeNotifications[notificationId];
    delete this.notification.activeNotifications[notificationId];

    this.stopSoundAlarm();

    this.seatsSvc.removeNotification(notification);

    // if (!!window.cordova) {
    //     // this will remove notification it's not active yet
    //     $cordovaLocalNotification.cancel(notificationId);
    //     // this will clear notification if it's active
    //     //$cordovaLocalNotification.clear(notificationId);
    // }
  }

  updateOrderNotification(notification: any) {
    console.log('UPDATE', notification);
    // const orderId = (!!notification.order) ? notification.order.order_id : null;
    // if (orderId === null) {
    //   return of({});
    // }
    return this.dataSvc
      .UpdateOrderNotification({
        venueId: this.state.venue.id,
        notification: notification
      })
      .pipe(
        map(
          (response: any) => {
            return response;
          },
          (error: any) => {
            return error;
          }
        )
      );
  }

  acknowledgeNotification(notification: any) {
    return this.dataSvc
      .AcknowledgeNotification({
        guid: notification.guid,
        venueId: this.state.venue.id
      })
      .pipe(
        map(
          (response: any) => {
            return response;
          },
          (error: any) => {
            return error;
          }
        )
      );
  }

  setPrintedStatus(notification: any) {
    return this.dataSvc
      .SetPrintedStatus({
        guid: notification.guid,
        venueId: this.state.venue.id,
        seatId: notification.seatId,
        tableId: notification.tableId,
        waiterId: this.waiterSvc.waiter.id,
        restaurantId: this.state.venue.id,
        environment: this.ENVIRONMENT
      })
      .pipe(
        map(
          (response: any) => {
            return response;
          },
          (error: any) => {
            return error;
          }
        )
      );
  }

  setStationPrintedStatus(notification: any, printer: any, status?: boolean) {
    const data = { guid: notification.guid,
      venueId: this.state.venue.id,
      seatId: notification.seatId,
      tableId: notification.tableId,
      waiterId: this.waiterSvc.waiter.id,
      restaurantId: this.state.venue.id,
      printer: printer,
      status: status,
      notification: notification,
      environment: this.ENVIRONMENT };

    return this.dataSvc
      .SetStationPrintedStatus(data)
      .pipe(
        map(
          (response: any) => {
          //  console.log('SET-STATION-PRINTED-STATUS');
          //  console.log(JSON.stringify(printer));
            notification.stationprinted = (printer && printer.id) ? [printer.id] : [true];

            //console.log(JSON.stringify(response));
            return response;
          },
          (error: any) => {
            return error;
          }
        )
      );
  }

  // confirm to the client that notification received and remove it
  confirmNotification(notificationId) {
    this.removeNotification(notificationId);
  }

  confirmLastCall() {
    if (!!this.waiterSvc.waiter.lastCall) {
      if (
        this.state.venue.autoprintreq === true &&
        this.waiterSvc.waiter.lastCall.type === 'call.call'
      ) {
        this.confirmNotification(this.waiterSvc.waiter.lastCall);
      }
    }
  }

  // existsInLocalStorage(guid: string) {
  //   let messages: any;
  //   try {
  //     messages = this.storeSvc.get('servi-staff-messages');
  //   } catch (e) {}

  //   let exists = false;
  //   if (messages) {
  //     exists = messages.find((item: any) => item.guid === guid);
  //   }

  //   return exists ? true : false;
  // }

  // appendLocalStorage(guid: string, confirmed: boolean) {
  //   if (!this.existsInLocalStorage(guid)) {
  //     const now = +new Date();
  //     const data = { guid: guid, confirmed: confirmed, date: now };
  //     this.storeSvc.append('servi-staff-messages', data);
  //   }
  // }

  setSoundAlarm() {
    if (window.cordova) {
      setTimeout(() => {
        this.nativeAudio.loop('notifyaudio_' + this.state.venue.notifysound).then(() => {});
      }, 1500); // sync the sound and request msg emitter delay
    }
  }

  playSoundAlarm(notifysound?: number) {
    if (window.cordova) {
      const thisSound = (notifysound) ? notifysound.toString() : this.state.venue.notifysound;
      this.nativeAudio.play('notifyaudio_' + thisSound).then(() => {});
    }
  }

  stopSoundAlarm() {
    if (window.cordova) {
      this.nativeAudio.stop('notifyaudio_' + this.state.venue.notifysound).then(() => {});
    }
  }

  unloadSoundAlarm() {
    if (window.cordova) {
      this.nativeAudio.unload('notifyaudio_' + this.state.venue.notifysound).then(() => {});
    }
  }

  getNotificationFromHistory(
    searchedPropertyValue: any,
    searchedPropertyName: any
  ) {
    const searchCriteria = searchedPropertyName || 'id';

    return this.notification.history.filter((notification: any) => {
      return notification[searchCriteria] === searchedPropertyValue;
    })[0];
  }

  updateNotification(data) {
    this.notification.history.map((item: any) => {
      if (item.guid === data.guid) {

        switch(data.action) {
          case 'receiptprinted':
            item.printed = true;
            break;
          case 'requestprinted':
            item.printedreq = true;
            break;
            case 'stationprinted':
              const newItem = data.printerId ? data.printerId : '';
              // Check if 'item' and 'item.stationprinted' exist
              if (item && item.stationprinted) {
                // If 'item.stationprinted' is not an array, initialize it
                if (!Array.isArray(item.stationprinted)) {
                  item.stationprinted = [];
                }
                // Now that we're sure it's an array, check if 'newItem' is not in it
                if (item.stationprinted.indexOf(newItem) === -1) {
                  item.stationprinted.push(newItem);
                }
              } else if (item) {
                // If 'item.stationprinted' does not exist, initialize it with 'newItem'
                item.stationprinted = [newItem];
              }
              break;
          case 'reject':
            //messages.splice(i, 1);
            break;
          case 'revert':
            item.paid = null;
            break;
          case 'paid':
            item.paid = new Date();
            break;
          case 'refunded':
            item.refunded = new Date();
            break;
          case 'comp':
              item.compDate = new Date();
              break;
          case 'confirmed':
          default:
            item.confirmed = true;
        }
      }
    });
  }

  // create expiration timer for notification.
  // notification will be auto dismissed in 20 minutes.
  startExpirationTimer(notification: any) {
    this.expTimer = setInterval(() => {
      notification.time = this.dateDiff(notification.received);
    }, 1000);

    setTimeout(() => {
      clearInterval(this.expTimer);
      this.removeNotification(notification.id);
    }, 20 * 60 * 1000); // 20 minutes
  }

  // utility function to calculate human friendly expriration timer
  dateDiff(date: any) {
    if (typeof date === 'string') {
      date = new Date(date);
    }
    const miliseconds = new Date().getTime() - date.getTime();
    const seconds = miliseconds / 1000;
    const minutes = seconds / 60;
    let diffStr = Math.floor(seconds % 60) + 's';
    if (minutes > 1) {
      diffStr = Math.floor(minutes) + 'm ' + diffStr;
    }
    return diffStr;
  }

  // getCordovaPath(file: string) {
  //     return window.location.pathname.split('/').slice(0, -1).join('/') + '/' +
  //         (file || '');
  // }

  /* Error Handler Snackbar Msg */
  showSuccess(message: string): void {
    const snackBarRef = this.snackBar.open(message);
  }

  /* Error Handler Snackbar Msg */
  showError(message: string): void {
    // The second parameter is the text in the button.
    // In the third, we send in the css class for the snack bar.
    this.zone.run(() => {
      const snackBarRef = this.snackBar.open(message, 'X', {
        panelClass: ['error']
      });
      snackBarRef.afterDismissed().subscribe(() => {
        //  this.venueSvc.goToSeat();
      });
    });
  }

  _formatPhoneNumber(phoneNumberString: string) {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = match[1] ? '+1 ' : '';
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
  }
}
