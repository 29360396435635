import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class LocalStorageService {

    constructor() { }

    public storageAvailable = this.isLocalStorageAvailable();

    get(token: string) {
        if (!this.storageAvailable) {
            return;
        }

        const data = localStorage.getItem(token);
        if (data !== 'undefined') {
            return JSON.parse(data);
        }
    }

    set(token: string, data: any) {
        if (!this.storageAvailable) {
            return;
        }

        return localStorage.setItem(token, JSON.stringify(data));
    }

    remove(token: string) {
        if (!this.storageAvailable) {
            return;
        }

        return localStorage.removeItem(token);
    }

    append(token: string, obj: object) {
        const stored = this.get(token) || [];
        stored.push(obj);
        return this.set(token, stored);
    }

    showAll() {
        const $result = [];
        for (let i = 0; i < localStorage.length; i++) {
            const ikey = localStorage.key(i);
            const item = localStorage.getItem(ikey);
            $result[i] = Array(ikey);
            $result[i].push(item);
        }

        return JSON.stringify($result, null, 2);
    }

    clearAll() {
        localStorage.clear();
    }

    // This makes sure that localStorage is available and also writable (not disabled)
    isLocalStorageAvailable() {
        const storage = window.localStorage,
            x = '__storage_test__';

        try {
            storage.setItem(x, x);
            storage.removeItem(x);
            return true;

        } catch (e) {
            return e instanceof DOMException && (
                // everything except Firefox
                e.code === 22 ||
                // Firefox
                e.code === 1014 ||
                // test name field too, because code might not be present
                // everything except Firefox
                e.name === 'QuotaExceededError' ||
                // Firefox
                e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
                // acknowledge QuotaExceededError only if there's something already stored
                storage.length !== 0;
        }
    }
}
