import { AfterContentInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appAutoFocus]'
})
export class AutoFocusDirective implements AfterContentInit {

  @Input() public autoFocus: boolean;

  public constructor(private el: ElementRef) { }

  public ngAfterContentInit() {
      setTimeout(() => {
          alert('autofocus');
          this.el.nativeElement.focus();
      }, 2000);
  }

}
