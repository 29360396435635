import { Injectable, Output, EventEmitter } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { NativeAudio } from '@ionic-native/native-audio/ngx';
import { ImmutableService } from '@serviworldwide/core-js/classes/immutableService';
import { DataService } from './data.service';
import { LocalStorageService } from './local-storage.service';
import { environment } from 'src/environments/environment';
import { Platform } from '@ionic/angular';
import { StateService } from '@services/state.service';
import {
  MainHours,
  Venue
} from '@models/index';
import { Device } from '@ionic-native/device/ngx';

declare var window: any;

@Injectable({
  providedIn: 'root'
})
export class VenueService extends ImmutableService {
  @Output() refreshProducts: EventEmitter<any> = new EventEmitter();
  @Output() refreshHours: EventEmitter<any> = new EventEmitter();
  @Output() refreshSeats: EventEmitter<any> = new EventEmitter();
  @Output() refreshDataLoading: EventEmitter<any> = new EventEmitter();
  @Output() selectHistory: EventEmitter<any> = new EventEmitter();
  @Output() refreshSilentDataLoading: EventEmitter<any> = new EventEmitter();

  public countDownTime = 60;
  public requestsDataLoading = false;
  public requestsSilentDataLoading = false;
  public orderHistoryFilter = 'yesterday';
  public isLocal = false;
  public useAndroidPadding = false;

  constructor(
    private dataSvc: DataService,
    private storeSvc: LocalStorageService,
    private nativeAudio: NativeAudio,
    private platform: Platform,
    private state: StateService,
    private device: Device
  ) {
    super('venue');

    this._set('hideDelivered', false);
    this.setSearchDate(new Date());
  }

  init(venue: Venue) {
    this._set(venue);
    const hours = this.state.venue.mainHours;
    if (hours) {
      this._set({
        kitchenOpenHrs:
          hours.openAt && hours.openAt.substring(0, 1) === '0'
            ? hours.openAt.slice(1)
            : hours.openAt,
        kitchenClosedHrs:
          hours.closeAt && hours.closeAt.substring(0, 1) === '0'
            ? hours.closeAt.slice(1)
            : hours.openAt,
        isKitchenHrsOpen: hours.isOpenNow
      });
      this.needsAndroidPadding();
    }

    this.initSoundFiles();
  }

  needsAndroidPadding () {
   // this.useAndroidPadding = true;
    if (window.cordova && Number(this.device.version) >= 10) {
      this.useAndroidPadding = true;
    }
  }

  setRequestsDataLoading(set: boolean) {
    this.requestsDataLoading = set;
    this.refreshDataLoading.emit({ status: set });
  }

  doSelectHistory() {
    this.selectHistory.emit({ status: true });
  }

  setSilentRequestsDataLoading(set: boolean) {
    this.requestsSilentDataLoading = set;
    this.refreshSilentDataLoading.emit({ status: set });
  }

  setHours(hours: MainHours) {
    if (hours) {
      this._set('mainHours', hours);
      this.refreshHours.emit({
        status: true
      });
    }
  }

  tabSeatsSelected() {
    this.refreshSeats.emit({ status: true });
  }

  getOrderHistory(data: any): any {
    return this.dataSvc.GetOrderHistory(data).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getSeatStatus(data: any): any {
    return this.dataSvc.GetSeatStatus(data).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  updateSeatStatus(data: any): any {
    return this.dataSvc.UpdateSeatStatus(data).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  resendMissedMessages(data: any): any {
    return this.dataSvc.ResendMissedMessages(data).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  updatePrinters(data: any): any {
    return this.dataSvc.UpdatePrinters(data).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  setOrderDelivered(data: any): any {
    return this.dataSvc.SetOrderDelivered(data).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getVenueInfo(data: any): any {
    return this.dataSvc.GetVenueInfo(data).pipe(
      map((response: any) => {
        // TODO: do a more granular assignment here

        this._set({
          ...this.state.venue,
          ...response
        });
        return response;
      })
    );
  }

  getAllMenus(data: any): any {
    return this.dataSvc.GetAllMenus(data).pipe(
      map((response: any) => {
        let venueCopy = Object.assign({}, this.state.venue)
        venueCopy.allMenus = response.result;

        this._set({
          ...this.state.venue,
          ...venueCopy
        });

        return response;
      })
    );
  }

  updateMainVenueHours() {
    return this.dataSvc.GetMainVenueHours({ venueId: this.state.venue.id })
      .pipe(
        tap((response: any) => {
          if (response.success) {
            // Non-refactored version was setting the hours directly, without triggering the event emitter, not sure if
            // intended or bug; leaving it here to uncomment in case it was intended
            // this._set('mainHours', response.result);
            this.setHours(response.result);
          }
        })
      );
  }

  broadcastMainVenueHours(data: any): any {
    return this.dataSvc.BroadcastMainVenueHours(data).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  restoreCallHistoryByDate(data: any): any {
    return this.dataSvc.RestoreCallHistoryByDate(data).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  // restoreNotificationHistory(data: any): any {
  //   return this.dataSvc.RestoreNotificationHistory(data).pipe(
  //     map((response: any) => {
  //       return response;
  //     })
  //   );
  // }

  getSalesSummaryReport(data: any): any {
    return this.dataSvc.GetSalesSummaryReport(data).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getOrderNotification(data: any): any {
    return this.dataSvc.GetOrderNotification(data).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  setOpenToday(data: any): any {
    return this.dataSvc.SetOpenToday(data).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  setZoneOpenToday(data: any): any {
    return this.dataSvc.SetZoneOpenToday(data).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getReverseGeo(data: any): any {
    return this.dataSvc.GetReverseGeo(data).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  updateVenueGeoLocation(data: any): any {
    return this.dataSvc.UpdateVenueGeoLocation(data).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  restockProducts(data: any): any {
    return this.dataSvc.RestockProducts(data).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getProducts(data: any): any {
    return this.dataSvc.GetProducts(data)
      .pipe(
        tap(response => {
          if (response.success) {
            this._set('products', response.result);
          }
          // This code is outside the response.success if as this was the logic
          // before the refactor; it should be probably be included in the above if
          this.refreshProducts.emit({
            status: true
          });
        })
    );
  }

  getPickupQueue(data: any): any {
    return this.dataSvc.GetPickupQueue(data).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  setOrderReady(data: any) {
    return this.dataSvc.SetOrderReady(data)
      .pipe(
        tap(response => {
          if (response.success) {
            this.setLocal(true);
          }
        })
      );
  }

  undoOrderReady(data: any): any {
    return this.dataSvc.UndoOrderReady(data)
      .pipe(
        tap(response => {
          if (response.success) {
            this.setLocal(true);
          }
        })
      );
  }

  setOrderPickedUp(data: any): any {
    return this.dataSvc.SetOrderPickedUp(data)
      .pipe(
        tap(response => {
          if (response.success) {
            this.setLocal(true);
          }
        })
      );
  }

  setOrderDismissed(data: any): any {
    return this.dataSvc.SetOrderDismissed(data)
      .pipe(
        tap(response => {
          if (response.success) {
            this.setLocal(true);
          }
        })
      );
  }

  updateOrderTicketCount(data: any): any {
    return this.dataSvc.UpdateOrderTicketCount(data)
      .pipe(
        tap(response => {
          if (response.success) {
            this.setLocal(true);
          }
        })
      );
  }

  // compOrder(data: any): any {
  //   return this.dataSvc.CompOrder(data).pipe(
  //     map((response: any) => {
  //       return response;
  //     })
  //   );
  // }

  // refundOrder(data: any): any {
  //   return this.dataSvc.RefundOrder(data).pipe(
  //     map((response: any) => {
  //       return response;
  //     })
  //   );
  // }

  validateManagerPassword(data: any): any {
    return this.dataSvc.ValidateManagerPassword(data).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  checkForUpdates(): any {
    return this.dataSvc.GetLatestVersion().pipe(
      map((response: any) => {
        if (response.success) {
          const currentVersion = environment.VERSION.split(' ')[0].replace(
            /\D+/g,
            ''
          );
          const availableVersion = response.result.version
            .split(' ')[0]
            .replace(/\D+/g, '');

          response.result.hasUpdate = parseInt(availableVersion, 10) > parseInt(currentVersion, 10);
        }

        return response;
      })
    );
  }

  initSettings(): void {
    const data = {
      print: true,
      printstation: false,
      printstationaccept: false,
      printreceiptaccept: false,
      autoprint: false,
      autoprintreq: false,
      groupbycategory: false,
      disablerequests: false,
      qpkeepalive: false,
      btawake: false,
      notifymode: 'req',
      notifysound: '1',
      plugremind: true,
      requestfilter: 'all',
      language: 'en',
      translateInstructions: true,
      offlineCheck: true,
      idleTime: 240
    };
    this.setSettings(data);
    this.storeSvc.set('servi-staff-settings', JSON.stringify(data));
  }

  setSettings(settings: any) {
    this._set({
      autoprint: settings.autoprint,
      autoprintreq: settings.autoprintreq,
      groupbycategory: settings.groupbycategory,
      disablerequests: settings.disablerequests,
      idleTime: settings.idleTime,
      language: settings.language,
      notifymode: settings.notifymode,
      notifysound: settings.notifysound,
      plugremind: settings.plugremind,
      print: settings.print,
      printstation: settings.printstation,
      printstationaccept: settings.printstationaccept,
      printreceiptaccept: settings.printreceiptaccept,
      qpkeepalive: settings.qpkeepalive,
      btawake: settings.btawake,
      requestfilter: settings.requestfilter,
      offlineCheck: settings.offlineCheck,
      translateInstructions: settings.translateInstructions
    });
  }

  getOfflineCheck() {
    return this.state.venue.offlineCheck;
  }

  initSoundFiles() {
    if (window.cordova && this.platform.ready()) {
      this.nativeAudio.preloadComplex('notifyaudio_3', 'assets/sounds/notification_triple_ding_loud.mp3', 1, 1, 0).then(() => {
        this.nativeAudio.preloadComplex('notifyaudio_2', 'assets/sounds/notification_ding_dong.wav', 1, 1, 0).then(() => {
          this.nativeAudio.preloadComplex('notifyaudio_1', 'assets/sounds/notification_smooth.mp3', 1, 1, 0).then(() => {
            this.nativeAudio.preloadComplex('notifyaudio_0', 'assets/sounds/empty.mp3', 1, 1, 0).then(() => {
            });
          });
        });
      });
    }
  }

  setViewRequestModalOpen(value: boolean) {
    this._set('viewServerRequestModalOpen', value);
  }

  setPrinterErrorModalOpen(value: boolean) {
    this._set('printerErrorModalOpen', value);
  }

  setSelectedRequestsTab(tab: string) {
    this._set('selectedRequestsTab', tab)
  }

  clearRequestsTab() {
    this._set('selectedRequestsTab');
  }

  setLocal(value: boolean) {
    this._set('isLocal', value);
  }

  setPrinted(value: boolean) {
    this._set('isPrinted', value);
  }

  setStationPrinted(value: boolean) {
    this._set('isStationPrinted', value);
  }

  setPrintedReq(value: boolean) {
    this._set('isPrintedReq', value);
  }

  setProducts(value: any) {
    this._set('products', value);
  }

  setSearchDate(value: any) {
    this._set('searchDate', value);
  }

  getOrderTypeByAreaId(areaId: number) {
    const area = this.state.venue.areas.find(item => item.id === areaId);
    if (area && area.order_types) {
      return area.order_types;
    } else {
      return {delivery: false, dinein: true,  takeout: false};
    }
  }
}
