import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

declare let ga: (...args: any[]) => void;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  public UA_TRACKING_ID = environment.UA_TRACKING_ID;

  constructor() { }

  public emitEvent(eventCategory: string,
      eventAction: string,
      eventLabel: string = null,
      eventValue: number = null) {

      if (typeof ga === 'function') {
        console.log('GAEvent', eventCategory, eventAction, eventLabel, eventValue);

        ga('create', this.UA_TRACKING_ID, 'auto');
        ga('send', 'event', {
            eventCategory: eventCategory,
            eventLabel: eventLabel,
            eventAction: eventAction,
            eventValue: eventValue
        });
      }
  }
}
