import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { LocaleModule } from '@serviworldwide/core-js/modules/locale/locale.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { NativeAudio } from '@ionic-native/native-audio/ngx';
import { WebIntent } from '@ionic-native/web-intent/ngx';
import { Vibration } from '@ionic-native/vibration/ngx';

import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule } from '@angular/material/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';

import { BackgroundMode } from '@ionic-native/background-mode/ngx';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { AutoFocusDirective } from './directives/auto-focus.directive';
import { Device } from '@ionic-native/device/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { BatteryStatus } from '@ionic-native/battery-status/ngx';
import { Network } from '@ionic-native/network/ngx';
import { Insomnia } from '@ionic-native/insomnia/ngx';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { NetworkInterface } from '@ionic-native/network-interface/ngx';
import { CustomErrorHandler } from './classes/custom-error-handler';
import { ServerErrorInterceptor } from './classes/server-error-interceptor';
import { StarPRNT } from '@ionic-native/star-prnt/ngx';
import { DatePipe } from '@angular/common';
import { TruncateModule } from '@yellowspot/ng-truncate';
import { DataService } from './services/data.service';
import { environment } from '../environments/environment';
import { OfflineDialogComponent } from './partials/offline-dialog/offline-dialog.component';
import { SharedModule } from './shared.module';
import 'hammerjs';
import { MomentModule } from 'angular2-moment'; // optional, provides moment-style pipes for date formatting

@NgModule({
    declarations: [AppComponent, AutoFocusDirective, OfflineDialogComponent],
    entryComponents: [OfflineDialogComponent],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        DragDropModule,
        ScrollingModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatChipsModule,
        MatDatepickerModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatSelectModule,
        MatSnackBarModule,
        MatDialogModule,
        TruncateModule,
        SharedModule,
        DeviceDetectorModule,
        LocaleModule,
        LocaleModule.forRoot(),
        MomentModule
    ],
    providers: [
        BackgroundMode,
        StatusBar,
        SplashScreen,
        Geolocation,
        Device,
        ScreenOrientation,
        BatteryStatus,
        Network,
        NetworkInterface,
        Insomnia,
        Keyboard,
        DatePipe,
        StarPRNT,
        InAppBrowser,
        NativeAudio,
        WebIntent,
        Vibration,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        DataService,
        ...((environment.ENVIRONMENT !== 'dev' && environment.ENVIRONMENT !== 'staging') ? [{ provide: ErrorHandler, useClass: CustomErrorHandler }] : []),
        {
          provide: HTTP_INTERCEPTORS,
          useClass: ServerErrorInterceptor,
          multi: true
        },
        { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2500 } },
        LocaleModule.getLocaleProvider()
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
