import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { environment } from '../../environments/environment';
import { LocalStorageService } from './local-storage.service';
import { VenueService } from './venue.service';
import { Device } from '@ionic-native/device/ngx';
import { stripSummaryForJitFileSuffix } from '@angular/compiler/src/aot/util';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {
  public extra: any;
  private VERSION = environment.VERSION;

  constructor(
    private dataSvc: DataService,
    private venueSvc: VenueService,
    private device: Device,
    private storageSvc: LocalStorageService
  ) {}

  logError({
    message,
    stack,
    location
  }: {
    message: string;
    stack: string;
    location: string;
  }) {
    if (this.extra != null) {
      stack = this.extra;
    }

    const payload = {
      stackTrace: stack,
      errorMessage: message,
      app: 'staff_app: ' + this.VERSION + ' | UUID: ' + this.device.uuid,
      agent: navigator.userAgent,
      location: location
    };

    if (message.indexOf('No Internet Connection') !== -1) {
      // do not log these error to backend
      this.dataSvc.LogError(payload).subscribe();
    }
  }

  logConnectionErrorToLocalStorage(offline: boolean) {
    const log = this.storageSvc.get('servi-connection-errorlog');
    const d = new Date();
    const data = { offline: offline, date: d.toString() };
    this.storageSvc.append('servi-connection-errorlog', data);
  }

  clearConnectionErrorsInLocalStorage() {
    this.storageSvc.set('servi-connection-errorlog', []);
  }

  getConnectionErrorsInLocalStorage() {
    return this.storageSvc.get('servi-connection-errorlog');
  }
}
