import { Injectable, EventEmitter, Output } from '@angular/core';

import { Device } from '@ionic-native/device/ngx';
import { BackgroundModeService } from 'src/app/services/background-mode.service';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { DeviceData } from '../models/device-data';
import { Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { DataService } from './data.service';
import { map } from 'rxjs/operators';
import { VenueService } from './venue.service';
import { WaitersService } from './waiters.service';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from './local-storage.service';
import { NotificationService } from './notification.service';
import { SeatsService } from './seats.service';
import { ZonesService } from './zones.service';
import { LoggingService } from './logging.service';
import { PrintService } from './print.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { StateService } from '@services/state.service';

declare var navigator: any;
declare var window: any;

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  @Output() offline: EventEmitter<any> = new EventEmitter();

  public MODE: string;
  public deviceData = {} as DeviceData; // DeviceData interface
  public appVersion = environment.VERSION;

  constructor(
    private device: Device,
    private platform: Platform,
    private router: Router,
    private screen: ScreenOrientation,
    private geolocation: Geolocation,
    private bgmode: BackgroundModeService,
    private venueSvc: VenueService,
    private waiterSvc: WaitersService,
    private seatsSvc: SeatsService,
    private zonesSvc: ZonesService,
    private storeSvc: LocalStorageService,
    private notifySvc: NotificationService,
    private logSvc: LoggingService,
    private dataSvc: DataService,
    private printSvc: PrintService,
    private deviceDetector: DeviceDetectorService,
    private state: StateService
  ) {
    this.deviceData.offline = false;
    this.initDeviceData();
    this.MODE = this.deviceDetector.isTablet() ? 'tablet' : this.deviceDetector.isMobile() ? 'phone' : 'desktop';
  }

  initDeviceData() {
    this.platform.ready().then(() => {
      this.deviceData.uuid = this.device.uuid;
      this.deviceData.bgEnabled = this.bgmode.isEnabled();
      this.deviceData.isTablet = this.isTablet();
      this.deviceData.cordova = this.device.cordova;
      this.deviceData.model = this.device.model;
      this.deviceData.platform = this.device.platform;
      this.deviceData.version = this.device.version;
      this.deviceData.manufacturer = this.device.manufacturer;
      this.deviceData.isVirtual = this.device.isVirtual;
      this.deviceData.serial = this.device.serial;
      this.deviceData.orientation = this.screen.type;
      this.deviceData.network = navigator.connection.type;
      this.deviceData.route = this.router.url;
      this.deviceData.venueId = this.state.venue.id;
      this.deviceData.waiterId = this.waiterSvc.waiter.id;

      this.deviceData.info = this.deviceDetector.getDeviceInfo();
    });
  }

  getMode() {
    return this.MODE;
  }

  isDesktop() {
    this.MODE = this.deviceDetector.isTablet() ? 'tablet' : this.deviceDetector.isMobile() ? 'phone' : 'desktop';
    return this.MODE === 'desktop';
  }

  isTablet() {
    this.MODE = this.deviceDetector.isTablet() ? 'tablet' : this.deviceDetector.isMobile() ? 'phone' : 'desktop';
    return this.MODE === 'tablet';
  }

  isPhone() {
    this.MODE = this.deviceDetector.isTablet() ? 'tablet' : this.deviceDetector.isMobile() ? 'phone' : 'desktop';
    return this.MODE === 'phone';
  }

  getDeviceInfo() {
    return this.deviceDetector.getDeviceInfo();
  }

  isIos() {
    return navigator.userAgent.match(/(iPad|iPhone|iPod)/i) ? true : false;
  }

  setBatteryStatus(info) {
    this.deviceData.batteryLevel = info.level;
    this.deviceData.isPlugged = info.isPlugged;
  }

  getBatteryStatus() {
    return { level: this.deviceData.batteryLevel, isPlugged: this.deviceData.isPlugged };
  }

  setPosition(position: any) {
    this.deviceData.position = position;
  }

  getPosition() {
    return this.deviceData.position;
  }

  setOnPause() {
    this.deviceData.isPaused = true;
  }

  setOnResume() {
    this.deviceData.isPaused = false;
  }

  pingPrinter() {
    if (this.state.venue.autoprint || this.state.venue.autoprintreq || this.state.venue.printstation) {
      this.printSvc.sendPing();
    }
  }

  setWifiIpAddress(ip) {
    this.deviceData.wifiIpAddress = ip;
  }

  setIpAddress(ip) {
    this.deviceData.carrierIpAddress = ip;
  }

  getIpAddress() {
    return this.deviceData.carrierIpAddress;
  }

  getWifiIpAddress() {
    return this.deviceData.wifiIpAddress;
  }

  getDeviceId() {
    return !!window.cordova ? this.deviceData.uuid : 'ABCDE12345FGHI';
  }

  isOnline() {
    if (navigator) {
      if (
        navigator.connection.type === 'none' ||
        navigator.connection.type === 'unknown' ||
        navigator.connection.type === 'Connection.NONE' ||
        navigator.connection.type === 'Connection.UNKNOWN'
      ) {
        return false;
      }
    }
    return true;
  }

  setOfflineStatus(status: boolean) {
    this.offline.emit({
      status: status
    });
    this.deviceData.offline = status;
  }

  getOfflineStatus() {
    return this.deviceData.offline;
  }

  heartbeat() {
    let loginUser = null;
    const storage = window.localStorage;
    try {
      loginUser = storage.getItem('reloginVenueUser');
    } catch (e) { }

    this.deviceData.venueId = this.state.venue.id;
    this.deviceData.venueToken = this.state.venue.token;
    this.deviceData.waiterId = this.waiterSvc.waiter.id;
    this.deviceData.username = loginUser;
    this.deviceData.printEnabled = this.state.venue.print;
    this.deviceData.autoPrint = this.state.venue.autoprint;
    this.deviceData.autoPrintReq = this.state.venue.autoprintreq;
    this.deviceData.printStationEnabled = this.state.venue.printstation;
    this.deviceData.location = this.router.url;
    this.deviceData.appVersion = this.appVersion;
    this.deviceData.storedPrinters = this.storeSvc.get(`${this.state.venue.token}_servi-printers`);
    // this.deviceData.outageData = this.logSvc.getConnectionErrorsInLocalStorage();

    try {
      const settingsString = this.storeSvc.get('servi-staff-settings');
      if (settingsString) {
        this.deviceData.appSettings = JSON.parse(settingsString);
      } else {
        this.deviceData.appSettings = {};
      }
    } catch (error) { }

    setTimeout(() => {
      // console.log('HEARTBEAT');
      //console.log(this.deviceData);
      // console.log(JSON.stringify(this.deviceData));
      this.dataSvc.heartbeat(this.deviceData).subscribe(
        (response: any) => {

          console.log('HB-RESPONSE');
          console.log(JSON.stringify(response));


          if (response.success) {
            if (response.result) {
              this.venueSvc.setHours(response.result.hours);
            }

            // const isOpenCurrently = this.venueSvc.venue.mainHours.isOpenNow !== undefined ? this.venueSvc.venue.mainHours.isOpenNow : 0;
            // const isOpenNow = response.result.isOpenNow !== undefined ? response.result.isOpenNow : 0;
            // if (isOpenNow !== 0 && isOpenCurrently !== 0) {
            //   if (isOpenNow !== isOpenCurrently) {
            //     // open status has changed
            //     const thisData = { venueId: this.venueSvc.venue.id };
            //     this.venueSvc.broadcastMainVenueHours(thisData).subscribe((resp: any) => {});
            //   }
            // }
          }

          // this.venueSvc.getMainVenueHours({ venueId: this.venueSvc.venue.id }).subscribe((res: any) => {
          //     if (res.success) {
          //         this.venueSvc.setHours(res.result);
          //     }
          // });
        }
      );
    });
  }
}
