import { Injectable, EventEmitter } from '@angular/core';
import { DataService } from './data.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProductSpecialsService {
  public refreshSpecials: EventEmitter<any> = new EventEmitter();

  constructor(private dataSvc: DataService) {}

  getSpecials(data: any): any {
    return this.dataSvc.GetSpecials(data).pipe(
      map(
        (response: any) => {
         // this.refreshSpecials.emit(response);
          return response;
        }
      )
    );
  }

  update(data: any): any {
    return this.dataSvc.UpdateSpecial(data).pipe(
      map(
        (response: any) => {
          this.refreshSpecials.emit(response);
          return response;
        }
      )
    );
  }

  setStatus(data: any): any {
    return this.dataSvc.SetSpecialStatus(data).pipe(
      map(
        (response: any) => {
          return response;
        }
      )
    );
  }
}
