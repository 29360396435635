import { Injectable } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { VenueService } from 'src/app/services/venue.service';
import { DatePipe } from '@angular/common';
import { ProductsService } from './products.service';
import { map } from 'rxjs/operators';
import { TranslationService } from '@serviworldwide/core-js/modules/locale';
import { StateService } from '@services/state.service';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  constructor(
    private datePipe: DatePipe,
    private translationSvc: TranslationService,
    private venueSvc: VenueService,
    private productsSvc: ProductsService,
    private dataSvc: DataService,
    private state: StateService
  ) {}

  getTicketCount(data: any): any {
    return this.dataSvc.GetTicketCount(data).pipe(
      map(
        (response: any) => {
          return response;
        },
        (error: any) => {
          return error;
        }
      )
    );
  }

  getCatalogGroupQty(reportData: any, group: any) {
    let grandTotal = 0;
    let total = 0;

    reportData.forEach((rItem: any) => {
      total = rItem.products
        .filter((product: any) => {
          return group.id === product.menuGroupId;
        })
        .reduce((acc: number, item: any) => {
          return acc + item.qty;
        }, 0);

      grandTotal = grandTotal + total;
    });

    return grandTotal;
  }

  getCatalogGroupPrice(reportData: any, group: any) {
    let grandTotal = 0;
    let price = 0;

    reportData.forEach((rItem: any) => {
      price = rItem.products
        .filter((product: any) => {
          return group.id === product.menuGroupId;
        })
        .reduce((acc: number, item: any) => {
          return acc + (Number(item.price) + Number(item.optionsPrice)) * Number(item.qty);
        }, 0);

      grandTotal = grandTotal + price;
    });

    return grandTotal;
  }

  getGrandTotal(reportData: any) {
    return reportData.reduce((acc: number, item: any) => {
      return acc + Number(item.grandTotal);
    }, 0);
  }

  getFeeTotal(reportData: any) {
    return reportData.reduce((acc: number, item: any) => {
      return acc + Number(item.feeTotal);
    }, 0);
  }

  getTipsTotal(reportData: any) {
    return reportData.reduce((acc: number, item: any) => {
      return acc + Number(item.tipTotal);
    }, 0);
  }

  getRefundTotal(reportData: any) {
    return reportData.reduce((acc: number, item: any) => {
      return acc + Number(item.refundTotal);
    }, 0);
  }

  getCompTotal(reportData: any) {
    return reportData.reduce((acc: number, item: any) => {
      return acc + Number(item.compTotal);
    }, 0);
  }

  getDiscountTotal(reportData: any) {
    return reportData.reduce((acc: number, item: any) => {
      return acc + Number(item.discountTotal);
    }, 0);
  }

  getTaxTotal(reportData: any) {
    return reportData.reduce((acc: number, item: any) => {
      return acc + Number(item.taxTotal);
    }, 0);
  }

  calcSubTotal(reportData: any) {
    return (
      Number(this.getGrandTotal(reportData)) -
      Number(this.getTipsTotal(reportData)) -
      Number(this.getTaxTotal(reportData)) -
      Number(this.getFeeTotal(reportData))
    );
  }

  getFeeLabel(reportData: any) {
    return reportData.length > 0 ? reportData[0].feeLabel : 'Fees';
  }

  getTotalOrders(reportData: any) {
    return reportData.reduce((acc: number, item: any) => {
      return acc + Number(item.totalOrders);
    }, 0);
  }

  getTotalItems(reportData: any) {
    return reportData.reduce((acc: number, item: any) => {
      return acc + Number(item.totalItems);
    }, 0);
  }

  getProductIdObj(reportData: any) {
    let merged = [];
    reportData.forEach((rData: any) => {
      merged = merged.concat(rData.productIdObj.filter((item: any) => merged.indexOf(item) < 0));
    });
    return merged;
  }

  getItemName(reportData: any, productIdStr: string) {
    const parts = productIdStr.split('-');
    const productId = Number(parts[0]);
    const variantId = parts[1] !== undefined && parts[1] !== '' ? Number(parts[1]) : null;
    let filtered: any;
    let name: string;
    let item: any;

    name = ' - ';
    for (item of reportData) {
      filtered = item.products.filter((product: any) => {
        return product.productId === productId && product.variantId === variantId;
      });
      if (filtered[0] !== undefined) {
        name = filtered[0].name + (filtered[0].variantName != null ? ' - ' + filtered[0].variantName : '');
        break;
      }
    }

    // reportData.forEach((item: any) => {
    //     filtered = item.products.filter((product: any) => {
    //         return (product.productId === productId && product.variantId === variantId);
    //     });
    //     if (filtered[0] !== undefined) {
    //         name = filtered[0].name + ((filtered[0].variantName != null) ? ' - ' + filtered[0].variantName : '');
    //     }
    // });

    return this.translationSvc.translateContent(name);
  }

  sumItemQty(reportData: any, productIdStr: string) {
    const parts = productIdStr.split('-');
    const productId = Number(parts[0]);
    const variantId = parts[1] !== undefined && parts[1] !== '' ? Number(parts[1]) : null;

    let qty: number;
    let totalQty = 0;
    reportData.forEach((rData: any) => {
      qty = rData.products
        .filter((product: any) => {
          return product.productId === productId && product.variantId === variantId;
        })
        .reduce((acc: any, item: any) => {
          return acc + item.qty;
        }, 0);

      totalQty = totalQty + qty;
    });

    return totalQty;
  }

  sumItemPrice(reportData: any, productIdStr: string) {
    const parts = productIdStr.split('-');
    const productId = Number(parts[0]);
    const variantId = parts[1] !== undefined && parts[1] !== '' ? Number(parts[1]) : null;

    let sum: number;
    let totalSum = 0;
    reportData.forEach((rData: any) => {
      sum = rData.products
        .filter((product: any) => {
          return product.productId === productId && product.variantId === variantId;
        })
        .reduce((acc: any, item: any) => {
          return acc + (Number(item.price) + Number(item.optionsPrice)) * Number(item.qty);
        }, 0);

      totalSum = totalSum + sum;
    });

    return totalSum;
  }

  sumNetAndFees(reportData: any) {
    return Number(this.getFeeTotal(reportData)) + Number(this.calcSubTotal(reportData));
  }

  sumTaxRateTotals(reportData: any) {
    const totals = this.getTaxRateTotals(reportData);
    const taxTotal = totals.reduce((acc: any, obj: any) => {
      return acc + Number(obj.taxTotal);
    }, 0);

    const totalsIncl = this.getTaxRateIncludedTotals(reportData);
    const taxInclTotal = totalsIncl.reduce((acc: any, obj: any) => {
      return acc + Number(obj.totalTax);
    }, 0);

    return Number(taxTotal) + Number(taxInclTotal);
  }

  getTaxRateTotals(reportData: any) {
    let grandTotal: number;
    let total: number;
    let sum: number;
    let rateId: number;
    let rateLabel: string;
    let rate: number;
    const filtered = [];

    this.state.venue.taxRates.forEach(item => {
      rateId = item.id;
      rateLabel = item.title;
      grandTotal = 0;

      reportData.forEach((rItem: any) => {
        total = rItem.products
          .filter((product: any) => {
            return product.taxRateId === item.id && !product.taxIncluded;
          })
          .reduce((acc: number, pItem: any) => {
            sum = (Number(pItem.price) + Number(pItem.optionsPrice)) * Number(pItem.qty);
            rate = pItem.taxRate;
            return acc + sum * (Number(rate) / 100);
          }, 0);

        grandTotal = grandTotal + total;
      });

      filtered.push({ taxRateLabel: rateLabel, taxTotal: this.roundValue(grandTotal), taxRate: rate });
    });

    return filtered;
  }

  getTaxRateIncludedTotals(reportData: any) {
    let totalTax: number;
    let sum: number;
    let rateId: number;
    let rateLabel: string;
    let rate: number;
    let totalWithTax: number;
    let totalBeforeTax: number;
    let thisBeforeTax: number;
    let grandTotalTax: number;
    let grandTotalWithTax: number;
    let grandTotalBeforeTax: number;
    const textVat = this.translationSvc.translateStatic('text_vat');
    const filtered = [];

    this.state.venue.taxRates.forEach(item => {
      rateId = item.id;
      rateLabel = item.title;
      totalWithTax = 0;
      totalBeforeTax = 0;
      grandTotalTax = 0;
      grandTotalWithTax = 0;
      grandTotalBeforeTax = 0;

      reportData.forEach((rItem: any) => {
        totalTax = rItem.products
          .filter((product: any) => {
            return product.taxRateId === item.id && product.taxIncluded;
          })
          .reduce((acc: number, pItem: any) => {
            sum = (Number(pItem.price) + Number(pItem.optionsPrice)) * Number(pItem.qty);
            totalWithTax = totalWithTax + sum;
            rate = pItem.taxRate;

            thisBeforeTax = Number(sum) / (Number(rate) / 100 + 1);
            totalBeforeTax = totalBeforeTax + thisBeforeTax;

            return acc + (sum - thisBeforeTax);
          }, 0);

        grandTotalBeforeTax = grandTotalBeforeTax + totalBeforeTax;
        grandTotalWithTax = grandTotalWithTax + totalWithTax;
        grandTotalTax = grandTotalTax + totalTax;
      });

      filtered.push({
        taxRateLabel: textVat,
        taxRate: rate,
        totalBeforeTax: grandTotalBeforeTax,
        totalWithTax: grandTotalWithTax,
        totalTax: grandTotalTax
      });
    });

    return filtered;
  }

  getTaxRateIncludedTotalsByNotification(notification: any) {
    let pObj: any;
    const reportData = [];
    let taxInclTotals: any;

    if (!!notification && !!notification.products) {
      notification.products.forEach((product: any) => {
        pObj = this.productsSvc.getById(product.product_id);
        if (!!pObj) {
          product.taxIncluded = pObj.taxIncluded;
          product.taxRate = pObj.taxRate;
          product.taxRateId = pObj.taxRateId;
          product.optionsPrice = product.options != null && product.options.length > 0 ? this.getOptionsPrice(product) : 0;
        }
      });

      reportData.push(notification);
      taxInclTotals = this.getTaxRateIncludedTotals(reportData);
    }

    return taxInclTotals;
  }

  getShiftReportTaxRateIncludedTotals(reportData: any) {
    let totalTax: number;
    let sum: number;
    let rateId: number;
    let rateLabel: string;
    let rate: number;
    let totalWithTax: number;
    let totalBeforeTax: number;
    let thisBeforeTax: number;
    let grandTotalTax: number;
    let grandTotalWithTax: number;
    let grandTotalBeforeTax: number;
    let products: any;
    let optionsPrice: number;
    const textVat = this.translationSvc.translateStatic('text_vat');
    const filtered = [];

    this.state.venue.taxRates.forEach(item => {
      rateId = item.id;
      rateLabel = item.title;
      totalWithTax = 0;
      totalBeforeTax = 0;
      grandTotalTax = 0;
      grandTotalWithTax = 0;
      grandTotalBeforeTax = 0;
      optionsPrice = 0;

      reportData.forEach((rItem: any) => {
        try {
          products = JSON.parse(rItem.data);
        } catch (e) {}

        totalTax = products.products.filter((product: any) => {
             return product.product.taxRateId === item.id && product.product.taxIncluded;
         })
          .reduce((acc: number, pItem: any) => {

            optionsPrice = Number(this.getOptionsPrice(pItem.product));
            if (!!pItem.product.selectedVariant) {
              sum = (Number(pItem.product.selectedVariant.price) + optionsPrice) * Number(pItem.product.qty);
            } else {
              sum = (Number(pItem.product.price) + optionsPrice) * Number(pItem.product.qty);
            }

            totalWithTax = totalWithTax + sum;
            rate = Number(pItem.product.taxRate);

            thisBeforeTax = Number(sum) / (rate / 100 + 1);
            totalBeforeTax = totalBeforeTax + thisBeforeTax;

            return acc + (sum - thisBeforeTax);
          }, 0);

        grandTotalBeforeTax = grandTotalBeforeTax + totalBeforeTax;
        grandTotalWithTax = grandTotalWithTax + totalWithTax;
        grandTotalTax = grandTotalTax + totalTax;
      });

      filtered.push({
        taxRateLabel: textVat,
        taxRate: (rate != undefined) ? this.formatNumber(rate): 0,
        totalBeforeTax: this.formatNumber(grandTotalBeforeTax),
        totalWithTax: this.formatNumber(grandTotalWithTax),
        totalTax: this.formatNumber(grandTotalTax)
      });
    });

    console.log('TAX-FILTERED', filtered);

    return filtered;
  }

  getOptionsPrice(product: any) {
    let itemTotal = 0;
    return product.options.reduce((total: number, option: any) => {
      if (option.items != null) {
        itemTotal = option.items.reduce((acc: number, item: any) => {
          if (item.item_price) {
            return acc + Number(item.item_price);
          } else {
            return acc + Number(item.price);
          }
        }, 0);
      }

      return total + itemTotal;
    }, 0);
  }

  getRefundsArr(reportData: any) {
    const refundsArr = [];
    reportData.forEach((rData: any) => {
      if (rData.refunds) {
        rData.refunds.forEach((refund: any) => {
          refundsArr.push(refund);
        });
      }
    });

    return refundsArr;
  }

  roundValue(num: number) {
    return Math.round(num * Math.pow(10, 2)) / Math.pow(10, 2);
  }

  formatNumber(n: number) {
    const currencyCode = this.state.venue.currency?.code || 'USD';
    let formatted: string;

    switch (currencyCode) {
      case 'EUR':
        formatted = n.toLocaleString('de-DE', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
        break;

      case 'GBP':
        formatted = n.toLocaleString('en-GB', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
        break;

      case 'SEK':
        formatted = n.toLocaleString('sv-SE', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
        break;

      case 'AED':
        formatted = n.toLocaleString('ar-SA', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
        break;

      case 'KWD':
        formatted = n.toLocaleString('ar-SA', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
        break;

      case 'SAR':
        // formatted = n.toLocaleString('ar-SA', {
        formatted = n.toLocaleString('en-GB', { // use comma delimiter but keep English numbers
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
        break;

      case 'MYR':
        formatted = n.toLocaleString('ms-BN', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
        break;

      case 'INR':
        formatted = n.toLocaleString('hi-IN', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
        break;

      case 'SGD':
        formatted = n.toLocaleString('zh-CN', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
        break;

      case 'PHP':
        formatted = n.toLocaleString('es-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
        break;

      case 'AUD':
        formatted = n.toLocaleString('en-AU', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
        break;

      case 'NZD':
        formatted = n.toLocaleString('en-NZ', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
        break;

      default:
        formatted = n.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
    }

    return formatted;
  }
}
