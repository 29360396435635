import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorService } from '../services/error.service';
import { NotificationService } from '../services/notification.service';
import { LoggingService } from '../services/logging.service';

@Injectable()
export class CustomErrorHandler implements ErrorHandler {
    // Error handling to be loaded first so manually inject the services.
    constructor(private injector: Injector) { }

    handleError(error: Error | TypeError | HttpErrorResponse) {
        const errorService = this.injector.get(ErrorService);
        const logger = this.injector.get(LoggingService);
        const notifier = this.injector.get(NotificationService);

        let message: string;
        let stackTrace: string;
        const location = window.location.href;

        console.log('ERROR', error);

        if (error instanceof HttpErrorResponse) {
            // Server Error
            message = errorService.getServerMessage(error);
            stackTrace = errorService.getServerStack(error);
            logger.logError({ message: message, stack: stackTrace, location: location });
            notifier.showError('Server Error');
        } else if ((error instanceof TypeError) || (error instanceof Error)) {
            // Client Error
            message = errorService.getClientMessage(error);

            // ignore these errors
            if (message.indexOf('for the specified audio id') !== -1) {
            } else {
                // stacktrace-js for a prettier stacktrace
                errorService.getJSStackTrace(error).then((trace: string) => {
                    stackTrace = trace;
                    logger.logError({ message: message, stack: stackTrace, location: location });
                });
                notifier.showError('Application Error');
            }
        } else {
            // Unknown Error
            if (error !== 'cordova_not_available') { // skip cordova errors
              message = errorService.getClientMessage(error);
              stackTrace = errorService.getClientStack(error);
              logger.logError({ message: message, stack: stackTrace, location: location });
              notifier.showError('Unknown Error3');
            }
        }

        setTimeout(() => {
            throw error; // throw error to New Relic
        }, 0);
    }
}
