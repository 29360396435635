import { Injectable, Output, Injector } from '@angular/core';
import {
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';
import { DeviceService } from '../services/device.service';
import { ErrorService } from '../services/error.service';
import { NotificationService } from '../services/notification.service';
import { LoggingService } from '../services/logging.service';

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {
  public errCnt: number;
  public isOffline: boolean;
  public tOut: number;
  public tIn: number;

  constructor(
    private logSvc: LoggingService,
    private deviceSvc: DeviceService,
    private injector: Injector
  ) {
    this.errCnt = 0;
    this.isOffline = false;
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      map((response: any) => {
        // console.log('Request-URL', request.url);

        return response;
      }),
      catchError((error: any) => {
       // console.log('ERROR-SERVER-INTERCEPTOR');
       // console.log(JSON.stringify(error));
        if (error.status === 401) {
          // refresh token
          // console.log('ServerErrorInterceptor: 401');
        } else {
          if (error.error) {
            if (error.error.text != null) {
              this.logSvc.extra = error.error.text.replace(/(<([^>]+)>)/gi, '');
            } else if (error.error.message != null) {
              this.logSvc.extra = error.error.message.replace(
                /(<([^>]+)>)/gi,
                ''
              );
            }
          }
          const errorService = this.injector.get(ErrorService);
          const message = errorService.getServerMessage(error);
          const stackTrace = errorService.getServerStack(error);
          const location = window.location.href;
          const logIt =
            request.url.indexOf('waiter/system/error') === -1 &&
            message.indexOf('No Internet') === -1
              ? true
              : false;
          if (!this.isOffline && logIt) {
            this.logSvc.logError({
              message: message,
              stack: stackTrace,
              location: location
            });
          }
        }

        if (error) throw error;

        return of({});
      })
    );
  }
}
