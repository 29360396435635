import {
  Component,
  OnInit,
  Input,
  AfterContentInit,
  AfterViewInit
} from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { VenueService } from 'src/app/services/venue.service';
import { StateService } from '@services/index';
import { Venue } from '@models/index';

@Component({
  selector: 'app-reprint-stations-modal',
  templateUrl: './reprint-stations-modal.component.html',
  styleUrls: ['./reprint-stations-modal.component.scss']
})
export class ReprintStationsModalComponent implements OnInit, AfterContentInit {
  @Input() item: any;

  public venue: Venue;
  public printers: any;
  public showPrintAll: boolean;
  public printerIds: any;

  constructor(
    private modalCtrl: PopoverController,
    private venueSvc: VenueService,
    private state: StateService
  ) {
    this.venue = this.state.venue;
    this.printers = [];
    this.showPrintAll = false;
  }

  ngOnInit() {}

  ngAfterContentInit() {
    this.printerIds = this.getPrinterIds(this.item);
    if (this.venue.printers) {
      this.printers = this.venue.printers.filter(
        printer =>
          printer.type !== 'receipt' &&
          this.printerIds.indexOf(printer.id) !== -1
      );
    }
    this.showPrintAll = (this.printers.length > 1) ? true : false;

    // console.log('Printers', this.printers);
    // console.log('Item', this.item);
    // console.log('printerIds', this.printerIds);
  }

  getPrinterIds(item: any): any {
    const ids = [];
    if (!!item.products) {
      item.products.map((product: any) => {
        if (product.printer_id && ids.indexOf(product.printer_id) === -1) {
          ids.push(product.printer_id);
        }
      });
    }

    return ids;
  }

  dismiss(printer: any) {
    this.modalCtrl.dismiss({
      printer: printer,
      item: this.item
    });
  }
}
