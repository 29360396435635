import { Injectable, OnInit, Output, EventEmitter } from '@angular/core';
import { Seats } from '../models/seats';
import { PusherService } from './pusher.service';
import { DataService } from './data.service';
import { VenueService } from './venue.service';
import { map } from 'rxjs/operators';
import { WaitersService } from './waiters.service';
import { Observable } from 'rxjs';
import { StateService } from '@services/state.service';

@Injectable({
  providedIn: 'root',
})
export class SeatsService {
  @Output() refreshSeats: EventEmitter<any> = new EventEmitter();

  public seats = {} as Seats;
  public processing = 1;
  public totalSeats: number;
  public descriptors: any = [];

  constructor(
    private pusher: PusherService,
    private venueSvc: VenueService,
    private waitersSvc: WaitersService,
    private dataSvc: DataService,
    private state: StateService
  ) {
    this.seats.allSeats = [];
    this.seats.mySeats = [];
    this.seats.availableSeats = [];
  }

  init() {
    this.descriptors = [];
    this.pusher.bindVenue('TableChanged', (item: any) => {
      this.loadOneSeatInformation(item);
    });

    return this.loadSeats();
  }

  processSeat(seat: any): Observable<any> {
    return this.dataSvc
      .ProcessSeat({
        id: this.state.venue.id,
        seat: seat,
        waiterId: this.waitersSvc.waiter.id,
      })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  processDescriptors(response: any) {
    this.seats.mySeats.length = 0;
    this.seats.availableSeats.length = 0;
    response.forEach((item: any) => {
      this.updateSeat(item.descriptor);
    });
    this.seats.mySeats.sort(this.compareSeatsByName);
    this.seats.availableSeats.sort(this.compareSeatsByName);
    this.seats.allSeats.sort(this.compareSeatsByName);
    console.log('SEATS', this.seats);
  }

  loadSeats() {
    return this.dataSvc.GetSeats({ id: this.state.venue.id, waiterId: this.waitersSvc.waiter.id }).pipe(
      map((response) => {
        if (response.success) {
          return response.result;
        }
      })
    );
  }

  loadOneSeatInformation(seat: any) {
    return this.dataSvc
      .LoadOneSeatInformation({
        tableId: seat.id,
        waiterId: this.waitersSvc.waiter.id,
      })
      .pipe(
        map(
          (response) => {
            if (response.success) {
              this.updateSeat(response.result.table);
            }
            return response;
          },
          (error: any) => {
            console.log('Seats::loadOneZoneInformation::ERROR', error);
          }
        )
      );
  }

  addSeatToActive(seatId) {
    return this.dataSvc
      .AddSeatToActive({ tableId: seatId, waiterId: this.waitersSvc.waiter.id })
      .pipe(
        map(
          (response) => {
            if (response.success) {
              const item = this.removeSeat(this.seats.availableSeats, seatId);
              this.seats.mySeats.push(item);
            }
            return response;
          },
          (error: any) => {
            console.log('Seats::addSeatToActive::ERROR', error);
          }
        )
      );
  }

  releaseActiveSeat(seatId: any) {
    return this.dataSvc
      .ReleaseActiveSeat({
        tableId: seatId,
        waiterId: this.waitersSvc.waiter.id,
      })
      .pipe(
        map(
          (response) => {
            if (response.success) {
              const item = this.removeSeat(this.seats.mySeats, seatId);
              this.seats.availableSeats.push(item);
            }
            return response;
          },
          (error: any) => {
            console.log('Seats::releaseActiveSeat::ERROR', error);
          }
        )
      );
  }

  updateSeat(item: any) {
    const seatInfo = this.searchSeatLocal(item.id);
    let seat: any;
    if (seatInfo == null) {
      seat = {}; // new one
    } else {
      seat = seatInfo.seat;
      this.removeSeat(seatInfo.list, seatInfo.seat.id);
    }
    seat.id = item.id;
    seat.name = item.name;
    seat.token = item.token;
    seat.active = true;
    seat.zones = item.zones;
    seat.isMine = item.isMine;

    if (seat.isMine) {
      this.seats.mySeats.push(seat);
    } else {
      this.seats.availableSeats.push(seat);
    }

    this.seats.allSeats.push(seat);
  }

  removeSeat(seats, seatId) {
    for (let i = seats.length; i--; ) {
      if (seats[i].id === seatId) {
        const item = seats[i];
        seats.splice(i, 1);
        return item;
      }
    }
  }

  isMySeat(seatId: number) {
    return this.seats.mySeats.find((item: any) => item.id === seatId);
  }

  attachNotification(notification) {
    const seatInfo = this.searchSeatLocal(notification.tableId);
    if (seatInfo != null) {
      seatInfo.seat.active = true;
      seatInfo.seat.notification = notification;
    }
  }

  removeNotification(notification) {
    const seatInfo = this.searchSeatLocal(notification.tableId);
    if (seatInfo != null) {
      seatInfo.seat.notification = null;
    }
  }

  searchSeatLocal(seatId) {
    let seat = this.searchTableInArray(this.seats.mySeats, seatId);
    if (seat != null) {
   //   this.updateOwners(seat, -1);
      return { seat: seat, list: this.seats.mySeats };
    }
    seat = this.searchTableInArray(this.seats.availableSeats, seatId);
    if (seat != null) {
    //  this.updateOwners(seat, -1);
      return { seat: seat, list: this.seats.availableSeats };
    }

    return null;
  }

  searchTableInArray(array, tableId) {
    for (let i = array.length; i--; ) {
      if (array[i].id === tableId) {
        return array[i];
      }
    }
    return null;
  }

  // updateOwners(table, sign) {
  //   table.waiters.forEach((waiter: any) => {
  //     if (this.seats.seatOwners[waiter]) {
  //       this.seats.seatOwners[waiter] = 0;
  //     }
  //     this.seats.seatOwners[waiter] += sign;
  //   });
  // }

  compareSeatsByName(table1, table2) {
    const table1Nr = parseInt(table1.name, 10);
    const table2Nr = parseInt(table2.name, 10);

    if (table1Nr > table2Nr || isNaN(table2Nr)) {
      return 1;
    } else if (table1Nr === table2Nr) {
      return 0;
    } else {
      return -1;
    }
  }

  getAllStatusByWaiter(data: any): any {
    return this.dataSvc.GetAllStatusByWaiter(data).pipe(
      map(
        (response: any) => {
          if (data.emit) {
            this.refreshSeats.emit({ status: true });
          }
          return response;
        },
        (error: any) => {
          return error;
        }
      )
    );
  }
}
