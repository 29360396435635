import { EventEmitter, Injectable, Output } from '@angular/core';
import { Waiter } from 'src/app/models/waiter';

@Injectable({
  providedIn: 'root',
})
export class WaitersService {

  @Output() refreshButton: EventEmitter<number> = new EventEmitter();

  public waiter = {} as Waiter;

  constructor() {
    this.waiter.waiters = [];
  }

  init(waiters: any) {
    this.waiter.waiters = [...waiters];
    this.waiter.waiters.forEach((item: any, key) => {
      if (item.id === this.waiter.id) {
        this.waiter.waiters[key].selected = true;
      }
    });

    this.waiter.showRefreshButton = 0;
  }

  toggleRefreshButton(state?: number) {
    if (state === 1) {
      this.waiter.showRefreshButton = 1;
    } else {
      this.waiter.showRefreshButton = 0;
    }
    this.refreshButton.emit(state);
  }
}
