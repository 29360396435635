import { Injectable } from '@angular/core';
import { BackgroundMode } from '@ionic-native/background-mode/ngx';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class BackgroundModeService {
  public title: 'Servi is running.';
  public ticker: 'Servi running in the background.';
  public disconnectedMessage: 'Status: NOT CONNECTED TO SERVER!';
  public connectedMessage: 'Status: Ok. Connected to server.';

  constructor(
    private backgroundMode: BackgroundMode,
    private platform: Platform
    ) {
    this.platform.ready().then(() => {
      this.backgroundMode.on('enable').subscribe(() => console.log('bg-enabled'));
      this.backgroundMode.on('disable').subscribe(() => console.log('bg-disable'));
      this.backgroundMode.on('activate').subscribe(() => console.log('bg-activate'));
      this.backgroundMode.on('deactivate ').subscribe(() => console.log('bg-deactivate'));
      this.backgroundMode.on('failure ').subscribe(() => console.log('bg-failure'));
    });
  }

  enable() {
    this.onStartListening();
    this.backgroundMode.enable();
  }

  disable() {
    this.onStopListening();
    this.backgroundMode.disable();
  }

  isEnabled() {
    return this.backgroundMode.isEnabled();
  }

  isActive() {
    return this.backgroundMode.isActive();
  }

  moveToForeground() {
    this.enable();
    this.unlock();
    this.backgroundMode.moveToForeground();
  }

  moveToBackground() {
    this.backgroundMode.moveToBackground();
  }

  wakeUp() {
    this.backgroundMode.wakeUp();
  }

  unlock() {
    this.backgroundMode.unlock();
  }

  onStartListening() {
    this.backgroundMode.setDefaults({
      title: this.title,
      ticker: this.ticker,
      text: this.connectedMessage
    });
  }

  onStopListening() {
    this.backgroundMode.setDefaults({
      title: this.title,
      ticker: this.ticker,
      text: this.disconnectedMessage
    });
  }
}
