import { Injectable, OnInit, Output, EventEmitter } from '@angular/core';
import { PusherService } from './pusher.service';
import { DataService } from './data.service';
import { map } from 'rxjs/operators';
import { VenueService } from './venue.service';
import { WaitersService } from './waiters.service';
import { SeatsService } from './seats.service';
import { Zones } from '@models/index';
import { StateService } from '@services/state.service';

@Injectable({
  providedIn: 'root'
})
export class ZonesService {
  @Output() refreshZones: EventEmitter<any> = new EventEmitter();

  public zones = {} as Zones;

  constructor(
    private pusher: PusherService,
    private venueSvc: VenueService,
    private waiterSvc: WaitersService,
    private seatsSvc: SeatsService,
    private dataSvc: DataService,
    private state: StateService
  ) {
    this.zones.myZones = [];
    this.zones.availableZones = [];
  }

  init() {
    this.pusher.bindVenue('ZoneChanged', (item: any) => {
      this.loadOneZoneInformation(item);
      this.seatsSvc.loadSeats();
    });

    return this.listZones();
  }

  private listZones() {
    return this.dataSvc.ListZones({ id: this.state.venue.id, waiterId: this.waiterSvc.waiter.id }).pipe(
      map(
        response => {
          if (response.success) {
            this.zones.myZones.splice(0);
            response.result.myZones.forEach((zone: any) => {
              this.zones.myZones.push(zone);
            });
            this.zones.availableZones.splice(0);
            response.result.availableZones.forEach((zone: any) => {
              this.zones.availableZones.push(zone);
            });
          }

          this.refreshZones.emit({
            status: true
          });
          return response;
        },
        (error: any) => {
          console.log('Zones::listZones::ERROR', error);
        }
      )
    );
  }

  addZoneToActive(zoneId: any) {
    return this.dataSvc.AddZoneToActive({ zoneId: zoneId, waiterId: this.waiterSvc.waiter.id }).pipe(
      map(
        response => {
          // Tables.loadTables();
          this.zones.myZones.push(this.zones.availableZones.find(this.zoneWithId(zoneId)));
          this.zones.availableZones.splice(this.zones.availableZones.findIndex(this.zoneWithId(zoneId)), 1);
          return response;
        },
        (error: any) => {
          console.log('Zones::addZoneToActive::ERROR', error);
        }
      )
    );
  }

  releaseActiveZone(zoneId: any) {
    return this.dataSvc.ReleaseActiveZone({ zoneId: zoneId, waiterId: this.waiterSvc.waiter.id }).pipe(
      map(
        response => {
          // Tables.loadTables();
          this.zones.availableZones.push(this.zones.myZones.find(this.zoneWithId(zoneId)));
          this.zones.myZones.splice(this.zones.myZones.findIndex(this.zoneWithId(zoneId)), 1);
          return response;
        },
        (error: any) => {
          console.log('Zones::releaseActiveZone::ERROR', error);
          return error;
        }
      )
    );
  }

  loadOneZoneInformation(zone: any) {
    return this.dataSvc
      .LoadOneZoneInformation({
        zoneId: zone.zoneId,
        venueId: this.state.venue.id,
        waiterId: this.waiterSvc.waiter.id
      })
      .pipe(
        map(
          response => {
            this.updateZone(response.result.zone);
            return response;
          },
          (error: any) => {
            console.log('Zones::loadOneZoneInformation::ERROR', error);
          }
        )
      );
  }

  getZone(zoneId: number) {
    const allZones = [...this.zones.myZones, ...this.zones.availableZones];
    return allZones.filter((zone: any) => zone.id === zoneId);
  }

  updateZone(updatedZone: any) {
    if (this.zones.myZones.find(this.zoneWithId(updatedZone.id))) {
      this.zones.myZones.splice(this.zones.myZones.findIndex(this.zoneWithId(updatedZone.id)), 1, updatedZone.isMine ? updatedZone : undefined);
    }
    if (this.zones.availableZones.find(this.zoneWithId(updatedZone.id))) {
      this.zones.availableZones.splice(this.zones.availableZones.findIndex(this.zoneWithId(updatedZone.id)), 1, !updatedZone.isMine ? updatedZone : undefined);
    }
    console.log('Updating item state for zone ' + updatedZone.id);
  }

  getZonesWithHours() {
    const allZones = [...this.zones.myZones, ...this.zones.availableZones];
    return allZones.filter((zone: any) => {
      // return (zone.zoneName !== 'All' && zone.hoursGroupId != null && zone.hoursGroupId !== 0) ? true : false;
      return (zone.hoursGroupId != null && zone.role === 'service') || zone.role === 'staff' ? true : false;
    });
  }

  getMyZonesWithHours() {
    return this.zones.myZones.filter((zone: any) => {
      // return (zone.zoneName !== 'All' && zone.hoursGroupId != null && zone.hoursGroupId !== 0) ? true : false;
      return (zone.hoursGroupId != null && zone.role === 'service') || zone.role === 'staff' ? true : false;
    });
  }

  zoneIsOpen(zone: any) {
    let openNow = true;
    if (this.state.venue.mainHours?.groups) {
      this.state.venue.mainHours.groups.map(v => {
        if (zone.hoursGroupId != null && zone.hoursGroupId !== 0 && zone.hoursGroupId === v.hoursGroupId) {
          openNow = v.openNow;
        }
      });
    }
    // hours overrides
    if (zone.hoursOvr === 'open') {
      openNow = true;
    } else if (zone.hoursOvr === 'closed') {
      openNow = false;
    }

    return openNow;
  }

  zoneIsOpenBySeatId(seatId: any) {
    let open = true;
    if (this.seatsSvc.seats.mySeats.length > 0 && this.waiterSvc.waiter.role !== 'manager') {
      const myZonesWithHours = this.getMyZonesWithHours();
      if (myZonesWithHours != null && myZonesWithHours.length > 0) {
        open = false;
        const seat = this.seatsSvc.seats.mySeats.find((item: any) => item.id === seatId);
        if (!!seat) {
          let isZoneOpen = false;
          for (let i = 0; i < myZonesWithHours.length; i++) {
            if (myZonesWithHours[i].role !== 'service') {
              open = true;
              break;
            } else {
              if (seat.zones.indexOf(myZonesWithHours[i].id) !== -1) {
                isZoneOpen = this.zoneIsOpen(myZonesWithHours[i]);
                if (isZoneOpen) {
                  open = true;
                  break;
                }
              }
            }
          }
        }
      }
    }

    return open;
  }

  zoneIsLocked(zone: any) {
    const id = zone.id != null ? zone.id : 0;
    const allZones = [...this.zones.myZones, ...this.zones.availableZones];

    return allZones.find((z: any) => id === z.id && z.hoursOvrLocked === 1) || false;
  }

  private zonesWithoutId(zoneId: number): any {
    return (zone: any) => {
      return zone.id !== zoneId;
    };
  }

  private zoneWithId(zoneId: number): any {
    return (zone: any) => {
      return zone.id === zoneId;
    };
  }
}
