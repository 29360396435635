import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { DeviceService } from 'src/app/services/device.service';

@Component({
    selector: 'app-offline-message',
    templateUrl: './offline-message.component.html',
    styleUrls: ['./offline-message.component.scss'],
})
export class OfflineMessageComponent implements OnInit {

    public offline: boolean;
    public color: string;

    constructor(private deviceSvc: DeviceService, private cd: ChangeDetectorRef) {
        this.offline = this.deviceSvc.deviceData.offline;
        this.color = 'warn';

        this.deviceSvc.offline.subscribe((res: any) => {
            if (res.status) { // offline
                this.offline = true;
            } else {
                this.offline = false;
            }
            this.cd.detectChanges();
        });
    }

    ngOnInit() {
    }
}
