import { Injectable, EventEmitter, Output } from '@angular/core';
import { DataService } from './data.service';
import { map } from 'rxjs/operators';
import { WaitersService } from './waiters.service';
import { SessionService } from './session.service';
import { VenueService } from './venue.service';
import { PusherService } from './pusher.service';
import { SeatsService } from './seats.service';
import { ZonesService } from './zones.service';
import { MessagingService } from './messaging.service';
import { ProductCategoriesService } from './product-categories.service';
import { ProductsService } from './products.service';
import { LocalStorageService } from './local-storage.service';
import { Router } from '@angular/router';
import { DeviceService } from './device.service';
import { StateService } from '@services/state.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  @Output() initialized: EventEmitter<any> = new EventEmitter();
  public isInitialized: boolean;

  constructor(
    private dataSvc: DataService,
    private waiterSvc: WaitersService,
    private venueSvc: VenueService,
    private sessionSvc: SessionService,
    private storeSvc: LocalStorageService,
    private seatsSvc: SeatsService,
    private zonesSvc: ZonesService,
    private messageSvc: MessagingService,
    private productsSvc: ProductsService,
    private deviceSvc: DeviceService,
    private categoriesSvc: ProductCategoriesService,
    private router: Router,
    private pusher: PusherService,
    private state: StateService
  ) {
    this.isInitialized = false;
  }

  login(
    token: string,
    username: string,
    password: string,
    ipAddress: string,
    wifiIpAddress: string
  ): any {
    return this.dataSvc
      .Login({
        restaurantToken: token,
        login: username,
        password: password,
        rememberMe: true,
        ipAddress: ipAddress,
        wifiIpAddress: wifiIpAddress
      })
      .pipe(
        map((response: any) => {
          if (response.success) {
            // console.log('login-response', response);
            this.onLogin(response.result, null, token, username);
          }
          return response;
        })
      );
  }

  logout() {
    this.clearSavedCredentials();
    this.messageSvc.unsubscribeNotifications();
    this.pusher.logout();
    return this.dataSvc.Logout({ waiterId: this.waiterSvc.waiter.id }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  isLoggedIn() {
    return !!this.waiterSvc.waiter.id;
  }

  relogin(waiterId: any) {
    if (!this.hasSavedCredentials()) {
      this.redirectToLogin();
      return;
    }

    const savedCredentials = this.getSavedCredentials(),
      url = !waiterId ? 'waiter/relogin' : 'waiter/reloginOther',
      data = {
        seriesIdentifier: savedCredentials.seriesIdentifier,
        reloginToken: savedCredentials.reloginToken,
        waiterId: null
      };

    if (waiterId) {
      data.waiterId = waiterId;
    }

    if (
      data.seriesIdentifier === undefined ||
      data.reloginToken === undefined
    ) {
      this.redirectToLogin();
      return;
    }

    return this.dataSvc.ReLogin(url, data).pipe(
      map(
        (response: any) => {
          //  console.log('relogin-response', response);
          if (response.success) {
            this.onLogin(response.result, !!waiterId, '', '');
          } else {
            this.redirectToLogin();
          }

          return response;
        },
        (error: any) => {
          this.redirectToLogin();
        }
      )
    );
  }

  clearSavedCredentials() {
    return this.sessionSvc.clearCredentials();
  }

  getSavedCredentials() {
    return this.sessionSvc.getSavedCredentials();
  }

  hasSavedCredentials() {
    return !!this.getSavedCredentials();
  }

  redirectToLogin() {
    this.sessionSvc.clearCredentials();
    this.router.navigateByUrl('/');
  }

  onLogin(
    waiterInformation: any,
    loggingInOtherWaiter: any,
    venueCode: string,
    username: string
  ) {
    console.log('waiterInformation', waiterInformation);

    this.waiterSvc.waiter.id = waiterInformation.waiterId;
    this.waiterSvc.waiter.venueId = waiterInformation.restaurantId;
    this.waiterSvc.waiter.name = waiterInformation.name;
    this.waiterSvc.waiter.role = waiterInformation.waiterRole;
    this.waiterSvc.waiter.locationLabel = waiterInformation.locationLabel;

    this.venueSvc.init({
      ...waiterInformation.restaurant, // TODO: remove restautantId
      id: waiterInformation.restaurant.restaurantId
    });

    // GA.setUserId(this.waiterSvc.waiter.waiterId);
    this.pusher.logout();
    this.pusher.connectToServer(
      this.waiterSvc.waiter.id,
      this.state.venue.id,
      this.deviceSvc.getDeviceId()
    );

    if (!loggingInOtherWaiter) {
      this.categoriesSvc.init();
      this.productsSvc.init();
      this.waiterSvc.init(waiterInformation.waiters);
    }

    this.seatsSvc.init().subscribe((response: any) => {
      this.seatsSvc.processDescriptors(response);
      setTimeout(() => {
        this.isInitialized = true;
        this.initialized.emit({
          status: true
        });
      }, 500);
    });

    this.zonesSvc.init().subscribe((zones: any) => {
      /* console.log('init-zones', zones); */
    });

    this.sessionSvc.saveReloginData(
      waiterInformation.seriesIdentifier,
      waiterInformation.reloginToken,
      venueCode,
      username
    );

    this.messageSvc.subscribeNotifications();

    let settings: any;
    try {
      settings = JSON.parse(this.storeSvc.get('servi-staff-settings'));
    } catch (e) { }

    if (!!settings) {
      this.venueSvc.setSettings(settings);
    } else {
      this.venueSvc.initSettings();
    }
  }
}
