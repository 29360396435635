import { Component, OnInit, Input, AfterContentInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { VenueService } from 'src/app/services/venue.service';
import { ProductsService } from 'src/app/services/products.service';
import { StateService } from '@services/index';
import { Venue } from '@models/index';

@Component({
  selector: 'app-view-order',
  templateUrl: './view-order.component.html',
  styleUrls: ['./view-order.component.scss']
})
export class ViewOrderComponent implements OnInit, AfterContentInit {
  @Input() item: any;
  @Input() q: any;

  public venue: Venue;
  public orderId: number;
  public taxLabel: string;
  public convenienceFeeLabel: string;
  public print: boolean;
  public stationprint: boolean;
  public printstation: boolean;

  constructor(
    private modalCtrl: ModalController,
    private venueSvc: VenueService,
    private productsSvc: ProductsService,
    private state: StateService
  ) {
    this.venue = this.state.venue;
    this.taxLabel = this.state.venue.venueSettings.TAX_DESCRIPTION;
    this.convenienceFeeLabel = this.state.venue.venueSettings.CONVENIENCE_FEE_LABEL;
  }

  ngOnInit() {
    this.printstation = this.state.venue.printstation;
    this.print = this.state.venue.print;
  }

  ngAfterContentInit() {
    if (this.item.order !== undefined) {
      this.orderId = this.item.order.order_id;
    }
    this.print = false;
    this.stationprint = false;
  }

  screenWidth() {
    return screen.width;
  }

  dismiss(item: any, print: boolean, stationprint: boolean) {
    this.modalCtrl.dismiss({
      print: print,
      stationprint: stationprint,
      item: item
    });
  }

  doDismissPager(q: any) {
    this.dismiss(q, false, false);
  }

  doPrint(item: any) {
    this.dismiss(item, true, false);
  }

  doStationPrint(item: any) {
    this.dismiss(item, false, true);
  }

  getProductName(product: any) {
    return this.productsSvc.getProductName(product);
  }

  getOptionItemName(productId: number, optionItem: any) {
    return this.productsSvc.getOptionItemName(productId, optionItem);
  }

  getVariantName(product: any) {
    return this.productsSvc.getVariantName(product);
  }

  getTotalPrice(product: any) {
    const qty = Number(product.qty) || 1;
    const base = Number(product.price) || 0;
    let price = 0;

    if (!!product.options) {
      product.options.forEach((option: any) => {
        if (!!option.items) {
          option.items.forEach((item: any) => {
            price += Number(item.item_price);
          });
        }
      });
    }

    return (base + price) * qty;
  }

  getLastChar(char: any) {
    if (char === undefined) {
      return;
    }
    const str = char.toString();
    return str[str.length - 1];
  }

  productsHavePrinterId(products: any) {
    let ok = false;
    if (!!products) {
      for (let i = 0; i < products.length; i++) {
        if (products[i].printer_id !== undefined) {
          if (products[i].printer_id != null) {
            ok = true;
            break;
          }
        }
      }
    }

    return ok;
  }
}
