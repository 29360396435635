import { Injectable, Output, EventEmitter } from '@angular/core';
import { NotificationService } from './notification.service';
import { VenueService } from './venue.service';
import { DataService } from './data.service';
import { map } from 'rxjs/operators';
import { SessionService } from './session.service';
import { PusherService } from './pusher.service';
import { BackgroundModeService } from './background-mode.service';
import { of } from 'rxjs';
import { ZonesService } from './zones.service';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { LocalStorageService } from './local-storage.service';
import { StateService } from '@services/state.service';
import { WaitersService } from './waiters.service';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {
  public subscribed = true;

  @Output() messageReceived: EventEmitter<any> = new EventEmitter();

  constructor(
    private notifySvc: NotificationService,
    private dataSvc: DataService,
    private sessionSvc: SessionService,
    private pusherSvc: PusherService,
    private backgroundSvc: BackgroundModeService,
    private waitersSvc: WaitersService,
    private venueSvc: VenueService,
    private zonesSvc: ZonesService,
    private storeSvc: LocalStorageService,
    private splashScreen: SplashScreen,
    private state: StateService
  ) {}

  messageDispatcher(type: string, data: any) {
    let ok = true;
    let open = true;
    let thisData: any;

    try {
      thisData = JSON.parse(data);
    } catch (e) {
      thisData = data; // catch the data if already decoded; backwards compatibliity; to be removed later
    }

    if (!thisData) {
      ok = false;
    }

    console.log('pusher-data: ' + type, thisData);
    const isServiceRole = (this.waitersSvc.waiter.role === 'service') ? true : false;

      switch (type) {
        case 'staff_restart':
          this.splashScreen.show();
          document.location.href = 'index.html';
          break;

        case 'print_confirmed':
          if (thisData) {
            this.notifySvc.updateNotification({guid: thisData.guid, action: 'receiptprinted'});
          }
          break;

        case 'stationprint_confirmed':
          if (thisData) {
            this.notifySvc.updateNotification({guid: thisData.guid, action: 'stationprinted', printerId: thisData.data.printerId});

            // this.notifySvc.notification.history.map((item: any) => {
            //   if (item.guid === thisData.guid) {
            //     if (item.stationprinted === undefined) {
            //       item.stationprinted = [];
            //     }

            //     const newItem = thisData.data.printerId;
            //     if (item.stationprinted.indexOf(newItem) === -1) {
            //       item.stationprinted.push(newItem);
            //     }
            //     //this.syncLocalStorage(thisData, 'stationprinted', item.stationprinted);

            //     return item.stationprinted;
            //   }
            // });
          }
          break;

        case 'staff_msg':
          this.notifySvc.onGuestNotification(data);
          break;

        case 'customer_call':
          if (thisData) {
            // if notification table zone has hours; do not push if closed
            // open = this.zonesSvc.zoneIsOpenBySeatId(thisData.tableId);

            const isServiceRole = (this.waitersSvc.waiter.role === 'service') ? true : false;
            if (!isServiceRole) {
              this.waitersSvc.toggleRefreshButton(1);
              open = false;
            }
            if (open) {
              // only push new messages, no duplicates
              this.notifySvc.notification.history.map((item: any) => {
                if (item.guid === thisData.guid) {
                  ok = false;
                }
              });

              if (ok) {
                this.notifySvc
                  .acknowledgeNotification(thisData)
                  .subscribe((res: any) => {});
                this.notifySvc.onGuestNotification(thisData);
              }

              if (this.state.venue.disablerequests && thisData.type === 'call.call') {
                ok = false;
              }
            }
          }
          break;

        case 'customer_call_confirmed':
          const call = this.notifySvc.getNotificationFromHistory(
            thisData.uuid,
            'guid'
          );
          if (!!call) {
            this.notifySvc.confirmNotification(call.id);
            this.callConfirm(call, true).subscribe((response: any): void => {});

            this.notifySvc.notification.history.map((item: any) => {
              if (item.guid === thisData.guid) {
                item.printedreq = true;
              }
            });

          }
          break;
      }

      if (ok && open) {
        // allow time for page redirect before broadcasting
        setTimeout(() => {
          // if (this.backgroundSvc.isActive) { // if in background mode, move to forground when msg received
            this.backgroundSvc.moveToForeground();
          // }
          this.messageReceived.emit({
            type: type,
            data: data,
            msg: thisData
          });
        }, 2000);
      }

  }

  callAcknowledge(call: any) {
    return this.dataSvc
      .CallAcknowledge({ guid: call.guid, venueId: this.state.venue.id })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  // existsInLocalStorage(guid: string) {
  //   let messages: any;
  //   try {
  //     messages = this.storeSvc.get('servi-staff-messages');
  //   } catch (e) {}

  //   let exists = false;
  //   if (messages) {
  //     exists = messages.find((item: any) => item.guid === guid);
  //   }

  //   // console.log('exists: ' + ((exists) ? true : false), messages);

  //   return exists ? true : false;
  // }

  // appendLocalStorage(guid: string, confirmed: boolean) {
  //   this.purgeOldMessageGuids();
  //   if (!this.existsInLocalStorage(guid)) {
  //     const now = +new Date();
  //     const data = { guid: guid, confirmed: confirmed, date: now };
  //     this.storeSvc.append('servi-staff-messages', data);
  //   }
  // }

  // getFromLocalStorage(guid: string) {
  //   let messages: any = [];
  //   let message: any;
  //   try {
  //     messages = this.storeSvc.get('servi-staff-messages');
  //   } catch (e) { }

  //   // console.log('STORED-MSGS', messages);

  //   if (!!messages) {
  //     message = messages.find((item: any) => item.guid === guid);
  //   }

  //   return message;
  // }

  // purgeOldMessageGuids() {
  //   // purge old messages > 24 hours
  //   let messages: any;
  //   try {
  //     messages = this.storeSvc.get('servi-staff-messages');
  //   } catch (e) { }
  //   const validMessages = [];
  //   if (messages) {
  //     const yesterday = new Date(new Date().getTime() - (24 * 60 * 60 * 1000));
  //     messages.map((msg: any) => {
  //       if(new Date(msg.date) >= yesterday) {
  //         validMessages.push(msg);
  //       }
  //     });
  //   }
  //   messages = [...validMessages];
  //   this.storeSvc.set('servi-staff-messages', messages);
  // }

  // syncLocalStorage(call: any, action?: string, data?: any) {
  //   let messages: any = [];
  //   if (!this.existsInLocalStorage(call.guid)) {
  //     this.appendLocalStorage(call.guid, true);
  //     messages = this.storeSvc.get('servi-staff-messages');
  //   } else {
  //     try {
  //       messages = this.storeSvc.get('servi-staff-messages');
  //       for (const i in messages) {
  //         if (messages[i].guid === call.guid) {
  //           switch(action) {
  //             case 'receiptprinted':
  //               messages[i].printed = true;
  //               break;
  //             case 'requestprinted':
  //               messages[i].printedreq = true;
  //               break;
  //             case 'stationprinted':
  //               messages[i].stationprinted = data;
  //               break;
  //             case 'reject':
  //               messages.splice(i, 1);
  //               break;
  //             case 'revert':
  //               messages[i].paid = null;
  //               break;
  //             case 'paid':
  //               messages[i].paid = new Date();
  //               break;
  //             case 'refunded':
  //               messages[i].refunded = new Date();
  //               break;
  //             case 'comp':
  //                 messages[i].compDate = new Date();
  //                 break;
  //             case 'confirmed':
  //             default:
  //               messages[i].confirmed = true;
  //           }
  //         }
  //       }
  //       this.storeSvc.set('servi-staff-messages', messages);
  //     } catch (e) {}
  //   }
  // }

  callConfirm(call: any, noApiRequest: boolean) {
    call.confirmed = true;
    //this.syncLocalStorage(call, 'confirmed');

    if (noApiRequest) {
      return of({});
    }

    let tableId: number;
    tableId = !!call.table && call.table.id ? call.table.id : call.table;
    if (tableId === undefined) {
      tableId = call.tableId !== undefined ? call.tableId : 0;
    }

    return this.dataSvc
      .CallConfirm({
        guid: call.guid,
        tableId: tableId,
        venueId: this.state.venue.id
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  markRefunded(call: any, noApiRequest: boolean) {

    if (noApiRequest) {
      return of({});
    }

    return this.dataSvc
      .RefundOrder({
        venueId: this.state.venue.id,
        item: call
      })
      .pipe(
        map((response: any) => {
          if (response.success) {
            if (response.result.code === 1) {
              const payRef = (response.result.reference) ? response.result.reference : null;
              if (payRef) {
                call.refunded = new Date();
               // this.syncLocalStorage(call, 'refunded');
              }
            }
          }
          return response;
        })
      );
  }

  markComp(call: any, noApiRequest: boolean) {
    call.compDate = new Date();
  //  this.syncLocalStorage(call, 'comp');

    if (noApiRequest) {
      return of({});
    }

    return this.dataSvc
      .CompOrder({
        venueId: this.state.venue.id,
        item: call
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  markPaid(call: any, noApiRequest: boolean) {
    call.paid = true;
  //  this.syncLocalStorage(call, 'paid');

    if (noApiRequest) {
      return of({});
    }

    return this.dataSvc
      .MarkPaid({
        venueId: this.state.venue.id,
        notification: call
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  revertPaid(call: any, noApiRequest: boolean) {
    call.paid = null;
  //  this.syncLocalStorage(call, 'revert');

    if (noApiRequest) {
      return of({});
    }

    return this.dataSvc
      .RevertPaid({
        venueId: this.state.venue.id,
        notification: call
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  rejectCall (call: any, noApiRequest: boolean) {
    call.confirmed = null;
  //  this.syncLocalStorage(call, 'reject');

    if (noApiRequest) {
      return of({});
    }

    return this.dataSvc
      .CallReject({
        venueId: this.state.venue.id,
        notification: call
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  subscribeNotifications() {
    if (this.subscribed) {
      this.unsubscribeNotifications();
    }

    this.sessionSvc.goOnline();
    // GA.trackEvent('Requests', 'Subscribe notifications');
    this.pusherSvc.bindWaiter(
      'customer_call',
      this.messageDispatcher.bind(this, 'customer_call')
    );
    this.pusherSvc.bindWaiter(
      'customer_call_confirmed',
      this.messageDispatcher.bind(this, 'customer_call_confirmed')
    );
    this.pusherSvc.bindRequests(
      'print_confirmed',
      this.messageDispatcher.bind(this, 'print_confirmed')
    );
    this.pusherSvc.bindVenue(
      'staff_msg',
      this.messageDispatcher.bind(this, 'staff_msg')
    );
    // NEW
    this.pusherSvc.bindPagers(
      'customer_pickup',
      this.messageDispatcher.bind(this, 'customer_pickup')
    );
    this.pusherSvc.bindDevice(
      'staff_restart',
      this.messageDispatcher.bind(this, 'staff_restart')
    );
    this.pusherSvc.bindRequests(
      'customer_call',
      this.messageDispatcher.bind(this, 'customer_call')
    );
    this.pusherSvc.bindRequests(
      'staff_msg',
      this.messageDispatcher.bind(this, 'staff_msg')
    );
    this.pusherSvc.bindRequests(
      'stationprint_confirmed',
      this.messageDispatcher.bind(this, 'stationprint_confirmed')
    );
    this.pusherSvc.bindSystem(
      'staff_msg',
      this.messageDispatcher.bind(this, 'staff_msg')
    );
  }

  unsubscribeNotifications() {
    this.sessionSvc.goOffline();
    // GA.trackEvent('Requests', 'Unsubscribe notifications');
    this.pusherSvc.unbindWaiter('customer_call', null);
    this.pusherSvc.unbindWaiter('customer_call_confirmed', null);
    this.pusherSvc.unbindRequests('print_confirmed', null);
    this.pusherSvc.unbindRequests('stationprint_confirmed', null);
    this.pusherSvc.unbindVenue('staff_msg', null);
    this.pusherSvc.unbindPagers('customer_pickup', null);
    this.pusherSvc.unbindDevice('staff_restart', null);
    this.pusherSvc.unbindRequests('customer_call', null);
    this.pusherSvc.unbindRequests('staff_msg', null);
    this.pusherSvc.unbindSystem('staff_msg', null);
  }
}
