import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-offline-dialog',
  templateUrl: './offline-dialog.component.html',
  styleUrls: ['./offline-dialog.component.scss'],
})
export class OfflineDialogComponent implements OnInit {

    constructor(private dialogRef: MatDialogRef<OfflineDialogComponent>) { }

  ngOnInit() {}

    close() {
        this.dialogRef.close();
    }

}
