export const environment = {
    production: true,
    ENVIRONMENT: 'production',
    ENV_FILE: 'production',
    VERSION: '3.3.12',
    UA_TRACKING_ID: 'UA-72506076-1',
    // GOOGLE_API_KEY: 'AIzaSyCdyuNSb-XB5TWyg_Mv7rMa7qYiea37Owo',
    GOOGLE_API_KEY: 'AIzaSyA0hFkppNeAizW5EACJMZ_CW_Smjb-wKLs',
    //API_URL: 'https://api.ser.vi/0_4/',
    API_URL: 'https://servi-api2-server.herokuapp.com/0_4/',
    // API_URL: 'https://servi-api2-server-staging.herokuapp.com/0_4/',
    // API_URL: 'http://serviapi.zloaded.com/0_4/',
    ALT_API_URL: 'https://assets.ser.vi/rpc.php',
    pusher: {
        key: 'ae36a99eb10f0af80628',
        cluster: 'mt1',
    }
};

