import { Component, OnInit } from '@angular/core';
import { VenueService } from 'src/app/services/venue.service';
import { PrintService } from 'src/app/services/print.service';
import { ZonesService } from 'src/app/services/zones.service';
import { WaitersService } from 'src/app/services/waiters.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { StateService } from '@services/index';
import { Venue } from '@models/index';

declare var window: any;

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss']
})
export class MainHeaderComponent implements OnInit {
  public venue: Venue;
  public myZones: any;
  public availableZones: any;
  public headerZones = [];
  public venueName: string;
  public storeOpen: boolean;
  public printing: boolean;
  public loaded: boolean;
  public dataLoading: boolean;
  public dataSilentLoading: boolean;
  public seatsLoaded: number;
  public color: string;
  public refreshing: boolean;

  constructor(
    private venueSvc: VenueService,
    private printSvc: PrintService,
    private zonesSvc: ZonesService,
    private waitersSvc: WaitersService,
    private authSvc: AuthService,
    private router: Router,
    private state: StateService
  ) {
    this.venue = this.state.venue;
    this.myZones = this.zonesSvc.zones.myZones;
    this.availableZones = this.zonesSvc.zones.availableZones;
    this.printing = false;
    this.loaded = false;
    this.dataLoading = false;
    this.dataSilentLoading = false;
    this.color = 'warn';

    // events
    this.authSvc.initialized.subscribe((response: any) => {
      if (response.processing) {
        this.dataLoading = true;
        this.seatsLoaded = response.processing;
      } else {
        this.dataLoading = false;
        this.ngOnInit();
      }
    });
    this.zonesSvc.refreshZones.subscribe((response: any) => {
      this.ngOnInit();
    });
    this.venueSvc.refreshHours.subscribe((response: any) => {
      this.ngOnInit();
    });
    this.printSvc.isPrinting.subscribe((response: any) => {
      if (response.status) {
        this.printing = true;
        } else {
        this.printing = false;
        }
    });
    this.printSvc.printerSuccess.subscribe((response: any) => {
      this.printing = false;
    });
    this.printSvc.printerError.subscribe((response: any) => {
      this.printing = false;
    });
    this.venueSvc.refreshSilentDataLoading.subscribe((response: any) => {
      setTimeout(() => {
        this.dataSilentLoading = response.status;
      });
    });
  }

  ngOnInit() {
    this.venueName = this.state.venue.restaurantName.substring(0, 10) + '...';
    this.storeOpen = this.storeIsOpen();
    this.getMyZonesWithHours();
  }

  storeIsOpen() {
    return this.state.venue.mainHours ?
      this.state.venue.mainHours.isOpenNow :
      // open by default for backwards compatibility with venues that have no hours
      true;
  }

  isManager() {
    return this.waitersSvc.waiter.role === 'manager';
  }

  needsAndroidPadding () {
    return this.venueSvc.useAndroidPadding;
  }

  getMyZonesWithHours() {
    let zones: any;
    this.headerZones = [];
    if (this.isManager()) {
      zones = this.zonesSvc.getZonesWithHours();
    } else {
      zones = this.zonesSvc.getMyZonesWithHours();
    }

    if (zones) {
      zones.map((v: any) => {
        this.headerZones.push({ name: v.zoneName, isOpen: this.zoneIsOpen(v), isLocked: this.zoneIsLocked(v) });
      });
    }
    const prop = 'name';
    this.headerZones.sort((a, b) => (a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1));
  }

  zoneIsOpen(zone: any) {
    return this.zonesSvc.zoneIsOpen(zone);
  }

  zoneIsLocked(zone: any) {
    return this.zonesSvc.zoneIsLocked(zone);
  }

  getCountDownTime() {
    return this.venueSvc.countDownTime;
  }

  showCountDownTime() {
    return this.getCountDownTime() < 60 && this.getCountDownTime() !== 0 ? true : false;
  }

  goToManage() {
    if (this.authSvc.isInitialized) {
      if (this.router.url === '/manage') {
        this.router.navigate(['/requests']);
      } else {
        this.venueSvc.setSelectedRequestsTab('hrs');
        this.router.navigate(['/manage']);
      }
    }
  }
}
