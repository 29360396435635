import { Component, OnInit, Input, AfterContentInit, AfterViewInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { VenueService } from 'src/app/services/venue.service';
import { FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { StateService } from '@services/index';
import { Venue } from '@models/index';

@Component({
  selector: 'app-password-modal',
  templateUrl: './password-modal.component.html',
  styleUrls: ['./password-modal.component.scss'],
})
export class PasswordModalComponent implements OnInit, AfterContentInit {

  @Input() item: any;

  public venue: Venue;
  public error: string | boolean;

  public passwordForm = this.formBuilder.group({
    password: ['', Validators.required]
  });

  constructor(
    private popoverCtrl: PopoverController,
    private venueSvc: VenueService,
    private formBuilder: FormBuilder,
    private keyboard: Keyboard,
    private state: StateService
  ) {
    this.venue = this.state.venue;
    this.error = false;
  }

  ngOnInit() {
  }

  ngAfterContentInit() {
    setTimeout(() => {
      $('#pass').focus();
      document.getElementById('pass').focus();
      this.keyboard.show();
    }, 1000);
  }

  dismiss(mode: string) {
    const password = this.passwordForm.get('password').value;
    if (mode === 'submit') {
      const data = {venueId: this.venue.id, password: password};
      this.venueSvc.validateManagerPassword(data).subscribe((response: any) => {
        if (response.success && response.result === true) {
          this.popoverCtrl.dismiss({
            mode: mode,
            valid: true
          });
        } else {
          this.error = 'Validation failed.  Please try again.'
        }
      });

    } else if (mode === 'cancel') {
      this.popoverCtrl.dismiss({
        mode: mode,
        valid: false
      });
    }
  }

}
