import { Injectable } from '@angular/core';
import { CoreStateService } from '@serviworldwide/core-js/services/state.service';
import {
    // Action,
    // CartItem,
    // Featured,
    // Hours,
    // MainHours, Menu, PaymentInfo, Product,
    // ProductCategory, Seat, Special, User,
    Venue, Waiter
} from '@models/index';


const data = {
    // actions: new Map() as Map<Action['name'], Action>,
    // cart: [] as CartItem[],
    // categories: [] as ProductCategory[],
    // featured: [] as Featured[],
    // hours: {
    //     active: [], all: [], main: undefined, currentDateTime: undefined
    // } as { active: Hours[], all: Hours[], main: MainHours, currentDateTime: number },
    // menus: [] as Menu[],
    // orders: { str: '', arr: [] } as { str: string, arr: any[] },
    // other: {} as any,
    // payment: { tip: 0 } as PaymentInfo,
    // products: [] as Product[],
    // seat: {} as Seat,
    // specials: [] as Special[],
    // ui: {} as any,
    // user: {} as User,
    venue: {} as Venue,
    // visit: {} as { id: number, created: string, lastSeen: string },
    waiter: {} as Waiter
};

@Injectable({
    providedIn: 'root'
})
export class StateService extends CoreStateService {
    // public actions: Map<Action['name'], Action>;
    // public cart: CartItem[];
    // public categories: ProductCategory[];
    // public featured: Featured[];
    // public hours: { active: Hours[], all: Hours[], main: MainHours, currentDateTime: number };
    // public menus: Menu[];
    // public orders: { str: string, arr: any[] };
    // public other: any;
    // public payment: PaymentInfo;
    // public products: Product[];
    // public seat: Seat;
    // public specials: Special[];
    // public ui: any;
    // public user: User;
    public venue: Venue;
    // public visit: { id: number, created: string, lastSeen: string };
    public waiter: Waiter;

    constructor() {
        super(data);
    }
}
