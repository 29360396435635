import { Injectable } from '@angular/core';
import { VenueService } from './venue.service';
import { StateService } from '@services/state.service';
import { ProductsService } from '.';

@Injectable({
  providedIn: 'root'
})
export class ProductCategoriesService {

  public categories: any = [];

  constructor(
    private _venueSvc: VenueService,
    private state: StateService,
    private productsSvc: ProductsService
  ) { }

  init() {
    this.categories = this.state.venue.productCategories;
    // console.log('init-catgories', this.categories);
  }

  getCategoryById(id: any) {
    // console.log('getting category with id ' + id);
    const c = this.categories.filter((category: any) => {
      return parseInt(category.id, 10) === parseInt(id, 10);
    });

    return c.length ? c[0] : null;
  }

  addCategoryIdToProductArray(item: any) {
    if (item.products) {
      let fullProduct: any;
      let category: any;
      item.products.forEach((product: any, index: number) => {
        fullProduct = this.productsSvc.getById(product.product_id);
        category = (fullProduct && fullProduct.category) ? this.getCategoryById(fullProduct.category) : 0;
        item.products[index].categoryId = (category.id) ? category.id : 0;
      });
    }

    return item;
  }

  getCategoryArrayForPrintSort(products: any) {
    const groupByCategory = this.state.venue.groupbycategory;
    if (!groupByCategory) {
      return false;
    }

    let productsStr = '';
    let fullProduct: any;
    let fullCategory: any;
    let result: any = [];
    products.forEach((product: any) => {
      fullProduct = this.productsSvc.getById(product.product_id);
      if (fullProduct) {
        fullCategory = this.getCategoryById(fullProduct.category);
      }
      if (fullCategory) {
        const exists = result.find((item: any) => item.id === fullCategory.id);
        if (!exists) {
          result.push({ id: fullCategory.id, label: fullCategory.name });
        }
      }
    });

    return result;
  }
}
