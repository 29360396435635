import { Injectable, Output, EventEmitter } from '@angular/core';
import { StarPRNT } from '@ionic-native/star-prnt/ngx';
import { DatePipe } from '@angular/common';
import { LocalStorageService } from './local-storage.service';
import { VenueService } from './venue.service';
import { NotificationService } from './notification.service';
import { ReportsService } from './reports.service';
import { ProductsService } from './products.service';
import { parsePhoneNumber, CountryCode } from 'libphonenumber-js/min';
import { TranslationService } from '@serviworldwide/core-js/modules/locale';
import { WebIntent } from '@ionic-native/web-intent/ngx';
import { StateService, BackgroundModeService } from '@services/index';
import { Platform } from '@ionic/angular';

declare var window: any;

@Injectable({
    providedIn: 'root'
})
export class PrintService {
    @Output() printerSuccess: EventEmitter<any> = new EventEmitter();
    @Output() printerError: EventEmitter<any> = new EventEmitter();
    @Output() isPrinting: EventEmitter<any> = new EventEmitter();

    // public printing: boolean;
    public printDuplicate: boolean;
    public sendExtraCutCommand: boolean;
    public textDuplicate: string;
    public catArr: any;

    private extraCutCommand: string = '<BR><BR><BR><BR><BR><COMMAND>0x1B,0x69<BR>'; // extra cut command for QP Raw Mode

    constructor(
        private starprnt: StarPRNT,
        private datePipe: DatePipe,
        private translationSvc: TranslationService,
        private venueSvc: VenueService,
        private reportsSvc: ReportsService,
        private productsSvc: ProductsService,
        private notifySvc: NotificationService,
        private storeSvc: LocalStorageService,
        private webIntent: WebIntent,
        private state: StateService,
        private bgModeSvc: BackgroundModeService,
        private platform: Platform
    ) {
        // this.printing = false;
        // this.printDuplicate = (this.state.venue.printstationdupe != null) ? this.state.venue.printstationdupe : false;
        this.textDuplicate = this.translationSvc.translateStatic('text_duplicate');
    }

    hasReceiptPrinter() {
        let printers = [];
        const storedPrinters = this.storeSvc.get(
            this.state.venue.token + '_servi-printers'
        );
        if (storedPrinters != null) {
            printers = storedPrinters.filter((pitem: any) => {
                return pitem.type === 'receipt' && pitem.alias != null;
            });
        }
        return printers.length > 0 ? true : false;
    }

    /**
     * Common Print Methods
     */

    send(item: any) {
        // console.log('PRINT-ITEM', item);
        if (item) {
            // get the receipt printer
            const storedPrinters = this.storeSvc.get(
                this.state.venue.token + '_servi-printers'
            );
            if (storedPrinters != null) {
                // this.printing = true;
                this.isPrinting.emit({ status: true });

                const printer = storedPrinters.filter((pitem: any) => {
                    return pitem.type === 'receipt';
                });

                if (!printer[0]) {
                    this.printerError.emit({
                        result: -1,
                        text: 'No printer defined.  Please define a printer in settings.'
                    });
                    return false;
                }

                const svc = printer[0].svc !== undefined ? printer[0].svc : 'qp';
                if (svc === 'sd') {
                    this.printReceiptToStarPrinter(item, printer);
                } else {
                    this.printReceiptToQuickPrinter(item, printer);
                }
            }
        }
    }

    sendReq(item: any) {
        if (item) {
            // get the receipt printer
            const storedPrinters = this.storeSvc.get(
                this.state.venue.token + '_servi-printers'
            );
            if (storedPrinters != null) {
                // this.printing = true;
                this.isPrinting.emit({ status: true });

                const printer = storedPrinters.filter((pitem: any) => {
                    return pitem.type === 'receipt';
                });

                if (!printer[0]) {
                    this.printerError.emit({
                        result: -1,
                        text: 'No printer defined.  Please define a printer in settings.'
                    });
                    return false;
                }

                const svc = printer[0].svc !== undefined ? printer[0].svc : 'qp';
                if (svc === 'sd') {
                    this.printRequestToStarPrinter(item, printer);
                } else {
                    this.printRequestToQuickPrinter(item, printer);
                }
            }
        }
    }

    sendReport(item: any, code: string, dateStr: string) {
        if (item) {
            // get the receipt printer
            const storedPrinters = this.storeSvc.get(
                this.state.venue.token + '_servi-printers'
            );
            if (storedPrinters != null) {
                // this.printing = true;
                this.isPrinting.emit({ status: true });

                const printer = storedPrinters.filter((pitem: any) => {
                    return pitem.type === 'receipt';
                });

                if (!printer[0]) {
                    this.printerError.emit({
                        result: -1,
                        text: 'No printer defined.  Please define a printer in settings.'
                    });
                    return false;
                }

                const svc = printer[0].svc !== undefined ? printer[0].svc : 'qp';

                // printer[0].selectedPrinter = {};
                // printer[0].selectedPrinter.portName = 'TCP:10.0.0.172';
                // printer[0].selectedPrinter.modelName = 'TSP143 (STR_T-001)';
                if (svc === 'sd') {
                    this.printReportToStarPrinter(item, printer, code, dateStr);
                } else {
                    this.printReportToQuickPrinter(item, printer, code, dateStr);
                }
            }
        }
    }

    async sendToStations(
        item: any,
        reroute: boolean,
        retry: boolean,
        printer: any,
        auto: boolean,
        categories?: any
    ) {
        this.catArr = categories;
        let printers: any = [];
        const storedPrinters = this.storeSvc.get(
            this.state.venue.token + '_servi-printers'
        );
        if (!storedPrinters) {
            this.printerError.emit({
                result: -1,
                text: 'No printers defined.  Please define a printer in settings.'
            });
            return false;
        }

        if (item) {
            if (!!printer && printer !== 'all') {
                // send to individual print station
                // printers.push(printer);

                printers = storedPrinters.filter(
                    (pitem: any) => pitem.id === printer.id
                );
            } else {
                // send to all print stations
                if (storedPrinters != null) {
                    // remove receipt and any uninstalled printers from the send queue
                    printers = storedPrinters.filter((pitem: any) => {
                        return (
                            pitem.type !== 'receipt' &&
                            pitem.alias != null &&
                            pitem.numberOfProducts > 0
                        );
                    });

                    if (!printers) {
                        this.printerError.emit({
                            result: -1,
                            text: 'No printers defined.  Please define a printer in settings.'
                        });
                        return false;
                    }
                }
            }
            this.isPrinting.emit({ status: true });

            console.log('PRINTERS: ' + printers.length);
            console.log(JSON.stringify(printers));

            for (let i = 0; i < printers.length; i++) {
                this.printDuplicate = printers[i].duplicate != null ? printers[i].duplicate : false;
                const printCount = this.printDuplicate ? 2 : 1;
                const svc = printers[i].svc !== undefined ? printers[i].svc : 'qp';

                for (let j = 1; j <= printCount; j++) {
                    // if retry, only send failed; printer.id not in item.stationprinted[]
                    // if (retry) {
                    //   alert('retry');
                    //   if (item.stationprinted !== undefined) {
                    //     if (item.stationprinted.indexOf(printers[i].id) !== -1) {
                    //       continue;
                    //     }
                    //   }
                    // }
                    // create a closure to preserve the value of "i"
                    ((i: number) => {
                        setTimeout(() => {
                            printers[i].auto = auto;
                            if (svc === 'sd') {
                                this.printStationsToStarPrinter(item, printers[i], reroute, retry, j);
                            } else {
                                this.printStationsToQuickPrinter(item, printers[i], j);
                            }
                        }, i * 3000);
                    }).bind(this)(i);

                    if (this.printDuplicate && svc === 'qp') {
                        await this.sleep(2000);
                    }
                }
            }
        }
    }

    sendTest(printer: any) {
        // this.printing = true;
        this.isPrinting.emit({ status: true });

        this.printTestToStarPrinter(printer);
        this.printerSuccess.emit({
            result: 1,
            text: 'success'
        });
    }

    formatNumber(n: number) {
        return this.reportsSvc.formatNumber(n);
    }

    getSeatTokenStrings(item: any) {
        let orderTypeStr: string;
        let tableName: string;
        let printTypeStr: string;

        const seatToken =
            item.order && item.order.seat && item.order.seat.token
                ? item.order.seat.token
                : null;
        const seatLabel =
            item.order && item.order.seat && item.order.seat.seatNumber
                ? item.order.seat.seatNumber
                : null;

        orderTypeStr = 'Dine-In';
        tableName = seatLabel ? seatLabel : 'Table ' + seatToken;

        if (item.order && item.order.orderTypes) {
            if (item.order.orderTypes.delivery) {
                // delivery
                orderTypeStr = 'Delivery';
                tableName = 'Delivery';
                printTypeStr = 'Deliver ';
            } else if (
                item.order.orderTypes.takeout &&
                !item.order.orderTypes.dinein
            ) {
                // takeout
                orderTypeStr = 'Take-Out';
                tableName = 'Take-Out';
                printTypeStr = 'Pickup ';
            } else {
                //dinein
                orderTypeStr = 'Dine-In';
                tableName = seatLabel ? seatLabel : 'Table ' + seatToken;
            }
        }

        return { orderTypeStr, tableName, printTypeStr };
    }

    /**
     * Star Micronics Print
     */

    printReportToStarPrinter(
        item: any,
        printer: any,
        code: string,
        dateStr: string
    ) {
        this.bgModeSvc.moveToForeground();
        setTimeout(() => {
            let rasterObj: any;
            const portName = printer[0].selectedPrinter.portName !== undefined ? printer[0].selectedPrinter.portName : null;
            if (portName === null) {
                return;
            }
            const modelName = printer[0].selectedPrinter.modelName !== undefined ? printer[0].selectedPrinter.modelName : null;
            const mode = modelName.substring(0, 4) === 'TSP1' ? 'StarGraphic' : 'StarLine';

            if (mode === 'StarLine') {
                const commandsArray = this.formatStarLineMode(item, code, printer, false, null, 1, dateStr, null);
                this.starprnt
                    .print(portName, 'StarLine', commandsArray)
                    .then((result: any) => {
                        this.printerSuccess.emit({ result: 1, text: result });
                        this.notifySvc.setPrintedStatus(item).subscribe(() => { });
                    })
                    .catch((error: any) => {
                        this.printerError.emit({
                            result: 0,
                            error: error,
                            item: item,
                            printer: printer[0].selectedPrinter,
                            type: code
                        });
                    });
            } else {
                rasterObj = this.formatStarGraphicMode(item, code, printer, false, null, 1, dateStr, null);
                this.starprnt
                    .printRasterReceipt(portName, 'StarGraphic', rasterObj)
                    .then((result: any) => {
                        this.printerSuccess.emit({ result: 1, text: result });
                        this.notifySvc.setPrintedStatus(item).subscribe(() => { });
                    })
                    .catch((error: any) => {
                        this.printerError.emit({
                            result: 0,
                            error: error,
                            item: item,
                            printer: printer[0].selectedPrinter,
                            type: code
                        });
                    });
            }
        }, 500);
    }

    printReceiptToStarPrinter(item: any, printer: any) {
        this.bgModeSvc.moveToForeground();
        setTimeout(() => {
            let rasterObj: any;
            const portName =
                printer[0].selectedPrinter.portName !== undefined
                    ? printer[0].selectedPrinter.portName
                    : null;
            if (portName === null) {
                return;
            }
            const modelName =
                printer[0].selectedPrinter.modelName !== undefined
                    ? printer[0].selectedPrinter.modelName
                    : null;
            const mode =
                modelName.substring(0, 4) === 'TSP1' ? 'StarGraphic' : 'StarLine';

            if (mode === 'StarLine') {
                const commandsArray = this.formatStarLineMode(
                    item,
                    'receipt',
                    printer,
                    false,
                    null,
                    1,
                    null,
                    null
                );
                this.starprnt
                    .print(portName, 'StarLine', commandsArray)
                    .then((result: any) => {
                        this.printerSuccess.emit({ result: 1, text: result });
                        this.notifySvc.setPrintedStatus(item).subscribe(() => { });
                    })
                    .catch((error: any) => {
                        this.printerError.emit({
                            result: 0,
                            error: error,
                            item: item,
                            printer: printer[0].selectedPrinter,
                            type: 'receipt'
                        });
                    });
            } else {
                rasterObj = this.formatStarGraphicMode(
                    item,
                    'receipt',
                    printer,
                    false,
                    null,
                    1,
                    null,
                    null
                );
                this.starprnt
                    .printRasterReceipt(portName, 'StarGraphic', rasterObj)
                    .then((result: any) => {
                        this.printerSuccess.emit({ result: 1, text: result });
                        this.notifySvc.setPrintedStatus(item).subscribe(() => { });
                    })
                    .catch((error: any) => {
                        this.starprnt
                            .printRasterReceipt(portName, 'StarGraphic', rasterObj)
                            .then((result: any) => {
                                this.printerSuccess.emit({ result: 1, text: result });
                                this.notifySvc.setPrintedStatus(item).subscribe(() => { });
                            })
                            .catch((error2: any) => {
                                if (error2.indexOf('JSON') === -1) {
                                    this.printerError.emit({
                                        result: 0,
                                        error: error,
                                        item: item,
                                        printer: printer[0].selectedPrinter,
                                        type: 'receipt'
                                    });
                                }
                            });
                    });
            }
        }, 500);
    }

    printRequestToStarPrinter(item: any, printer: any) {
        this.bgModeSvc.moveToForeground();
        setTimeout(() => {
            let rasterObj: any;
            const portName =
                printer[0].selectedPrinter.portName !== undefined
                    ? printer[0].selectedPrinter.portName
                    : null;
            if (portName === null) {
                return;
            }
            const modelName =
                printer[0].selectedPrinter.modelName !== undefined
                    ? printer[0].selectedPrinter.modelName
                    : null;
            const mode =
                modelName.substring(0, 4) === 'TSP1' ? 'StarGraphic' : 'StarLine';

            if (mode === 'StarLine') {
                const commandsArray = this.formatStarLineMode(
                    item,
                    'request',
                    printer,
                    false,
                    null,
                    1,
                    null,
                    null
                );
                this.starprnt
                    .print(portName, 'StarLine', commandsArray)
                    .then((result: any) => {
                        this.printerSuccess.emit({ result: 1, text: result });
                        this.notifySvc.setPrintedStatus(item).subscribe(() => { });
                    })
                    .catch((error: any) => {
                        this.printerError.emit({
                            result: 0,
                            error: error,
                            item: item,
                            printer: printer[0].selectedPrinter,
                            type: 'request'
                        });
                    });
            } else {
                rasterObj = this.formatStarGraphicMode(
                    item,
                    'request',
                    printer,
                    false,
                    null,
                    1,
                    null,
                    null
                );
                this.starprnt
                    .printRasterReceipt(portName, 'StarGraphic', rasterObj)
                    .then((result: any) => {
                        this.printerSuccess.emit({ result: 1, text: result });
                        this.notifySvc.setPrintedStatus(item).subscribe(() => { });
                    })
                    .catch((error: any) => {
                        this.printerError.emit({
                            result: 0,
                            error: error,
                            item: item,
                            printer: printer[0].selectedPrinter,
                            type: 'request'
                        });
                    });
            }
        }, 500);
    }

    printStationsToStarPrinter(
        item: any,
        printer: any,
        reroute: boolean,
        retry: boolean,
        printCount: number
    ) {
        let portName = null;
        let reroutePrinterType = null;
        let printerType = null;

        if (reroute) {
            const storedPrinters = this.storeSvc.get(
                this.state.venue.token + '_servi-printers'
            );
            const thisPrinter = storedPrinters.filter((pitem: any) => {
                return pitem.type === 'receipt';
            });
            reroutePrinterType =
                thisPrinter[0].selectedPrinter.modelName.substring(0, 1) === 'T'
                    ? 'thermal'
                    : 'impact';
            portName =
                thisPrinter[0].selectedPrinter.portName !== undefined
                    ? thisPrinter[0].selectedPrinter.portName
                    : null;
            printerType = reroutePrinterType;
        } else {
            portName =
                printer.selectedPrinter.portName !== undefined
                    ? printer.selectedPrinter.portName
                    : null;
            printerType =
                printer.selectedPrinter.modelName.substring(0, 1) === 'T'
                    ? 'thermal'
                    : 'impact';
        }
        if (portName === null) {
            return;
        }

        const modelName =
            printer.selectedPrinter.modelName !== undefined
                ? printer.selectedPrinter.modelName
                : null;
        const mode =
            modelName.substring(0, 4) === 'TSP1' ? 'StarGraphic' : 'StarLine';

        if (mode === 'StarLine') {
            let commandsArray: any;
            commandsArray = this.formatStarLineMode(
                item,
                'station',
                printer,
                reroute,
                reroutePrinterType,
                printCount,
                null,
                null
            );
            this.sendToStarPrintService(commandsArray, portName, item, printer);

            // send same output to mapped printers
            const mapTo = printer.mapTo !== undefined ? printer.mapTo : null;
            if (mapTo != null) {
                let thisMappedPrinterArr: any;
                let thisMappedPrinter: any;
                let mapId: number;
                const storedPrinters = this.storeSvc.get(
                    this.state.venue.token + '_servi-printers'
                );

                for (let k = 0; k < mapTo.length; k++) {
                    // create a closure to preserve the value of "k"
                    ((k) => {
                        setTimeout(() => {
                            mapId = mapTo[k] != null ? mapTo[k] : 0;
                            thisMappedPrinterArr = storedPrinters.filter(
                                (pitem: any) => pitem.id === mapId
                            );
                            thisMappedPrinter =
                                thisMappedPrinterArr[0] !== undefined
                                    ? thisMappedPrinterArr[0]
                                    : null;
                            if (
                                thisMappedPrinter != null &&
                                thisMappedPrinter.selectedPrinter != null
                            ) {
                                portName =
                                    thisMappedPrinter.selectedPrinter.portName !== undefined
                                        ? thisMappedPrinter.selectedPrinter.portName
                                        : null;
                                if (portName != null) {
                                    commandsArray = this.formatStarLineMode(
                                        item,
                                        'station',
                                        printer,
                                        reroute,
                                        reroutePrinterType,
                                        printCount,
                                        null,
                                        thisMappedPrinter
                                    );
                                    this.sendToStarPrintService(
                                        commandsArray,
                                        portName,
                                        item,
                                        printer
                                    );
                                }
                            }
                        }, k * 2000);
                    }).bind(this)(k);
                }
            }
        } else {
            let rasterObj: any;
            rasterObj = this.formatStarGraphicMode(
                item,
                'station',
                printer,
                reroute,
                reroutePrinterType,
                printCount,
                null,
                null
            );
            this.sendToStarGraphicPrintService(rasterObj, portName, item, printer);

            // send same output to mapped printers
            const mapTo = printer.mapTo !== undefined ? printer.mapTo : null;
            if (mapTo != null) {
                let thisMappedPrinterArr: any;
                let thisMappedPrinter: any;
                let mapId: number;
                const storedPrinters = this.storeSvc.get(
                    this.state.venue.token + '_servi-printers'
                );

                for (let k = 0; k < mapTo.length; k++) {
                    // create a closure to preserve the value of "k"
                    ((k) => {
                        setTimeout(() => {
                            mapId = mapTo[k] != null ? mapTo[k] : 0;
                            thisMappedPrinterArr = storedPrinters.filter(
                                (pitem: any) => pitem.id === mapId
                            );
                            thisMappedPrinter =
                                thisMappedPrinterArr[0] !== undefined
                                    ? thisMappedPrinterArr[0]
                                    : null;
                            if (
                                thisMappedPrinter != null &&
                                thisMappedPrinter.selectedPrinter != null
                            ) {
                                portName =
                                    thisMappedPrinter.selectedPrinter.portName !== undefined
                                        ? thisMappedPrinter.selectedPrinter.portName
                                        : null;
                                if (portName != null) {
                                    rasterObj = this.formatStarGraphicMode(
                                        item,
                                        'station',
                                        printer,
                                        reroute,
                                        reroutePrinterType,
                                        printCount,
                                        null,
                                        null
                                    );
                                    this.sendToStarGraphicPrintService(
                                        rasterObj,
                                        portName,
                                        item,
                                        printer
                                    );
                                }
                            }
                        }, k * 2000);
                    }).bind(this)(k);
                }
            }
        }
    }

    sendToStarGraphicPrintService(
        rasterObj: any,
        portName: any,
        item: any,
        printer: any
    ) {
        this.bgModeSvc.moveToForeground();
        setTimeout(() => {
            const okToPrint = rasterObj && rasterObj.text ? true : false;
            if (okToPrint) {
                this.starprnt
                    .printRasterReceipt(portName, 'StarGraphic', rasterObj)
                    .then((result: any) => {
                        console.log('SUCCESS: ' + result);
                        this.printerSuccess.emit({ result: 1, text: result });
                        this.notifySvc
                            .setStationPrintedStatus(item, printer, true)
                            .subscribe(() => { });
                    })
                    .catch((error: any) => {
                        console.log('ERROR: ' + error);
                        // if error, retry once before failing
                        if (error.indexOf('JSON') === -1) {
                            this.starprnt
                                .printRasterReceipt(portName, 'StarGraphic', rasterObj)
                                .then((result: any) => {
                                    this.printerSuccess.emit({ result: 1, text: result });
                                    this.notifySvc
                                        .setStationPrintedStatus(item, printer, true)
                                        .subscribe(() => { });
                                })
                                .catch((error2: any) => {
                                    if (error2.indexOf('JSON') === -1) {
                                        this.printerError.emit({
                                            result: 0,
                                            error: error2,
                                            item: item,
                                            printer: printer.selectedPrinter,
                                            type: 'station'
                                        });
                                        this.notifySvc
                                            .setStationPrintedStatus(item, printer, false)
                                            .subscribe(() => { });
                                    }
                                });
                        }
                    });
            }
        }, 500);
    }

    sendToStarPrintService(
        commandsArray: any,
        portName: any,
        item: any,
        printer: any
    ) {
        this.bgModeSvc.moveToForeground();
        setTimeout(() => {
            if (commandsArray.length > 0) {
                this.starprnt
                    .print(portName, 'StarLine', commandsArray)
                    .then((result: any) => {
                        printer.status = 'success';
                        printer.auto = printer.auto ? 1 : 0;
                        this.notifySvc
                            .setStationPrintedStatus(item, printer, true)
                            .subscribe(() => { });
                        this.printerSuccess.emit({
                            result: 1,
                            text: result
                        });
                    })
                    .catch((error: any) => {
                        if (error.indexOf('was no response') !== -1 && this.printDuplicate) {
                            printer.status = 'success';
                            printer.auto = printer.auto ? 1 : 0;
                            this.notifySvc
                                .setStationPrintedStatus(item, printer, true)
                                .subscribe(() => { });
                            this.printerSuccess.emit({
                                result: 1,
                                text: 'success'
                            });
                        } else {
                            this.starprnt
                                .print(portName, 'StarLine', commandsArray)
                                .then((result: any) => {
                                    printer.status = 'success';
                                    printer.auto = printer.auto ? 1 : 0;
                                    this.notifySvc
                                        .setStationPrintedStatus(item, printer, true)
                                        .subscribe(() => { });
                                    this.printerSuccess.emit({
                                        result: 1,
                                        text: result
                                    });
                                })
                                .catch((error2: any) => {
                                    if (
                                        error.indexOf('was no response') !== -1 &&
                                        this.printDuplicate
                                    ) {
                                        printer.status = 'success';
                                        printer.auto = printer.auto ? 1 : 0;
                                        this.notifySvc
                                            .setStationPrintedStatus(item, printer, true)
                                            .subscribe(() => { });
                                        this.printerSuccess.emit({
                                            result: 1,
                                            text: 'success'
                                        });
                                    } else {
                                        printer.status = 'error';
                                        printer.errors = error;
                                        printer.auto = printer.auto ? 1 : 0;
                                        this.notifySvc
                                            .setStationPrintedStatus(item, printer, false)
                                            .subscribe(() => { });
                                        if (error2.indexOf('JSON') === -1) {
                                            this.printerError.emit({
                                                result: 0,
                                                error: error2,
                                                item: item,
                                                printer: printer.selectedPrinter,
                                                printerName: printer.name,
                                                type: 'station'
                                            });
                                        }
                                    }
                                });
                        }
                    });
            }
        }, 500);
    }

    printTestToStarPrinter(printer: any) {
        // alert(JSON.stringify(printer));
        const portName = printer.portName !== undefined ? printer.portName : null;
        const modelName =
            printer.modelName !== undefined ? printer.modelName : null;
        const macAddress =
            printer.macAddress !== undefined ? printer.macAddress : null;
        if (portName === null) {
            return;
        }
        const widthOffset =
            printer.widthOffset !== undefined ? Number(printer.widthOffset) : 0;
        const printerType =
            printer.modelName.substring(0, 1) === 'T' ? 'thermal' : 'impact';
        const mode =
            modelName.substring(0, 4) === 'TSP1' ? 'StarGraphic' : 'StarLine';

        if (mode === 'StarLine') {
            const ln = printerType === 'impact' ? 42 + widthOffset : 46 + widthOffset;
            const line = '-'.repeat(ln);
            const dline = '='.repeat(ln);

            const date = new Date();
            const dateStr = this.datePipe.transform(date, 'EEE MMM d, h:mm:ss a');
            const timeStr = this.datePipe.transform(date, 'h:mm a');

            const cs = this.getCommandSet(printerType);

            const commandsArray = [];

            if (printerType === 'impact') {
                commandsArray.push({ appendCodePage: 'CP858' });
                commandsArray.push({ appendInternational: 'UK' });
                commandsArray.push({ appendEncoding: 'UTF-8' });

                commandsArray.push({ appendBytes: cs.dot7x9 }); // default
                commandsArray.push({ appendAlignment: 'Center' });
                commandsArray.push({ append: '\n*** PRINTER TEST ***\n\n' });
                commandsArray.push({ appendAlignment: 'Left' });
                commandsArray.push({ append: dline + '\n' });

                commandsArray.push({ append: 'Model: ' + modelName + '\n' });
                commandsArray.push({ append: 'MAC Address: ' + macAddress + '\n' });
                commandsArray.push({ append: 'Type: ' + printerType + '\n' });
                commandsArray.push({ append: line + '\n' });

                commandsArray.push({ appendBytes: cs.dot5x9x2 }); // ?
                commandsArray.push({ append: 'This is 5x9x2 font' + '\n' });
                commandsArray.push({ appendBytes: cs.dot5x9x3 }); // ?
                commandsArray.push({ append: 'This is 5x9x3 font' + '\n' });

                commandsArray.push({ appendBytes: cs.dot7x9 }); // back to default
                commandsArray.push({ append: 'Back to default 7x9 font' + '\n' });

                commandsArray.push({ appendBytes: cs.height2x }); // enable double high
                commandsArray.push({ append: 'This is  2x high normal' + '\n' });
                commandsArray.push({ appendEmphasis: 'This is 2x high bold' + '\n' });
                commandsArray.push({ appendBytes: cs.heightCancel }); // cancel double high

                commandsArray.push({ appendBytes: cs.wide2x }); // enable 2x wide
                commandsArray.push({ append: 'This is 2x wide norm' + '\n' });
                commandsArray.push({ appendEmphasis: 'This is 2x wide bold' + '\n' });

                commandsArray.push({ appendBytes: cs.height2x }); // enable 2x high
                commandsArray.push({ append: '2x high/wide' + '\n' });
                commandsArray.push({ appendEmphasis: '2x high/wide bold' + '\n' });

                commandsArray.push({ appendBytes: cs.red }); // enable red
                commandsArray.push({ append: '2x high/wide Red' + '\n' });
                commandsArray.push({ appendEmphasis: '2x high/wide Red bold' + '\n' });
                commandsArray.push({ appendBytes: cs.redCancel }); // cancel red

                commandsArray.push({ appendBytes: cs.wideCancel }); // cancel expanded wide
                commandsArray.push({ appendBytes: cs.heightCancel }); // cancel expanded high
                commandsArray.push({ appendBytes: cs.dot7x9 }); // 12 dot
                commandsArray.push({ append: 'Back to default 7x9 font.' + '\n' });
                commandsArray.push({ append: line + '\n' });

                // Euro
                commandsArray.push({ appendEncoding: 'Windows-1252' });
                commandsArray.push({ appendInternational: 'Spain' });
                commandsArray.push({ append: 'Euro symbol: ' });
                commandsArray.push({ appendBytes: [0xd5] });
                commandsArray.push({ append: '\n' });

                // Pound
                commandsArray.push({ appendEncoding: 'US-ASCII' });
                commandsArray.push({ appendInternational: 'UK' });
                commandsArray.push({ append: 'Pound symbol: ' });
                commandsArray.push({ appendBytes: [0x9c] });
                commandsArray.push({ append: '\n' });

                commandsArray.push({ append: dline + '\n' });
                commandsArray.push({ appendAlignment: 'Center' });
                commandsArray.push({ append: dateStr + '\n\n' });
                commandsArray.push({ appendCutPaper: 'PartialCutWithFeed' });
            } else {
                commandsArray.push({ appendCodePage: 'CP858' });
                commandsArray.push({ appendInternational: 'UK' });
                commandsArray.push({ appendEncoding: 'UTF-8' });
                commandsArray.push({ appendBytes: cs.dot12 }); // 12 dot
                commandsArray.push({ appendBytes: cs.leftMargin }); // left margin
                commandsArray.push({ appendAlignment: 'Center' });
                commandsArray.push({ append: '\n*** PRINTER TEST ***\n\n' });
                commandsArray.push({ appendAlignment: 'Left' });
                commandsArray.push({ append: dline + '\n' });

                commandsArray.push({ append: 'Model: ' + modelName + '\n' });
                commandsArray.push({ append: 'MAC Address: ' + macAddress + '\n' });
                commandsArray.push({ append: 'Type: ' + printerType + '\n' });
                commandsArray.push({ append: line + '\n' });

                commandsArray.push({ appendBytes: cs.dot12 }); // 12 dot
                commandsArray.push({ append: 'This is normal 12 dpi' + '\n' });
                commandsArray.push({ appendEmphasis: 'This is bold 12 dpi' + '\n' });
                commandsArray.push({ appendBytes: cs.dot15 }); // 15 dot
                commandsArray.push({ append: 'This is normal 15 dpi' + '\n' });
                commandsArray.push({ appendEmphasis: 'This is bold 15 dpi' + '\n' });
                commandsArray.push({ appendBytes: cs.dot16 }); // 16 dot
                commandsArray.push({ append: 'This is normal 16 dpi' + '\n' });
                commandsArray.push({ appendEmphasis: 'This is bold 16 dpi' + '\n' });

                commandsArray.push({ appendBytes: cs.dot12 }); // 12 dot
                commandsArray.push({ append: line + '\n' });
                commandsArray.push({ append: 'Back to normal 12 dpi' + '\n' });

                commandsArray.push({ appendBytes: cs.height2x }); // enable double high
                commandsArray.push({ append: 'This is  2x high normal' + '\n' });
                commandsArray.push({ appendEmphasis: 'This is 2x high bold' + '\n' });
                commandsArray.push({ appendBytes: cs.heightCancel }); // cancel double high

                commandsArray.push({ appendBytes: cs.wide2x }); // enable 2x wide
                commandsArray.push({ append: 'This is 2x wide normal' + '\n' });
                commandsArray.push({ appendEmphasis: 'This is 2x wide bold' + '\n' });

                commandsArray.push({ appendBytes: cs.height2x }); // enable 2x high
                commandsArray.push({ append: '2x high/wide' + '\n' });
                commandsArray.push({ appendEmphasis: '2x high/wide bold' + '\n' });
                commandsArray.push({ appendInvert: '2x high/wide inverted' + '\n' });

                commandsArray.push({ appendBytes: cs.height3x }); // enable 3x high
                commandsArray.push({ append: '3x high/wide' + '\n' });
                commandsArray.push({ appendEmphasis: '3x high/wide bold' + '\n' });
                commandsArray.push({ appendInvert: '3x high/wide inverted' + '\n' });

                commandsArray.push({ appendBytes: cs.height4x }); // enable 4x high
                commandsArray.push({ appendBytes: cs.wide4x }); // enable 4x wide
                commandsArray.push({ append: '4x ' });

                commandsArray.push({ appendBytes: cs.height5x }); // enable 5x high
                commandsArray.push({ appendBytes: cs.wide5x }); // enable 5x wide
                commandsArray.push({ append: '5x ' });

                commandsArray.push({ appendBytes: cs.height6x }); // enable 6x high
                commandsArray.push({ appendBytes: cs.wide6x }); // enable 6x wide
                commandsArray.push({ append: '6x\n' });

                commandsArray.push({ appendBytes: cs.height4x }); // enable 4x high
                commandsArray.push({ appendBytes: cs.wide4x }); // enable 4x wide
                commandsArray.push({ appendEmphasis: '4x ' });

                commandsArray.push({ appendBytes: cs.height5x }); // enable 5x high
                commandsArray.push({ appendBytes: cs.wide5x }); // enable 5x wide
                commandsArray.push({ appendEmphasis: '5x ' });

                commandsArray.push({ appendBytes: cs.height6x }); // enable 6x high
                commandsArray.push({ appendBytes: cs.wide6x }); // enable 6x wide
                commandsArray.push({ appendEmphasis: '6x\n' });

                commandsArray.push({ appendBytes: cs.wideCancel }); // cancel expanded wide
                commandsArray.push({ appendBytes: cs.heightCancel }); // cancel expanded high
                commandsArray.push({ appendBytes: cs.dot12 }); // 12 dot
                commandsArray.push({ append: 'Back to normal 12 dpi.' + '\n' });
                commandsArray.push({ append: line + '\n' });

                // Euro
                commandsArray.push({ appendEncoding: 'Windows-1252' });
                commandsArray.push({ appendInternational: 'Spain' });
                commandsArray.push({ append: 'Euro symbol: ' });
                commandsArray.push({ appendBytes: [0xd5] });
                commandsArray.push({ append: '\n' });
                // Pound
                commandsArray.push({ appendEncoding: 'US-ASCII' });
                commandsArray.push({ appendInternational: 'UK' });
                commandsArray.push({ append: 'Pound symbol: ' });
                commandsArray.push({ appendBytes: [0x9c] });
                commandsArray.push({ append: '\n' });

                commandsArray.push({ append: dline + '\n' });
                commandsArray.push({ appendAlignment: 'Center' });
                commandsArray.push({ append: dateStr + '\n\n' });
                commandsArray.push({ appendCutPaper: 'PartialCutWithFeed' });
            }

            this.starprnt
                .print(portName, 'StarLine', commandsArray)
                .then((result: any) => {
                    this.printerSuccess.emit({
                        result: 1,
                        text: result
                    });
                })
                .catch((error: any) => {
                    this.printerError.emit({
                        result: 0,
                        error: error,
                        //   item: item,
                        printer: printer,
                        type: 'test'
                    });
                });
        } else {
            // StarGraphic
            let ln = 38;
            let dline = String.fromCharCode(9552).repeat(32);
            const lf = '\n';
            const rObj = {
                text:
                    ' '.repeat(Math.ceil((ln - '*** PRINTER TEST ***'.length) / 2)) +
                    '*** PRINTER TEST ***' +
                    lf +
                    dline +
                    lf +
                    'This is a test of the TSP100 printer.' +
                    lf +
                    'This is a test of the TSP100 printer.' +
                    lf +
                    'This is a test of the TSP100 printer.' +
                    lf +
                    'This is a test of the TSP100 printer.' +
                    lf +
                    'This is a test of the TSP100 printer.' +
                    lf +
                    'This is a test of the TSP100 printer.' +
                    lf +
                    'This is a test of the TSP100 printer.' +
                    lf +
                    'This is a test of the TSP100 printer.' +
                    lf +
                    'This is a test of the TSP100 printer.' +
                    lf +
                    'This is a test of the TSP100 printer.' +
                    lf +
                    dline +
                    lf,
                fontSize: 25,
                paperWidth: 576, // Options: 384 = 2", 576 = 3", 832 = 4"
                cutReceipt: true,
                openCashDrawer: false
            };

            this.starprnt
                .printRasterReceipt(portName, 'StarGraphic', rObj)
                .then((result: any) => { })
                .catch((error: any) => { });
        }
    }

    formatStarGraphicMode(
        item: any,
        format: string,
        printer: any,
        reroute: boolean,
        reroutePrinterType: string,
        printCount: number,
        dateStr: any,
        mapToPrinter: any
    ) {
        if (printer[0] !== undefined) {
            printer = printer[0];
        }

        let widthOffset: number;
        let printerType: string;
        if (mapToPrinter != null) {
            widthOffset =
                mapToPrinter.widthOffset !== undefined
                    ? Number(mapToPrinter.widthOffset)
                    : 0;
            printerType =
                mapToPrinter.selectedPrinter.modelName.substring(0, 1) === 'T'
                    ? 'thermal'
                    : 'impact';
        } else {
            widthOffset =
                printer.widthOffset !== undefined ? Number(printer.widthOffset) : 0;
            printerType =
                printer.selectedPrinter.modelName.substring(0, 1) === 'T'
                    ? 'thermal'
                    : 'impact';
        }

        if (reroute) {
            printerType = reroutePrinterType;
        }

        const country = this.state.venue.country.code === 'US' ? 'US' : 'UK';

        const dataObj = this.getDataObj(format, item, dateStr);

        // 38 chars @ 25px
        // 32 chars @ 30px
        // 27 chars @ 35px
        // 24 chars @ 40px
        let ln = 38;
        let col1 = 20;
        let col2 = 6;
        let col3 = 12;
        let col4 = 9;
        let colh = 19;

        let line = String.fromCharCode(9472).repeat(32);
        let dline = String.fromCharCode(9552).repeat(32);
        let sdline = String.fromCharCode(9552).repeat(12);
        let aline = '*';
        let pad = ' '.repeat(4);
        let spc = null;
        const lf = '\n';

        let commandsArray = [];
        let rObj = {};
        let okToSend = false;
        let products;

        const currencySymbolCommands = this.getStarCurrencySymbolCommands(
            'StarGraphic'
        );
        const cs = this.getCommandSet(printerType);

        switch (format) {
            case 'station':
                ln = 32;
                col1 = 19;
                col2 = 0;
                col3 = 12;
                line = ('-').repeat(31);
                dline = ('=').repeat(31);

                const printerId = printer.id;
                const textDuplicate = this.translationSvc.translateStatic(
                    'text_duplicate'
                );

                products = item.products !== undefined ? item.products : false;

                // check if printerId is listed in any of the products
                let hasproducts = false;
                for (let i = 0; i < products.length; i++) {
                    if (products[i].printer_id === printerId) {
                        hasproducts = true;
                        break;
                    }
                }

                if (!hasproducts) {
                    return [];
                }

                if (reroute) {
                    if (item.stationprinted) {
                        if (item.stationprinted.indexOf(printerId) !== -1) {
                            // do not print if already printed
                            return [];
                        }
                    }
                }

                // set print as line item flag
                if (!!products) {
                    products.map((product: any) => {
                        const thisProduct = this.productsSvc.getById(product.product_id);
                        if (!!product.options) {
                            product.options.map((option: any) => {
                                option.print_as_line_item = 0;
                                const thisOption = thisProduct.options.find(
                                    (opt: any) => opt.id === option.option_id
                                );
                                if (!!thisOption) {
                                    option.print_as_line_item =
                                        thisOption.print_as_line_item !== undefined
                                            ? thisOption.print_as_line_item
                                            : 0;
                                }
                            });
                        }
                    });
                }

                if (products) {
                    let orderTypeStrings = this.getSeatTokenStrings(item);
                    let orderTypeStr = orderTypeStrings.orderTypeStr
                        ? orderTypeStrings.orderTypeStr
                        : null;
                    let tableName = orderTypeStrings.tableName
                        ? orderTypeStrings.tableName
                        : null;
                    let printTypeStr = orderTypeStrings.printTypeStr
                        ? orderTypeStrings.printTypeStr
                        : 'Pick-Up';

                    let serverStr = '';
                    if (mapToPrinter != null) {
                        serverStr = dataObj.waiterName;
                        // serverStr = (dataObj.waiterName).padEnd(col1 + col2, ' ') + ('Loc: ' + mapToPrinter.id).padStart(col3, ' ') + lf;
                    } else {
                        serverStr = dataObj.waiterName;
                        //serverStr = (dataObj.waiterName).padEnd(col1 + col2, ' ') + ('Loc: ' + printerId).padStart(col3, ' ') + lf;
                    }

                    const phoneNumber =
                        this.state.venue.phone != null && this.state.venue.phone !== ''
                            ? parsePhoneNumber(
                                this.state.venue.phone + '',
                                this.state.venue.country.code as CountryCode
                            )
                            : null;

                    let deliveryStr = '';
                    if (dataObj.seatToken === 'delivery') {
                        deliveryStr += dataObj.extraInfo.addrLine1.trim() + lf;
                        if (phoneNumber != null) {
                            deliveryStr += 'Ph: ' + phoneNumber.formatNational() + lf;
                        }
                    }

                    let productsStr = '';
                    let variantName = '';
                    let productCategory: any;
                    let printCategory = false;

                    let printCnt = 0;
                    if (this.catArr) {
                        this.catArr.forEach((category: any, index: number) => {
                            printCategory = false;
                            products.forEach((product: any) => {
                                if (
                                    product.printer_id === printer.id &&
                                    product.categoryId === category.id
                                ) {
                                    printCategory = true;
                                }
                            });

                            if (printCategory) {
                                if (printCnt > 0) productsStr += line + lf;
                                productsStr +=
                                    ' '.repeat(Math.ceil((ln - category.label.length) / 2)) +
                                    category.label.toUpperCase() +
                                    lf;
                                productsStr += line + lf;
                                printCnt++;
                            }

                            products.forEach((product: any) => {
                                if (
                                    product.printer_id === printer.id &&
                                    printer.alias != null &&
                                    product.categoryId === category.id
                                ) {
                                    variantName =
                                        product.variant_name != null
                                            ? product.variant_name.toUpperCase() + ' '
                                            : null;
                                    productsStr +=
                                        Number(product.qty).toFixed(0) +
                                        ' ' +
                                        product.product_name.toUpperCase() +
                                        lf;

                                    if (variantName) {
                                        productsStr += '  > ' + variantName + lf;
                                    }

                                    if (product.options != null && product.options.length > 0) {
                                        product.options.forEach((option: any) => {
                                            if (
                                                option.tags &&
                                                option.tags.indexOf('print_group_name') !== -1 &&
                                                option.option_name && option.items && option.items.length > 0
                                            ) {
                                                productsStr += '  > ' + this.productsSvc.formatOptionName(option.option_name) + lf;
                                            }
                                            if (option.items != null && option.items.length > 0) {
                                                option.items.forEach((oitem: any) => {
                                                    if (
                                                        option.print_as_line_item !== undefined &&
                                                        option.print_as_line_item === 1
                                                    ) {
                                                        productsStr +=
                                                            Number(product.qty).toFixed(0) +
                                                            ' ' +
                                                            oitem.item_name.toUpperCase() +
                                                            lf;
                                                    } else {
                                                        productsStr += '  > ' + oitem.item_name + lf;
                                                    }
                                                });
                                            }
                                        });
                                    }

                                    if (product.instructions) {
                                        productsStr += '  > ' + product.instructions + lf;
                                    }

                                    if (product.extra_info && product.extra_info.guestName) {
                                        productsStr +=
                                            '   Guest Name - ' + product.extra_info.guestName + lf;
                                    }

                                    productsStr += lf;
                                }
                            });
                        });
                    } else {
                        products.forEach((product: any, index: number) => {
                            if (product.printer_id === printerId) {
                                productsStr +=
                                    Number(product.qty).toFixed(0) +
                                    ' ' +
                                    product.product_name.toUpperCase() +
                                    lf;

                                if (product.variant_name) {
                                    productsStr += pad + product.variant_name + lf;
                                }

                                if (
                                    typeof product.options !== 'undefined' &&
                                    product.options.length > 0
                                ) {
                                    product.options.forEach((option: any) => {
                                        if (
                                            option.tags &&
                                            option.tags.indexOf('print_group_name') !== -1 &&
                                            option.option_name && option.items && option.items.length > 0
                                        ) {
                                            productsStr += '  ' + this.productsSvc.formatOptionName(option.option_name) + '\n';
                                        }
                                        if (
                                            typeof option.items !== 'undefined' &&
                                            option.items.length > 0
                                        ) {
                                            option.items.forEach((oitem: any) => {
                                                if (
                                                    option.print_as_line_item !== undefined &&
                                                    option.print_as_line_item === 1
                                                ) {
                                                    productsStr +=
                                                        Number(product.qty).toFixed(0) +
                                                        ' ' +
                                                        oitem.item_name.toUpperCase() +
                                                        lf;
                                                } else {
                                                    productsStr += pad + oitem.item_name + lf;
                                                }
                                            });
                                        }
                                    });
                                }

                                if (product.instructions) {
                                    productsStr += '  > ' + product.instructions + lf;
                                }

                                // print option as line item after special instructions and any other options
                                // if (typeof product.options !== 'undefined' && product.options.length > 0) {
                                //   product.options.forEach((option: any) => {
                                //     if (typeof option.items !== 'undefined' && option.items.length > 0) {
                                //       option.items.forEach((oitem: any) => {
                                //         if (option.print_as_line_item !== undefined && option.print_as_line_item === 1) {
                                //           productsStr += Number(product.qty).toFixed(0) + ' ' + oitem.item_name.toUpperCase() + lf;
                                //         }
                                //       });
                                //     }
                                //   });
                                // }

                                if (product.extra_info && product.extra_info.guestName) {
                                    productsStr +=
                                        '  Guest Name - ' + product.extra_info.guestName + lf;
                                }

                                productsStr += lf;

                                okToSend = true;
                            }
                        });
                    }

                    rObj = {
                        text:
                            lf +
                            ' '.repeat(Math.ceil((ln - 6) / 2)) +
                            'SER.VI' +
                            lf +
                            lf +
                            (printCount > 1
                                ? ' '.repeat(
                                    Math.ceil(
                                        (ln -
                                            ('*** ' + textDuplicate.toUpperCase() + ' ***')
                                                .length) /
                                        2
                                    )
                                ) +
                                '*** ' +
                                textDuplicate.toUpperCase() +
                                ' ***' +
                                lf
                                : '') +
                            (mapToPrinter != null
                                ? ' '.repeat(
                                    Math.ceil(
                                        (ln -
                                            ('*** ' + mapToPrinter.name.toUpperCase() + ' Copy ***')
                                                .length) /
                                        2
                                    )
                                ) +
                                '*** ' +
                                mapToPrinter.name.toUpperCase() +
                                ' Copy ***' +
                                lf
                                : '') +
                            dline +
                            lf +
                            serverStr +
                            lf +
                            ' '.repeat(Math.ceil((ln - orderTypeStr.length) / 2)) +
                            orderTypeStr.toUpperCase() +
                            lf +
                            dataObj.dateStrShort.padEnd(col1 + col2, ' ') +
                            dataObj.timeStrShort.padStart(col3, ' ') +
                            lf +
                            dline +
                            lf +
                            // dataObj.fullName + lf +
                            (dataObj.seatToken === 'delivery'
                                ? dataObj.extraInfo.addrLine1.trim() + lf
                                : '') +
                            (dataObj.seatToken === 'delivery' && phoneNumber != null
                                ? 'Ph: ' + phoneNumber.formatNational() + lf
                                : '') +
                            (dataObj.extraInfo && dataObj.extraInfo.dayTimeStr != null
                                ? printTypeStr + ' ' + dataObj.extraInfo.dayTimeStr + lf + lf
                                : '') +
                            // line + lf +
                            productsStr +
                            line +
                            lf +
                            ' '.repeat(
                                Math.ceil(
                                    (ln - ('Ticket #: ' + dataObj.ticketCount).length) / 2
                                )
                            ) +
                            'Order #' +
                            dataObj.ticketCount.toFixed(0).padStart(3, '0') +
                            lf +
                            // line + lf +
                            ' '.repeat(
                                Math.ceil((ln - ('Order #: ' + dataObj.orderId).length) / 2)
                            ) +
                            'Order ID: ' +
                            dataObj.orderId +
                            lf +
                            line +
                            lf +
                            ' '.repeat(Math.ceil((ln - dataObj.fullName.length) / 2)) +
                            dataObj.fullName +
                            lf +
                            ' '.repeat(Math.ceil((ln - (dataObj.tableName.length + 4)) / 2)) +
                            '* ' +
                            dataObj.tableName +
                            ' *' +
                            lf +
                            deliveryStr +
                            (dataObj.extraInfo && dataObj.extraInfo.dayTimeStr != null
                                ? printTypeStr + ' ' + dataObj.extraInfo.dayTimeStr + lf
                                : '') +
                            lf +
                            (printCount > 1
                                ? ' '.repeat(
                                    Math.ceil(
                                        (ln -
                                            ('*** ' + textDuplicate.toUpperCase() + ' ***')
                                                .length) /
                                        2
                                    )
                                ) +
                                '*** ' +
                                textDuplicate.toUpperCase() +
                                ' ***' +
                                lf
                                : ''),
                        fontSize: 30, // Defaults to 25
                        paperWidth: 576, // Options: 384 = 2", 576 = 3", 832 = 4"
                        cutReceipt: true, // Defaults to true
                        openCashDrawer: false // Defaults to true
                    };
                    okToSend = true;
                }
                break;

            case 'request':
                spc = ' '.repeat(
                    ln - (dataObj.request.length + dataObj.tableId.length)
                );
                const requestLine = dataObj.request + spc + dataObj.tableId;

                rObj = {
                    text:
                        ' '.repeat(Math.ceil((ln - '*** SERVER MESSAGE ***'.length) / 2)) +
                        '*** SERVER MESSAGE ***' +
                        lf +
                        dline +
                        lf +
                        requestLine +
                        lf +
                        line +
                        lf +
                        ' '.repeat(Math.ceil((ln - dataObj.caption.length) / 2)) +
                        dataObj.caption +
                        lf +
                        dline +
                        lf +
                        ' '.repeat(Math.ceil((ln - dataObj.dateStr.length) / 2)) +
                        dataObj.dateStr +
                        lf +
                        lf,
                    fontSize: 25,
                    paperWidth: 576, // Options: 384 = 2", 576 = 3", 832 = 4"
                    cutReceipt: true,
                    openCashDrawer: false
                };

                okToSend = true;
                break;

            case 'sales-summary':
                products = item.products ? item.products : {};

                if (products) {
                    const convFeeLabel = dataObj.feeLabel != '' ? dataObj.feeLabel : 'Fees';
                    let totalsStr = '';
                    if (dataObj.currencySymbolLocation === 'after') {
                        // total collected
                        totalsStr +=
                            'Total Collected'.padEnd(
                                col1 +
                                col2 +
                                (col3 -
                                    this.formatNumber(
                                        Number(dataObj.grandTotal) - Number(dataObj.totalRefunds)
                                    ).length) -
                                dataObj.currencySymbol.length,
                                ' '
                            ) +
                            this.formatNumber(
                                Number(dataObj.grandTotal) - Number(dataObj.totalRefunds)
                            ) +
                            currencySymbolCommands +
                            lf;
                        // total net + fees
                        // if (Number(dataObj.feeTotal) > 0) {
                        //   totalsStr += convFeeLabel.padEnd(col1 + col2 + (col3 - this.formatNumber(Number(dataObj.netFeeTotal)).length) - dataObj.currencySymbol.length, ' ') + this.formatNumber(Number(dataObj.netFeeTotal)) + currencySymbolCommands + lf;
                        // }
                        // total tax
                        if (Number(dataObj.taxRateTotalSum) > 0) {
                            totalsStr +=
                                'Total Tax'.padEnd(
                                    col1 +
                                    col2 +
                                    (col3 -
                                        this.formatNumber(
                                            Number(dataObj.taxRateTotalSum) -
                                            Number(dataObj.taxRateTotalSum)
                                        ).length) -
                                    dataObj.currencySymbol.length,
                                    ' '
                                ) +
                                this.formatNumber(
                                    Number(dataObj.taxRateTotalSum) -
                                    Number(dataObj.taxRateTotalSum)
                                ) +
                                currencySymbolCommands +
                                lf;
                        }
                    } else {
                        // total collected
                        totalsStr +=
                            'Total Collected'.padEnd(
                                col1 +
                                col2 +
                                (col3 -
                                    this.formatNumber(
                                        Number(dataObj.grandTotal) - Number(dataObj.totalRefunds)
                                    ).length) -
                                dataObj.currencySymbol.length,
                                ' '
                            ) +
                            currencySymbolCommands +
                            this.formatNumber(
                                Number(dataObj.grandTotal) - Number(dataObj.totalRefunds)
                            ) +
                            lf;
                        // total net + fees
                        // if (Number(dataObj.feeTotal) > 0) {
                        //   totalsStr += convFeeLabel.padEnd(col1 + col2 + (col3 - this.formatNumber(Number(dataObj.netFeeTotal)).length) - dataObj.currencySymbol.length, ' ') + currencySymbolCommands + this.formatNumber(Number(dataObj.netFeeTotal)) + lf;
                        // }
                        // total tax
                        if (Number(dataObj.taxRateTotalSum) > 0) {
                            totalsStr += ('Total Tax + ' + convFeeLabel).padEnd(
                                col1 +
                                col2 +
                                (col3 -
                                    this.formatNumber(Number(dataObj.taxRateTotalSum))
                                        .length) -
                                dataObj.currencySymbol.length,
                                ' '
                            ) +
                                currencySymbolCommands +
                                this.formatNumber(Number(dataObj.taxRateTotalSum)) +
                                lf;
                        }
                    }

                    let taxStr = '';
                    dataObj.taxTotals.forEach((tax: any) => {
                        if (tax.taxTotal > 0) {
                            taxStr +=
                                (
                                    tax.taxRateLabel +
                                    ' (' +
                                    Number(tax.taxRate).toFixed(2) +
                                    '%)'
                                ).padEnd(col1 + col2, ' ') +
                                this.formatNumber(Number(tax.taxTotal)).padStart(col3, ' ') +
                                lf;
                        }
                    });

                    let taxInclStr = '';
                    dataObj.taxInclTotals.forEach((taxIncl: any) => {
                        if (taxIncl.totalTax > 0) {
                            taxInclStr +=
                                (
                                    taxIncl.taxRateLabel +
                                    ' (' +
                                    this.formatNumber(Number(taxIncl.totalTax)) +
                                    ' @ ' +
                                    Number(taxIncl.taxRate).toFixed(2) +
                                    '%)'
                                ).padEnd(col1 + col2, ' ') +
                                'included'.padStart(col3, ' ') +
                                lf;
                        }
                    });

                    // discounts
                    let discountStr = '';
                    if (Number(dataObj.discountTotal) > 0) {
                        discountStr +=
                            ' '.repeat(Math.ceil((ln - 9) / 2)) + 'DISCOUNTS' + lf;
                        discountStr +=
                            'Discount Name'.padEnd(col1 + col2, ' ') +
                            'Amount'.padStart(col3, ' ') +
                            lf +
                            line +
                            lf;
                        discountStr +=
                            'First Visit Discount'.padEnd(col1 + col2, ' ') +
                            this.formatNumber(Number(dataObj.discountTotal)).padStart(
                                col3,
                                ' '
                            ) +
                            lf +
                            line +
                            lf;

                        // total discounts
                        if (dataObj.currencySymbolLocation === 'after') {
                            discountStr +=
                                'Total Discounts'.padEnd(
                                    col1 +
                                    col2 +
                                    (col3 -
                                        this.formatNumber(Number(dataObj.discountTotal)).length) -
                                    1,
                                    ' '
                                ) +
                                this.formatNumber(Number(dataObj.discountTotal)) +
                                currencySymbolCommands +
                                lf;
                        } else {
                            discountStr +=
                                'Total Discounts'.padEnd(
                                    col1 +
                                    col2 +
                                    (col3 -
                                        this.formatNumber(Number(dataObj.discountTotal)).length) -
                                    1,
                                    ' '
                                ) +
                                currencySymbolCommands +
                                this.formatNumber(Number(dataObj.discountTotal)) +
                                lf;
                        }
                    }

                    let categoryStr = '';
                    dataObj.catalogGroups.forEach((group: any) => {
                        categoryStr +=
                            group.label.padEnd(col1, ' ') +
                            Number(this.getCatalogGroupQty(item, group))
                                .toFixed(0)
                                .padStart(col2, ' ') +
                            this.formatNumber(
                                Number(this.getCatalogGroupPrice(item, group))
                            ).padStart(col3, ' ') +
                            lf;
                    });

                    let categoryTotalStr = '';
                    if (dataObj.currencySymbolLocation === 'after') {
                        categoryTotalStr +=
                            'Total Net Sales'.padEnd(col1, ' ') +
                            Number(dataObj.totalItems).toFixed(0).padStart(col2, ' ') +
                            ' '.repeat(
                                col3 -
                                this.formatNumber(Number(dataObj.subtotal)).length -
                                dataObj.currencySymbol.length
                            ) +
                            this.formatNumber(Number(dataObj.subtotal)) +
                            currencySymbolCommands +
                            lf;
                    } else {
                        categoryTotalStr +=
                            'Total Net Sales'.padEnd(col1, ' ') +
                            Number(dataObj.totalItems).toFixed(0).padStart(col2, ' ') +
                            ' '.repeat(
                                col3 -
                                this.formatNumber(Number(dataObj.subtotal)).length -
                                dataObj.currencySymbol.length
                            ) +
                            currencySymbolCommands +
                            this.formatNumber(Number(dataObj.subtotal)) +
                            lf;
                    }

                    let productsStr = '';
                    dataObj.productIdObj.forEach((productId: any) => {
                        productsStr +=
                            this.getItemName(item, productId)
                                .substring(0, col1)
                                .padEnd(col1, ' ') +
                            Number(this.sumItemQty(item, productId))
                                .toFixed(0)
                                .padStart(col2, ' ') +
                            this.formatNumber(
                                Number(this.sumItemPrice(item, productId))
                            ).padStart(col3, ' ') +
                            lf;
                    });

                    let refundsStr = '';
                    dataObj.refunds.forEach((refund: any) => {
                        refundsStr +=
                            refund.id.toString().padEnd(col4, ' ') +
                            refund.refunded.padStart(col1, ' ') +
                            this.formatNumber(Number(refund.refund_amount)).padStart(
                                col4,
                                ' '
                            ) +
                            lf;
                        refundsStr +=
                            '> ' +
                            refund.refund_reason
                                .replace('Ref:', ' ')
                                .replace(/<[^>]*>?/gm, '') +
                            lf +
                            lf;
                    });

                    const payMethodTotals = (
                        (dataObj && dataObj.payMethodTotals && dataObj.payMethodTotals.bank > 0) ?
                            'Bank Payment'.padEnd(col1 + col2, ' ') + dataObj.payMethodTotals.bank.toFixed(2).padStart(col3, ' ') + lf : ''
                    ) +
                        (
                            (dataObj && dataObj.payMethodTotals && dataObj.payMethodTotals.cash > 0) ?
                                'CASH'.padEnd(col1 + col2, ' ') + dataObj.payMethodTotals.cash.toFixed(2).padStart(col3, ' ') + lf : ''
                        ) +
                        (
                            (dataObj && dataObj.payMethodTotals && dataObj.payMethodTotals.card > 0) ?
                                'Credit Card'.padEnd(col1 + col2, ' ') + dataObj.payMethodTotals.card.toFixed(2).padStart(col3, ' ') + lf : ''
                        ) +
                        (
                            (dataObj && dataObj.payMethodTotals && dataObj.payMethodTotals.tab > 0) ?
                                'Tab'.padEnd(col1 + col2, ' ') + dataObj.payMethodTotals.tab.toFixed(2).padStart(col3, ' ') + lf : ''
                        ) +
                        (
                            (dataObj && dataObj.payMethodTotals && dataObj.payMethodTotals.terminal > 0) ?
                                'Terminal'.padEnd(col1 + col2, ' ') + dataObj.payMethodTotals.terminal.toFixed(2).padStart(col3, ' ') + lf : ''
                        );

                    rObj = {
                        text:
                            ' '.repeat(Math.ceil((ln - dataObj.venueName.length) / 2)) +
                            dataObj.venueName.toUpperCase() +
                            lf +
                            (dataObj.venueAddrLine1 != null &&
                                dataObj.venueAddrLine1.trim().length > 0
                                ? ' '.repeat(
                                    Math.ceil((ln - dataObj.venueAddrLine1.length) / 2)
                                ) +
                                dataObj.venueAddrLine1 +
                                lf
                                : '') +
                            (dataObj.venueAddrLine2 != null &&
                                dataObj.venueAddrLine2.trim().length > 0
                                ? ' '.repeat(
                                    Math.ceil((ln - dataObj.venueAddrLine2.length) / 2)
                                ) +
                                dataObj.venueAddrLine2 +
                                lf
                                : '') +
                            (dataObj.venueAddrLine3 != null &&
                                dataObj.venueAddrLine3.trim().length > 0
                                ? ' '.repeat(
                                    Math.ceil((ln - dataObj.venueAddrLine3.length) / 2)
                                ) +
                                dataObj.venueAddrLine3 +
                                lf
                                : '') +
                            (dataObj.venueAddrLine4 != null &&
                                dataObj.venueAddrLine4.trim().length > 0
                                ? ' '.repeat(
                                    Math.ceil((ln - dataObj.venueAddrLine4.length) / 2)
                                ) +
                                dataObj.venueAddrLine4 +
                                lf
                                : '') +
                            lf +
                            dline +
                            lf +
                            ' '.repeat(Math.ceil((ln - 13) / 2)) +
                            'SALES SUMMARY' +
                            lf +
                            ' '.repeat(Math.ceil((ln - dataObj.reportDateStr.length) / 2)) +
                            dataObj.reportDateStr +
                            lf +
                            dline +
                            lf +
                            ' '.repeat(Math.ceil((ln - 23) / 2)) +
                            'SALES AND TAXES SUMMARY' +
                            lf +
                            line +
                            lf +
                            'Total Net Sales'.padEnd(col1 + col2, ' ') +
                            this.formatNumber(Number(dataObj.subtotal)).padStart(col3, ' ') +
                            lf +
                            (dataObj.feeTotal > 0
                                ? convFeeLabel.padEnd(col1 + col2, ' ') +
                                this.formatNumber(Number(dataObj.feeTotal)).padStart(
                                    col3,
                                    ' '
                                ) +
                                lf
                                : '') +
                            (dataObj.totalRefunds > 0
                                ? 'Refunds'.padEnd(col1 + col2, ' ') +
                                this.formatNumber(Number(dataObj.totalRefunds) * -1).padStart(
                                    col3,
                                    ' '
                                ) +
                                lf
                                : '') +
                            (dataObj.totalComp > 0
                                ? 'Complimentary'.padEnd(col1 + col2, ' ') +
                                this.formatNumber(Number(dataObj.total)).padStart(col3, ' ') +
                                lf
                                : '') +
                            (dataObj.tipTotal > 0
                                ? 'Tips'.padEnd(col1 + col2, ' ') +
                                this.formatNumber(Number(dataObj.tipTotal)).padStart(
                                    col3,
                                    ' '
                                ) +
                                lf
                                : '') +
                            (taxStr != '' ? taxStr : '') +
                            (taxInclStr != '' ? taxInclStr + lf : '') +
                            line +
                            lf +
                            totalsStr +
                            lf +

                            ' '.repeat(Math.ceil((ln - 23) / 2)) +
                            'SALES BY PAYMENT METHOD' +
                            lf +
                            line +
                            lf +
                            payMethodTotals +
                            lf +

                            ' '.repeat(Math.ceil((ln - 14) / 2)) +
                            'ORDERS SUMMARY' +
                            lf +
                            line +
                            lf +
                            'Total Orders'.padEnd(col1 + col2, ' ') +
                            Number(dataObj.totalOrders).toFixed(0).padStart(col3, ' ') +
                            lf +
                            'Average Ticket'.padEnd(col1 + col2, ' ') +
                            this.formatNumber(
                                Number(dataObj.subtotal / dataObj.totalOrders)
                            ).padStart(col3, ' ') +
                            lf +
                            lf +
                            (refundsStr !== ''
                                ? ' '.repeat(Math.ceil((ln - 7) / 2)) +
                                'REFUNDS' +
                                lf +
                                line +
                                lf
                                : '') +
                            (refundsStr !== ''
                                ? 'Order ID'.padEnd(col4, ' ') +
                                'Refund Date'.padEnd(col1, ' ') +
                                'Amount'.padStart(col4, ' ') +
                                lf +
                                line +
                                lf
                                : '') +
                            (refundsStr !== '' ? refundsStr : '') +
                            line +
                            lf +
                            (refundsStr !== ''
                                ? 'Total Refunds'.padEnd(col1 + col2, ' ') +
                                this.formatNumber(Number(dataObj.totalRefunds) * -1).padStart(
                                    col3,
                                    ' '
                                ) +
                                lf +
                                lf
                                : '') +
                            discountStr +
                            ' '.repeat(Math.ceil((ln - 17) / 2)) +
                            'SALES BY CATEGORY' +
                            lf +
                            'Category'.padEnd(col1, ' ') +
                            'Qty'.padStart(col2, ' ') +
                            'Category'.padStart(col3, ' ') +
                            lf +
                            line +
                            lf +
                            categoryStr +
                            lf +
                            line +
                            lf +
                            categoryTotalStr +
                            lf +
                            ' '.repeat(Math.ceil((ln - 13) / 2)) +
                            'SALES BY ITEM' +
                            lf +
                            'Name'.padEnd(col1, ' ') +
                            'Qty'.padStart(col2, ' ') +
                            'Category'.padStart(col3, ' ') +
                            lf +
                            line +
                            lf +
                            productsStr +
                            line +
                            lf +
                            categoryTotalStr +
                            lf +
                            dline +
                            lf +
                            ' '.repeat(Math.ceil((ln - dataObj.dateStr.length) / 2)) +
                            dataObj.dateStr +
                            lf +
                            lf,
                        fontSize: 25,
                        paperWidth: 576, // Options: 384 = 2", 576 = 3", 832 = 4"
                        cutReceipt: true,
                        openCashDrawer: false
                    };

                    okToSend = true;
                }
                break;

            case 'shift-report':
                let detailsStr = '';
                if (dataObj.mode === 'detail') {
                    detailsStr += dline + lf;
                    detailsStr +=
                        ' '.repeat(
                            Math.ceil((ln - '*** TRANSACTION DETAILS ***'.length) / 2)
                        ) +
                        '*** TRANSACTION DETAILS ***' +
                        lf;
                    detailsStr += line + lf;
                    if (!!dataObj.orders) {
                        detailsStr +=
                            'OrderID'.padEnd(col4) +
                            'Table'.padEnd(col4) +
                            'Time'.padEnd(col4) +
                            'Amount'.padStart(col4 + 2) +
                            lf +
                            line +
                            lf;
                        let seatObj: any = {};
                        let seatToken: string = '';
                        let created: any;
                        dataObj.orders.map((item: any) => {
                            try {
                                seatObj = JSON.parse(item.seat);
                                seatToken = !!seatObj ? seatObj.token : '';
                            } catch (e) { }

                            created = new Date(item.created);

                            detailsStr +=
                                item.id.toFixed(0).padEnd(col4) +
                                seatToken.padEnd(col4) +
                                this.datePipe.transform(created, 'HH:mm:ss').padEnd(col4) +
                                item.total.padStart(col4 + 2) +
                                lf;
                        });
                    }
                    detailsStr += line + lf;
                    detailsStr +=
                        ' '.repeat(Math.ceil((ln - '*** END DETAILS ***'.length) / 2)) +
                        '*** END DETAILS ***' +
                        lf;
                }

                let taxInclStr = '';
                if (!!dataObj.taxInclTotals) {
                    dataObj.taxInclTotals.forEach((taxIncl: any) => {
                        if (Number(taxIncl.totalTax.replace(/\D/g, '')) > 0) {
                            const len =
                                col1 +
                                col2 +
                                col3 -
                                (
                                    taxIncl.taxRateLabel +
                                    '(' +
                                    taxIncl.totalTax +
                                    ' @ ' +
                                    taxIncl.taxRate +
                                    '%)'
                                ).length;
                            taxInclStr +=
                                taxIncl.taxRateLabel +
                                '(' +
                                taxIncl.totalTax +
                                ' @ ' +
                                taxIncl.taxRate +
                                '%)' +
                                'included'.padStart(len, ' ') +
                                lf;
                        }
                    });
                }

                rObj = {
                    text:
                        ' '.repeat(Math.ceil((ln - dataObj.venueName.length) / 2)) +
                        dataObj.venueName.toUpperCase() +
                        lf +
                        (dataObj.venueAddrLine1 != null &&
                            dataObj.venueAddrLine1.trim().length > 0
                            ? ' '.repeat(
                                Math.ceil((ln - dataObj.venueAddrLine1.length) / 2)
                            ) +
                            dataObj.venueAddrLine1 +
                            lf
                            : '') +
                        (dataObj.venueAddrLine2 != null &&
                            dataObj.venueAddrLine2.trim().length > 0
                            ? ' '.repeat(
                                Math.ceil((ln - dataObj.venueAddrLine2.length) / 2)
                            ) +
                            dataObj.venueAddrLine2 +
                            lf
                            : '') +
                        (dataObj.venueAddrLine3 != null &&
                            dataObj.venueAddrLine3.trim().length > 0
                            ? ' '.repeat(
                                Math.ceil((ln - dataObj.venueAddrLine3.length) / 2)
                            ) +
                            dataObj.venueAddrLine3 +
                            lf
                            : '') +
                        (dataObj.venueAddrLine4 != null &&
                            dataObj.venueAddrLine4.trim().length > 0
                            ? ' '.repeat(
                                Math.ceil((ln - dataObj.venueAddrLine4.length) / 2)
                            ) +
                            dataObj.venueAddrLine4 +
                            lf
                            : '') +
                        lf +
                        dline +
                        lf +
                        ' '.repeat(Math.ceil(ln - 20) / 2) +
                        '*** SHIFT REPORT ***' +
                        lf +
                        ' '.repeat(Math.ceil((ln - dataObj.shiftName.length) / 2)) +
                        dataObj.shiftName +
                        lf +
                        line +
                        lf +
                        'Station: '.padEnd(col1, ' ') +
                        dataObj.waiterName.padStart(col2 + col3, ' ') +
                        lf +
                        'Start: '.padEnd(col1, ' ') +
                        dataObj.reportDateStr.padStart(col2 + col3, ' ') +
                        lf +
                        line +
                        lf +
                        'Total Orders:'.padEnd(col1 + col2, ' ') +
                        parseInt(dataObj.totalOrders).toFixed(0).padStart(col3, ' ') +
                        lf +
                        lf +
                        (Number(dataObj.subtotal.replace(/\D/g, '')) > 0
                            ? 'Total Net Sales:'.padEnd(col1 + col2, ' ') +
                            dataObj.subtotal.padStart(col3, ' ') +
                            lf
                            : '') +
                        taxInclStr +
                        (Number(dataObj.totalTax.replace(/\D/g, '')) > 0
                            ? 'Tax:'.padEnd(col1 + col2, ' ') +
                            dataObj.totalTax.padStart(col3, ' ') +
                            lf
                            : '') +
                        (Number(dataObj.totalFees.replace(/\D/g, '')) > 0
                            ? 'Fees:'.padEnd(col1 + col2, ' ') +
                            dataObj.totalFees.padStart(col3, ' ') +
                            lf
                            : '') +
                        (Number(dataObj.totalTips.replace(/\D/g, '')) > 0
                            ? 'Tips:'.padEnd(col1 + col2, ' ') +
                            dataObj.totalTips.padStart(col3, ' ') +
                            lf
                            : '') +
                        (Number(dataObj.totalDiscount.replace(/\D/g, '')) > 0
                            ? 'Discounts:'.padEnd(col1 + col2, ' ') +
                            dataObj.totalDiscount.padStart(col3, ' ') +
                            lf
                            : '') +
                        ' '.repeat(26) +
                        '============' +
                        lf +
                        (dataObj.currencySymbolLocation !== 'after'
                            ? 'TOTAL:'.padEnd(col1 + col2, ' ') +
                            (currencySymbolCommands + dataObj.totalSales).padStart(
                                col3,
                                ' '
                            ) +
                            lf +
                            lf
                            : '') +
                        (dataObj.currencySymbolLocation === 'after'
                            ? 'TOTAL:'.padEnd(col1 + col2, ' ') +
                            dataObj.totalSales.padStart(col3 - 1, ' ') +
                            currencySymbolCommands +
                            lf +
                            lf
                            : '') +
                        (dataObj.mode === 'detail' ? detailsStr + lf : '') +
                        dline +
                        lf +
                        ' '.repeat(Math.ceil((ln - dataObj.dateStr.length) / 2)) +
                        dataObj.dateStr +
                        lf +
                        lf,
                    fontSize: 25,
                    paperWidth: 576, // Options: 384 = 2", 576 = 3", 832 = 4"
                    cutReceipt: true,
                    openCashDrawer: false
                };

                okToSend = true;
                break;

            case 'receipt':
            default:
                products = item.products ? item.products : {};
                let variantStr = '';
                let optionsStr = '';
                let optionsPrice = 0;
                let productName = '';
                let productPrice = 0;
                let productTotal = 0;

                let orderTypeStrings = this.getSeatTokenStrings(item);
                let printTypeStr = orderTypeStrings.printTypeStr
                    ? orderTypeStrings.printTypeStr
                    : 'Pick-Up';

                const phoneNumber =
                    this.state.venue.phone != null && this.state.venue.phone !== ''
                        ? parsePhoneNumber(
                            this.state.venue.phone + '',
                            this.state.venue.country.code as CountryCode
                        )
                        : null;

                let productsStr = '';
                products.forEach((product: any) => {
                    if (product.variant_name) {
                        variantStr = ' - ' + product.variant_name + ' ';
                    } else {
                        variantStr = '';
                    }

                    optionsStr = '';
                    if (
                        typeof product.options !== 'undefined' &&
                        product.options.length > 0
                    ) {
                        optionsPrice = 0;
                        product.options.forEach((option: any) => {
                            if (
                                option.tags &&
                                option.tags.indexOf('print_group_name') !== -1 &&
                                option.option_name && option.items && option.items.length > 0
                            ) {
                                optionsStr += '  ' + this.productsSvc.formatOptionName(option.option_name) + '\n';
                            }
                            if (
                                typeof option.items !== 'undefined' &&
                                option.items.length > 0
                            ) {
                                option.items.forEach((oitem: any) => {
                                    optionsPrice = optionsPrice + Number(oitem.item_price);
                                    // optionsStr += '  - ' + oitem.item_name + '\n';
                                    optionsStr +=
                                        '  - ' +
                                        oitem.item_name +
                                        ' +' +
                                        Number(oitem.item_price).toFixed(2) +
                                        '\n';
                                });
                            }
                        });
                    }

                    productPrice = (Number(product.price) + optionsPrice) * product.qty;
                    productTotal = Number(productTotal) + Number(productPrice);

                    productName = product.product_name.toUpperCase().substring(0, 20);
                    productsStr +=
                        (product.qty + ' ' + productName).padEnd(col1 + col2, ' ') +
                        productPrice.toFixed(2).padStart(col3, ' ') +
                        lf;
                    if (variantStr !== '') productsStr += variantStr + lf;
                    productsStr += optionsStr;
                    if (product.instructions) {
                        productsStr += '  - ' + product.instructions + lf;
                    }
                    if (product.extra_info && product.extra_info.guestName) {
                        productsStr +=
                            '  - Guest Name - ' + product.extra_info.guestName + lf;
                    }
                    productsStr += lf;
                });

                const feeLabel =
                    dataObj.convenienceFeeLabel != null
                        ? dataObj.convenienceFeeLabel
                        : 'Convenience Fee';
                const extraFeeLabel =
                    dataObj.extraFeeLabel != null ? dataObj.extraFeeLabel : "Add'l Fee";

                let paidByStr = '';
                if (item.order.method.type === 'redirect') {
                    if (item.order.method.code === 'Paypal') {
                        paidByStr =
                            'PayPal TEND:'.padEnd(col1 + col2, ' ') +
                            this.formatNumber(Number(dataObj.total)).padStart(col3, ' ') +
                            lf;
                    }
                    if (item.order.method.code === 'Ccvideal') {
                        paidByStr =
                            'iDEAL Bank TEND:'.padEnd(col1 + col2, ' ') +
                            this.formatNumber(Number(dataObj.total)).padStart(col3, ' ') +
                            lf;
                    }
                } else {
                    if (
                        item.order.method.code === 'Tabpayments' ||
                        item.order.method.code === 'Cashpayments'
                    ) {
                        const tendStr = item.paid ? 'Order PAID:' : 'Open Order:';
                        paidByStr =
                            tendStr.padEnd(col1 + col2, ' ') +
                            this.formatNumber(
                                Number(dataObj.total - dataObj.compAmount)
                            ).padStart(col3, ' ') +
                            lf;
                    } else {
                        paidByStr =
                            (dataObj.cardType + ' ' + dataObj.lastFour + ' TEND:').padEnd(
                                col1 + col2,
                                ' '
                            ) +
                            this.formatNumber(
                                Number(dataObj.total - dataObj.compAmount)
                            ).padStart(col3, ' ') +
                            lf;
                    }
                }

                let taxInclTotalsStr = '';
                if (dataObj.taxInclTotals != null && country !== 'US') {
                    const textVat = this.translationSvc.translateStatic('text_vat');
                    const textVatExcluded = this.translationSvc.translateStatic(
                        'text_excluded_vat'
                    );
                    const textOver = this.translationSvc.translateStatic('text_over');
                    const grandTotalBeforeTax = this.roundValue(
                        dataObj.taxInclTotals.reduce((oTotal: number, opt: any) => {
                            return oTotal + Number(opt.totalBeforeTax);
                        }, 0)
                    );

                    taxInclTotalsStr +=
                        textVat +
                        lf +
                        textVatExcluded.padEnd(col1 + col2, ' ') +
                        this.formatNumber(Number(grandTotalBeforeTax)).padStart(col3, ' ') +
                        lf;

                    dataObj.taxInclTotals.forEach((taxIncl: any) => {
                        if (Number(taxIncl.totalTax) > 0) {
                            taxInclTotalsStr +=
                                (
                                    Number(taxIncl.taxRate).toString() +
                                    '% ' +
                                    textOver +
                                    ' ' +
                                    this.formatNumber(Number(taxIncl.totalBeforeTax)) +
                                    ' (' +
                                    this.formatNumber(taxIncl.totalWithTax) +
                                    ')'
                                ).padEnd(col1 + col2, ' ') +
                                this.formatNumber(Number(taxIncl.totalTax)).padStart(
                                    col3,
                                    ' '
                                ) +
                                lf;
                        }
                    });
                }

                let addressBlockStr = '';
                const fullAddress = dataObj.extraInfo.address || '';
                const addressComponents = fullAddress.split(', ');
                const [street = '', city = '', stateZip = ''] = addressComponents;
                addressBlockStr += street.trim() + '\n' + (city?.trim() || '') + ', ' + (stateZip?.trim() || '');

                // const venueNameLength = dataObj.venueName.length;
                // const repeatCount = Math.max(0, Math.ceil((ln - venueNameLength) / 2));
                // const paddedVenueName = ' '.repeat(repeatCount) + dataObj.venueName.toUpperCase();

                const maxLineLength = 32; // Maximum length for each line
                const totalWidth = ln; // Width for centering

                function padCenter(text, width) {
                    const textLength = text.length;
                    const repeatCount = Math.max(0, Math.ceil((width - textLength) / 2));
                    return ' '.repeat(repeatCount) + text + ' '.repeat(repeatCount);
                }

                function splitAndPadVenueName(venueName, maxLineLength, totalWidth) {
                    const lines = [];
                    for (let i = 0; i < venueName.length; i += maxLineLength) {
                        lines.push(venueName.substring(i, i + maxLineLength));
                    }
                    return lines.map(line => padCenter(line, totalWidth)).join('\n');
                }

                const venueName = dataObj.venueName.toUpperCase();
                const formattedVenueName = splitAndPadVenueName(venueName, maxLineLength, totalWidth);

                rObj = {
                    text:
                        formattedVenueName +
                        lf +
                        (dataObj.venueAddrLine1 && // Check if venueAddrLine1 is not undefined or null
                            dataObj.venueAddrLine1.trim().length > 0
                            ? ' '.repeat(
                                Math.ceil((ln - dataObj.venueAddrLine1.length) / 2)
                            ) +
                            dataObj.venueAddrLine1 +
                            lf
                            : '') +
                        (dataObj.venueAddrLine2 &&
                            dataObj.venueAddrLine2.trim().length > 0
                            ? ' '.repeat(
                                Math.ceil((ln - dataObj.venueAddrLine2.length) / 2)
                            ) +
                            dataObj.venueAddrLine2 +
                            lf
                            : '') +
                        (dataObj.venueAddrLine3 &&
                            dataObj.venueAddrLine3.trim().length > 0
                            ? ' '.repeat(
                                Math.ceil((ln - dataObj.venueAddrLine3.length) / 2)
                            ) +
                            dataObj.venueAddrLine3 +
                            lf
                            : '') +
                        (dataObj.venueAddrLine4 &&
                            dataObj.venueAddrLine4.trim().length > 0
                            ? ' '.repeat(
                                Math.ceil((ln - dataObj.venueAddrLine4.length) / 2)
                            ) +
                            dataObj.venueAddrLine4 +
                            lf
                            : '') +
                        lf +
                        dline +
                        lf +
                        ('Server: ' + dataObj.waiterName).padEnd(colh, ' ') +
                        ('Order: ' + dataObj.orderId).padStart(colh, ' ') +
                        lf +
                        line +
                        lf +
                        dataObj.name.padEnd(col1, ' ') +
                        dataObj.tableId.padStart(col2 + col3, ' ') +
                        lf +

                        (dataObj.extraInfo && dataObj.serviceMode === 'delivery'
                            ? addressBlockStr + lf
                            : '') +
                        (dataObj.seatToken === 'delivery' && phoneNumber != null
                            ? phoneNumber.formatNational() + lf
                            : '') +

                        (dataObj.seatToken === 'curbside' &&
                            dataObj.extraInfo.address
                            ? dataObj.extraInfo.address.trim() + lf
                            : '') +
                        (dataObj.extraInfo && dataObj.extraInfo.dayTimeStr != null
                            ? printTypeStr + ' ' + dataObj.extraInfo.dayTimeStr + lf
                            : '') +
                        dline +
                        lf +
                        '          >> ORDER SETTLED <<' +
                        lf +
                        lf +
                        productsStr +
                        lf +
                        (productTotal > 0
                            ? 'SUB TOTAL:'.padEnd(col1 + col2, ' ') +
                            this.formatNumber(Number(productTotal)).padStart(col3, ' ') +
                            lf
                            : '') +
                        (dataObj.tip > 0
                            ? 'TIP:'.padEnd(col1 + col2, ' ') +
                            this.formatNumber(Number(dataObj.tip)).padStart(col3, ' ') +
                            lf
                            : '') +
                        (dataObj.discount > 0
                            ? 'DISCOUNT:'.padEnd(col1 + col2, ' ') +
                            this.formatNumber(Number(dataObj.discount)).padStart(
                                col3,
                                ' '
                            ) +
                            lf
                            : '') +
                        (dataObj.convenienceFee > 0
                            ? (feeLabel.toUpperCase() + ':').padEnd(col1 + col2, ' ') +
                            this.formatNumber(Number(dataObj.convenienceFee)).padStart(
                                col3,
                                ' '
                            ) +
                            lf
                            : '') +
                        (dataObj.extraFee > 0
                            ? (extraFeeLabel.toUpperCase() + ':').padEnd(col1 + col2, ' ') +
                            this.formatNumber(Number(dataObj.extraFee)).padStart(
                                col3,
                                ' '
                            ) +
                            lf
                            : '') +
                        (dataObj.tax > 0
                            ? (dataObj.taxLabel.toUpperCase() + ':').padEnd(
                                col1 + col2,
                                ' '
                            ) +
                            this.formatNumber(Number(dataObj.tax)).padStart(col3, ' ') +
                            lf
                            : '') +
                        (dataObj.compAmount > 0
                            ? 'COMPLIMENTARY:'.padEnd(col1 + col2, ' ') +
                            this.formatNumber(Number(dataObj.compAmount * -1)).padStart(
                                col3,
                                ' '
                            ) +
                            lf
                            : '') +
                        ' '.repeat(26) +
                        '------------' +
                        lf +
                        (dataObj.currencySymbolLocation === 'after'
                            ? 'TOTAL:'.padEnd(
                                col1 +
                                col2 +
                                (col3 - Number(dataObj.total).toFixed(2).length) -
                                dataObj.currencySymbol.length,
                                ' '
                            ) +
                            this.formatNumber(Number(dataObj.total - dataObj.compAmount)) +
                            currencySymbolCommands +
                            lf +
                            lf
                            : '') +
                        (dataObj.currencySymbolLocation !== 'after'
                            ? 'TOTAL:'.padEnd(
                                col1 +
                                col2 +
                                (col3 - Number(dataObj.total).toFixed(2).length) -
                                dataObj.currencySymbol.length,
                                ' '
                            ) +
                            currencySymbolCommands +
                            this.formatNumber(Number(dataObj.total - dataObj.compAmount)) +
                            lf +
                            lf
                            : '') +
                        paidByStr +
                        ' '.repeat(26) +
                        '------------' +
                        lf +
                        'CHANGE:'.padEnd(col1 + col2, ' ') +
                        this.formatNumber(0.0).padStart(col3, ' ') +
                        lf +
                        lf +
                        taxInclTotalsStr +
                        line +
                        lf +
                        ' '.repeat(
                            Math.ceil(
                                (ln - ('>> Ticket #: ' + dataObj.ticketCount + ' <<').length) /
                                2
                            )
                        ) +
                        '>> Ticket #: ' +
                        dataObj.ticketCount +
                        ' <<' +
                        lf +
                        ' '.repeat(
                            Math.ceil(
                                (ln - ('>> Order #: ' + dataObj.orderId + ' <<').length) / 2
                            )
                        ) +
                        '>> Order #: ' +
                        dataObj.orderId +
                        ' <<' +
                        lf +
                        ' '.repeat(Math.ceil((ln - dataObj.dateStr.length) / 2)) +
                        dataObj.dateStr +
                        lf +
                        lf +
                        ' '.repeat(Math.ceil((ln - 'THANK YOU!'.length) / 2)) +
                        'THANK YOU!' +
                        lf,
                    fontSize: 25, // Defaults to 25
                    paperWidth: 576, // Options: 384 = 2", 576 = 3", 832 = 4"
                    cutReceipt: true, // Defaults to true
                    openCashDrawer: false // Defaults to true
                };

                okToSend = true;
        }

        return okToSend ? rObj : {};
    }

    formatStarLineMode(
        item: any,
        format: string,
        printer: any,
        reroute: boolean,
        reroutePrinterType: string,
        printCount: number,
        dateStr: any,
        mapToPrinter: any
    ) {
        if (printer[0] !== undefined) {
            printer = printer[0];
        }

        let widthOffset: number;
        let printerType: string;
        if (mapToPrinter != null) {
            widthOffset =
                mapToPrinter.widthOffset !== undefined
                    ? Number(mapToPrinter.widthOffset)
                    : 0;
            printerType =
                mapToPrinter.selectedPrinter.modelName.substring(0, 1) === 'T'
                    ? 'thermal'
                    : 'impact';
        } else {
            widthOffset =
                printer.widthOffset !== undefined ? Number(printer.widthOffset) : 0;
            printerType =
                printer.selectedPrinter.modelName.substring(0, 1) === 'T'
                    ? 'thermal'
                    : 'impact';
        }

        if (reroute) {
            printerType = reroutePrinterType;
        }

        const country = this.state.venue.country.code === 'US' ? 'US' : 'UK';

        let ln = printerType === 'impact' ? 42 + widthOffset : 46 + widthOffset; // 12dot, 7x9
        // const ln = (38 + widthOffset); // 15 dot
        // const ln = (35 + widthOffset); // 16 dot

        const dataObj = this.getDataObj(format, item, dateStr);

        // 47 chars
        let col1 = 30;
        let col2 = 5;
        let col3 = 12;
        let colh = 23;

        let line = '-'.repeat(ln);
        let dline = '='.repeat(ln);
        let aline = '*'.repeat(ln);
        let pad = ' '.repeat(6);
        let spc = null;

        let commandsArray = [];
        let okToSend = false;
        let products;

        const currencySymbolCommands = this.getStarCurrencySymbolCommands();
        const cs = this.getCommandSet(printerType);

        switch (format) {
            case 'station':
                const printerId = printer.id;
                const textDuplicate = this.translationSvc.translateStatic(
                    'text_duplicate'
                );

                products = item.products !== undefined ? item.products : false;

                // check if printerId is listed in any of the products
                let hasproducts = false;
                for (let i = 0; i < products.length; i++) {
                    if (products[i].printer_id === printerId) {
                        hasproducts = true;
                        break;
                    }
                }
                if (!hasproducts) {
                    return [];
                }

                if (reroute) {
                    if (item.stationprinted) {
                        if (item.stationprinted.indexOf(printerId) !== -1) {
                            // do not print if already printed
                            return [];
                        }
                    }
                }

                // set print as line item flag
                if (!!products) {
                    products.map((product: any) => {
                        const thisProduct = this.productsSvc.getById(product.product_id);
                        if (!!product.options) {
                            product.options.map((option: any) => {
                                option.print_as_line_item = 0;
                                const thisOption = thisProduct.options.find(
                                    (opt: any) => opt.id === option.option_id
                                );
                                if (!!thisOption) {
                                    option.print_as_line_item =
                                        thisOption.print_as_line_item !== undefined
                                            ? thisOption.print_as_line_item
                                            : 0;
                                }
                            });
                        }
                    });
                }

                if (printerType === 'impact') {
                    col1 = 21;
                    col2 = 21;

                    ln = 21 + widthOffset; // double wide
                    line = '-'.repeat(col1 + col2);
                    dline = '='.repeat(col1 + col2);
                    aline = '*'.repeat(col1 + col2);
                    pad = ' '.repeat(3);

                    if (products) {
                        commandsArray.push({ appendInternational: 'US' });
                        commandsArray.push({ appendEncoding: 'UTF-8' });
                        commandsArray.push({ appendBytes: cs.wide2x });

                        commandsArray.push({ appendAlignment: 'Center' });
                        commandsArray.push({ appendBytes: cs.height2x });
                        commandsArray.push({ appendBytes: cs.wide2x });
                        commandsArray.push({ appendEmphasis: 'SER.VI' + '\n\n' });
                        commandsArray.push({ appendBytes: cs.wideCancel });
                        commandsArray.push({ appendBytes: cs.heightCancel });

                        if (printCount > 1) {
                            commandsArray.push({ appendBytes: cs.height2x });
                            commandsArray.push({ appendBytes: cs.wide2x });
                            commandsArray.push({
                                appendEmphasis: '*** ' + this.textDuplicate + ' ***' + '\n'
                            });
                            commandsArray.push({ appendBytes: cs.wideCancel });
                            commandsArray.push({ appendBytes: cs.heightCancel });
                        }

                        if (mapToPrinter != null) {
                            commandsArray.push({
                                appendEmphasis: '*** ' + mapToPrinter.name + ' Copy ***' + '\n'
                            });
                        } else {
                            commandsArray.push({
                                appendEmphasis: '*** ' + printer.name + ' Copy ***' + '\n'
                            });
                        }
                        commandsArray.push({ appendAlignment: 'Left' });
                        commandsArray.push({ appendEmphasis: dline + '\n' });

                        if (mapToPrinter != null) {
                            commandsArray.push({
                                appendEmphasis: 'Server: ' + dataObj.waiterName + '\n'
                            });
                        } else {
                            commandsArray.push({
                                appendEmphasis: 'Server: ' + dataObj.waiterName + '\n'
                            });
                        }
                        commandsArray.push({ appendAlignment: 'Center' });

                        let orderTypeStrings = this.getSeatTokenStrings(item);
                        let orderTypeStr = orderTypeStrings.orderTypeStr
                            ? orderTypeStrings.orderTypeStr
                            : null;
                        let tableName = orderTypeStrings.tableName
                            ? orderTypeStrings.tableName
                            : null;
                        let printTypeStr = orderTypeStrings.printTypeStr
                            ? orderTypeStrings.printTypeStr
                            : 'Pick-Up';

                        commandsArray.push({ appendBytes: cs.height2x });
                        commandsArray.push({ appendBytes: cs.wide2x });
                        commandsArray.push({ appendBytes: cs.red });
                        commandsArray.push({
                            appendEmphasis: orderTypeStr.toUpperCase() + '\n'
                        });
                        commandsArray.push({ appendBytes: cs.redCancel });
                        commandsArray.push({ appendBytes: cs.heightCancel });
                        commandsArray.push({ appendBytes: cs.wideCancel });

                        commandsArray.push({ appendAlignment: 'Left' });
                        commandsArray.push({
                            appendEmphasis:
                                dataObj.dateStrShort.padEnd(col1, ' ') +
                                dataObj.timeStrShort.padStart(col2, ' ') +
                                '\n'
                        });
                        commandsArray.push({ appendEmphasis: dline + '\n' });

                        if (dataObj.seatToken === 'delivery') {
                            commandsArray.push({ appendEmphasis: dataObj.fullName + '\n' });
                            commandsArray.push({
                                append: dataObj.extraInfo.addrLine1.trim() + '\n'
                            });
                            const phoneNumber =
                                this.state.venue.phone != null && this.state.venue.phone !== ''
                                    ? parsePhoneNumber(
                                        this.state.venue.phone + '',
                                        this.state.venue.country.code as CountryCode
                                    )
                                    : null;
                            if (phoneNumber != null) {
                                commandsArray.push({
                                    append: 'Ph: ' + phoneNumber.formatNational() + '\n'
                                });
                            }
                        }

                        if (dataObj.extraInfo && dataObj.extraInfo.dayTimeStr != null) {
                            commandsArray.push({ append: printTypeStr + ' ' });
                            commandsArray.push({
                                appendEmphasis: dataObj.extraInfo.dayTimeStr + '\n'
                            });
                        }
                        if (
                            dataObj.seatToken === 'delivery' ||
                            (dataObj.extraInfo && dataObj.extraInfo.dayTimeStr != null)
                        ) {
                            commandsArray.push({ appendEmphasis: line + '\n' });
                        }

                        let printCategory = false;
                        let printCnt = 0;
                        if (this.catArr) {
                            this.catArr.forEach((category: any, index: number) => {
                                printCategory = false;
                                products.forEach((product: any) => {
                                    if (
                                        product.printer_id === printerId &&
                                        product.categoryId === category.id
                                    ) {
                                        printCategory = true;
                                    }
                                });

                                if (printCategory) {
                                    if (printCnt > 0) {
                                        commandsArray.push({ appendEmphasis: line + '\n' });
                                    }
                                    commandsArray.push({ appendBytes: cs.height2x });
                                    commandsArray.push({ appendBytes: cs.wide2x });
                                    commandsArray.push({ appendAlignment: 'Center' });
                                    commandsArray.push({
                                        appendEmphasis: category.label.toUpperCase()
                                    });
                                    commandsArray.push({ appendBytes: cs.heightCancel });
                                    commandsArray.push({ appendBytes: cs.wideCancel });
                                    commandsArray.push({ appendEmphasis: '\n' + line + '\n' });
                                    printCnt++;
                                }

                                products.forEach((product: any) => {
                                    if (
                                        product.printer_id === printerId &&
                                        product.categoryId === category.id
                                    ) {
                                        commandsArray.push({ appendBytes: cs.height2x });
                                        commandsArray.push({ appendBytes: cs.wide2x });
                                        commandsArray.push({ appendBytes: cs.red });
                                        commandsArray.push({ appendAlignment: 'Left' });
                                        commandsArray.push({
                                            appendEmphasis: Number(product.qty).toFixed(0) + ' '
                                        });
                                        commandsArray.push({ appendBytes: cs.redCancel });

                                        // var ln2 = (product.qty > 9) ? 17 : 18;
                                        commandsArray.push({
                                            appendEmphasis: product.product_name.toUpperCase() + '\n'
                                        });

                                        if (product.variant_name) {
                                            commandsArray.push({ appendBytes: cs.red });
                                            commandsArray.push({
                                                appendEmphasis: pad + product.variant_name + '\n'
                                            });
                                            commandsArray.push({ appendBytes: cs.redCancel });
                                        }

                                        if (product.options != null && product.options.length > 0) {
                                            product.options.forEach((option: any) => {
                                                if (
                                                    option.tags &&
                                                    option.tags.indexOf('print_group_name') !== -1 &&
                                                    option.option_name && option.items && option.items.length > 0
                                                ) {
                                                    commandsArray.push({
                                                        appendEmphasis: '  ' + this.productsSvc.formatOptionName(option.option_name) + '\n'
                                                    });
                                                }
                                                if (option.items != null && option.items.length > 0) {
                                                    option.items.forEach((oitem: any) => {
                                                        if (
                                                            option.print_as_line_item !== undefined &&
                                                            option.print_as_line_item === 1
                                                        ) {
                                                            commandsArray.push({ appendBytes: cs.height2x });
                                                            commandsArray.push({ appendBytes: cs.wide2x });
                                                            commandsArray.push({ appendBytes: cs.red });
                                                            commandsArray.push({
                                                                appendEmphasis:
                                                                    Number(product.qty).toFixed(0) + ' '
                                                            });
                                                            commandsArray.push({ appendBytes: cs.redCancel });
                                                            commandsArray.push({
                                                                appendEmphasis:
                                                                    oitem.item_name.toUpperCase() + '\n'
                                                            });
                                                        } else {
                                                            commandsArray.push({ appendBytes: cs.red });
                                                            commandsArray.push({
                                                                appendEmphasis: pad + oitem.item_name + '\n'
                                                            });
                                                            commandsArray.push({ appendBytes: cs.redCancel });
                                                        }
                                                    });
                                                }
                                            });
                                        }

                                        if (product.instructions) {
                                            commandsArray.push({ appendBytes: cs.red });
                                            commandsArray.push({
                                                appendEmphasis: pad + product.instructions + '\n'
                                            });
                                            commandsArray.push({ appendBytes: cs.redCancel });
                                        }

                                        // print option as line item after special instructions and any other options
                                        // if (product.options != null && product.options.length > 0) {
                                        //   product.options.forEach((option: any) => {
                                        //     if (option.items != null && option.items.length > 0) {
                                        //       option.items.forEach((oitem: any) => {
                                        //         if (option.print_as_line_item !== undefined && option.print_as_line_item === 1) {
                                        //           commandsArray.push({ appendBytes: cs.height2x });
                                        //           commandsArray.push({ appendBytes: cs.wide2x });
                                        //           commandsArray.push({ appendBytes: cs.red });
                                        //           commandsArray.push({
                                        //             appendEmphasis: Number(product.qty).toFixed(0) + ' ',
                                        //           });
                                        //           commandsArray.push({ appendBytes: cs.redCancel });
                                        //           commandsArray.push({
                                        //             appendEmphasis: oitem.item_name.toUpperCase() + '\n',
                                        //           });
                                        //         }
                                        //       });
                                        //     }
                                        //   });
                                        // }

                                        if (product.extra_info && product.extra_info.guestName) {
                                            commandsArray.push({
                                                append:
                                                    '  Guest Name - ' +
                                                    product.extra_info.guestName +
                                                    '\n'
                                            });
                                        }

                                        commandsArray.push({ appendBytes: cs.heightCancel });
                                        commandsArray.push({ appendBytes: cs.wideCancel });

                                        commandsArray.push({ append: '\n' });
                                        okToSend = true;
                                    }
                                });
                            });
                        } else {
                            products.forEach((product: any) => {
                                if (product.printer_id === printerId) {
                                    commandsArray.push({ appendBytes: cs.height2x });
                                    commandsArray.push({ appendBytes: cs.wide2x });
                                    commandsArray.push({ appendBytes: cs.red });
                                    commandsArray.push({
                                        appendEmphasis: Number(product.qty).toFixed(0) + ' '
                                    });
                                    commandsArray.push({ appendBytes: cs.redCancel });

                                    // var ln2 = (product.qty > 9) ? 17 : 18;
                                    commandsArray.push({
                                        appendEmphasis: product.product_name.toUpperCase() + '\n'
                                    });

                                    if (product.variant_name) {
                                        commandsArray.push({ appendBytes: cs.red });
                                        commandsArray.push({
                                            appendEmphasis: pad + product.variant_name + '\n'
                                        });
                                        commandsArray.push({ appendBytes: cs.redCancel });
                                    }

                                    if (product.options != null && product.options.length > 0) {
                                        product.options.forEach((option: any) => {
                                            if (
                                                option.tags &&
                                                option.tags.indexOf('print_group_name') !== -1 &&
                                                option.option_name && option.items && option.items.length > 0
                                            ) {
                                                commandsArray.push({
                                                    appendEmphasis: '  ' + this.productsSvc.formatOptionName(option.option_name) + '\n'
                                                });
                                            }
                                            if (option.items != null && option.items.length > 0) {
                                                option.items.forEach((oitem: any) => {
                                                    if (
                                                        option.print_as_line_item !== undefined &&
                                                        option.print_as_line_item === 1
                                                    ) {
                                                        commandsArray.push({ appendBytes: cs.height2x });
                                                        commandsArray.push({ appendBytes: cs.wide2x });
                                                        commandsArray.push({ appendBytes: cs.red });
                                                        commandsArray.push({
                                                            appendEmphasis:
                                                                Number(product.qty).toFixed(0) + ' '
                                                        });
                                                        commandsArray.push({ appendBytes: cs.redCancel });
                                                        commandsArray.push({
                                                            appendEmphasis:
                                                                oitem.item_name.toUpperCase() + '\n'
                                                        });
                                                    } else {
                                                        commandsArray.push({ appendBytes: cs.red });
                                                        commandsArray.push({
                                                            appendEmphasis: pad + oitem.item_name + '\n'
                                                        });
                                                        commandsArray.push({ appendBytes: cs.redCancel });
                                                    }
                                                });
                                            }
                                        });
                                    }

                                    if (product.instructions) {
                                        commandsArray.push({ appendBytes: cs.red });
                                        commandsArray.push({
                                            appendEmphasis: pad + product.instructions + '\n'
                                        });
                                        commandsArray.push({ appendBytes: cs.redCancel });
                                    }

                                    // print option as line item after special instructions and any other options
                                    // if (product.options != null && product.options.length > 0) {
                                    //   product.options.forEach((option: any) => {
                                    //     if (option.items != null && option.items.length > 0) {
                                    //       option.items.forEach((oitem: any) => {
                                    //         if (option.print_as_line_item !== undefined && option.print_as_line_item === 1) {
                                    //           commandsArray.push({ appendBytes: cs.height2x });
                                    //           commandsArray.push({ appendBytes: cs.wide2x });
                                    //           commandsArray.push({ appendBytes: cs.red });
                                    //           commandsArray.push({
                                    //             appendEmphasis: Number(product.qty).toFixed(0) + ' ',
                                    //           });
                                    //           commandsArray.push({ appendBytes: cs.redCancel });
                                    //           commandsArray.push({
                                    //             appendEmphasis: oitem.item_name.toUpperCase() + '\n',
                                    //           });
                                    //         }
                                    //       });
                                    //     }
                                    //   });
                                    // }

                                    if (product.extra_info && product.extra_info.guestName) {
                                        commandsArray.push({
                                            append:
                                                '  Guest Name - ' + product.extra_info.guestName + '\n'
                                        });
                                    }

                                    commandsArray.push({ appendBytes: cs.heightCancel });
                                    commandsArray.push({ appendBytes: cs.wideCancel });

                                    commandsArray.push({ append: '\n' });
                                    okToSend = true;
                                }
                            });
                        }

                        commandsArray.push({ appendEmphasis: line + '\n' });
                        commandsArray.push({ appendAlignment: 'Center' });
                        commandsArray.push({ appendBytes: cs.height2x });
                        commandsArray.push({ appendBytes: cs.wide2x });
                        commandsArray.push({
                            appendEmphasis:
                                'Order #:' +
                                dataObj.ticketCount.toFixed(0).padStart(3, '0') +
                                '\n'
                        });
                        commandsArray.push({ appendBytes: cs.heightCancel });
                        commandsArray.push({ appendBytes: cs.wideCancel });
                        // commandsArray.push({ appendEmphasis: line + '\n' });
                        commandsArray.push({
                            appendEmphasis: 'Order ID: ' + dataObj.orderId + '\n'
                        });

                        commandsArray.push({ appendEmphasis: line + '\n' });
                        commandsArray.push({ appendAlignment: 'Center' });
                        commandsArray.push({ appendBytes: cs.height2x });
                        commandsArray.push({ appendBytes: cs.wide2x });
                        commandsArray.push({ appendEmphasis: dataObj.fullName + '\n' });
                        commandsArray.push({ appendBytes: cs.red });
                        commandsArray.push({ appendEmphasis: dataObj.tableName + '\n' });
                        commandsArray.push({ appendBytes: cs.redCancel });
                        commandsArray.push({ appendBytes: cs.heightCancel });
                        commandsArray.push({ appendBytes: cs.wideCancel });

                        if (printCount > 1) {
                            commandsArray.push({ appendBytes: cs.height2x });
                            commandsArray.push({ appendBytes: cs.wide2x });
                            commandsArray.push({
                                appendEmphasis: '*** ' + this.textDuplicate + ' ***' + '\n'
                            });
                            commandsArray.push({ appendBytes: cs.wideCancel });
                            commandsArray.push({ appendBytes: cs.heightCancel });
                        }

                        commandsArray.push({ append: '\n' });
                        commandsArray.push({ appendBytes: cs.dot12 }); // reset back to default 12 dot
                        commandsArray.push({ appendCutPaper: 'PartialCutWithFeed' });
                    }
                } else {
                    ln = 47 + widthOffset;
                    line = '-'.repeat(ln);
                    dline = '='.repeat(ln);
                    aline = '*'.repeat(ln);
                    pad = ' '.repeat(6);

                    if (products) {
                        // commandsArray.push({ appendCodePage: 'CP858' });
                        commandsArray.push({ appendInternational: 'US' });
                        commandsArray.push({ appendEncoding: 'UTF-8' });
                        commandsArray.push({ appendBytes: cs.dot12 }); // 16 dot

                        // commandsArray.push({ appendBytes: cs.leftMargin }); // left margin
                        commandsArray.push({ appendAlignment: 'Center' });

                        commandsArray.push({ appendBytes: cs.height3x });
                        commandsArray.push({ appendBytes: cs.wide3x });
                        commandsArray.push({ appendEmphasis: 'SER.VI' + '\n\n' });
                        commandsArray.push({ appendBytes: cs.heightCancel });
                        commandsArray.push({ appendBytes: cs.wideCancel });

                        if (printCount > 1) {
                            commandsArray.push({ appendBytes: cs.height2x });
                            commandsArray.push({ appendBytes: cs.wide2x });
                            commandsArray.push({
                                appendEmphasis:
                                    '*** ' + textDuplicate.toUpperCase() + ' ***' + '\n'
                            });
                            commandsArray.push({ appendBytes: cs.wideCancel });
                            commandsArray.push({ appendBytes: cs.heightCancel });
                        }

                        if (mapToPrinter != null) {
                            commandsArray.push({
                                appendEmphasis:
                                    '*** ' + mapToPrinter.name.toUpperCase() + ' Copy ***' + '\n'
                            });
                        } else {
                            commandsArray.push({
                                appendEmphasis:
                                    '*** ' + printer.name.toUpperCase() + ' Copy ***' + '\n'
                            });
                        }
                        commandsArray.push({ appendAlignment: 'Left' });
                        commandsArray.push({ appendEmphasis: dline + '\n' });

                        if (mapToPrinter != null) {
                            commandsArray.push({
                                appendEmphasis: 'Server: ' + dataObj.waiterName + '\n'
                            });
                        } else {
                            commandsArray.push({
                                appendEmphasis: 'Server: ' + dataObj.waiterName + '\n'
                            });
                        }
                        commandsArray.push({ appendAlignment: 'Center' });

                        let orderTypeStrings = this.getSeatTokenStrings(item);
                        let orderTypeStr = orderTypeStrings.orderTypeStr
                            ? orderTypeStrings.orderTypeStr
                            : null;
                        let tableName = orderTypeStrings.tableName
                            ? orderTypeStrings.tableName
                            : null;
                        let printTypeStr = orderTypeStrings.printTypeStr
                            ? orderTypeStrings.printTypeStr
                            : 'Pick-Up';

                        commandsArray.push({ appendBytes: cs.height2x });
                        commandsArray.push({ appendBytes: cs.wide2x });
                        commandsArray.push({
                            appendEmphasis: orderTypeStr.toUpperCase() + '\n'
                        });
                        commandsArray.push({ appendBytes: cs.heightCancel });
                        commandsArray.push({ appendBytes: cs.wideCancel });

                        commandsArray.push({ appendAlignment: 'Left' });
                        commandsArray.push({
                            appendEmphasis:
                                dataObj.dateStrShort.padEnd(col1 + col2, ' ') +
                                dataObj.timeStrShort.padStart(col3, ' ') +
                                '\n'
                        });
                        commandsArray.push({ appendEmphasis: dline + '\n' });
                        // commandsArray.push({ appendEmphasis: dataObj.fullName + '\n' });

                        if (dataObj.seatToken === 'delivery') {
                            commandsArray.push({
                                append: dataObj.extraInfo.addrLine1.trim() + '\n'
                            });
                            const phoneNumber =
                                this.state.venue.phone != null && this.state.venue.phone !== ''
                                    ? parsePhoneNumber(
                                        this.state.venue.phone + '',
                                        this.state.venue.country.code as CountryCode
                                    )
                                    : null;
                            if (phoneNumber != null) {
                                commandsArray.push({
                                    append: 'Ph: ' + phoneNumber.formatNational() + '\n'
                                });
                            }
                        }

                        if (!!dataObj.extraInfo && dataObj.extraInfo.dayTimeStr != null) {
                            commandsArray.push({ append: printTypeStr + ' ' });
                            commandsArray.push({
                                appendEmphasis: dataObj.extraInfo.dayTimeStr + '\n'
                            });
                        }
                        // commandsArray.push({ appendEmphasis: line + '\n' });

                        let printCategory = false;
                        let printCnt = 0;
                        if (this.catArr) {
                            this.catArr.forEach((category: any, index: number) => {
                                printCategory = false;
                                products.forEach((product: any) => {
                                    if (
                                        product.printer_id === printerId &&
                                        product.categoryId === category.id
                                    ) {
                                        printCategory = true;
                                    }
                                });

                                if (printCategory) {
                                    if (printCnt > 0) {
                                        commandsArray.push({ appendEmphasis: line + '\n' });
                                    }
                                    commandsArray.push({ appendBytes: cs.height2x });
                                    commandsArray.push({ appendBytes: cs.wide2x });
                                    commandsArray.push({ appendAlignment: 'Center' });
                                    commandsArray.push({
                                        appendEmphasis: category.label.toUpperCase()
                                    });
                                    commandsArray.push({ appendBytes: cs.heightCancel });
                                    commandsArray.push({ appendBytes: cs.wideCancel });
                                    commandsArray.push({ appendEmphasis: '\n' + line + '\n' });
                                    printCnt++;
                                }

                                products.forEach((product: any) => {
                                    if (
                                        product.printer_id === printerId &&
                                        product.categoryId === category.id
                                    ) {
                                        commandsArray.push({ appendBytes: cs.height2x });
                                        commandsArray.push({ appendBytes: cs.wide2x });
                                        commandsArray.push({ appendAlignment: 'Left' });
                                        commandsArray.push({
                                            appendEmphasis:
                                                Number(product.qty).toFixed(0) +
                                                ' ' +
                                                product.product_name.toUpperCase() +
                                                '\n'
                                        });
                                        commandsArray.push({ appendBytes: cs.heightCancel });
                                        commandsArray.push({ appendBytes: cs.wideCancel });

                                        if (product.variant_name) {
                                            commandsArray.push({ appendBytes: cs.height2x });
                                            commandsArray.push({
                                                appendEmphasis: pad + product.variant_name + '\n'
                                            });
                                            commandsArray.push({ appendBytes: cs.heightCancel });
                                        }

                                        if (
                                            typeof product.options !== 'undefined' &&
                                            product.options.length > 0
                                        ) {
                                            product.options.forEach((option: any) => {
                                                if (
                                                    option.tags &&
                                                    option.tags.indexOf('print_group_name') !== -1 &&
                                                    option.option_name && option.items && option.items.length > 0
                                                ) {
                                                    commandsArray.push({ appendBytes: cs.height2x });
                                                    commandsArray.push({
                                                        appendEmphasis: '  ' + this.productsSvc.formatOptionName(option.option_name) + '\n'
                                                    });
                                                    commandsArray.push({ appendBytes: cs.heightCancel });
                                                }
                                                if (
                                                    typeof option.items !== 'undefined' &&
                                                    option.items.length > 0
                                                ) {
                                                    option.items.forEach((oitem: any) => {
                                                        if (
                                                            option.print_as_line_item !== undefined &&
                                                            option.print_as_line_item === 1
                                                        ) {
                                                            commandsArray.push({ appendBytes: cs.height2x });
                                                            commandsArray.push({ appendBytes: cs.wide2x });
                                                            commandsArray.push({
                                                                appendEmphasis:
                                                                    Number(product.qty).toFixed(0) +
                                                                    ' ' +
                                                                    oitem.item_name.toUpperCase() +
                                                                    '\n'
                                                            });
                                                            commandsArray.push({
                                                                appendBytes: cs.heightCancel
                                                            });
                                                            commandsArray.push({
                                                                appendBytes: cs.wideCancel
                                                            });
                                                        } else {
                                                            commandsArray.push({ appendBytes: cs.height2x });
                                                            commandsArray.push({
                                                                appendEmphasis: pad + oitem.item_name + '\n'
                                                            });
                                                            commandsArray.push({
                                                                appendBytes: cs.heightCancel
                                                            });
                                                        }
                                                    });
                                                }
                                            });
                                        }

                                        if (product.instructions) {
                                            commandsArray.push({ appendBytes: cs.height2x });
                                            commandsArray.push({
                                                appendEmphasis: pad + product.instructions + '\n'
                                            });
                                            commandsArray.push({ appendBytes: cs.heightCancel });
                                        }

                                        // print option as line item after special instructions and any other options
                                        // if (typeof product.options !== 'undefined' && product.options.length > 0) {
                                        //   product.options.forEach((option: any) => {
                                        //     if (typeof option.items !== 'undefined' && option.items.length > 0) {
                                        //       option.items.forEach((oitem: any) => {
                                        //         if (option.print_as_line_item !== undefined && option.print_as_line_item === 1) {
                                        //           commandsArray.push({ appendBytes: cs.height2x });
                                        //           commandsArray.push({ appendBytes: cs.wide2x });
                                        //           commandsArray.push({
                                        //             appendEmphasis: Number(product.qty).toFixed(0) + ' ' + oitem.item_name.toUpperCase() + '\n',
                                        //           });
                                        //           commandsArray.push({ appendBytes: cs.heightCancel });
                                        //           commandsArray.push({ appendBytes: cs.wideCancel });
                                        //         }
                                        //       });
                                        //     }
                                        //   });
                                        // }

                                        if (product.extra_info && product.extra_info.guestName) {
                                            commandsArray.push({ appendBytes: cs.height2x });
                                            commandsArray.push({
                                                appendEmphasis:
                                                    pad +
                                                    'Guest Name - ' +
                                                    product.extra_info.guestName +
                                                    '\n'
                                            });
                                            commandsArray.push({ appendBytes: cs.heightCancel });
                                        }
                                        commandsArray.push({ append: '\n' });

                                        okToSend = true;
                                    }
                                });
                            });
                        } else {
                            products.forEach((product: any) => {
                                if (product.printer_id === printerId) {
                                    commandsArray.push({ appendBytes: cs.height2x });
                                    commandsArray.push({ appendBytes: cs.wide2x });
                                    commandsArray.push({
                                        appendEmphasis:
                                            Number(product.qty).toFixed(0) +
                                            ' ' +
                                            product.product_name.toUpperCase() +
                                            '\n'
                                    });
                                    commandsArray.push({ appendBytes: cs.heightCancel });
                                    commandsArray.push({ appendBytes: cs.wideCancel });

                                    if (product.variant_name) {
                                        commandsArray.push({ appendBytes: cs.height2x });
                                        commandsArray.push({
                                            appendEmphasis: pad + product.variant_name + '\n'
                                        });
                                        commandsArray.push({ appendBytes: cs.heightCancel });
                                    }

                                    if (
                                        typeof product.options !== 'undefined' &&
                                        product.options.length > 0
                                    ) {
                                        product.options.forEach((option: any) => {
                                            if (
                                                option.tags &&
                                                option.tags.indexOf('print_group_name') !== -1 &&
                                                option.option_name && option.items && option.items.length > 0
                                            ) {
                                                commandsArray.push({ appendBytes: cs.height2x });
                                                commandsArray.push({
                                                    appendEmphasis: '  ' + this.productsSvc.formatOptionName(option.option_name) + '\n'
                                                });
                                                commandsArray.push({ appendBytes: cs.heightCancel });
                                            }
                                            if (
                                                typeof option.items !== 'undefined' &&
                                                option.items.length > 0
                                            ) {
                                                option.items.forEach((oitem: any) => {
                                                    if (
                                                        option.print_as_line_item !== undefined &&
                                                        option.print_as_line_item === 1
                                                    ) {
                                                        commandsArray.push({ appendBytes: cs.height2x });
                                                        commandsArray.push({ appendBytes: cs.wide2x });
                                                        commandsArray.push({
                                                            appendEmphasis:
                                                                Number(product.qty).toFixed(0) +
                                                                ' ' +
                                                                oitem.item_name.toUpperCase() +
                                                                '\n'
                                                        });
                                                        commandsArray.push({
                                                            appendBytes: cs.heightCancel
                                                        });
                                                        commandsArray.push({ appendBytes: cs.wideCancel });
                                                    } else {
                                                        commandsArray.push({ appendBytes: cs.height2x });
                                                        commandsArray.push({
                                                            appendEmphasis: pad + oitem.item_name + '\n'
                                                        });
                                                        commandsArray.push({
                                                            appendBytes: cs.heightCancel
                                                        });
                                                    }
                                                });
                                            }
                                        });
                                    }

                                    if (product.instructions) {
                                        commandsArray.push({ appendBytes: cs.height2x });
                                        commandsArray.push({
                                            appendEmphasis: pad + product.instructions + '\n'
                                        });
                                        commandsArray.push({ appendBytes: cs.heightCancel });
                                    }

                                    // print option as line item after special instructions and any other options
                                    // if (typeof product.options !== 'undefined' && product.options.length > 0) {
                                    //   product.options.forEach((option: any) => {
                                    //     if (typeof option.items !== 'undefined' && option.items.length > 0) {
                                    //       option.items.forEach((oitem: any) => {
                                    //         if (option.print_as_line_item !== undefined && option.print_as_line_item === 1) {
                                    //           commandsArray.push({ appendBytes: cs.height2x });
                                    //           commandsArray.push({ appendBytes: cs.wide2x });
                                    //           commandsArray.push({
                                    //             appendEmphasis: Number(product.qty).toFixed(0) + ' ' + oitem.item_name.toUpperCase() + '\n',
                                    //           });
                                    //           commandsArray.push({ appendBytes: cs.heightCancel });
                                    //           commandsArray.push({ appendBytes: cs.wideCancel });
                                    //         }
                                    //       });
                                    //     }
                                    //   });
                                    // }

                                    if (product.extra_info && product.extra_info.guestName) {
                                        commandsArray.push({ appendBytes: cs.height2x });
                                        commandsArray.push({
                                            appendEmphasis:
                                                pad +
                                                'Guest Name - ' +
                                                product.extra_info.guestName +
                                                '\n'
                                        });
                                        commandsArray.push({ appendBytes: cs.heightCancel });
                                    }
                                    commandsArray.push({ append: '\n' });

                                    okToSend = true;
                                }
                            });
                        }

                        commandsArray.push({ appendEmphasis: line + '\n' });
                        commandsArray.push({ appendAlignment: 'Center' });
                        commandsArray.push({ appendBytes: cs.height2x });
                        commandsArray.push({ appendBytes: cs.wide2x });
                        commandsArray.push({
                            appendEmphasis:
                                'Order #' +
                                dataObj.ticketCount.toFixed(0).padStart(3, '0') +
                                '\n'
                        });
                        commandsArray.push({ appendBytes: cs.heightCancel });
                        commandsArray.push({ appendBytes: cs.wideCancel });
                        // commandsArray.push({ appendEmphasis: line + '\n' });
                        commandsArray.push({
                            appendEmphasis: 'Order ID: ' + dataObj.orderId + '\n'
                        });
                        commandsArray.push({ appendBytes: cs.height2x });

                        commandsArray.push({ appendEmphasis: line + '\n' });
                        commandsArray.push({ appendAlignment: 'Center' });
                        commandsArray.push({ appendBytes: cs.height2x });
                        commandsArray.push({ appendBytes: cs.wide2x });
                        commandsArray.push({ appendEmphasis: dataObj.fullName + '\n' });
                        commandsArray.push({ appendBytes: cs.wideCancel });
                        commandsArray.push({ appendEmphasis: dataObj.tableName + '\n' });
                        commandsArray.push({ appendBytes: cs.heightCancel });
                        commandsArray.push({ appendBytes: cs.wideCancel });

                        if (dataObj.seatToken === 'delivery') {
                            commandsArray.push({ appendAlignment: 'Left' });
                            commandsArray.push({
                                append: dataObj.extraInfo.addrLine1.trim() + '\n'
                            });
                            const phoneNumber =
                                this.state.venue.phone != null && this.state.venue.phone !== ''
                                    ? parsePhoneNumber(
                                        this.state.venue.phone + '',
                                        this.state.venue.country.code as CountryCode
                                    )
                                    : null;
                            if (phoneNumber != null) {
                                commandsArray.push({
                                    append: 'Ph: ' + phoneNumber.formatNational() + '\n'
                                });
                            }
                            commandsArray.push({ appendAlignment: 'Center' });
                        }

                        if (!!dataObj.extraInfo && dataObj.extraInfo.dayTimeStr != null) {
                            commandsArray.push({ append: printTypeStr + ' ' });
                            commandsArray.push({
                                appendEmphasis: dataObj.extraInfo.dayTimeStr + '\n'
                            });
                        }

                        if (printCount > 1) {
                            commandsArray.push({ appendBytes: cs.height2x });
                            commandsArray.push({ appendBytes: cs.wide2x });
                            commandsArray.push({
                                appendEmphasis:
                                    '*** ' + textDuplicate.toUpperCase() + ' ***' + '\n'
                            });
                            commandsArray.push({ appendBytes: cs.wideCancel });
                            commandsArray.push({ appendBytes: cs.heightCancel });
                        }

                        commandsArray.push({ appendBytes: cs.dot12 }); // reset back to default 12 dot
                        commandsArray.push({ appendCutPaper: 'PartialCutWithFeed' });
                    }
                }

                okToSend = true;
                break;

            case 'request':
                spc = ' '.repeat(
                    ln - (dataObj.request.length + dataObj.tableId.length)
                );
                const requestLine = dataObj.request + spc + dataObj.tableId;

                commandsArray.push({ appendCodePage: 'CP858' });
                commandsArray.push({ appendInternational: 'UK' });
                commandsArray.push({ appendEncoding: 'UTF-8' });
                commandsArray.push({ appendBytes: cs.dot12 });
                commandsArray.push({ appendBytes: cs.leftMargin }); // left margin
                commandsArray.push({ appendAlignment: 'Center' });
                commandsArray.push({ append: '\n*** SERVER MESSAGE ***\n\n' });
                commandsArray.push({ appendAlignment: 'Left' });
                commandsArray.push({ append: dline + '\n' });
                commandsArray.push({ append: requestLine + '\n' });
                commandsArray.push({ append: line + '\n' });
                commandsArray.push({ appendAlignment: 'Center' });
                commandsArray.push({ appendEmphasis: dataObj.caption + '\n' });
                commandsArray.push({ append: dline + '\n' });
                commandsArray.push({ append: dataObj.dateStr + '\n\n' });
                commandsArray.push({ appendCutPaper: 'PartialCutWithFeed' });
                commandsArray.push({ appendBytes: cs.dot12 }); // reset back to default 12 dot

                okToSend = true;
                break;

            case 'sales-summary':
                ln = 47 + widthOffset;
                line = '-'.repeat(ln);
                dline = '='.repeat(ln);
                products = item.products ? item.products : {};

                if (products) {
                    commandsArray.push({ appendCodePage: 'CP858' });
                    commandsArray.push({ appendInternational: country });
                    commandsArray.push({ appendEncoding: 'UTF-8' });
                    commandsArray.push({ appendBytes: cs.dot12 }); // 16 dot
                    // commandsArray.push({ appendBytes: cs.leftMargin }); // left margin
                    commandsArray.push({ appendAlignment: 'Center' });
                    commandsArray.push({ appendBytes: cs.height2x });
                    commandsArray.push({ appendBytes: cs.wide2x });
                    commandsArray.push({ appendEmphasis: '\n' + 'Sales Summary' + '\n' });
                    commandsArray.push({ appendBytes: cs.wideCancel });
                    commandsArray.push({ appendBytes: cs.heightCancel });
                    commandsArray.push({ appendEmphasis: dataObj.reportDateStr + '\n' });
                    commandsArray.push({ appendAlignment: 'Left' });
                    commandsArray.push({ append: dline + '\n' });
                    commandsArray.push({ appendAlignment: 'Center' });
                    commandsArray.push({
                        appendEmphasis: 'SALES AND TAXES SUMMARY' + '\n'
                    });
                    commandsArray.push({ appendAlignment: 'Left' });
                    commandsArray.push({ append: line + '\n' });
                    // subtotal
                    const subTotalStr = this.formatNumber(Number(dataObj.subtotal));
                    commandsArray.push({
                        append:
                            'Total Net Sales'.padEnd(col1 + col2, ' ') +
                            this.formatNumber(Number(dataObj.subtotal)).padStart(col3, ' ') +
                            '\n'
                    });
                    // fees
                    const convFeeLabel = dataObj.feeLabel
                        ? dataObj.feeLabel
                        : 'Convenience Fees';
                    if (dataObj.feeTotal > 0) {
                        commandsArray.push({
                            append:
                                convFeeLabel.padEnd(col1 + col2, ' ') +
                                this.formatNumber(Number(dataObj.feeTotal)).padStart(
                                    col3,
                                    ' '
                                ) +
                                '\n'
                        });
                    }
                    // refunds
                    if (dataObj.totalRefunds > 0) {
                        commandsArray.push({
                            append:
                                'Refunds'.padEnd(col1 + col2, ' ') +
                                this.formatNumber(Number(dataObj.totalRefunds) * -1).padStart(
                                    col3,
                                    ' '
                                ) +
                                '\n'
                        });
                    }
                    // comp
                    if (dataObj.totalComp > 0) {
                        commandsArray.push({
                            append:
                                'Complimentary'.padEnd(col1 + col2, ' ') +
                                this.formatNumber(Number(dataObj.totalComp)).padStart(
                                    col3,
                                    ' '
                                ) +
                                '\n'
                        });
                    }
                    // tips
                    if (dataObj.tipTotal > 0) {
                        commandsArray.push({
                            append:
                                'Tips'.padEnd(col1 + col2, ' ') +
                                this.formatNumber(Number(dataObj.tipTotal)).padStart(
                                    col3,
                                    ' '
                                ) +
                                '\n'
                        });
                    }

                    // tax
                    dataObj.taxTotals.forEach((tax: any) => {
                        if (tax.taxTotal > 0) {
                            commandsArray.push({
                                append:
                                    (
                                        tax.taxRateLabel +
                                        ' (' +
                                        Number(tax.taxRate).toFixed(2) +
                                        '%)'
                                    ).padEnd(col1 + col2, ' ') +
                                    this.formatNumber(Number(tax.taxTotal)).padStart(col3, ' ') +
                                    '\n'
                            });
                        }
                    });
                    // tax included
                    dataObj.taxInclTotals.forEach((taxIncl: any) => {
                        if (taxIncl.totalTax > 0) {
                            commandsArray.push({
                                append:
                                    (
                                        taxIncl.taxRateLabel +
                                        ' (' +
                                        this.formatNumber(Number(taxIncl.totalTax)) +
                                        ' @ ' +
                                        Number(taxIncl.taxRate).toFixed(2) +
                                        '%)'
                                    ).padEnd(col1 + col2, ' ') +
                                    'included'.padStart(col3, ' ') +
                                    '\n'
                            });
                        }
                    });
                    // totals
                    commandsArray.push({ appendEmphasis: line + '\n' });
                    const feeLabel =
                        dataObj.feeLabel != null ? dataObj.feeLabel : 'Convenience Fee';
                    const grandTotal =
                        Number(dataObj.grandTotal) - Number(dataObj.totalRefunds);
                    if (dataObj.currencySymbolLocation === 'after') {
                        // total collected
                        commandsArray.push({
                            appendEmphasis: 'Total Collected'.padEnd(
                                col1 +
                                col2 +
                                (col3 - grandTotal.toFixed(2).length) -
                                dataObj.currencySymbol.length,
                                ' '
                            )
                        });
                        commandsArray.push({
                            appendEmphasis: this.formatNumber(grandTotal)
                        });
                        commandsArray = [...commandsArray, ...currencySymbolCommands];
                        commandsArray.push({ append: '\n' });
                        // // total net + fees
                        // if (Number(dataObj.feeTotal) > 0) {
                        //   commandsArray.push({
                        //     appendEmphasis: ('Total Net + ' + feeLabel).padEnd(
                        //       col1 + col2 + (col3 - Number(dataObj.netFeeTotal).toFixed(2).length) - 1,
                        //       ' '
                        //     ),
                        //   });
                        //   commandsArray = [...commandsArray, ...currencySymbolCommands];
                        //   commandsArray.push({ appendEmphasis: this.formatNumber(Number(dataObj.netFeeTotal)) });
                        //   commandsArray = [...commandsArray, ...currencySymbolCommands];
                        //   commandsArray.push({ append: '\n' });
                        // }
                        // total tax
                        if (Number(dataObj.taxRateTotalSum) > 0) {
                            commandsArray.push({
                                appendEmphasis: 'Total Tax'.padEnd(
                                    col1 +
                                    col2 +
                                    (col3 - Number(dataObj.taxRateTotalSum).toFixed(2).length) -
                                    dataObj.currencySymbol.length,
                                    ' '
                                )
                            });
                            commandsArray.push({
                                appendEmphasis: this.formatNumber(
                                    Number(dataObj.taxRateTotalSum)
                                )
                            });
                            commandsArray = [...commandsArray, ...currencySymbolCommands];
                            commandsArray.push({ append: '\n' });
                        }
                    } else {
                        // total collected
                        commandsArray.push({
                            appendEmphasis: 'Total Collected'.padEnd(
                                col1 +
                                col2 +
                                (col3 - grandTotal.toFixed(2).length) -
                                dataObj.currencySymbol.length,
                                ' '
                            )
                        });
                        commandsArray = [...commandsArray, ...currencySymbolCommands];
                        commandsArray.push({
                            appendEmphasis: this.formatNumber(grandTotal) + '\n'
                        });
                        // // total net + fees
                        // if (Number(dataObj.feeTotal) > 0) {
                        //   commandsArray.push({
                        //     appendEmphasis: ('Total Net + ' + feeLabel).padEnd(
                        //       col1 + col2 + (col3 - Number(dataObj.netFeeTotal).toFixed(2).length) - 1,
                        //       ' '
                        //     ),
                        //   });
                        //   commandsArray = [...commandsArray, ...currencySymbolCommands];
                        //   commandsArray.push({ appendEmphasis: this.formatNumber(Number(dataObj.netFeeTotal)) + '\n' });
                        // }
                        // total tax
                        if (Number(dataObj.taxRateTotalSum) > 0) {
                            commandsArray.push({
                                appendEmphasis: 'Total Tax'.padEnd(
                                    col1 +
                                    col2 +
                                    (col3 - Number(dataObj.taxRateTotalSum).toFixed(2).length) -
                                    dataObj.currencySymbol.length,
                                    ' '
                                )
                            });
                            commandsArray = [...commandsArray, ...currencySymbolCommands];
                            commandsArray.push({
                                appendEmphasis:
                                    this.formatNumber(Number(dataObj.taxRateTotalSum)) + '\n'
                            });
                        }
                    }

                    // paymethod summary
                    commandsArray.push({ append: '\n' });
                    commandsArray.push({ appendAlignment: 'Center' });
                    commandsArray.push({ appendEmphasis: 'SALES BY PAYMENT METHOD' + '\n' });
                    commandsArray.push({ appendAlignment: 'Left' });
                    commandsArray.push({ append: line + '\n' });
                    if (dataObj && dataObj.payMethodTotals && dataObj.payMethodTotals.bank > 0) {
                        commandsArray.push({
                            append: 'Bank Payment'.padEnd(col1 + col2, ' ') + this.formatNumber(dataObj.payMethodTotals.bank).padStart(col3, ' ') + '\n'
                        });
                    }
                    if (dataObj && dataObj.payMethodTotals && dataObj.payMethodTotals.cash > 0) {
                        commandsArray.push({
                            append: 'CASH'.padEnd(col1 + col2, ' ') + this.formatNumber(dataObj.payMethodTotals.cash).padStart(col3, ' ') + '\n'
                        });
                    }
                    if (dataObj && dataObj.payMethodTotals && dataObj.payMethodTotals.card > 0) {
                        commandsArray.push({
                            append: 'Credit Card'.padEnd(col1 + col2, ' ') + this.formatNumber(dataObj.payMethodTotals.card).padStart(col3, ' ') + '\n'
                        });
                    }
                    if (dataObj && dataObj.payMethodTotals && dataObj.payMethodTotals.tab > 0) {
                        commandsArray.push({
                            append: 'Tab'.padEnd(col1 + col2, ' ') + this.formatNumber(dataObj.payMethodTotals.tab).padStart(col3, ' ') + '\n'
                        });
                    }
                    if (dataObj && dataObj.payMethodTotals && dataObj.payMethodTotals.terminal > 0) {
                        commandsArray.push({
                            append: 'Terminal'.padEnd(col1 + col2, ' ') + this.formatNumber(dataObj.payMethodTotals.terminal).padStart(col3, ' ') + '\n'
                        });
                    }

                    // orders summary
                    commandsArray.push({ append: '\n' });
                    commandsArray.push({ appendAlignment: 'Center' });
                    commandsArray.push({ appendEmphasis: 'ORDERS SUMMARY' + '\n' });
                    commandsArray.push({ appendAlignment: 'Left' });
                    commandsArray.push({ append: line + '\n' });
                    commandsArray.push({
                        append:
                            'Total Orders'.padEnd(col1 + col2, ' ') +
                            Number(dataObj.totalOrders).toFixed(0).padStart(col3, ' ') +
                            '\n'
                    });
                    commandsArray.push({
                        append:
                            'Average Ticket'.padEnd(col1 + col2, ' ') +
                            this.formatNumber(
                                Number(dataObj.subtotal / dataObj.totalOrders)
                            ).padStart(col3, ' ') +
                            '\n'
                    });

                    // refunds
                    if (Number(dataObj.totalRefunds) > 0) {
                        commandsArray.push({ append: '\n' });
                        commandsArray.push({ appendAlignment: 'Center' });
                        commandsArray.push({ appendEmphasis: 'REFUNDS' + '\n' });
                        commandsArray.push({ appendAlignment: 'Left' });
                        commandsArray.push({
                            append:
                                'Order ID'.padEnd(col2, ' ') +
                                'Refund Date'.padStart(col1 - 10, ' ') +
                                'Amount'.padStart(col3 + 7, ' ') +
                                '\n'
                        });
                        commandsArray.push({ append: line + '\n' });

                        dataObj.refunds.forEach((refund: any) => {
                            commandsArray.push({
                                append:
                                    refund.id.toString().padEnd(col2, ' ') +
                                    refund.refunded.padStart(col1, ' ') +
                                    this.formatNumber(Number(refund.refund_amount)).padStart(
                                        col3 - 1,
                                        ' '
                                    ) +
                                    '\n'
                            });
                            commandsArray.push({
                                append:
                                    refund.refund_reason
                                        .replace('Ref:', ' ')
                                        .replace(/<[^>]*>?/gm, '') + '\n\n'
                            });
                        });
                        commandsArray.push({ append: line + '\n' });
                        commandsArray.push({
                            append:
                                'Total Refunds'.padEnd(col1 + col2, ' ') +
                                this.formatNumber(Number(dataObj.totalRefunds) * -1).padStart(
                                    col3,
                                    ' '
                                ) +
                                '\n'
                        });
                        commandsArray.push({ append: '\n' });
                    }

                    // discounts
                    if (Number(dataObj.discountTotal) > 0) {
                        commandsArray.push({ append: '\n' });
                        commandsArray.push({ appendAlignment: 'Center' });
                        commandsArray.push({ appendEmphasis: 'DISCOUNTS' + '\n' });
                        commandsArray.push({ appendAlignment: 'Left' });
                        commandsArray.push({
                            append:
                                'Discount Name'.padEnd(col1 + col2, ' ') +
                                'Amount'.padStart(col3, ' ') +
                                '\n'
                        });
                        commandsArray.push({ append: line + '\n' });
                        commandsArray.push({
                            append:
                                'First Visit Discount'.padEnd(col1 + col2, ' ') +
                                this.formatNumber(Number(dataObj.discountTotal)).padStart(
                                    col3,
                                    ' '
                                ) +
                                '\n'
                        });
                        commandsArray.push({ append: line + '\n' });
                        // total discounts
                        if (dataObj.currencySymbolLocation === 'after') {
                            commandsArray.push({
                                appendEmphasis: 'Total Discounts'.padEnd(
                                    col1 +
                                    col2 +
                                    (col3 -
                                        this.formatNumber(Number(dataObj.discountTotal)).length) -
                                    dataObj.currencySymbol.length,
                                    ' '
                                )
                            });
                            commandsArray.push({
                                appendEmphasis: this.formatNumber(Number(dataObj.discountTotal))
                            });
                            commandsArray = [...commandsArray, ...currencySymbolCommands];
                            commandsArray.push({ append: '\n' });
                        } else {
                            commandsArray.push({
                                appendEmphasis: 'Total Discounts'.padEnd(
                                    col1 +
                                    col2 +
                                    (col3 -
                                        this.formatNumber(Number(dataObj.discountTotal)).length) -
                                    dataObj.currencySymbol.length,
                                    ' '
                                )
                            });
                            commandsArray = [...commandsArray, ...currencySymbolCommands];
                            commandsArray.push({
                                appendEmphasis:
                                    this.formatNumber(Number(dataObj.discountTotal)) + '\n'
                            });
                        }
                    }

                    // sales by category
                    commandsArray.push({ append: '\n' });
                    commandsArray.push({ appendAlignment: 'Center' });
                    commandsArray.push({ appendEmphasis: 'SALES BY CATEGORY' + '\n' });
                    commandsArray.push({ appendAlignment: 'Left' });
                    commandsArray.push({
                        append:
                            'Category'.padEnd(col1, ' ') +
                            'Qty'.padStart(col2, ' ') +
                            'Category'.padStart(col3, ' ') +
                            '\n'
                    });
                    commandsArray.push({ append: line + '\n' });
                    dataObj.catalogGroups.forEach((group: any) => {
                        commandsArray.push({
                            append:
                                group.label.padEnd(col1, ' ') +
                                Number(this.getCatalogGroupQty(item, group))
                                    .toFixed(0)
                                    .padStart(col2, ' ') +
                                this.formatNumber(
                                    Number(this.getCatalogGroupPrice(item, group))
                                ).padStart(col3, ' ') +
                                '\n'
                        });
                    });
                    commandsArray.push({ appendEmphasis: line + '\n' });
                    if (dataObj.currencySymbolLocation === 'after') {
                        commandsArray.push({
                            appendEmphasis: 'Total Net Sales'.padEnd(col1, ' ')
                        });
                        commandsArray.push({
                            appendEmphasis: Number(dataObj.totalItems)
                                .toFixed(0)
                                .padStart(col2, ' ')
                        });
                        commandsArray.push({
                            appendEmphasis: ' '.repeat(
                                col3 -
                                Number(dataObj.subtotal).toFixed(2).length -
                                dataObj.currencySymbol.length
                            )
                        });
                        commandsArray.push({
                            appendEmphasis: this.formatNumber(Number(dataObj.subtotal))
                        });
                        commandsArray = [...commandsArray, ...currencySymbolCommands];
                        commandsArray.push({ append: '\n' });
                    } else {
                        commandsArray.push({
                            appendEmphasis: 'Total Net Sales'.padEnd(col1, ' ')
                        });
                        commandsArray.push({
                            appendEmphasis: Number(dataObj.totalItems)
                                .toFixed(0)
                                .padStart(col2, ' ')
                        });
                        commandsArray.push({
                            appendEmphasis: ' '.repeat(
                                col3 -
                                Number(dataObj.subtotal).toFixed(2).length -
                                dataObj.currencySymbol.length
                            )
                        });
                        commandsArray = [...commandsArray, ...currencySymbolCommands];
                        commandsArray.push({
                            appendEmphasis: this.formatNumber(Number(dataObj.subtotal)) + '\n'
                        });
                    }
                    // sales by item
                    commandsArray.push({ append: '\n' });
                    commandsArray.push({ appendAlignment: 'Center' });
                    commandsArray.push({ appendEmphasis: 'SALES BY ITEM' + '\n' });
                    commandsArray.push({ appendAlignment: 'Left' });
                    commandsArray.push({
                        append:
                            'Name'.padEnd(col1, ' ') +
                            'Qty'.padStart(col2, ' ') +
                            'Category'.padStart(col3, ' ') +
                            '\n'
                    });
                    commandsArray.push({ append: line + '\n' });
                    dataObj.productIdObj.forEach((productId: any) => {
                        commandsArray.push({
                            append:
                                this.getItemName(item, productId)
                                    .substring(0, col1)
                                    .padEnd(col1, ' ') +
                                Number(this.sumItemQty(item, productId))
                                    .toFixed(0)
                                    .padStart(col2, ' ') +
                                this.formatNumber(
                                    Number(this.sumItemPrice(item, productId))
                                ).padStart(col3, ' ') +
                                '\n'
                        });
                    });
                    commandsArray.push({ append: line + '\n' });
                    if (dataObj.currencySymbolLocation === 'after') {
                        commandsArray.push({
                            appendEmphasis: 'Total Net Sales'.padEnd(col1, ' ')
                        });
                        commandsArray.push({
                            appendEmphasis: Number(dataObj.totalItems)
                                .toFixed(0)
                                .padStart(col2, ' ')
                        });
                        commandsArray.push({
                            appendEmphasis: ' '.repeat(
                                col3 -
                                Number(dataObj.subtotal).toFixed(2).length -
                                dataObj.currencySymbol.length
                            )
                        });
                        commandsArray.push({
                            appendEmphasis: this.formatNumber(Number(dataObj.subtotal))
                        });
                        commandsArray = [...commandsArray, ...currencySymbolCommands];
                        commandsArray.push({ append: '\n' });
                    } else {
                        commandsArray.push({
                            appendEmphasis: 'Total Net Sales'.padEnd(col1, ' ')
                        });
                        commandsArray.push({
                            appendEmphasis: Number(dataObj.totalItems)
                                .toFixed(0)
                                .padStart(col2, ' ')
                        });
                        commandsArray.push({
                            appendEmphasis: ' '.repeat(
                                col3 -
                                Number(dataObj.subtotal).toFixed(2).length -
                                dataObj.currencySymbol.length
                            )
                        });
                        commandsArray = [...commandsArray, ...currencySymbolCommands];
                        commandsArray.push({
                            appendEmphasis: this.formatNumber(Number(dataObj.subtotal)) + '\n'
                        });
                    }

                    commandsArray.push({ appendAlignment: 'Left' });
                    commandsArray.push({ append: dline + '\n' });
                    commandsArray.push({ appendAlignment: 'Center' });
                    commandsArray.push({ append: dataObj.dateStr + '\n\n' });
                    commandsArray.push({ appendAlignment: 'Left' });
                    commandsArray.push({ appendCutPaper: 'PartialCutWithFeed' });
                    commandsArray.push({ appendBytes: cs.dot12 }); // reset back to default 12 dot
                }

                okToSend = true;
                break;

            case 'shift-report':
                ln = 47 + widthOffset;
                line = '-'.repeat(ln);
                dline = '='.repeat(ln);

                commandsArray.push({ appendCodePage: 'CP858' });
                commandsArray.push({ appendInternational: country });
                commandsArray.push({ appendEncoding: 'UTF-8' });
                commandsArray.push({ appendBytes: cs.dot12 }); // 16 dot
                if (dataObj.printLogo && printerType === 'thermal') {
                    commandsArray.push({
                        appendLogo: 1, // Logo number configured using Star Print utility
                        logoSize: 'Normal'
                    });
                    commandsArray.push({ appendBytes: cs.leftMargin }); // left margin
                } else {
                    commandsArray.push({ appendBytes: cs.height2x }); // enable double high
                    commandsArray.push({ appendAlignment: 'Center' });
                    commandsArray.push({
                        appendEmphasis: '\n' + dataObj.venueName + '\n'
                    });
                    commandsArray.push({ appendBytes: cs.heightCancel }); // cancel double high
                    if (
                        dataObj.venueAddrLine1 != null &&
                        dataObj.venueAddrLine1.length > 0
                    ) {
                        commandsArray.push({ append: dataObj.venueAddrLine1 + '\n' });
                    }
                    if (dataObj.venueAddrLine2.trim() != '') {
                        commandsArray.push({
                            append: '2:[' + dataObj.venueAddrLine2 + ']\n'
                        });
                    }
                    if (
                        dataObj.venueAddrLine3 != null &&
                        dataObj.venueAddrLine3.length > 0
                    ) {
                        commandsArray.push({ append: dataObj.venueAddrLine3 + '\n' });
                    }
                    if (
                        dataObj.venueAddrLine4 != null &&
                        dataObj.venueAddrLine4.length > 0
                    ) {
                        commandsArray.push({ append: dataObj.venueAddrLine4 + '\n' });
                    }
                }
                commandsArray.push({ append: '\n' });
                commandsArray.push({ appendAlignment: 'Center' });
                commandsArray.push({ append: dline + '\n' });
                commandsArray.push({ appendEmphasis: '*** SHIFT REPORT ***' + '\n' });
                commandsArray.push({ appendEmphasis: dataObj.shiftName + '\n' });
                commandsArray.push({ appendAlignment: 'Left' });
                commandsArray.push({ append: line + '\n' });
                commandsArray.push({ append: 'Station: ' });
                commandsArray.push({ appendEmphasis: dataObj.waiterName + '\n' });
                commandsArray.push({ append: 'Start: ' });
                commandsArray.push({ appendEmphasis: dataObj.reportDateStr + '\n' });
                commandsArray.push({ append: line + '\n' });

                commandsArray.push({
                    appendEmphasis:
                        'Total Orders:'.padEnd(col1 + col2, ' ') +
                        parseInt(dataObj.totalOrders).toFixed(0).padStart(col3, ' ') +
                        '\n\n'
                });

                if (!!dataObj.taxInclTotals) {
                    dataObj.taxInclTotals.forEach((taxIncl: any) => {
                        if (Number(taxIncl.totalTax.replace(/\D/g, '')) > 0) {
                            const len =
                                col1 +
                                col2 +
                                col3 -
                                (
                                    taxIncl.taxRateLabel +
                                    '(' +
                                    taxIncl.totalTax +
                                    ' @ ' +
                                    taxIncl.taxRate +
                                    '%)'
                                ).length;
                            commandsArray.push({
                                append:
                                    taxIncl.taxRateLabel +
                                    '(' +
                                    taxIncl.totalTax +
                                    ' @ ' +
                                    taxIncl.taxRate +
                                    '%)' +
                                    'included'.padStart(len, ' ') +
                                    '\n'
                            });
                        }
                    });
                }

                commandsArray.push({
                    append:
                        'Tax:'.padEnd(col1 + col2, ' ') +
                        dataObj.totalTax.padStart(col3, ' ') +
                        '\n'
                });
                if (Number(dataObj.totalFees) > 0) {
                    commandsArray.push({
                        append:
                            'Fees:'.padEnd(col1 + col2, ' ') +
                            dataObj.totalFees.padStart(col3, ' ') +
                            '\n'
                    });
                }
                commandsArray.push({
                    append:
                        'Tips:'.padEnd(col1 + col2, ' ') +
                        dataObj.totalTips.padStart(col3, ' ') +
                        '\n'
                });
                commandsArray.push({
                    append:
                        'Discounts:'.padEnd(col1 + col2, ' ') +
                        dataObj.totalDiscount.padStart(col3, ' ') +
                        '\n'
                });

                commandsArray.push({
                    append: '============'.padStart(col1 + col2 + col3, ' ') + '\n'
                });
                if (dataObj.currencySymbolLocation === 'after') {
                    commandsArray.push({
                        appendEmphasis: 'TOTAL:'.padEnd(
                            col1 + col2 + (col3 - dataObj.total.length) - 1,
                            ' '
                        )
                    });
                    commandsArray.push({ appendEmphasis: dataObj.totalSales });
                    commandsArray = [...commandsArray, ...currencySymbolCommands];
                    commandsArray.push({ append: '\n\n' });
                } else {
                    commandsArray.push({
                        appendEmphasis: 'TOTAL:'.padEnd(
                            col1 + col2 + (col3 - dataObj.totalSales.length) - 1,
                            ' '
                        )
                    });
                    commandsArray = [...commandsArray, ...currencySymbolCommands];
                    commandsArray.push({ appendEmphasis: dataObj.totalSales + '\n' });
                }

                if (dataObj.mode === 'detail') {
                    commandsArray.push({ appendAlignment: 'Center' });
                    commandsArray.push({ append: dline + '\n' });
                    commandsArray.push({
                        appendEmphasis: '*** TRANSACTION DETAILS ***' + '\n'
                    });
                    commandsArray.push({ append: line + '\n' });
                    commandsArray.push({ appendAlignment: 'Left' });

                    if (!!dataObj.orders) {
                        commandsArray.push({
                            appendEmphasis:
                                'OrderID'.padEnd(col3) +
                                'Table'.padEnd(col3) +
                                'Time'.padEnd(col3) +
                                'Amount'.padStart(col3 - 1) +
                                '\n'
                        });
                        let seatObj: any = {};
                        let seatToken: string = '';
                        let created: any;
                        dataObj.orders.map((item: any) => {
                            try {
                                seatObj = JSON.parse(item.seat);
                                seatToken = !!seatObj ? seatObj.token : '';
                            } catch (e) { }

                            created = new Date(item.created);

                            commandsArray.push({ append: item.id.toFixed(0).padEnd(col3) });
                            commandsArray.push({ append: seatToken.padEnd(col3) });
                            commandsArray.push({
                                append: this.datePipe
                                    .transform(created, 'HH:mm:ss')
                                    .padEnd(col3)
                            });
                            commandsArray.push({
                                append: item.total.padStart(col3 - 1) + '\n'
                            });
                        });
                    }
                    commandsArray.push({ appendAlignment: 'Center' });
                    commandsArray.push({ append: dline + '\n' });
                    commandsArray.push({ appendEmphasis: '*** END DETAILS ***' + '\n' });
                    commandsArray.push({ appendAlignment: 'Left' });
                }
                commandsArray.push({ appendAlignment: 'Left' });
                commandsArray.push({ append: dline + '\n' });
                commandsArray.push({ appendAlignment: 'Center' });
                commandsArray.push({ append: dataObj.dateStr + '\n\n' });
                commandsArray.push({ appendAlignment: 'Left' });
                commandsArray.push({ appendCutPaper: 'PartialCutWithFeed' });
                commandsArray.push({ appendBytes: cs.dot12 }); // reset back to default 12 dot

                okToSend = true;
                break;

            case 'receipt':
            default:
                line = '-'.repeat(47);
                dline = '='.repeat(47);
                products = item.products ? item.products : {};
                let variantStr = '';
                let optionsStr = '';
                let optionsPrice = 0;
                let productName = '';
                let productPrice = 0;
                let productTotal = 0;

                let orderTypeStrings = this.getSeatTokenStrings(item);
                let printTypeStr = orderTypeStrings.printTypeStr
                  ? orderTypeStrings.printTypeStr
                  : 'Pick-Up';

                commandsArray.push({ appendCodePage: 'CP858' });
                commandsArray.push({ appendInternational: 'UK' });
                commandsArray.push({ appendEncoding: 'UTF-8' });

                commandsArray.push({ appendBytes: cs.dot12 });
                if (dataObj.printLogo && printerType === 'thermal') {
                    commandsArray.push({
                        appendLogo: 1, // Logo number configured using Star Print utility
                        logoSize: 'Normal'
                    });
                    commandsArray.push({ appendBytes: cs.leftMargin }); // left margin
                } else {
                    commandsArray.push({ appendBytes: cs.height2x }); // enable double high
                    commandsArray.push({ appendAlignment: 'Center' });
                    commandsArray.push({
                        appendEmphasis: '\n' + dataObj.venueName + '\n'
                    });
                    commandsArray.push({ appendBytes: cs.heightCancel }); // cancel double high
                }

                if (
                    dataObj.venueAddrLine1 != null &&
                    dataObj.venueAddrLine1.trim().length > 0
                ) {
                    commandsArray.push({ append: dataObj.venueAddrLine1 + '\n' });
                }
                if (
                    dataObj.venueAddrLine2 != null &&
                    dataObj.venueAddrLine2.trim().length > 0
                ) {
                    commandsArray.push({ append: dataObj.venueAddrLine2 + '\n' });
                }
                if (
                    dataObj.venueAddrLine3 != null &&
                    dataObj.venueAddrLine3.trim().length > 0
                ) {
                    commandsArray.push({ append: dataObj.venueAddrLine3 + '\n' });
                }
                if (
                    dataObj.venueAddrLine4 != null &&
                    dataObj.venueAddrLine4.trim().length > 0
                ) {
                    commandsArray.push({ append: dataObj.venueAddrLine4 + '\n\n' });
                }

                commandsArray.push({ appendAlignment: 'Left' });
                commandsArray.push({ append: dline + '\n' });
                commandsArray.push({
                    append:
                        ('Server: ' + dataObj.waiterName).padEnd(colh + 1, ' ') +
                        ('Order: ' + dataObj.orderId).padStart(colh, ' ') +
                        '\n'
                });
                commandsArray.push({ append: line + '\n' });
                commandsArray.push({
                    append:
                        (dataObj.name).padEnd(colh + 1, ' ') +
                        dataObj.tableId.padStart(colh, ' ') +
                        '\n'
                });

                if (dataObj.extraInfo && dataObj.serviceMode === 'delivery') {
                    const fullAddress = dataObj.extraInfo.address || '';
                    const addressComponents = fullAddress.split(', ');
                    addressComponents.pop();
                    const [street, city, stateZip] = addressComponents;
                    commandsArray.push({ append: street.trim() + '\n' });
                    commandsArray.push({ append: city.trim() + ', ' + stateZip + '\n' });
                    const phoneNumber =
                        this.state.venue.phone != null && this.state.venue.phone !== ''
                            ? parsePhoneNumber(
                                this.state.venue.phone + '',
                                this.state.venue.country.code as CountryCode
                            )
                            : null;
                    if (phoneNumber != null) {
                        commandsArray.push({
                            append: 'Ph: ' + phoneNumber.formatNational() + '\n'
                        });
                    }
                }

                if (
                    dataObj.seatToken === 'curbside' &&
                    dataObj.extraInfo.address != null
                ) {
                    commandsArray.push({
                        append: 'Vehicle: ' + dataObj.extraInfo.address.trim() + '\n'
                    });
                    const phoneNumber =
                        this.state.venue.phone != null && this.state.venue.phone !== ''
                            ? parsePhoneNumber(
                                this.state.venue.phone + '',
                                this.state.venue.country.code as CountryCode
                            )
                            : null;
                    if (phoneNumber != null) {
                        commandsArray.push({
                            append: 'Ph: ' + phoneNumber.formatNational() + '\n'
                        });
                    }
                }

                if (!!dataObj.extraInfo && dataObj.extraInfo.dayTimeStr != null) {
                    commandsArray.push({ append: printTypeStr + ' ' });
                    commandsArray.push({
                        appendEmphasis: dataObj.extraInfo.dayTimeStr + '\n'
                    });
                }

                commandsArray.push({ append: dline + '\n' });
                commandsArray.push({ appendAlignment: 'Center' });
                commandsArray.push({ append: '>> ORDER SETTLED <<\n' });
                commandsArray.push({ appendAlignment: 'Left' });

                products.forEach((product: any) => {
                    if (product.variant_name) {
                        variantStr = product.variant_name + ' ';
                    } else {
                        variantStr = '';
                    }

                    optionsStr = '';
                    if (
                        typeof product.options !== 'undefined' &&
                        product.options.length > 0
                    ) {
                        optionsPrice = 0;
                        product.options.forEach((option: any) => {
                            if (
                                option.tags &&
                                option.tags.indexOf('print_group_name') !== -1 &&
                                option.option_name && option.items && option.items.length > 0
                            ) {
                                optionsStr += '  ' + this.productsSvc.formatOptionName(option.option_name) + '\n';
                            }
                            if (
                                typeof option.items !== 'undefined' &&
                                option.items.length > 0
                            ) {
                                option.items.forEach((oitem: any) => {
                                    optionsPrice = optionsPrice + Number(oitem.item_price);
                                    // optionsStr += '  - ' + oitem.item_name + '\n';
                                    optionsStr +=
                                        '  - ' +
                                        oitem.item_name +
                                        ' +' +
                                        Number(oitem.item_price).toFixed(2) +
                                        '\n';
                                });
                            }
                        });
                    }

                    productPrice = (Number(product.price) + optionsPrice) * product.qty;
                    productTotal = Number(productTotal) + Number(productPrice);

                    productName = product.product_name.substring(0, 20);
                    commandsArray.push({
                        appendEmphasis:
                            (product.qty + ' ' + variantStr + productName).padEnd(
                                col1 + col2,
                                ' '
                            ) +
                            productPrice.toFixed(2).padStart(col3, ' ') +
                            '\n'
                    });

                    commandsArray.push({ append: optionsStr });
                    if (product.instructions) {
                        commandsArray.push({
                            append: '  - ' + product.instructions + '\n'
                        });
                    }
                    if (product.extra_info && product.extra_info.guestName) {
                        commandsArray.push({
                            append: '  - Guest Name - ' + product.extra_info.guestName + '\n'
                        });
                    }
                });

                commandsArray.push({ append: '\n' });
                if (productTotal > 0) {
                    commandsArray.push({
                        append:
                            'SUB TOTAL:'.padEnd(col1 + col2, ' ') +
                            this.formatNumber(Number(productTotal)).padStart(col3, ' ') +
                            '\n'
                    });
                }
                if (dataObj.tip > 0) {
                    commandsArray.push({
                        append:
                            'TIP:'.padEnd(col1 + col2, ' ') +
                            this.formatNumber(Number(dataObj.tip)).padStart(col3, ' ') +
                            '\n'
                    });
                }
                if (dataObj.discount > 0) {
                    commandsArray.push({
                        append:
                            'DISCOUNT:'.padEnd(col1 + col2, ' ') +
                            ('-' + this.formatNumber(Number(dataObj.discount))).padStart(
                                col3,
                                ' '
                            ) +
                            '\n'
                    });
                }
                if (dataObj.convenienceFee > 0) {
                    const feeLabel =
                        dataObj.convenienceFeeLabel != null
                            ? dataObj.convenienceFeeLabel
                            : 'Convenience Fee';
                    commandsArray.push({
                        append:
                            (feeLabel.toUpperCase() + ':').padEnd(col1 + col2, ' ') +
                            this.formatNumber(Number(dataObj.convenienceFee)).padStart(col3, ' ') +
                            '\n'
                    });
                }
                if (dataObj.extraFee > 0) {
                    const extraFeeLabel =
                        dataObj.extraFeeLabel != null ? dataObj.extraFeeLabel : "Add'l Fee";
                    commandsArray.push({
                        append:
                            (extraFeeLabel.toUpperCase() + ':').padEnd(col1 + col2, ' ') +
                            this.formatNumber(Number(dataObj.extraFee)).padStart(col3, ' ') +
                            '\n'
                    });
                }
                if (dataObj.tax > 0) {
                    commandsArray.push({
                        append:
                            (dataObj.taxLabel.toUpperCase() + ':').padEnd(col1 + col2, ' ') +
                            this.formatNumber(Number(dataObj.tax)).padStart(col3, ' ') +
                            '\n'
                    });
                }
                if (dataObj.compAmount > 0) {
                    commandsArray.push({
                        append:
                            'COMPLIMENTARY:'.padEnd(col1 + col2, ' ') +
                            this.formatNumber(Number(dataObj.compAmount * -1)).padStart(
                                col3,
                                ' '
                            ) +
                            '\n'
                    });
                }

                commandsArray.push({
                    append: '============'.padStart(col1 + col2 + col3, ' ') + '\n'
                });
                if (dataObj.currencySymbolLocation === 'after') {
                    commandsArray.push({
                        appendEmphasis: 'TOTAL:'.padEnd(
                            col1 +
                            col2 +
                            (col3 - Number(dataObj.total).toFixed(2).length) -
                            dataObj.currencySymbol.length,
                            ' '
                        )
                    });
                    commandsArray.push({
                        appendEmphasis: this.formatNumber(
                            Number(dataObj.total - dataObj.compAmount)
                        )
                    });
                    commandsArray = [...commandsArray, ...currencySymbolCommands];
                    commandsArray.push({ append: '\n\n' });
                } else {
                    commandsArray.push({
                        appendEmphasis: 'TOTAL:'.padEnd(
                            col1 +
                            col2 +
                            (col3 - Number(dataObj.total).toFixed(2).length) -
                            1,
                            ' '
                        )
                    });
                    commandsArray = [...commandsArray, ...currencySymbolCommands];
                    commandsArray.push({
                        appendEmphasis:
                            this.formatNumber(Number(dataObj.total - dataObj.compAmount)) +
                            '\n\n'
                    });
                }

                if (item.order.method.type === 'redirect') {
                    if (item.order.method.code === 'Paypal') {
                        commandsArray.push({
                            append:
                                'PayPal TEND:'.padEnd(col1 + col2, ' ') +
                                this.formatNumber(Number(dataObj.total)).padStart(col3, ' ') +
                                '\n'
                        });
                    }
                    if (item.order.method.code === 'Ccvideal') {
                        commandsArray.push({
                            append:
                                'iDEAL Bank TEND:'.padEnd(col1 + col2, ' ') +
                                this.formatNumber(Number(dataObj.total)).padStart(col3, ' ') +
                                '\n'
                        });
                    }
                } else {
                    if (
                        item.order.method.code === 'Tabpayments' ||
                        item.order.method.code === 'Cashpayments'
                    ) {
                        const tendStr = item.paid ? 'Order PAID:' : 'Open Order:';
                        commandsArray.push({
                            append:
                                tendStr.padEnd(col1 + col2, ' ') +
                                this.formatNumber(
                                    Number(dataObj.total - dataObj.compAmount)
                                ).padStart(col3, ' ') +
                                '\n'
                        });
                    } else {
                        commandsArray.push({
                            append:
                                (dataObj.cardType + ' ' + dataObj.lastFour + ' TEND:').padEnd(
                                    col1 + col2,
                                    ' '
                                ) +
                                this.formatNumber(
                                    Number(dataObj.total - dataObj.compAmount)
                                ).padStart(col3, ' ') +
                                '\n'
                        });
                    }
                }

                commandsArray.push({
                    append: '============'.padStart(col1 + col2 + col3, ' ') + '\n'
                });
                commandsArray.push({
                    append:
                        'CHANGE:'.padEnd(col1 + col2, ' ') +
                        this.formatNumber(0.0).padStart(col3, ' ') +
                        '\n\n'
                });

                if (dataObj.taxInclTotal > 0) {
                    const taxLabel =
                        dataObj.taxInclLabel != null ? dataObj.taxInclLabel : 'VAT';
                    if (dataObj.currencySymbolLocation === 'after') {
                        commandsArray.push({
                            append: (taxLabel.toUpperCase() + ' (included):').padEnd(
                                col1 +
                                col2 +
                                (col3 - Number(dataObj.taxInclTotal).toFixed(2).length) -
                                1,
                                ' '
                            )
                        });
                        commandsArray = [...commandsArray, ...currencySymbolCommands];
                        commandsArray.push({
                            append: this.formatNumber(Number(dataObj.taxInclTotal))
                        });
                        commandsArray = [...commandsArray, ...currencySymbolCommands];
                        commandsArray.push({ append: '\n' });
                    } else {
                        commandsArray.push({
                            append: (taxLabel.toUpperCase() + ' (included):').padEnd(
                                col1 +
                                col2 +
                                (col3 - Number(dataObj.taxInclTotal).toFixed(2).length) -
                                1,
                                ' '
                            )
                        });
                        commandsArray = [...commandsArray, ...currencySymbolCommands];
                        commandsArray.push({
                            append: this.formatNumber(Number(dataObj.taxInclTotal)) + '\n'
                        });
                    }
                }

                if (dataObj.taxInclTotals != null && country !== 'US') {
                    const textVat = this.translationSvc.translateStatic('text_vat');
                    const textVatExcluded = this.translationSvc.translateStatic(
                        'text_excluded_vat'
                    );
                    const textOver = this.translationSvc.translateStatic('text_over');

                    const grandTotalBeforeTax = this.roundValue(
                        dataObj.taxInclTotals.reduce((oTotal: number, opt: any) => {
                            return oTotal + Number(opt.totalBeforeTax);
                        }, 0)
                    );

                    commandsArray.push({ append: textVat + '\n' });
                    commandsArray.push({
                        append:
                            textVatExcluded.padEnd(col1 + col2, ' ') +
                            this.formatNumber(Number(grandTotalBeforeTax)).padStart(
                                col3,
                                ' '
                            ) +
                            '\n'
                    });

                    dataObj.taxInclTotals.forEach((taxIncl: any) => {
                        if (Number(taxIncl.totalTax) > 0) {
                            commandsArray.push({
                                append:
                                    (
                                        Number(taxIncl.taxRate).toString() +
                                        '% ' +
                                        textOver +
                                        ' ' +
                                        this.formatNumber(Number(taxIncl.totalBeforeTax)) +
                                        ' (' +
                                        this.formatNumber(taxIncl.totalWithTax) +
                                        ')'
                                    ).padEnd(col1 + col2, ' ') +
                                    this.formatNumber(Number(taxIncl.totalTax)).padStart(
                                        col3,
                                        ' '
                                    ) +
                                    '\n'
                            });
                        }
                    });
                }
                commandsArray.push({ append: line + '\n' });
                commandsArray.push({ appendAlignment: 'Center' });
                commandsArray.push({
                    append: ' >> Ticket #: ' + dataObj.ticketCount + ' <<\n'
                });
                commandsArray.push({
                    append: ' >> Order #: ' + dataObj.orderId + ' <<\n'
                });
                commandsArray.push({ append: dataObj.dateStr + '\n\n' });
                commandsArray.push({ append: 'THANK YOU!\n' });
                commandsArray.push({ appendCutPaper: 'PartialCutWithFeed' });
                commandsArray.push({ appendBytes: cs.dot12 }); // reset back to default 12 dot / 7x9

                okToSend = true;
        }

        return okToSend ? commandsArray : [];
    }

    getCatalogGroupQty(reportData: any, group: any) {
        return this.reportsSvc.getCatalogGroupQty(reportData, group);
    }

    getCatalogGroupPrice(reportData: any, group: any) {
        return this.reportsSvc.getCatalogGroupPrice(reportData, group);
    }

    getItemName(reportData: any, productIdStr: any) {
        return this.reportsSvc.getItemName(reportData, productIdStr);
    }

    sumItemQty(reportData: any, productIdStr: any) {
        return this.reportsSvc.sumItemQty(reportData, productIdStr);
    }

    sumItemPrice(reportData: any, productIdStr: any) {
        return this.reportsSvc.sumItemPrice(reportData, productIdStr);
    }

    getStarCurrencySymbolCommands(mode?: string) {
        let commandArr: any;
        const currencySymbol = this.state.venue.country.currency.symbol.text
            ? this.state.venue.country.currency.symbol.text
            : '$';
        if (mode === 'StarGraphic') {
            commandArr = currencySymbol;
        } else {
            commandArr = [];
            switch (currencySymbol) {
                case '€':
                    commandArr.push({ appendEncoding: 'Windows-1252' });
                    commandArr.push({ appendInternational: 'Spain' });
                    commandArr.push({ appendBytes: [0xd5] });
                    break;

                case '£':
                    commandArr.push({ appendEncoding: 'US-ASCII' });
                    commandArr.push({ appendInternational: 'UK' });
                    commandArr.push({ appendBytes: [0x9c] });
                    break;

                default:
                    commandArr.push({ appendEncoding: 'UTF-8' });
                    commandArr.push({ append: currencySymbol });
            }
        }

        return commandArr;
    }

    getDataObj(type: string, item: any, reportDateStr: string) {
        let dataObj: any = {};
        const date =
            item.order != null && item.order.order_date != null
                ? item.order.order_date
                : new Date().toString().replace(/-/g, '/');
        const dateStr = this.datePipe.transform(date, 'EEE MMM d, h:mm:ss a');
        const country = this.state.venue.country.code === 'US' ? 'US' : 'UK';
        let dateStrShort: string;
        let timeStrShort: string;

        if (country === 'US') {
            dateStrShort = this.datePipe.transform(date, 'MM-dd-yy');
            timeStrShort = this.datePipe.transform(date, 'H:mm');
        } else {
            dateStrShort = this.datePipe.transform(date, 'dd-MM-yy');
            timeStrShort = this.datePipe.transform(date, 'H:mm');
        }

        let address: string;
        let addrArr: any;
        let phone: string;
        let phoneNumber: any;
        switch (type) {
            case 'station':
                //  console.log(JSON.stringify(item));
                let sToken = item.seatToken ? item.seatToken : null;
                if (sToken === null) {
                    sToken =
                        !!item.order && !!item.order.seat ? item.order.seat.token : null;
                }
                if (sToken === null) {
                    sToken =
                        !!item.order && !!item.order.table
                            ? item.order.table.tableToken
                            : '';
                }
                dataObj = {
                    orderId: item.order.order_id != null ? item.order.order_id : '',
                    seatToken: sToken,
                    serviceMode: item.serviceMode != null ? item.serviceMode : '',
                    tableName:
                        item.order.table.tableCode != null
                            ? item.order.table.tableCode
                            : item.seatToken
                                ? item.seatToken
                                : '',
                    fullName: item.order.full_name != null ? item.order.full_name : '',
                    waiterName:
                        item.order.waiter.name != null ? item.order.waiter.name : 'STAFF',
                    loginAreaAction:
                        item.order.seat.loginAreaAction != null
                            ? item.order.seat.loginAreaAction
                            : '',
                    ticketCount:
                        item.order.ticketCount != null ? item.order.ticketCount : '',
                    extraInfo: item.order.extraInfo != null ? item.order.extraInfo : '',
                    dateStrShort: this.datePipe.transform(date, 'MMM d, y'),
                    timeStrShort: this.datePipe.transform(date, 'h:mm:ss a')
                };
                break;

            case 'request':
                dataObj = {
                    tableId: item.title !== undefined ? item.title : '',
                    caption: item.caption !== undefined ? item.caption : '',
                    request: item.text !== undefined ? item.text : '',
                    dateStr: dateStr
                };
                break;

            case 'shift-report':
                address = this.state.venue.address;
                addrArr = address.split(',');
                phoneNumber =
                    this.state.venue.phone != null && this.state.venue.phone !== ''
                        ? parsePhoneNumber(
                            this.state.venue.phone + '',
                            this.state.venue.country.code as CountryCode
                        )
                        : null;
                phone = phoneNumber != null ? phoneNumber.formatNational() : null;

                const dateStrg = this.datePipe.transform(
                    new Date(),
                    'EEE MMM d, h:mm:ss a'
                );

                dataObj = {
                    venueName: this.state.venue.restaurantName,
                    venueAddrLine1: addrArr[0] ? addrArr[0] : '',
                    venueAddrLine2: addrArr[1] ? addrArr[1] : '',
                    venueAddrLine3: addrArr[2]
                        ? addrArr[2] + ', ' + addrArr[3] + ' ' + addrArr[4]
                        : '',
                    venueAddrLine4: phone,
                    reportDateStr: reportDateStr,
                    shiftName: item.shiftName ? item.shiftName : '',
                    dateStr: dateStrg,
                    waiterName: item.shiftReport.clerkStr,
                    totalOrders: !!item.orders ? item.orders.length : 0,
                    totalSales: item.shiftReport.totalSales,
                    totalTax: item.shiftReport.totalTax,
                    taxInclTotals: item.shiftReport.taxInclTotals,
                    totalTips: item.shiftReport.totalTips,
                    totalDiscount: item.shiftReport.totalDiscount,
                    totalFees: item.shiftReport.totalFees,
                    subtotal: item.shiftReport.subtotal,
                    orders: !!item.orders ? item.orders : [],
                    mode: item.mode,
                    currencySymbol: this.state.venue.country.currency.symbol.text
                        ? this.state.venue.country.currency.symbol.text
                        : '$',
                    currencySymbolLocation: this.state.venue.country.currency.symbol
                        .location
                        ? this.state.venue.country.currency.symbol.location
                        : 'before'
                };

                // console.log('SHIFTRPT');
                // console.log(JSON.stringify(item.shiftReport));
                // console.log('DATAOBJ');
                // console.log(JSON.stringify(dataObj));

                break;

            case 'sales-summary':
            case 'report':
                address = this.state.venue.address;
                addrArr = address.split(',');
                phoneNumber =
                    this.state.venue.phone != null && this.state.venue.phone !== ''
                        ? parsePhoneNumber(
                            this.state.venue.phone + '',
                            this.state.venue.country.code as CountryCode
                        )
                        : null;
                phone = phoneNumber != null ? phoneNumber.formatNational() : null;
                const grandTotal = this.reportsSvc.getGrandTotal(item);
                const tipTotal = this.reportsSvc.getTipsTotal(item);
                const subtotal = this.reportsSvc.calcSubTotal(item);
                const discountTotal = this.reportsSvc.getDiscountTotal(item);
                const feeTotal = this.reportsSvc.getFeeTotal(item);
                const feeLabel = this.reportsSvc.getFeeLabel(item);
                const taxTotals = this.reportsSvc.getTaxRateTotals(item);
                const taxInclTotals = this.reportsSvc.getTaxRateIncludedTotals(item);
                const netFeeTotal = this.reportsSvc.sumNetAndFees(item);
                const productIdObj = this.reportsSvc.getProductIdObj(item);
                const totalItems = this.reportsSvc.getTotalItems(item);
                const totalOrders = this.reportsSvc.getTotalOrders(item);
                const catalogGroups = this.state.venue.catalogGroups;
                const totalRefunds = this.reportsSvc.getRefundTotal(item);
                const totalComp = this.reportsSvc.getCompTotal(item);
                const taxRateTotalSum = Number(this.reportsSvc.sumTaxRateTotals(item));
                const refunds = this.reportsSvc.getRefundsArr(item);

                dataObj = {
                    payMethodTotals: item[0].payMethodTotals,
                    venueName: this.translationSvc.translateContent(
                        this.state.venue.restaurantName
                    ),
                    venueAddrLine1: addrArr[0] ? addrArr[0] : '',
                    venueAddrLine2: addrArr[1] ? addrArr[1] : '',
                    venueAddrLine3: addrArr[2]
                        ? addrArr[2] + ', ' + addrArr[3] + ' ' + addrArr[4]
                        : '',
                    venueAddrLine4: phone,
                    reportDateStr: reportDateStr,
                    subtotal: subtotal,
                    feeLabel: feeLabel,
                    feeTotal: feeTotal,
                    taxTotals: taxTotals,
                    taxInclTotals: taxInclTotals,
                    netFeeTotal: netFeeTotal,
                    taxRateTotalSum: taxRateTotalSum,
                    tipTotal: tipTotal,
                    totalRefunds: totalRefunds,
                    totalComp: totalComp,
                    grandTotal: grandTotal,
                    discountTotal: discountTotal,
                    catalogGroups: catalogGroups,
                    productIdObj: productIdObj,
                    totalItems: totalItems,
                    totalOrders: totalOrders,
                    dateStr: dateStr,
                    refunds: refunds,
                    currencySymbol: this.state.venue.country.currency.symbol.text
                        ? this.state.venue.country.currency.symbol.text
                        : '$',
                    currencySymbolLocation: this.state.venue.country.currency.symbol
                        .location
                        ? this.state.venue.country.currency.symbol.location
                        : 'before'
                };

                console.log(JSON.stringify(dataObj));
                break;

            case 'receipt':
            default:
                address = this.state.venue.address;
                addrArr = address.split(',');
                const taxInclTotal = this.reportsSvc.getTaxRateIncludedTotalsByNotification(
                    item
                );
                phoneNumber =
                    this.state.venue.phone != null && this.state.venue.phone !== ''
                        ? parsePhoneNumber(
                            this.state.venue.phone + '',
                            this.state.venue.country.code as CountryCode
                        )
                        : null;
                phone = phoneNumber != null ? phoneNumber.formatNational() : null;
                const tableId =
                    item.title != null
                        ? item.title
                        : !!item.order && item.order.seat.seatNumber !== undefined
                            ? item.order.seat.seatNumber
                            : '';
                const seatToken =
                    item.tableToken != null
                        ? item.tableToken
                        : item.seatToken != null
                            ? item.seatToken
                            : '';
                dataObj = {
                    serviceMode: item.serviceMode != null ? item.serviceMode : '',
                    seatToken: seatToken,
                    orderId: item.order.order_id != null ? item.order.order_id : '',
                    tableId: tableId,
                    payMethod: item.method != null ? item.method : '',
                    cardType: item.order.card_type != null ? item.order.card_type : '',
                    lastFour: item.order.last_four != null ? item.order.last_four : '',
                    name: item.order.full_name != null ? item.order.full_name : '',
                    tip: item.order.tip != null ? item.order.tip : 0,
                    convenienceFee:
                        item.order.convenienceFee != null ? item.order.convenienceFee : 0,
                    convenienceFeeLabel: this.state.venue.venueSettings
                        .CONVENIENCE_FEE_LABEL,
                    extraFee: item.order.extraFee != null ? item.order.extraFee : 0,
                    extraFeeLabel:
                        item.order.extraFeeLabel != null ? item.order.extraFeeLabel : '',
                    tax: item.order.tax != null ? item.order.tax : 0,
                    taxLabel: this.state.venue.venueSettings.TAX_DESCRIPTION,
                    taxInclTotals: taxInclTotal,
                    taxInclLabel: this.state.venue.venueSettings.TAX_DESCRIPTION,
                    printLogo:
                        this.state.venue.venueSettings.ENABLE_PRINT_LOGO &&
                            this.state.venue.venueSettings.ENABLE_PRINT_LOGO === 'on'
                            ? true
                            : false,
                    discount: item.order.discount != null ? item.order.discount : 0,
                    total: item.order.total ? item.order.total : 0,
                    dateStr: dateStr,
                    currencySymbol: this.state.venue.country.currency.symbol.text
                        ? this.state.venue.country.currency.symbol.text
                        : '$',
                    currencySymbolLocation: this.state.venue.country.currency.symbol
                        .location
                        ? this.state.venue.country.currency.symbol.location
                        : 'before',
                    waiterName:
                        item.order.waiter.name != null ? item.order.waiter.name : 'STAFF',
                    venueName: this.translationSvc.translateContent(
                        this.state.venue.restaurantName
                    ),
                    address: address,
                    addrArr: addrArr,
                    venueAddrLine1: addrArr[0] ? addrArr[0] : '',
                    venueAddrLine2: addrArr[1] ? addrArr[1] : '',
                    venueAddrLine3: addrArr[2]
                        ? addrArr[2] + ', ' + addrArr[3] + ' ' + addrArr[4]
                        : '',
                    venueAddrLine4: phone,
                    extraInfo: item.order.extraInfo != null ? item.order.extraInfo : '',
                    ticketCount:
                        item.order.ticketCount != null ? item.order.ticketCount : 0,
                    compAmount: item.compAmount != null ? item.compAmount : 0,
                    compReason: item.compReason != null ? item.compReason : ''
                };
        }

        return dataObj;
    }

    getCommandSet(printerType: string) {
        const cs: any = {};
        if (printerType === 'impact') {
            cs.dot7x9 = [0x1b, 0x4d]; // 7x9 default
            cs.dot5x9x2 = [0x1b, 0x50]; // 5x9 (2 pulse = 1)
            cs.dot5x9x3 = [0x1b, 0x3a]; // 5x9 (3 pulse = 1)

            // compatibilitty
            cs.dot12 = cs.dot7x9;

            cs.height2x = [0x1b, 0x68, 0x1]; // enable 2x high
            cs.height3x = [0x1b, 0x68, 0x2]; // enable 3x high
            cs.heightCancel = [0x1b, 0x68, 0x0]; // cancel expanded high

            cs.wide2x = [0x1b, 0x57, 0x1]; // enable 2x wide
            cs.wide3x = [0x1b, 0x57, 0x2]; // enable 3x wide
            cs.wideCancel = [0x1b, 0x57, 0x0]; // cancel expanded wide

            cs.red = [0x1b, 0x34]; // enable red print (inverted on single color models)
            cs.redCancel = [0x1b, 0x35]; // cancel red print

            cs.reset = [0x1b, 0x06, 0x18]; // real-time reset
        } else {
            cs.dot12 = [0x1b, 0x4d]; // 12 dot
            // cs.dot14 = [0x1B, 0x67]; // 14 dot
            cs.dot15 = [0x1b, 0x50]; // 15 dot
            cs.dot16 = [0x1b, 0x3a]; // 16 dot

            cs.invert = [0x1b, 0x34]; // inverted text
            cs.invertCancel = [0x1b, 0x35]; // cancel inverted

            cs.height2x = [0x1b, 0x68, 0x1]; // enable 2x high
            cs.height3x = [0x1b, 0x68, 0x2]; // enable 3x high
            cs.height4x = [0x1b, 0x68, 0x3]; // enable 4x high
            cs.height5x = [0x1b, 0x68, 0x4]; // enable 5x high
            cs.height6x = [0x1b, 0x68, 0x5]; // enable 6x high
            cs.heightCancel = [0x1b, 0x68, 0x0]; // cancel expanded high

            cs.wide2x = [0x1b, 0x57, 0x1]; // enable 2x wide
            cs.wide3x = [0x1b, 0x57, 0x2]; // enable 3x wide
            cs.wide4x = [0x1b, 0x57, 0x3]; // enable 4x wide
            cs.wide5x = [0x1b, 0x57, 0x4]; // enable 5x wide
            cs.wide6x = [0x1b, 0x57, 0x5]; // enable 6x wide
            cs.wideCancel = [0x1b, 0x57, 0x0]; // cancel expanded wide

            cs.reset = [0x1b, 0x06, 0x18]; // real-time reset
            cs.leftMargin = [0x1b, 0x6c, 1]; // set left margin
        }

        return cs;
    }

    /**
     * QuickPrinter Print
     */

    printReceiptToQuickPrinter(item: any, printer: any) {
        const date =
            item.order.order_date != null
                ? item.order.order_date
                : new Date().toString().replace(/-/g, '/');
        const thisDateStr = this.datePipe.transform(date, 'EEE MMM d, h:mm:ss a');

        const col1 = 23;
        const col2 = 5;
        const col3 = 12;
        const colh = 20;

        this.sendExtraCutCommand = printer[0] && printer[0].sendExtraCut != null ? printer[0].sendExtraCut : false;

        if (item) {
           const country = this.state.venue.country.code === 'US' ? 'US' : 'UK';

            const serviceMode = item.serviceMode != null ? item.serviceMode : '';
            const seatToken =
                item.tableToken != null
                    ? item.tableToken
                    : item.seatToken != null
                        ? item.seatToken
                        : '';
            const orderId = item.order.order_id ? item.order.order_id : '';
            const ticketCount = item.order.ticketCount ? item.order.ticketCount : 0;
            const tableId =
                item.title != null
                    ? item.title
                    : !!item.order && item.order.seat.seatNumber !== undefined
                        ? item.order.seat.seatNumber
                        : '';
            const payMethod = item.method ? item.method : '';
            const cardType = item.order.card_type ? item.order.card_type : '';
            const lastFour = item.order.last_four ? item.order.last_four : '';
            const name = item.order.full_name ? item.order.full_name : '';
            const tax = item.order.tax ? item.order.tax : 0;
            const tip = item.order.tip ? item.order.tip : 0;
            const convenienceFee = item.order.convenienceFee
                ? item.order.convenienceFee
                : 0;
            const convenienceFeeLabel = this.state.venue.venueSettings
                .CONVENIENCE_FEE_LABEL;
            const extraFee = item.order.extraFee != null ? item.order.extraFee : 0;
            const extraFeeLabel =
                item.order.extraFeeLabel != null
                    ? item.order.extraFeeLabel
                    : 'Addl Fee';
            const taxLabel = this.state.venue.venueSettings.TAX_DESCRIPTION;
            const printLogo =
                this.state.venue.venueSettings.ENABLE_PRINT_LOGO &&
                    this.state.venue.venueSettings.ENABLE_PRINT_LOGO === 'on'
                    ? true
                    : false;
            const discount = item.order.discount ? item.order.discount : 0;
            const total = item.order.total ? item.order.total : 0;
            const currencySymbol = this.state.venue.country.currency.symbol.text
                ? this.state.venue.country.currency.symbol.text
                : '$';
            const currencySymbolLocation = this.state.venue.country.currency.symbol
                .location
                ? this.state.venue.country.currency.symbol.location
                : 'before';
            const waiterName = !!item.order.waiter ? item.order.waiter.name : 'STAFF';
            const venueName = this.translationSvc.translateContent(
                this.state.venue.restaurantName
            );
            const address = this.state.venue.address;
            const addrArr = address.split(',');
            const venueAddrLine1 = addrArr[0] ? addrArr[0] : null;
            const venueAddrLine2 = addrArr[1] ? addrArr[1] : null;
            const venueAddrLine3 = addrArr[2]
                ? addrArr[2] + ', ' + addrArr[3] + ' ' + addrArr[4]
                : null;

            const phoneNumber =
                this.state.venue.phone != null && this.state.venue.phone !== ''
                    ? parsePhoneNumber(
                        this.state.venue.phone + '',
                        this.state.venue.country.code as CountryCode
                    )
                    : null;
            const venueAddrLine4 =
                phoneNumber != null ? phoneNumber.formatNational() : null;

            const taxInclTotals = this.reportsSvc.getTaxRateIncludedTotalsByNotification(
                item
            );
            const taxInclLabel = this.state.venue.venueSettings.TAX_DESCRIPTION;
            const extraInfo =
                item.order.extraInfo != null ? item.order.extraInfo : '';
            const compAmount = item.compAmount != null ? item.compAmount : 0;
            const compReason = item.compReason != null ? item.compReason : '';

            const printjob = tableId + '-' + ticketCount.toFixed(0).padStart(3, '0');

            const alias = printer[0].alias ? printer[0].alias : false;
            let productStr = '';
            const products = item.products ? item.products : {};
            let variantStr = '';
            let optionsStr = '';
            let optionsPrice = 0;
            let productName = '';
            let productPrice = 0;
            let productTotal = 0;

            products.forEach((product: any) => {
                if (product.variant_name != null) {
                    variantStr = product.variant_name + ' ';
                } else {
                    variantStr = '';
                }

                optionsStr = '';
                if (
                    typeof product.options !== 'undefined' &&
                    product.options.length > 0
                ) {
                    optionsPrice = 0;
                    product.options.forEach((option: any) => {
                        if (
                            option.tags &&
                            option.tags.indexOf('print_group_name') !== -1 &&
                            option.option_name && option.items && option.items.length > 0
                        ) {
                            optionsStr += '  ' + this.productsSvc.formatOptionName(option.option_name) + '\n';
                        }
                        if (
                            typeof option.items !== 'undefined' &&
                            option.items.length > 0
                        ) {
                            option.items.forEach((oitem: any) => {
                                optionsPrice = optionsPrice + Number(oitem.item_price);
                                //optionsStr += '<NORMAL>  - ' + oitem.item_name + '<BR>';
                                optionsStr +=
                                    '<NORMAL>  - ' +
                                    oitem.item_name +
                                    ' +' +
                                    this.formatNumber(Number(oitem.item_price)) +
                                    '<BR>';
                            });
                        }
                    });
                }

                productPrice = (Number(product.price) + optionsPrice) * product.qty;
                productTotal = Number(productTotal) + Number(productPrice);
                productName = product.product_name.substring(0, 20);

                productStr +=
                    '<NORMAL><BOLD>' +
                    (product.qty + ' ' + variantStr + productName).padEnd(
                        col1 + col2,
                        ' '
                    ) +
                    this.formatNumber(Number(productPrice)).padStart(col3, ' ') +
                    '<BR>';
                productStr += optionsStr;

                if (product.instructions) {
                    productStr += '<NORMAL>  - ' + product.instructions + '<BR>';
                }

                if (product.extra_info && product.extra_info.guestName) {
                    productStr +=
                        '<NORMAL>  - Guest Name - ' + product.extra_info.guestName + '<BR>';
                }
            });

            let prnt = alias
                ? '<PRINTER alias="' + alias + '" printjob_name="' + printjob + '">'
                : '';

            if (printLogo) {
                prnt += '<LOGO2>'; // Epson TM-88IV
            } else {
                prnt += '<NORMAL><BOLD><CENTER>' + venueName + '  <BR>';
            }

            if (venueAddrLine1 != null && venueAddrLine1.trim().length > 0) {
                prnt += '<NORMAL><CENTER>' + venueAddrLine1 + '  <BR>';
            }
            if (venueAddrLine2 != null && venueAddrLine2.trim().length > 0) {
                prnt += '<NORMAL><CENTER>' + venueAddrLine2 + '  <BR>';
            }
            if (venueAddrLine3 != null && venueAddrLine3.trim().length > 0) {
                prnt += '<NORMAL><CENTER>' + venueAddrLine3 + '  <BR>';
            }
            if (venueAddrLine4 != null && venueAddrLine4.trim().length > 0) {
                prnt += '<NORMAL><CENTER>' + venueAddrLine4 + '  <BR>';
            }

            prnt += '<DLINE><BR>';
            prnt +=
                ('Server: ' + waiterName).padEnd(col1 + col2 - 1, ' ') +
                ('Order: ' + orderId).padStart(col3, ' ') +
                '<BR>';
            prnt += '<LINE>';
            prnt += name.padEnd(col1 + col2 - 1, ' ') + tableId.slice(0, 13).padStart(col3, ' ') + '<BR>';

            if (extraInfo && item.serviceMode === 'delivery') {
                const fullAddress = extraInfo.address || '';
                const addressComponents = fullAddress.split(', ');
                addressComponents.pop();
                const [street, city, stateZip] = addressComponents;
                prnt += '<NORMAL>' + street + '<BR>';
                prnt += '<NORMAL>' + city + ', ' + stateZip + '<BR>';
                if (extraInfo.phone) {
                    const parsedPhone = parsePhoneNumber(
                        extraInfo.phone + '',
                        this.state.venue.country.code as CountryCode
                    );
                    prnt += '<NORMAL>' + parsedPhone.formatNational() + '<BR>';
                }
            }

            if (extraInfo && seatToken === 'curbside' &&
                extraInfo.address != null
            ) {
                prnt += extraInfo.address + '<BR>';
            }

            let orderTypeStrings = this.getSeatTokenStrings(item);
            let printTypeStr = orderTypeStrings.printTypeStr
                ? orderTypeStrings.printTypeStr
                : 'Pick-Up';

            if (!!extraInfo && extraInfo.dayTimeStr != null) {
                prnt += printTypeStr + '<BOLD> ' + extraInfo.dayTimeStr + '<BR>';
            }

            prnt += '<DLINE><BR>';
            prnt += '<CENTER>>> ORDER SETTLED << <BR>';

            prnt += productStr + '<BR>';

            if (productTotal > 0) {
                prnt +=
                    '<NORMAL>' +
                    'SUB-TOTAL:'.padEnd(col1 + col2, ' ') +
                    this.formatNumber(Number(productTotal)).padStart(col3, ' ') +
                    '<BR>';
            }

            if (tip > 0) {
                prnt +=
                    '<NORMAL>' +
                    'TIP:'.padEnd(col1 + col2, ' ') +
                    this.formatNumber(Number(tip)).padStart(col3, ' ') +
                    '<BR>';
            }

            if (discount > 0) {
                prnt +=
                    '<NORMAL>' +
                    'DISCOUNT:'.padEnd(col1 + col2, ' ') +
                    this.formatNumber(Number(discount)).padStart(col3, ' ') +
                    '<BR>';
            }

            if (convenienceFee > 0) {
                const feeLabel =
                    convenienceFeeLabel != null ? convenienceFeeLabel : 'Convenience Fee';
                prnt +=
                    '<NORMAL>' +
                    (feeLabel.toUpperCase() + ':').padEnd(col1 + col2, ' ') +
                    this.formatNumber(Number(convenienceFee)).padStart(col3, ' ') +
                    '<BR>';
            }

            if (extraFee > 0) {
                prnt +=
                    '<NORMAL>' +
                    (extraFeeLabel.toUpperCase() + ':').padEnd(col1 + col2, ' ') +
                    this.formatNumber(Number(extraFee)).padStart(col3, ' ') +
                    '<BR>';
            }

            if (tax > 0) {
                prnt +=
                    '<NORMAL>' +
                    (taxLabel.toUpperCase() + ':').padEnd(col1 + col2, ' ') +
                    this.formatNumber(Number(tax)).padStart(col3, ' ') +
                    '<BR>';
            }

            if (compAmount > 0) {
                prnt +=
                    '<NORMAL>' +
                    'COMPLIMENTARY:'.padEnd(col1 + col2, ' ') +
                    this.formatNumber(Number(compAmount * -1)).padStart(col3, ' ') +
                    '<BR>';
            }

            prnt +=
                '<NORMAL>' + '============'.padStart(col1 + col2 + col3, ' ') + '<BR>';

            if (currencySymbolLocation === 'after') {
                prnt +=
                    '<NORMAL><BOLD>' +
                    'TOTAL:'.padEnd(col1 + col2, ' ') +
                    (
                        this.formatNumber(Number(total - compAmount)) + currencySymbol
                    ).padStart(col3, ' ') +
                    '<BR><BR>';
            } else {
                prnt +=
                    '<NORMAL><BOLD>' +
                    'TOTAL:'.padEnd(col1 + col2, ' ') +
                    (
                        currencySymbol + this.formatNumber(Number(total - compAmount))
                    ).padStart(col3, ' ') +
                    '<BR><BR>';
            }

            let payStr = null;
            if (item.order.method.type === 'redirect') {
                if (item.order.method.code === 'Paypal') {
                    payStr =
                        '<NORMAL>' +
                        'PayPal TEND:'.padEnd(col1 + col2, ' ') +
                        this.formatNumber(Number(total)).padStart(col3, ' ') +
                        '<BR>';
                }
                if (item.order.method.code === 'Ccvideal') {
                    payStr =
                        '<NORMAL>' +
                        'iDEAL Bank TEND:'.padEnd(col1 + col2, ' ') +
                        this.formatNumber(Number(total)).padStart(col3, ' ') +
                        '<BR>';
                }
            } else {
                if (
                    item.order.method.code === 'Tabpayments' ||
                    item.order.method.code === 'Cashpayments'
                ) {
                    const tendStr = item.paid ? 'Order PAID:' : 'Open Order:';
                    payStr =
                        '<NORMAL>' +
                        tendStr.padEnd(col1 + col2, ' ') +
                        this.formatNumber(Number(total - compAmount)).padStart(col3, ' ') +
                        '<BR>';
                } else {
                    payStr =
                        '<NORMAL>' +
                        (cardType + ' ' + lastFour + ' TEND:').padEnd(col1 + col2, ' ') +
                        this.formatNumber(Number(total - compAmount)).padStart(col3, ' ') +
                        '<BR>';
                }
            }

            prnt += payStr;

            prnt +=
                '<NORMAL>' + '============'.padStart(col1 + col2 + col3, ' ') + '<BR>';
            prnt +=
                '<NORMAL>' +
                'CHANGE:'.padEnd(col1 + col2, ' ') +
                this.formatNumber(0.0).padStart(col3, ' ') +
                '<BR><BR>';

            if (taxInclTotals != null && country !== 'US') {
                const textVat = this.translationSvc.translateStatic('text_vat');
                const textVatExcluded = this.translationSvc.translateStatic(
                    'text_excluded_vat'
                );
                const textOver = this.translationSvc.translateStatic('text_over');

                const grandTotalBeforeTax = this.roundValue(
                    taxInclTotals.reduce((oTotal: number, opt: any) => {
                        return oTotal + Number(opt.totalBeforeTax);
                    }, 0)
                );

                prnt += '<NORMAL>' + textVat + '\n';
                prnt +=
                    '<NORMAL>' +
                    textVatExcluded.padEnd(col1 + col2, ' ') +
                    this.formatNumber(Number(grandTotalBeforeTax)).padStart(col3, ' ') +
                    '<BR>';

                // { taxRateLabel: textVat, taxRate: rate, totalBeforeTax: totalBeforeTax, totalWithTax: totalWithTax, totalTax: totalTax }

                taxInclTotals.forEach((taxIncl: any) => {
                    if (Number(taxIncl.totalTax) > 0) {
                        prnt +=
                            '<NORMAL>' +
                            (
                                Number(taxIncl.taxRate).toString() +
                                '% ' +
                                textOver +
                                ' ' +
                                this.formatNumber(Number(taxIncl.totalBeforeTax)) +
                                ' (' +
                                this.formatNumber(taxIncl.totalWithTax) +
                                ')'
                            ).padEnd(col1 + col2, ' ') +
                            this.formatNumber(Number(taxIncl.totalTax)).padStart(col3, ' ') +
                            '<BR>';
                    }
                });
            }
            prnt += '<LINE>';

            prnt += '<CENTER><NORMAL> >> Ticket #: ' + ticketCount + ' <<<BR>';
            prnt += '<CENTER><NORMAL> >> Order #: ' + orderId + ' <<<BR>';
            prnt +=
                '<CENTER><NORMAL>' +
                thisDateStr +
                '<BR><BR>' +
                '<CENTER>THANK YOU!<BR><BR><BR><BR>' +
                '<CUT>';

            if (this.sendExtraCutCommand) {
                prnt += this.extraCutCommand;
            }

            // send to quickprinter
            this.printQuickPrinterSend(prnt, printer, item);
        }
    }

    printRequestToQuickPrinter(item: any, printer: any) {
        const date = item.date != null ? item.date : new Date().toString().replace(/-/g, '/');
        const dateStr = this.datePipe.transform(date, 'EEE MMM d, h:mm:ss a');
        this.sendExtraCutCommand = printer && printer.sendExtraCut != null ? printer.sendExtraCut : false;

        if (item) {
            const alias = printer[0].alias ? printer[0].alias : false;
            const tableId = item.title ? item.title : '';
            const caption = item.caption;
            const request = item.text;

            let prnt = alias ? '<PRINTER alias="' + alias + '">' : '';
            prnt +=
                '<BIG><CENTER>SERVER NEEDED' +
                '<BR><BR>' +
                '<BIG><BOLD>' +
                tableId +
                '<MEDIUM3><BOLD>: ' +
                request +
                '<BR><BR>' +
                '<BIG><CENTER>* ' +
                caption +
                ' *<BR><BR>' +
                '<CENTER><MEDIUM1>' +
                dateStr +
                '<BR>' +
                '<CENTER><LINE><BR><BR><BR>' +
                '<CUT>';

            if (this.sendExtraCutCommand) {
                prnt += this.extraCutCommand;
            }

            // send to quickprinter
            this.printQuickPrinterSend(prnt, printer, item);
        }
    }

    printStationsToQuickPrinter(item: any, printer: any, printCount: number) {
        let output = null;
        output = this.formatQPMode(item, printer, printCount, null);
        if (output !== '') {
            printer.status = 'success';
            // //printer.errors = {code: 403, text: 'Unknown Error'};
            printer.auto = printer.auto ? 1 : 0;

            //  this.notifySvc.setStationPrintedStatus(item, printer).subscribe(() => {
            //    this.printQuickPrinterSend(output, printer, item);
            //  });
            this.printQuickPrinterSend(output, printer, item);
        }

        // send same output to mapped printers
        // setTimeout(() => {
        //   const mapTo = printer.mapTo !== undefined ? printer.mapTo : null;
        //   if (mapTo != null) {
        //     let thisMappedPrinterArr: any;
        //     let thisMappedPrinter: any;
        //     let mapId: number;
        //     const storedPrinters = this.storeSvc.get(this.state.venue.token + '_servi-printers');
        //     for (let k = 0; k < mapTo.length; k++) {
        //       // create a closure to preserve the value of "k"
        //       ((k: number) => {
        //         setTimeout(() => {
        //           mapId = mapTo[k] != null ? mapTo[k] : 0;
        //           thisMappedPrinterArr = storedPrinters.filter((pitem: any) => pitem.id === mapId);
        //           thisMappedPrinter = thisMappedPrinterArr[0] !== undefined ? thisMappedPrinterArr[0] : null;
        //           if (thisMappedPrinter != null) {
        //             output = this.formatQPMode(item, printer, printCount, thisMappedPrinter);
        //             if (output !== '') {
        //               const textEncoded = encodeURI(output);
        //               window.location.href = 'quickprinter://' + textEncoded;
        //             }
        //           }
        //         }, k * 4000);
        //       }).bind(this)(k);
        //     }
        //   }
        // }, 4000);
    }

    formatQPMode(
        item: any,
        printer: any,
        printCount: number,
        mappedPrinter: any
    ) {
        let alias = '';
        if (mappedPrinter != null) {
            alias = mappedPrinter.alias;
        } else {
            alias = printer.alias;
        }

        if (alias === '') {
            return alias;
        }

        this.sendExtraCutCommand = printer && printer.sendExtraCut != null ? printer.sendExtraCut : false;

        const col = 20;
        const date =
            item.order.order_date != null
                ? item.order.order_date
                : new Date().toString().replace(/-/g, '/');
        const dateStr = this.datePipe.transform(date, 'MMM d, y');
        const timeStr = this.datePipe.transform(date, 'h:mm:ss a');
        const orderId = item.order.order_id != null ? item.order.order_id : '';
        const extraInfo = item.order.extraInfo != null ? item.order.extraInfo : '';
        const seatName =
            item.title != null
                ? item.title
                : !!item.order && item.order.seat.seatNumber !== undefined
                    ? item.order.seat.seatNumber
                    : '';
        const fullName = item.order.full_name != null ? item.order.full_name : '';
        const waiterName =
            item.order.waiter.name != null ? item.order.waiter.name : 'STAFF';
        const ticketCount =
            item.order.ticketCount != null ? item.order.ticketCount : '';
        const products = item.products ? item.products : {};
        // set print as line item flag
        if (!!products) {
            products.map((product: any) => {
                const thisProduct = this.productsSvc.getById(product.product_id);
                if (!!product.options && thisProduct) {
                    product.options.map((option: any) => {
                        option.print_as_line_item = 0;
                        const thisOption = thisProduct.options.find(
                            (opt: any) => opt.id === option.option_id
                        );
                        if (!!thisOption) {
                            option.print_as_line_item =
                                thisOption.print_as_line_item !== undefined
                                    ? thisOption.print_as_line_item
                                    : 0;
                        }
                    });
                }
            });
        }

        let productStr = '';
        let variantName = '';
        let productCategory: any;

        console.log('CAT-ARRAY');
        console.log(this.catArr);

        let printCnt = 0;
        if (this.catArr) {
            this.catArr.forEach((category: any, index: number) => {
                let printCategory = false;
                products.forEach((product: any) => {
                    if (
                        product.printer_id === printer.id &&
                        printer.alias != null &&
                        product.categoryId === category.id
                    ) {
                        printCategory = true;
                    }
                });

                if (printCategory) {
                    if (printCnt > 0) productStr += '<LINE>';
                    productStr += '<MEDIUM3><BOLD><CENTER>' + category.label + '<BR>';
                    productStr += '<LINE><BR>';
                    printCnt++;
                }

                products.forEach((product: any) => {
                    if (
                        product.printer_id === printer.id &&
                        printer.alias != null &&
                        product.categoryId === category.id
                    ) {
                        variantName =
                            product.variant_name != null
                                ? product.variant_name.toUpperCase() + ' '
                                : null;
                        productStr +=
                            '<MEDIUM3><BOLD>' +
                            (Number(product.qty).toFixed(0) +
                                ' ' +
                                product.product_name.toUpperCase()) +
                            '<BR>';

                        if (variantName) {
                            productStr += '<MEDIUM2><BOLD>     ' + variantName + '<BR>';
                        }

                        if (product.options != null && product.options.length > 0) {
                            product.options.forEach((option: any) => {
                                if (
                                    option.tags &&
                                    option.tags.indexOf('print_group_name') !== -1 &&
                                    option.option_name && option.items && option.items.length > 0
                                ) {
                                    productStr += '<MEDIUM2><BOLD>  ' + this.productsSvc.formatOptionName(option.option_name) + '\n';
                                }
                                if (option.items != null && option.items.length > 0) {
                                    option.items.forEach((oitem: any) => {
                                        if (
                                            option.print_as_line_item !== undefined &&
                                            option.print_as_line_item === 1
                                        ) {
                                            productStr +=
                                                '<MEDIUM3><BOLD>' +
                                                (Number(product.qty).toFixed(0) +
                                                    ' ' +
                                                    oitem.item_name.toUpperCase()) +
                                                '<BR>';
                                        } else {
                                            productStr +=
                                                '<MEDIUM2><BOLD>     ' + oitem.item_name + '<BR>';
                                        }
                                    });
                                }
                            });
                        }

                        if (product.instructions) {
                            productStr +=
                                '<MEDIUM2><BOLD>   > ' + product.instructions + '<BR>';
                        }

                        if (product.extra_info && product.extra_info.guestName) {
                            productStr +=
                                '<MEDIUM2><BOLD>   Guest Name - ' +
                                product.extra_info.guestName +
                                '<BR>';
                        }

                        productStr += '<BR>';
                    }
                });
            });
        } else {
            products.forEach((product: any) => {
                if (product.printer_id === printer.id && printer.alias != null) {
                    variantName =
                        product.variant_name != null
                            ? product.variant_name.toUpperCase() + ' '
                            : null;
                    productStr +=
                        '<MEDIUM3><BOLD>' +
                        (Number(product.qty).toFixed(0) +
                            ' ' +
                            product.product_name.toUpperCase()) +
                        '<BR>';

                    if (variantName) {
                        productStr += '<MEDIUM2><BOLD>     ' + variantName + '<BR>';
                    }

                    if (product.options != null && product.options.length > 0) {
                        product.options.forEach((option: any) => {
                            if (
                                option.tags &&
                                option.tags.indexOf('print_group_name') !== -1 &&
                                option.option_name && option.items && option.items.length > 0
                            ) {
                                productStr += '<MEDIUM2><BOLD>  ' + this.productsSvc.formatOptionName(option.option_name) + '\n';
                            }
                            if (option.items != null && option.items.length > 0) {
                                option.items.forEach((oitem: any) => {
                                    if (
                                        option.print_as_line_item !== undefined &&
                                        option.print_as_line_item === 1
                                    ) {
                                        productStr +=
                                            '<MEDIUM3><BOLD>' +
                                            (Number(product.qty).toFixed(0) +
                                                ' ' +
                                                oitem.item_name.toUpperCase()) +
                                            '<BR>';
                                    } else {
                                        productStr +=
                                            '<MEDIUM2><BOLD>     ' + oitem.item_name + '<BR>';
                                    }
                                });
                            }
                        });
                    }

                    if (product.instructions) {
                        productStr +=
                            '<MEDIUM2><BOLD>     ' + product.instructions + '<BR>';
                    }

                    // print option as line item after special instructions and any other options
                    // if (product.options != null && product.options.length > 0) {
                    //   product.options.forEach((option: any) => {
                    //     if (option.items != null && option.items.length > 0) {
                    //       option.items.forEach((oitem: any) => {
                    //         if (option.print_as_line_item !== undefined && option.print_as_line_item === 1) {
                    //           productStr += '<MEDIUM3><BOLD>' + (Number(product.qty).toFixed(0) + ' ' + oitem.item_name.toUpperCase()) + '<BR>';
                    //         }
                    //       });
                    //     }
                    //   });
                    // }

                    if (product.extra_info && product.extra_info.guestName) {
                        productStr +=
                            '<MEDIUM2><BOLD>   Guest Name - ' +
                            product.extra_info.guestName +
                            '<BR>';
                    }

                    productStr += '<BR>';
                }
            });
        }

        if (productStr === '') {
            return productStr;
        }

        let prnt: string;
        let orderTypeStrings = this.getSeatTokenStrings(item);
        let orderTypeStr = orderTypeStrings.orderTypeStr
            ? orderTypeStrings.orderTypeStr
            : null;
        let tableName = orderTypeStrings.tableName
            ? orderTypeStrings.tableName
            : 'Unknown';
        let printTypeStr = orderTypeStrings.printTypeStr
            ? orderTypeStrings.printTypeStr
            : 'Pick-Up';

        const printjob = seatName + '-' + ticketCount.toFixed(0).padStart(3, '0');

        prnt = '<PRINTER alias="' + alias + '" printjob_name="' + printjob + '">';
        prnt += '<BIG><BOLD><CENTER>SER.VI<BR><BR>';
        if (printCount > 1) {
            prnt +=
                '<MEDIUM3><BOLD><CENTER>*** ' +
                this.textDuplicate.toUpperCase() +
                ' ***<BR><BR>';
        }

        if (mappedPrinter != null) {
            prnt += '<NORMAL><CENTER>*** ' + mappedPrinter.name + ' Copy ***<BR>';
        } else {
            prnt += '<NORMAL><CENTER>*** ' + printer.name + ' Copy ***<BR>';
        }
        prnt += '<DLINE><BR>';

        if (mappedPrinter != null) {
            prnt += 'Server: ' + waiterName + '<BR>';
            // prnt += ('Server: ' + waiterName).padEnd(col, ' ') + ('Loc: ' + mappedPrinter.id).padStart(col - 5, ' ') + '<BR>';
        } else {
            prnt += 'Server: ' + waiterName + '<BR>';
            // prnt += ('Server: ' + waiterName).padEnd(col, ' ') + ('Loc: ' + printer.id).padStart(col - 5, ' ') + '<BR>';
        }

        prnt += '<MEDIUM3><BOLD><CENTER>' + orderTypeStr.toUpperCase() + '<BR>';
        prnt += dateStr.padEnd(col, ' ') + timeStr.padStart(20, ' ') + '<BR>';
        prnt += '<DLINE><BR>';

        // prnt += fullName + '<BR>';

        if (
            !!extraInfo &&
            item.seatToken === 'delivery' &&
            extraInfo.addrLine1 != null
        ) {
            prnt += extraInfo.addrLine1 + '<BR>';
        }

        if (
            !!extraInfo &&
            item.seatToken === 'delivery' &&
            extraInfo.addrLine3 != null
        ) {
            prnt += extraInfo.addrLine3 + '<BR>';
        }

        if (!!extraInfo && extraInfo.dayTimeStr != null) {
            prnt += printTypeStr + '<BOLD> ' + extraInfo.dayTimeStr + '<BR>';
        }

        prnt += productStr;

        prnt += '<LINE>';
        if (ticketCount !== '') {
            prnt +=
                '<CENTER><MEDIUM3>Order #' +
                ticketCount.toFixed(0).padStart(3, '0') +
                '<BR>';
            // prnt += '<LINE>';
        }
        prnt += '<CENTER>Order ID: ' + orderId + '<BR>';
        prnt += '<LINE>';

        prnt += '<BOLD><CENTER><MEDIUM3>' + fullName + '<BR>';
        prnt += '<CENTER><MEDIUM2>' + seatName + '<BR>';

        if (
            !!extraInfo &&
            item.seatToken === 'delivery' &&
            extraInfo.addrLine1 != null
        ) {
            prnt += extraInfo.addrLine1 + '<BR>';
        }
        if (
            !!extraInfo &&
            item.seatToken === 'delivery' &&
            extraInfo.addrLine3 != null
        ) {
            prnt += extraInfo.addrLine3 + '<BR>';
        }

        if (!!extraInfo && extraInfo.dayTimeStr != null) {
            prnt += '<CENTER>' + printTypeStr + '<BOLD> ' + extraInfo.dayTimeStr + '<BR>';
        }

        if (printCount > 1) {
            prnt += '<BR>';
            prnt +=
                '<MEDIUM3><BOLD><CENTER>*** ' +
                this.textDuplicate.toUpperCase() +
                ' ***<BR>';
        }
        prnt += '<BR><BR>';
        prnt += '<CUT>';

        if (this.sendExtraCutCommand) {
            prnt += this.extraCutCommand;
        }

        return prnt;
    }

    printReportToQuickPrinter(
        item: any,
        printer: any,
        code: any,
        reportDateStr: any
    ) {

        this.sendExtraCutCommand = printer && printer.sendExtraCut != null ? printer.sendExtraCut : false;

        const col1 = 23;
        const col2 = 5;
        const col3 = 12;
        const alias = printer[0].alias ? printer[0].alias : false;
        const currencySymbol = this.state.venue.country.currency.symbol.text
            ? this.state.venue.country.currency.symbol.text
            : '$';
        const currencySymbolLocation = this.state.venue.country.currency.symbol
            .location
            ? this.state.venue.country.currency.symbol.location
            : 'before';
        const date =
            item.date != null ? item.date : new Date().toString().replace(/-/g, '/');
        const dateStr = this.datePipe.transform(date, 'EEE MMM d, h:mm:ss a');
        const venueName = this.translationSvc.translateContent(
            this.state.venue.restaurantName
        );
        const address = this.state.venue.address;
        const addrArr = address.split(',');
        const venueAddrLine1 = addrArr[0] ? addrArr[0] : '';
        const venueAddrLine2 = addrArr[1] ? addrArr[1] : '';
        const venueAddrLine3 = addrArr[2]
            ? addrArr[2] + ', ' + addrArr[3] + ' ' + addrArr[4]
            : '';
        const phoneNumber =
            this.state.venue.phone != null && this.state.venue.phone !== ''
                ? parsePhoneNumber(
                    this.state.venue.phone + '',
                    this.state.venue.country.code as CountryCode
                )
                : null;
        const venueAddrLine4 =
            phoneNumber != null ? phoneNumber.formatNational() : '';

        switch (code) {
            case 'shift-report':
                if (item) {
                    const shiftName = item.shiftName ? item.shiftName : '';
                    const shiftDate = item.shiftReport.shiftDateStr
                        ? item.shiftReport.shiftDateStr
                        : '';
                    const waiterName = item.shiftReport.clerkStr;
                    const totalOrders = !!item.orders ? item.orders.length : 0;
                    const totalSales = item.shiftReport.totalSales;
                    const totalTax = item.shiftReport.totalTax;
                    const taxInclTotals = item.shiftReport.taxInclTotals;
                    const totalTips = item.shiftReport.totalTips;
                    const totalDiscount = item.shiftReport.totalDiscount;
                    const totalFees = item.shiftReport.totalFees;
                    const orders = !!item.orders ? item.orders : [];
                    const mode = item.mode;

                    let prnt = alias ? '<PRINTER alias="' + alias + '">' : '';

                    prnt += '<NORMAL><BOLD><CENTER>' + venueName + '  <BR>';

                    if (venueAddrLine1.trim() != '') {
                        prnt += '<NORMAL><CENTER>' + venueAddrLine1 + '  <BR>';
                    }
                    if (venueAddrLine2.trim() != '') {
                        prnt += '<NORMAL><CENTER>' + venueAddrLine2 + '  <BR>';
                    }
                    if (venueAddrLine3.trim() != '') {
                        prnt += '<NORMAL><CENTER>' + venueAddrLine3 + '  <BR>';
                    }
                    if (venueAddrLine4.trim() != '') {
                        prnt += '<NORMAL><CENTER>' + venueAddrLine4 + '  <BR><BR>';
                    }
                    prnt += '<BR><DLINE><BR>';
                    prnt += '<BOLD><CENTER>*** SHIFT REPORT ***<BR>';
                    prnt += '<BOLD><CENTER>' + shiftName + '<BR>';
                    prnt += '<LINE>';

                    prnt += '<NORMAL>Station: <BOLD>' + waiterName + '<BR>';
                    prnt += '<NORMAL>Start: <BOLD>' + shiftDate + '<BR>';
                    prnt += '<LINE>';

                    // total orders
                    prnt +=
                        '<BOLD>' +
                        'Total Orders'.padEnd(col1 + col2, ' ') +
                        Number(totalOrders).toFixed(0).padStart(col3, ' ') +
                        '<BR><BR>';

                    if (!!taxInclTotals) {
                        taxInclTotals.forEach((taxIncl: any) => {
                            if (Number(taxIncl.totalTax.replace(/\D/g, '')) > 0) {
                                const len =
                                    col1 +
                                    col2 +
                                    col3 -
                                    (
                                        taxIncl.taxRateLabel +
                                        '(' +
                                        taxIncl.totalTax +
                                        ' @ ' +
                                        taxIncl.taxRate +
                                        '%)'
                                    ).length;
                                prnt +=
                                    '<NORMAL>' +
                                    (taxIncl.taxRateLabel +
                                        '(' +
                                        taxIncl.totalTax +
                                        ' @ ' +
                                        taxIncl.taxRate +
                                        '%)') +
                                    'included'.padStart(len, ' ') +
                                    '<BR>';
                            }
                        });
                    }

                    prnt +=
                        '<NORMAL>' +
                        'Tax'.padEnd(col1 + col2, ' ') +
                        totalTax.padStart(col3, ' ') +
                        '<BR>';
                    prnt +=
                        '<NORMAL>' +
                        'Tips'.padEnd(col1 + col2, ' ') +
                        totalTips.padStart(col3, ' ') +
                        '<BR>';
                    prnt +=
                        '<NORMAL>' +
                        'Fees'.padEnd(col1 + col2, ' ') +
                        totalFees.padStart(col3, ' ') +
                        '<BR>';
                    prnt +=
                        '<NORMAL>' +
                        'Discounts'.padEnd(col1 + col2, ' ') +
                        totalDiscount.padStart(col3, ' ') +
                        '<BR>';
                    prnt +=
                        '<NORMAL>' +
                        '============'.padStart(col1 + col2 + col3, ' ') +
                        '<BR>';
                    prnt +=
                        '<BOLD>' +
                        'TOTAL:'.padEnd(col1 + col2, ' ') +
                        totalSales.padStart(col3, ' ') +
                        '<BR><BR>';

                    if (mode === 'detail') {
                        prnt += '<DLINE><BR>';
                        prnt += '<BOLD><CENTER>*** TRANSACTIONS ***<BR>';
                        prnt += '<DLINE><BR>';

                        if (!!orders) {
                            prnt +=
                                '<BOLD>' +
                                'OrderID'.padEnd(col3) +
                                'Table'.padEnd(col3) +
                                'Time'.padEnd(col3) +
                                'Amount'.padStart(col3 - 1) +
                                '<BR>';
                            let seatObj: any = {};
                            let seatToken: string = '';
                            let created: any;
                            orders.map((order: any) => {
                                try {
                                    seatObj = JSON.parse(order.seat);
                                    seatToken = !!seatObj ? seatObj.token : '';
                                } catch (e) { }

                                created = new Date(order.created);

                                prnt += '<NORMAL>' + order.id.toFixed(0).padEnd(col3);
                                prnt += '<NORMAL>' + seatToken.padEnd(col3);
                                prnt +=
                                    '<NORMAL>' +
                                    this.datePipe.transform(created, 'HH:mm:ss').padEnd(col3);
                                prnt += '<NORMAL>' + order.total.padStart(col3 - 1) + '<BR>';
                            });
                        }
                        prnt += '<DLINE><BR>';
                        prnt += '<BOLD><CENTER>*** END TRANSACTIONS ***<BR>';
                    }
                    prnt += '<DLINE><BR>';
                    prnt += '<CENTER>' + dateStr + '<BR><BR><BR>';
                    prnt += '<CUT>';

                    if (this.sendExtraCutCommand) {
                        prnt += this.extraCutCommand;
                    }

                    // send to quickprinter
                    this.printQuickPrinterSend(prnt, printer, item);
                }
                break;

            default:
                // sales summary
                if (item) {
                    // format the receipt; 40 cols
                    // const col1 = 23;
                    // const col2 = 5;
                    // const col3 = 12;
                    // const alias = printer[0].alias ? printer[0].alias : false;
                    // const currencySymbol = this.state.venue.country.currency.symbol.text ? this.state.venue.country.currency.symbol.text : '$';
                    // const currencySymbolLocation = this.state.venue.country.currency.symbol.location
                    //   ? this.state.venue.country.currency.symbol.location
                    //   : 'before';
                    // const date = item.date != null ? item.date : new Date().toString().replace(/-/g, "/");
                    // const dateStr = this.datePipe.transform(date, 'EEE MMM d, h:mm:ss a');
                    const grandTotal = this.reportsSvc.getGrandTotal(item);
                    const tipTotal = this.reportsSvc.getTipsTotal(item);
                    const subtotal = this.reportsSvc.calcSubTotal(item);
                    const discountTotal = this.reportsSvc.getDiscountTotal(item);
                    const feeTotal = this.reportsSvc.getFeeTotal(item);
                    const feeLabel = this.reportsSvc.getFeeLabel(item);
                    const taxTotals = this.reportsSvc.getTaxRateTotals(item);
                    const taxInclTotals = this.reportsSvc.getTaxRateIncludedTotals(item);
                    const netFeeTotal = this.reportsSvc.sumNetAndFees(item);
                    const taxRateTotalSum = this.reportsSvc.sumTaxRateTotals(item);
                    const productIdObj = this.reportsSvc.getProductIdObj(item);
                    const totalItems = this.reportsSvc.getTotalItems(item);
                    const totalOrders = this.reportsSvc.getTotalOrders(item);
                    const catalogGroups = this.state.venue.catalogGroups;
                    const totalRefunds = this.reportsSvc.getRefundTotal(item);
                    const totalComp = this.reportsSvc.getCompTotal(item);
                    const refunds = this.reportsSvc.getRefundsArr(item);
                    const payMethodTotals = item[0].payMethodTotals;

                    let prnt = alias ? '<PRINTER alias="' + alias + '">' : '';

                    prnt += '<BIG><CENTER>SALES SUMMARY' + '<BR><BR>';
                    prnt += '<NORMAL><CENTER>' + reportDateStr + '<BR>';
                    prnt += '<LINE>';
                    prnt += '<NORMAL><BOLD><CENTER>SALES AND TAXES SUMMARY<BR>';
                    prnt += '<LINE>';

                    // subtotal
                    prnt +=
                        '<NORMAL>' +
                        'Total Net Sales'.padEnd(col1 + col2, ' ') + Number(subtotal).toFixed(2).padStart(col3, ' ') + '<BR>';

                    // fees
                    if (Number(feeTotal) > 0) {
                        prnt +=
                            '<NORMAL>' +
                            feeLabel.padEnd(col1 + col2, ' ') +
                            Number(feeTotal).toFixed(2).padStart(col3, ' ') +
                            '<BR>';
                    }

                    // refund
                    if (Number(totalRefunds) > 0) {
                        prnt +=
                            '<NORMAL>' +
                            'Refunds'.padEnd(col1 + col2, ' ') +
                            (Number(totalRefunds) * -1).toFixed(2).padStart(col3, ' ') +
                            '<BR>';
                    }

                    // comp
                    if (Number(totalComp) > 0) {
                        prnt +=
                            '<NORMAL>' +
                            'Complimentary'.padEnd(col1 + col2, ' ') +
                            Number(totalComp).toFixed(2).padStart(col3, ' ') +
                            '<BR>';
                    }

                    // tips
                    if (Number(tipTotal) > 0) {
                        prnt +=
                            '<NORMAL>' +
                            'Tips Collected'.padEnd(col1 + col2, ' ') +
                            Number(tipTotal).toFixed(2).padStart(col3, ' ') +
                            '<BR>';
                    }

                    // tax
                    taxTotals.forEach((tax: any) => {
                        if (Number(tax.taxTotal) > 0) {
                            prnt +=
                                '<NORMAL>' +
                                (
                                    tax.taxRateLabel +
                                    ' (' +
                                    Number(tax.taxRate).toFixed(2) +
                                    '%)'
                                ).padEnd(col1 + col2, ' ') +
                                Number(tax.taxRate).toFixed(2).padStart(col3, ' ') +
                                '<BR>';
                        }
                    });

                    // tax included
                    let taxRateInclLabel: string;
                    taxInclTotals.forEach((taxIncl: any) => {
                        if (Number(taxIncl.totalTax) > 0) {
                            taxRateInclLabel =
                                taxIncl.taxRateLabel != null ? taxIncl.taxRateLabel : 'VAT';
                            prnt +=
                                '<NORMAL>' +
                                (
                                    taxRateInclLabel +
                                    ' (' +
                                    Number(taxIncl.totalTax).toFixed(2) +
                                    ' @ ' +
                                    Number(taxIncl.taxRate).toFixed(2) +
                                    '%)'
                                ).padEnd(col1 + col2, ' ') +
                                'included'.padStart(col3, ' ') +
                                '<BR>';
                        }
                    });

                    // totals
                    prnt += '<LINE>';
                    if (currencySymbolLocation === 'after') {
                        // total collected
                        const gTotal = grandTotal - totalRefunds;
                        prnt +=
                            '<NORMAL><BOLD>' +
                            'Total Collected'.padEnd(col1 + col2, ' ') +
                            (Number(gTotal).toFixed(2) + currencySymbol).padStart(
                                col3,
                                ' '
                            ) +
                            '<BR>';
                        // // total net + fees
                        // if (feeTotal > 0) {
                        //   prnt +=
                        //     '<NORMAL><BOLD>' +
                        //     feeLabel.padEnd(col1 + col2, ' ') +
                        //     (Number(netFeeTotal).toFixed(2) + currencySymbol).padStart(col3 - 1, ' ') +
                        //     '<BR>';
                        // }
                        // total tax
                        if (Number(taxRateTotalSum) > 0) {
                            prnt +=
                                '<NORMAL><BOLD>' +
                                'Total Tax'.padEnd(col1 + col2, ' ') +
                                (Number(taxRateTotalSum).toFixed(2) + currencySymbol).padStart(
                                    col3,
                                    ' '
                                ) +
                                '<BR>';
                        }
                    } else {
                        // total collected
                        prnt +=
                            '<NORMAL><BOLD>' +
                            'Total Collected'.padEnd(col1 + col2, ' ') +
                            (currencySymbol + Number(grandTotal).toFixed(2)).padStart(
                                col3,
                                ' '
                            ) +
                            '<BR>';
                        // // total net + fees
                        // if (feeTotal > 0) {
                        //   prnt +=
                        //     '<NORMAL><BOLD>' +
                        //     ('Total Net + ' + feeLabel).padEnd(col1 + col2, ' ') +
                        //     (currencySymbol + Number(netFeeTotal).toFixed(2)).padStart(col3, ' ') +
                        //     '<BR>';
                        // }
                        // total tax
                        if (Number(taxRateTotalSum) > 0) {
                            prnt +=
                                '<NORMAL><BOLD>' +
                                'Total Tax'.padEnd(col1 + col2, ' ') +
                                (currencySymbol + Number(taxRateTotalSum).toFixed(2)).padStart(col3, ' ') + '<BR>';
                        }
                    }
                    prnt += '<BR>';

                    // sales by paymethod
                    prnt += '<NORMAL><BOLD><CENTER>SALES BY PAYMENT METHOD<BR>';
                    prnt += '<LINE>';
                    prnt += (payMethodTotals && payMethodTotals.bank) ? ('<NORMAL>' + 'Bank'.padEnd(col1 + col2, ' ') + payMethodTotals.bank.toFixed(2).padStart(col3, ' ') + '<BR>') : '';
                    prnt += (payMethodTotals && payMethodTotals.card) ? ('<NORMAL>' + 'Credit Card'.padEnd(col1 + col2, ' ') + payMethodTotals.card.toFixed(2).padStart(col3, ' ') + '<BR>') : '';
                    prnt += (payMethodTotals && payMethodTotals.cash) ? ('<NORMAL>' + 'CASH'.padEnd(col1 + col2, ' ') + payMethodTotals.cash.toFixed(2).padStart(col3, ' ') + '<BR>') : '';
                    prnt += (payMethodTotals && payMethodTotals.tab) ? ('<NORMAL>' + 'Tab'.padEnd(col1 + col2, ' ') + payMethodTotals.tab.toFixed(2).padStart(col3, ' ') + '<BR>') : '';
                    prnt += (payMethodTotals && payMethodTotals.terminal) ? ('<NORMAL>' + 'Terminal'.padEnd(col1 + col2, ' ') + payMethodTotals.terminal.toFixed(2).padStart(col3, ' ') + '<BR>') : '';
                    prnt += '<BR>';

                    // orders summary
                    prnt += '<NORMAL><BOLD><CENTER>ORDERS SUMMARY<BR>';
                    prnt += '<LINE>';
                    prnt +=
                        '<NORMAL>' +
                        'Total Orders'.padEnd(col1 + col2, ' ') +
                        Number(totalOrders).toFixed(0).padStart(col3, ' ') +
                        '<BR>';
                    prnt +=
                        '<NORMAL>' +
                        'Average Ticket'.padEnd(col1 + col2, ' ') +
                        Number(subtotal / totalOrders)
                            .toFixed(2)
                            .padStart(col3, ' ') +
                        '<BR><BR>';

                    // refunds
                    if (refunds.length > 0) {
                        prnt += '<NORMAL><BOLD><CENTER>REFUNDS<BR>';
                        prnt +=
                            '<NORMAL><BOLD>' +
                            'Order ID'.padEnd(col2, ' ') +
                            'Refund Date'.padStart(col1 - 10, ' ') +
                            'Amount'.padStart(col3 + 8, ' ') +
                            '<BR>';
                        prnt += '<LINE>';
                        refunds.forEach((refund: any) => {
                            prnt +=
                                '<NORMAL>' +
                                refund.id.toString().padEnd(col2, ' ') +
                                refund.refunded.padStart(col1, ' ') +
                                this.formatNumber(Number(refund.refund_amount)).padStart(
                                    col3,
                                    ' '
                                ) +
                                '<BR>';
                            prnt +=
                                '<NORMAL>> ' +
                                refund.refund_reason
                                    .replace('Ref:', ' ')
                                    .replace(/<[^>]*>?/gm, '') +
                                '<BR><BR>';
                        });
                        prnt += '<LINE>';
                        prnt +=
                            '<NORMAL><BOLD>' +
                            'Total Refunds'.padEnd(col1 + col2, ' ') +
                            (Number(totalRefunds) * -1).toFixed(2).padStart(col3, ' ') +
                            '<BR>';
                    }

                    // discounts
                    if (Number(discountTotal) > 0) {
                        prnt += '<BR>';
                        prnt += '<NORMAL><BOLD><CENTER>DISCOUNTS<BR>';
                        prnt +=
                            '<NORMAL><BOLD>' +
                            'Discount Name'.padEnd(col1 + col2, ' ') +
                            'Amount'.padStart(col3, ' ') +
                            '<BR>';
                        prnt += '<LINE>';
                        prnt +=
                            '<NORMAL>' +
                            'First Visit Discount'.padEnd(col1 + col2, ' ') +
                            Number(discountTotal).toFixed(2).padStart(col3, ' ') +
                            '<BR>';
                        prnt += '<LINE>';

                        // total discounts
                        if (currencySymbolLocation === 'after') {
                            prnt +=
                                '<NORMAL><BOLD>' +
                                'Total Discounts'.padEnd(col1 + col2, ' ') +
                                (Number(discountTotal).toFixed(2) + currencySymbol).padStart(
                                    col3,
                                    ' '
                                ) +
                                '<BR>';
                        } else {
                            prnt +=
                                '<NORMAL><BOLD>' +
                                'Total Discounts'.padEnd(col1 + col2, ' ') +
                                (currencySymbol + Number(discountTotal).toFixed(2)).padStart(
                                    col3,
                                    ' '
                                ) +
                                '<BR>';
                        }
                    }

                    // sales by category
                    prnt += '<BR>';
                    prnt += '<NORMAL><BOLD><CENTER>SALES BY CATEGORY<BR>';
                    prnt +=
                        '<NORMAL><BOLD>' +
                        'Category'.padEnd(col1, ' ') +
                        'Qty'.padStart(col2, ' ') +
                        'Net Sales'.padStart(col3, ' ') +
                        '<BR>';
                    prnt += '<LINE>';
                    catalogGroups.forEach((group: any) => {
                        prnt +=
                            group.label.substring(0, col1).padEnd(col1, ' ') +
                            Number(this.getCatalogGroupQty(item, group))
                                .toFixed(0)
                                .padStart(col2, ' ') +
                            Number(this.getCatalogGroupPrice(item, group))
                                .toFixed(2)
                                .padStart(col3, ' ') +
                            '<BR>';
                    });
                    prnt += '<LINE>';
                    if (currencySymbolLocation === 'after') {
                        prnt +=
                            '<NORMAL><BOLD>' +
                            'Total Net Sales'.padEnd(col1, ' ') +
                            Number(totalItems).toFixed(0).padStart(col2, ' ') +
                            (Number(subtotal).toFixed(2) + currencySymbol).padStart(
                                col3,
                                ' '
                            ) +
                            '<BR>';
                    } else {
                        prnt +=
                            '<NORMAL><BOLD>' +
                            'Total Net Sales'.padEnd(col1, ' ') +
                            Number(totalItems).toFixed(0).padStart(col2, ' ') +
                            (currencySymbol + Number(subtotal).toFixed(2)).padStart(
                                col3,
                                ' '
                            ) +
                            '<BR>';
                    }

                    // sales by item
                    prnt += '<BR>';
                    prnt += '<NORMAL><BOLD><CENTER>SALES BY ITEM<BR>';
                    prnt +=
                        '<NORMAL><BOLD>' +
                        'Name'.padEnd(col1, ' ') +
                        'Qty'.padStart(col2, ' ') +
                        'Net Sales'.padStart(col3, ' ') +
                        '<BR>';
                    prnt += '<LINE>';
                    productIdObj.forEach((productId: any) => {
                        prnt +=
                            this.getItemName(item, productId)
                                .substring(0, col1)
                                .padEnd(col1, ' ') +
                            Number(this.sumItemQty(item, productId))
                                .toFixed(0)
                                .padStart(col2, ' ') +
                            Number(this.sumItemPrice(item, productId))
                                .toFixed(2)
                                .padStart(col3, ' ') +
                            '<BR>';
                    });
                    prnt += '<LINE>';
                    if (currencySymbolLocation === 'after') {
                        prnt +=
                            '<NORMAL><BOLD>' +
                            'Total Net Sales'.padEnd(col1, ' ') +
                            Number(totalItems).toFixed(0).padStart(col2, ' ') +
                            (Number(subtotal).toFixed(2) + currencySymbol).padStart(
                                col3,
                                ' '
                            ) +
                            '<BR>';
                    } else {
                        prnt +=
                            '<NORMAL><BOLD>' +
                            'Total Net Sales'.padEnd(col1, ' ') +
                            Number(totalItems).toFixed(0).padStart(col2, ' ') +
                            (currencySymbol + Number(subtotal).toFixed(2)).padStart(
                                col3,
                                ' '
                            ) +
                            '<BR>';
                    }

                    prnt += '<LINE>';
                    prnt += '<CENTER>' + dateStr + '<BR><BR><BR>';
                    prnt += '<CUT>';

                    if (this.sendExtraCutCommand) {
                        prnt += this.extraCutCommand;
                    }

                    // send to quickprinter
                    this.printQuickPrinterSend(prnt, printer, item);
                }
        }
    }

    sendPing = async (delay?: number): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            try {
                // const text = '<NOCUT>';
                const text = '<AWAKE>';
                const textEncoded = encodeURI(text);
                console.log('BLUETOOTH AWAKE', textEncoded);
                if (window.cordova) {
                    window.location.href = 'quickprinter://' + textEncoded;
                }
                setTimeout(() => {
                    resolve();
                }, delay ? delay : 1000);
            } catch (error) {
              console.log('BLUETOOTH ERROR', error);
                reject(error);
            }
        });
    };

    printQuickPrinterSend(output: any, printer: any, item: any) {
        this.bgModeSvc.moveToForeground();
        setTimeout(() => {
            const isReceipt =
                printer[0] && printer[0].type === 'receipt' ? true : false;
            if (item) {
                console.log('OUTPUT', output);
                this.platform.ready().then(() => {
                    const textEncoded = encodeURI(output);
                    window.location.href = 'quickprinter://' + textEncoded;
                });
                if (isReceipt) {
                    this.notifySvc.setPrintedStatus(item).subscribe((res: any) => { });
                    // this.printerSuccess.emit({ result: 1, printer: printer, text: 'success' });
                    this.isPrinting.emit({ status: false });
                } else {
                    printer.auto = printer.auto ? 1 : 0;
                    this.notifySvc
                        .setStationPrintedStatus(item, printer, true)
                        .subscribe((res: any) => { });
                    this.printerSuccess.emit({
                        result: 1,
                        printer: printer,
                        text: 'success'
                    });
                    this.isPrinting.emit({ status: false });
                }
            }
        }, 500);
    }

    printQuickPrinterSendIntent(output: any, printer: any, item: any) {
        this.bgModeSvc.moveToForeground();
        setTimeout(() => {
            const options = {
                action: this.webIntent.ACTION_SEND,
                package: 'pe.diegoveloper.printerserverapp',
                type: 'text/plain',
                requestCode: 1,
                extras: { 'android.intent.extra.TEXT': output }
            };

            const isReceipt =
                printer[0] && printer[0].type === 'receipt' ? true : false;

            this.webIntent.startActivity(options).then(
                (response) => {
                    //  alert('response: ' + JSON.stringify(response));

                    if (item) {
                        if (isReceipt) {
                            this.notifySvc.setPrintedStatus(item).subscribe((res: any) => {
                                this.isPrinting.emit({ status: false });
                            });
                        } else {
                            if (response && response === 'OK') {
                                printer.status = 'success';
                                this.printerSuccess.emit({
                                    result: 1,
                                    printer: printer,
                                    text: 'success'
                                });
                                this.isPrinting.emit({ status: false });
                            } else {
                                printer.status = 'error';
                                this.isPrinting.emit({ status: false });
                                this.printerError.emit({
                                    result: 0,
                                    printer: printer,
                                    text: 'error'
                                });
                            }
                            if (isReceipt) {
                                this.notifySvc
                                    .setPrintedStatus(item)
                                    .subscribe((res: any) => { });
                            } else {
                                printer.auto = printer.auto ? 1 : 0;
                                this.notifySvc
                                    .setStationPrintedStatus(item, printer, true)
                                    .subscribe((res: any) => { });
                            }
                            this.isPrinting.emit({ status: false });
                        }
                    }
                },
                (error) => {
                    if (isReceipt) {
                        this.notifySvc.setPrintedStatus(item).subscribe((res: any) => { });
                    } else {
                        printer.status = 'error';
                        printer.auto = printer.auto ? 1 : 0;
                        this.notifySvc
                            .setStationPrintedStatus(item, printer, false)
                            .subscribe((res: any) => { });
                    }
                    this.isPrinting.emit({ status: false });
                    // this.printerError.emit({
                    //   result: 0,
                    //   printer: printer,
                    //   text: 'error',
                    // });
                }
            );
        }, 500);
    }

    testQPWeb() {
        let prnt = "<PRINTER alias='E1'> HELLO WORLD! <BR><BR><BR><CUT>";
        const textEncoded = encodeURI(prnt);
        window.location.href = 'quickprinter://' + textEncoded;
    }

    testIntent(): void {
        const options = {
            action: this.webIntent.ACTION_PICK,
            url: 'content://com.android.contacts/contacts',
            requestCode: 1
        };
        this.webIntent.startActivityForResult(options).then(
            (response) => {
                alert('SUCCESS: ' + JSON.stringify(response));
            },
            (error) => {
                alert('ERROR: ' + JSON.stringify(error));
            }
        );
    }

    printQuickPrinterTestIntent(printer?: any): void {
        this.sendExtraCutCommand = printer && printer.sendExtraCut != null ? printer.sendExtraCut : false;

        let prnt = "<PRINTER alias='E1'> HELLO WORLD! <BR><BR><BR><CUT>";
        if (this.sendExtraCutCommand) {
            prnt += this.extraCutCommand;
        }
        const options = {
            //action: 'pe.diegoveloper.printing',
            action: this.webIntent.ACTION_SEND,
            type: 'text/plain',
            requestCode: 1,
            extras: { 'android.intent.extra.TEXT': prnt }
        };

        this.webIntent.startActivityForResult(options).then(
            (response) => {
                alert('SUCCESS: ' + JSON.stringify(response));
            },
            (error) => {
                alert('ERROR: ' + JSON.stringify(error));
            }
        );
    }

    printQuickPrinterTest(alias?: string): void {
        //const alias = 'E1';

        let prnt = '<PRINTER alias="' + alias + '">';

        prnt += '<SMALL>Small Text<BR>';
        prnt += '<SMALL><BOLD>Small Bold<BR>';

        prnt += '<NORMAL>Normal Text<BR>';
        prnt += '<NORMAL><BOLD>Normal Bold<BR>';

        prnt += '<MEDIUM1>Medium1 Text<BR>';
        prnt += '<MEDIUM1><BOLD>Medium1 Bold<BR>';

        prnt += '<MEDIUM2>Medium2 Text<BR>';
        prnt += '<MEDIUM2><BOLD>Medium2 Bold<BR>';

        prnt += '<MEDIUM3>Medium3 Text<BR>';
        prnt += '<MEDIUM3><BOLD>Medium3 Bold<BR>';

        prnt += '<BIG>Big Text<BR>';
        prnt += '<BIG><BOLD>Big Bold<BR>';

        prnt += '<LINE><BR><BR><CUT>';

        // const textEncoded = encodeURI(prnt);
        // this.printerSuccess.emit({ result: 1, printer: printer, text: 'success' });
        // window.location.href = 'quickprinter://' + textEncoded;

        // return;

        const options = {
            action: this.webIntent.ACTION_SEND,
            package: 'pe.diegoveloper.printerserverapp',
            type: 'text/plain',
            requestCode: 1,
            extras: { 'android.intent.extra.TEXT': prnt }
        };

        // this.options = {
        //   action: this.webIntent.ACTION_PICK,
        //   url: "content://com.android.contacts/contacts",
        //   requestCode: 1
        // }

        this.webIntent.startActivity(options).then(
            (response) => {
                console.log('SUCCESS', JSON.stringify(response));
            },
            (error) => {
                console.log('ERROR', JSON.stringify(error));
            }
        );
    }

    test() {
        // const cpArr = ["CP737","CP772","CP774","CP851","CP852","CP855","CP857","CP858","CP860","CP861","CP862",
        //    "CP863","CP864","CP865","CP869","CP874","CP928","CP932","CP999","CP1001","CP1250","CP1251","CP1252",
        //    "CP2001","CP3001","CP3002","CP3011","CP3012","CP3021","CP3041","CP3840","CP3841","CP3843","CP3845",
        //    "CP3846","CP3847","CP3848","UTF8","Blank"]
        // cpArr.forEach((page, key) => {
        //   commandsArray.push({appendCodePage: 'Blank'});
        //  commandsArray.push({appendCodePage: page});
        // commandsArray.push({appendEncoding:'US-ASCII'});
        // commandsArray.push({appendEncoding:'Windows-1252'});
        //   commandsArray.push({appendEncoding:'UTF-8'});
        // commandsArray.push({appendEncoding:'Shift-JIS'});
        // commandsArray.push({appendEncoding:'Windows-1251'});
        // commandsArray.push({appendEncoding:'GB2312'});
        // commandsArray.push({appendEncoding:'Big5'});
        // commandsArray.push({appendInternational: 'UK'});
        // commandsArray.push({appendInternational: 'USA'});
        // commandsArray.push({appendInternational: 'France'});
        // commandsArray.push({appendInternational: 'Spain'});
        // commandsArray.push({appendInternational: 'Spain2'});
        // commandsArray.push({appendInternational: 'Germany'});
        // commandsArray.push({appendInternational: 'Denmark'});
        // commandsArray.push({appendInternational: 'Sweden'});
        // commandsArray.push({appendInternational: 'Italy'});  // *
        // commandsArray.push({appendInternational: 'Japan'});
        // commandsArray.push({appendInternational: 'Norway'});
        //  commandsArray.push({appendInternational: 'Denmark2'});
        // commandsArray.push({appendInternational: 'LatinAmerica'});
        // commandsArray.push({appendInternational: 'Korea'});
        // commandsArray.push({appendInternational: 'Ireland'});
        // commandsArray.push({appendInternational: 'Legal'});
        //   });
    }

    rasterTest(item: any) {
        const portName = 'TCP:10.0.0.172';

        // var imageObj = {
        //   uri: 'file:///assets/img/logo.png',
        //   width: 576, // options: 384 = 2", 576 = 3", 832 = 4"
        //   cutReceipt: false, // Defaults to true
        //   openCashDrawer: false // Defaults to true
        // };

        // this.starprnt
        // .printImage(portName, 'StarGraphic', imageObj)
        // .then((result: any) => { console.log('SUCCESS', result); })
        // .catch((error: any) => { console.log('FAIL', error); });

        const line = String.fromCharCode(9472).repeat(32);
        const dline = String.fromCharCode(9552).repeat(32);

        const rObj = {
            text:
                '        Star Clothing Boutique\n' +
                '             123 Star Road\n' +
                '           City, State 12345\n' +
                '\n' +
                'Date:MM/DD/YYYY          Time:HH:MM PM\n' +
                dline +
                '\n' +
                'SALE\n' +
                'SKU            Description       Total\n' +
                '300678566      PLAIN T-SHIRT     10.99\n' +
                '300692003      BLACK DENIM       29.99\n' +
                '300651148      BLUE DENIM        29.99\n' +
                '300642980      STRIPED DRESS     49.99\n' +
                '30063847       BLACK BOOTS       35.99\n' +
                '\n' +
                'Subtotal                        156.95\n' +
                'Tax                               0.00\n' +
                '\n' +
                line +
                '\n' +
                'Total                          $156.95\n' +
                dline +
                '\n' +
                'Charge\n' +
                '156.95\n' +
                'Visa XXXX-XXXX-XXXX-0123\n' +
                'Refunds and Exchanges\n' +
                'Within 30 days with receipt\n' +
                'And tags attached\n',
            fontSize: 25, // Defaults to 25
            paperWidth: 576, // Options: 384 = 2", 576 = 3", 832 = 4"
            cutReceipt: true, // Defaults to true
            openCashDrawer: true // Defaults to true
        };

        this.starprnt
            .printRasterReceipt(portName, 'StarGraphic', rObj)
            .then((result: any) => { })
            .catch((error: any) => { });
    }

    roundValue(num: number) {
        return Math.round(num * Math.pow(10, 2)) / Math.pow(10, 2);
    }

    sleep(ms: number) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }
}
