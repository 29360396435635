import { Injectable } from '@angular/core';
import { VenueService } from './venue.service';
import { TranslationService } from '@serviworldwide/core-js/modules/locale';
import { StateService } from '@services/state.service';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  public products: any = [];

  constructor(
    private venueSvc: VenueService,
    private translationSvc: TranslationService,
    private state: StateService
  ) {}

  init() {
    this.products = this.state.venue.products;
  }

  getById(id: any) {
    // console.log('getting product with id ' + id);
    this.products = this.state.venue.products;
    if (this.products) {
      return this.products.find((product: any) => {
        return parseInt(product.id, 10) === parseInt(id, 10);
      });
    } else {
      return null;
    }
  }

  getVariantLowPrice(product: any) {
    const cheapestProduct = product.variants.reduce((cheapestVariantPrice, currentVariant) => {
      return Math.min(cheapestVariantPrice, parseFloat(currentVariant.price));
    }, 99999);

    return cheapestProduct + product.price;
  }

  getProductName(product: any) {
    const id = (product.product_id) ? product.product_id : (product.id) ? product.id : 0;
    let name: string = product.product_name;
    const productLocal = this.getById(id);

    if (!!productLocal && productLocal.name) {
      name = productLocal.name;
      if (productLocal.name.indexOf('|') !== -1) {
         name = this.translationSvc.translateContent(productLocal.name);
         // name = productLocal.name.split('|')[0];
      }
    }

    return name;
  }

  getVariantName(product: any) {
    const id = (product.product_id) ? product.product_id : product.id;
    const vid = (product.variant_id) ? product.variant_id : (product.selectedVariant.id) ? product.selectedVariant.id : 0;
    let variant_name: string = product.variant_name;
    const productLocal = this.getById(id);
    if (productLocal && productLocal.variants) {
      const variant = productLocal.variants.find((v: any) => v.id === vid);
      if (variant && variant.name.indexOf('|') !== -1) {
        variant_name = this.translationSvc.translateContent(variant.name);
        // variant_name = variant.name.split('|')[0];
      }
    }

    return variant_name;
  }

  getOptionItemName(productId: number, optionItem: any) {
    const product = this.getById(productId);
    const itemId = (optionItem.item_id) ? optionItem.item_id : (optionItem.id) ? optionItem.id : 0;
    let name = optionItem.item_name;
    if (product && !!product.options) {
      product.options.forEach((option: any, key) => {
        if (option.items) {
          const opt = option.items.find((item: any) => item.id === itemId);
          if (opt) {
            name = opt.name;
            if (opt.name.indexOf('|') !== -1) {
              name = this.translationSvc.translateContent(opt.name);
              // name = opt.name.split('|')[0];
            }
          }
        }
      });
    }

    return name;
  }

  formatOptionName(option_name: string) {
    let name = option_name;
    if (name.indexOf(':') !== -1) {
      name = name.split(':')[0] + ':';
    }

    return name;
  }

  getProductOptionsTags(productId: number, optionId: number) {
    let tags = '';
    const thisProduct = this.getById(productId);
    if (thisProduct && thisProduct.options) {
      const thisOption = thisProduct.options.find((option: any) => option.options_id === optionId);
      if (thisOption) {
        tags = thisOption.tags !== undefined ? thisOption.tags : null;
      }
    }

    return tags;
  }
}
